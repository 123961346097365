/* All Radix Themes styles */

:root, .light, .light-theme {
  --gray-1: #fcfcfc;
  --gray-2: #f9f9f9;
  --gray-3: #f0f0f0;
  --gray-4: #e8e8e8;
  --gray-5: #e0e0e0;
  --gray-6: #d9d9d9;
  --gray-7: #cecece;
  --gray-8: #bbbbbb;
  --gray-9: #8d8d8d;
  --gray-10: #838383;
  --gray-11: #646464;
  --gray-12: #202020;
  --gray-a1: rgba(0,0,0,0.01176);
  --gray-a2: rgba(0,0,0,0.02353);
  --gray-a3: rgba(0,0,0,0.05882);
  --gray-a4: rgba(0,0,0,0.0902);
  --gray-a5: rgba(0,0,0,0.12157);
  --gray-a6: rgba(0,0,0,0.14902);
  --gray-a7: rgba(0,0,0,0.19216);
  --gray-a8: rgba(0,0,0,0.26667);
  --gray-a9: rgba(0,0,0,0.44706);
  --gray-a10: rgba(0,0,0,0.48627);
  --gray-a11: rgba(0,0,0,0.60784);
  --gray-a12: rgba(0,0,0,0.87451);
  --mauve-1: #fdfcfd;
  --mauve-2: #faf9fb;
  --mauve-3: #f2eff3;
  --mauve-4: #eae7ec;
  --mauve-5: #e3dfe6;
  --mauve-6: #dbd8e0;
  --mauve-7: #d0cdd7;
  --mauve-8: #bcbac7;
  --mauve-9: #8e8c99;
  --mauve-10: #84828e;
  --mauve-11: #65636d;
  --mauve-12: #211f26;
  --mauve-a1: rgba(85,0,85,0.01176);
  --mauve-a2: rgba(43,0,85,0.02353);
  --mauve-a3: rgba(48,0,64,0.06275);
  --mauve-a4: rgba(32,0,54,0.09412);
  --mauve-a5: rgba(32,0,56,0.12549);
  --mauve-a6: rgba(20,0,53,0.15294);
  --mauve-a7: rgba(16,0,51,0.19608);
  --mauve-a8: rgba(8,0,49,0.27059);
  --mauve-a9: rgba(5,0,29,0.45098);
  --mauve-a10: rgba(5,0,25,0.4902);
  --mauve-a11: rgba(4,0,17,0.61176);
  --mauve-a12: rgba(2,0,8,0.87843);
  --slate-1: #fcfcfd;
  --slate-2: #f9f9fb;
  --slate-3: #f0f0f3;
  --slate-4: #e8e8ec;
  --slate-5: #e0e1e6;
  --slate-6: #d9d9e0;
  --slate-7: #cdced6;
  --slate-8: #b9bbc6;
  --slate-9: #8b8d98;
  --slate-10: #80838d;
  --slate-11: #60646c;
  --slate-12: #1c2024;
  --slate-a1: rgba(0,0,85,0.01176);
  --slate-a2: rgba(0,0,85,0.02353);
  --slate-a3: rgba(0,0,51,0.05882);
  --slate-a4: rgba(0,0,45,0.0902);
  --slate-a5: rgba(0,9,50,0.12157);
  --slate-a6: rgba(0,0,47,0.14902);
  --slate-a7: rgba(0,6,46,0.19608);
  --slate-a8: rgba(0,8,48,0.27451);
  --slate-a9: rgba(0,5,29,0.4549);
  --slate-a10: rgba(0,7,27,0.49804);
  --slate-a11: rgba(0,7,20,0.62353);
  --slate-a12: rgba(0,5,9,0.8902);
  --sage-1: #fbfdfc;
  --sage-2: #f7f9f8;
  --sage-3: #eef1f0;
  --sage-4: #e6e9e8;
  --sage-5: #dfe2e0;
  --sage-6: #d7dad9;
  --sage-7: #cbcfcd;
  --sage-8: #b8bcba;
  --sage-9: #868e8b;
  --sage-10: #7c8481;
  --sage-11: #5f6563;
  --sage-12: #1a211e;
  --sage-a1: rgba(0,128,64,0.01569);
  --sage-a2: rgba(0,64,32,0.03137);
  --sage-a3: rgba(0,45,30,0.06667);
  --sage-a4: rgba(0,31,21,0.09804);
  --sage-a5: rgba(0,24,8,0.12549);
  --sage-a6: rgba(0,20,13,0.15686);
  --sage-a7: rgba(0,20,10,0.20392);
  --sage-a8: rgba(0,15,8,0.27843);
  --sage-a9: rgba(0,17,11,0.47451);
  --sage-a10: rgba(0,16,10,0.51373);
  --sage-a11: rgba(0,10,7,0.62745);
  --sage-a12: rgba(0,8,5,0.89804);
  --olive-1: #fcfdfc;
  --olive-2: #f8faf8;
  --olive-3: #eff1ef;
  --olive-4: #e7e9e7;
  --olive-5: #dfe2df;
  --olive-6: #d7dad7;
  --olive-7: #cccfcc;
  --olive-8: #b9bcb8;
  --olive-9: #898e87;
  --olive-10: #7f847d;
  --olive-11: #60655f;
  --olive-12: #1d211c;
  --olive-a1: rgba(0,85,0,0.01176);
  --olive-a2: rgba(0,73,0,0.02745);
  --olive-a3: rgba(0,32,0,0.06275);
  --olive-a4: rgba(0,22,0,0.09412);
  --olive-a5: rgba(0,24,0,0.12549);
  --olive-a6: rgba(0,20,0,0.15686);
  --olive-a7: rgba(0,15,0,0.2);
  --olive-a8: rgba(4,15,0,0.27843);
  --olive-a9: rgba(5,15,0,0.47059);
  --olive-a10: rgba(4,14,0,0.5098);
  --olive-a11: rgba(2,10,0,0.62745);
  --olive-a12: rgba(1,6,0,0.8902);
  --sand-1: #fdfdfc;
  --sand-2: #f9f9f8;
  --sand-3: #f1f0ef;
  --sand-4: #e9e8e6;
  --sand-5: #e2e1de;
  --sand-6: #dad9d6;
  --sand-7: #cfceca;
  --sand-8: #bcbbb5;
  --sand-9: #8d8d86;
  --sand-10: #82827c;
  --sand-11: #63635e;
  --sand-12: #21201c;
  --sand-a1: rgba(85,85,0,0.01176);
  --sand-a2: rgba(37,37,0,0.02745);
  --sand-a3: rgba(32,16,0,0.06275);
  --sand-a4: rgba(31,21,0,0.09804);
  --sand-a5: rgba(31,24,0,0.12941);
  --sand-a6: rgba(25,19,0,0.16078);
  --sand-a7: rgba(25,20,0,0.20784);
  --sand-a8: rgba(25,21,1,0.2902);
  --sand-a9: rgba(15,15,0,0.47451);
  --sand-a10: rgba(12,12,0,0.51373);
  --sand-a11: rgba(8,8,0,0.63137);
  --sand-a12: rgba(6,5,0,0.8902);
  --amber-1: #fefdfb;
  --amber-2: #fefbe9;
  --amber-3: #fff7c2;
  --amber-4: #ffee9c;
  --amber-5: #fbe577;
  --amber-6: #f3d673;
  --amber-7: #e9c162;
  --amber-8: #e2a336;
  --amber-9: #ffc53d;
  --amber-10: #ffba18;
  --amber-11: #ab6400;
  --amber-12: #4f3422;
  --amber-a1: rgba(192,128,0,0.01569);
  --amber-a2: rgba(244,209,0,0.08627);
  --amber-a3: rgba(255,222,0,0.23922);
  --amber-a4: rgba(255,212,0,0.38824);
  --amber-a5: rgba(248,207,0,0.53333);
  --amber-a6: rgba(234,181,0,0.54902);
  --amber-a7: rgba(220,155,0,0.61569);
  --amber-a8: rgba(218,138,0,0.78824);
  --amber-a9: rgba(255,179,0,0.76078);
  --amber-a10: rgba(255,179,0,0.90588);
  --amber-a11: #ab6400;
  --amber-a12: rgba(52,21,0,0.86667);
  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce;
  --blue-12: #113264;
  --blue-a1: rgba(0,128,255,0.01569);
  --blue-a2: rgba(0,140,255,0.04314);
  --blue-a3: rgba(0,143,245,0.09804);
  --blue-a4: rgba(0,158,255,0.16471);
  --blue-a5: rgba(0,147,255,0.23922);
  --blue-a6: rgba(0,136,246,0.32549);
  --blue-a7: rgba(0,131,235,0.44314);
  --blue-a8: rgba(0,132,230,0.63137);
  --blue-a9: #0090ff;
  --blue-a10: rgba(0,134,240,0.98039);
  --blue-a11: rgba(0,109,203,0.94902);
  --blue-a12: rgba(0,35,89,0.93333);
  --bronze-1: #fdfcfc;
  --bronze-2: #fdf7f5;
  --bronze-3: #f6edea;
  --bronze-4: #efe4df;
  --bronze-5: #e7d9d3;
  --bronze-6: #dfcdc5;
  --bronze-7: #d3bcb3;
  --bronze-8: #c2a499;
  --bronze-9: #a18072;
  --bronze-10: #957468;
  --bronze-11: #7d5e54;
  --bronze-12: #43302b;
  --bronze-a1: rgba(85,0,0,0.01176);
  --bronze-a2: rgba(204,51,0,0.03922);
  --bronze-a3: rgba(146,37,0,0.08235);
  --bronze-a4: rgba(128,40,0,0.12549);
  --bronze-a5: rgba(116,35,0,0.17255);
  --bronze-a6: rgba(115,36,0,0.22745);
  --bronze-a7: rgba(108,31,0,0.29804);
  --bronze-a8: rgba(103,28,0,0.4);
  --bronze-a9: rgba(85,26,0,0.55294);
  --bronze-a10: rgba(76,21,0,0.59216);
  --bronze-a11: rgba(61,15,0,0.67059);
  --bronze-a12: rgba(29,6,0,0.83137);
  --brown-1: #fefdfc;
  --brown-2: #fcf9f6;
  --brown-3: #f6eee7;
  --brown-4: #f0e4d9;
  --brown-5: #ebdaca;
  --brown-6: #e4cdb7;
  --brown-7: #dcbc9f;
  --brown-8: #cea37e;
  --brown-9: #ad7f58;
  --brown-10: #a07553;
  --brown-11: #815e46;
  --brown-12: #3e332e;
  --brown-a1: rgba(170,85,0,0.01176);
  --brown-a2: rgba(170,85,0,0.03529);
  --brown-a3: rgba(160,75,0,0.09412);
  --brown-a4: rgba(155,74,0,0.14902);
  --brown-a5: rgba(159,77,0,0.20784);
  --brown-a6: rgba(160,78,0,0.28235);
  --brown-a7: rgba(163,78,0,0.37647);
  --brown-a8: rgba(159,74,0,0.50588);
  --brown-a9: rgba(130,60,0,0.6549);
  --brown-a10: rgba(114,51,0,0.67451);
  --brown-a11: rgba(82,33,0,0.72549);
  --brown-a12: rgba(20,6,0,0.81961);
  --crimson-1: #fffcfd;
  --crimson-2: #fef7f9;
  --crimson-3: #ffe9f0;
  --crimson-4: #fedce7;
  --crimson-5: #facedd;
  --crimson-6: #f3bed1;
  --crimson-7: #eaacc3;
  --crimson-8: #e093b2;
  --crimson-9: #e93d82;
  --crimson-10: #df3478;
  --crimson-11: #cb1d63;
  --crimson-12: #621639;
  --crimson-a1: rgba(255,0,85,0.01176);
  --crimson-a2: rgba(224,0,64,0.03137);
  --crimson-a3: rgba(255,0,82,0.08627);
  --crimson-a4: rgba(248,0,81,0.13725);
  --crimson-a5: rgba(229,0,79,0.19216);
  --crimson-a6: rgba(208,0,75,0.2549);
  --crimson-a7: rgba(191,0,71,0.32549);
  --crimson-a8: rgba(182,0,74,0.42353);
  --crimson-a9: rgba(226,0,91,0.76078);
  --crimson-a10: rgba(215,0,86,0.79608);
  --crimson-a11: rgba(196,0,79,0.88627);
  --crimson-a12: rgba(83,0,38,0.91373);
  --cyan-1: #fafdfe;
  --cyan-2: #f2fafb;
  --cyan-3: #def7f9;
  --cyan-4: #caf1f6;
  --cyan-5: #b5e9f0;
  --cyan-6: #9ddde7;
  --cyan-7: #7dcedc;
  --cyan-8: #3db9cf;
  --cyan-9: #00a2c7;
  --cyan-10: #0797b9;
  --cyan-11: #107d98;
  --cyan-12: #0d3c48;
  --cyan-a1: rgba(0,153,204,0.01961);
  --cyan-a2: rgba(0,157,177,0.05098);
  --cyan-a3: rgba(0,194,209,0.12941);
  --cyan-a4: rgba(0,188,212,0.20784);
  --cyan-a5: rgba(1,180,204,0.2902);
  --cyan-a6: rgba(0,167,193,0.38431);
  --cyan-a7: rgba(0,159,187,0.5098);
  --cyan-a8: rgba(0,163,192,0.76078);
  --cyan-a9: #00a2c7;
  --cyan-a10: rgba(0,148,183,0.97255);
  --cyan-a11: rgba(0,116,145,0.93725);
  --cyan-a12: rgba(0,50,62,0.94902);
  --gold-1: #fdfdfc;
  --gold-2: #faf9f2;
  --gold-3: #f2f0e7;
  --gold-4: #eae6db;
  --gold-5: #e1dccf;
  --gold-6: #d8d0bf;
  --gold-7: #cbc0aa;
  --gold-8: #b9a88d;
  --gold-9: #978365;
  --gold-10: #8c7a5e;
  --gold-11: #71624b;
  --gold-12: #3b352b;
  --gold-a1: rgba(85,85,0,0.01176);
  --gold-a2: rgba(157,138,0,0.05098);
  --gold-a3: rgba(117,96,0,0.09412);
  --gold-a4: rgba(107,78,0,0.14118);
  --gold-a5: rgba(96,70,0,0.18824);
  --gold-a6: rgba(100,68,0,0.25098);
  --gold-a7: rgba(99,66,0,0.33333);
  --gold-a8: rgba(99,61,0,0.44706);
  --gold-a9: rgba(83,50,0,0.60392);
  --gold-a10: rgba(73,45,0,0.63137);
  --gold-a11: rgba(54,33,0,0.70588);
  --gold-a12: rgba(19,12,0,0.83137);
  --grass-1: #fbfefb;
  --grass-2: #f5fbf5;
  --grass-3: #e9f6e9;
  --grass-4: #daf1db;
  --grass-5: #c9e8ca;
  --grass-6: #b2ddb5;
  --grass-7: #94ce9a;
  --grass-8: #65ba74;
  --grass-9: #46a758;
  --grass-10: #3e9b4f;
  --grass-11: #2a7e3b;
  --grass-12: #203c25;
  --grass-a1: rgba(0,192,0,0.01569);
  --grass-a2: rgba(0,153,0,0.03922);
  --grass-a3: rgba(0,151,0,0.08627);
  --grass-a4: rgba(0,159,7,0.1451);
  --grass-a5: rgba(0,147,5,0.21176);
  --grass-a6: rgba(0,143,10,0.30196);
  --grass-a7: rgba(1,139,15,0.41961);
  --grass-a8: rgba(0,141,25,0.60392);
  --grass-a9: rgba(0,134,25,0.72549);
  --grass-a10: rgba(0,123,23,0.75686);
  --grass-a11: rgba(0,101,20,0.83529);
  --grass-a12: rgba(0,32,6,0.87451);
  --green-1: #fbfefc;
  --green-2: #f4fbf6;
  --green-3: #e6f6eb;
  --green-4: #d6f1df;
  --green-5: #c4e8d1;
  --green-6: #adddc0;
  --green-7: #8eceaa;
  --green-8: #5bb98b;
  --green-9: #30a46c;
  --green-10: #2b9a66;
  --green-11: #218358;
  --green-12: #193b2d;
  --green-a1: rgba(0,192,64,0.01569);
  --green-a2: rgba(0,163,47,0.04314);
  --green-a3: rgba(0,164,51,0.09804);
  --green-a4: rgba(0,168,56,0.16078);
  --green-a5: rgba(1,156,57,0.23137);
  --green-a6: rgba(0,150,60,0.32157);
  --green-a7: rgba(0,145,64,0.44314);
  --green-a8: rgba(0,146,75,0.64314);
  --green-a9: rgba(0,143,74,0.81176);
  --green-a10: rgba(0,134,71,0.83137);
  --green-a11: rgba(0,113,63,0.87059);
  --green-a12: rgba(0,38,22,0.90196);
  --indigo-1: #fdfdfe;
  --indigo-2: #f7f9ff;
  --indigo-3: #edf2fe;
  --indigo-4: #e1e9ff;
  --indigo-5: #d2deff;
  --indigo-6: #c1d0ff;
  --indigo-7: #abbdf9;
  --indigo-8: #8da4ef;
  --indigo-9: #3e63dd;
  --indigo-10: #3358d4;
  --indigo-11: #3a5bc7;
  --indigo-12: #1f2d5c;
  --indigo-a1: rgba(0,0,128,0.00784);
  --indigo-a2: rgba(0,64,255,0.03137);
  --indigo-a3: rgba(0,71,241,0.07059);
  --indigo-a4: rgba(0,68,255,0.11765);
  --indigo-a5: rgba(0,68,255,0.17647);
  --indigo-a6: rgba(0,62,255,0.24314);
  --indigo-a7: rgba(0,55,237,0.32941);
  --indigo-a8: rgba(0,52,220,0.44706);
  --indigo-a9: rgba(0,49,210,0.75686);
  --indigo-a10: rgba(0,46,201,0.8);
  --indigo-a11: rgba(0,43,183,0.77255);
  --indigo-a12: rgba(0,16,70,0.87843);
  --iris-1: #fdfdff;
  --iris-2: #f8f8ff;
  --iris-3: #f0f1fe;
  --iris-4: #e6e7ff;
  --iris-5: #dadcff;
  --iris-6: #cbcdff;
  --iris-7: #b8baf8;
  --iris-8: #9b9ef0;
  --iris-9: #5b5bd6;
  --iris-10: #5151cd;
  --iris-11: #5753c6;
  --iris-12: #272962;
  --iris-a1: rgba(0,0,255,0.00784);
  --iris-a2: rgba(0,0,255,0.02745);
  --iris-a3: rgba(0,17,238,0.05882);
  --iris-a4: rgba(0,11,255,0.09804);
  --iris-a5: rgba(0,14,255,0.1451);
  --iris-a6: rgba(0,10,255,0.20392);
  --iris-a7: rgba(0,8,230,0.27843);
  --iris-a8: rgba(0,8,217,0.39216);
  --iris-a9: rgba(0,0,192,0.64314);
  --iris-a10: rgba(0,0,182,0.68235);
  --iris-a11: rgba(6,0,171,0.67451);
  --iris-a12: rgba(0,2,70,0.84706);
  --jade-1: #fbfefd;
  --jade-2: #f4fbf7;
  --jade-3: #e6f7ed;
  --jade-4: #d6f1e3;
  --jade-5: #c3e9d7;
  --jade-6: #acdec8;
  --jade-7: #8bceb6;
  --jade-8: #56ba9f;
  --jade-9: #29a383;
  --jade-10: #26997b;
  --jade-11: #208368;
  --jade-12: #1d3b31;
  --jade-a1: rgba(0,192,128,0.01569);
  --jade-a2: rgba(0,163,70,0.04314);
  --jade-a3: rgba(0,174,72,0.09804);
  --jade-a4: rgba(0,168,81,0.16078);
  --jade-a5: rgba(0,162,85,0.23529);
  --jade-a6: rgba(0,154,87,0.32549);
  --jade-a7: rgba(0,148,95,0.4549);
  --jade-a8: rgba(0,151,110,0.66275);
  --jade-a9: rgba(0,145,107,0.83922);
  --jade-a10: rgba(0,135,100,0.85098);
  --jade-a11: rgba(0,113,82,0.87451);
  --jade-a12: rgba(0,34,23,0.88627);
  --lime-1: #fcfdfa;
  --lime-2: #f8faf3;
  --lime-3: #eef6d6;
  --lime-4: #e2f0bd;
  --lime-5: #d3e7a6;
  --lime-6: #c2da91;
  --lime-7: #abc978;
  --lime-8: #8db654;
  --lime-9: #bdee63;
  --lime-10: #b0e64c;
  --lime-11: #5c7c2f;
  --lime-12: #37401c;
  --lime-a1: rgba(102,153,0,0.01961);
  --lime-a2: rgba(107,149,0,0.04706);
  --lime-a3: rgba(150,200,0,0.16078);
  --lime-a4: rgba(143,198,0,0.25882);
  --lime-a5: rgba(129,187,0,0.34902);
  --lime-a6: rgba(114,170,0,0.43137);
  --lime-a7: rgba(97,153,0,0.52941);
  --lime-a8: rgba(85,146,0,0.67059);
  --lime-a9: rgba(147,228,0,0.61176);
  --lime-a10: rgba(143,220,0,0.70196);
  --lime-a11: rgba(55,95,0,0.81569);
  --lime-a12: rgba(30,41,0,0.8902);
  --mint-1: #f9fefd;
  --mint-2: #f2fbf9;
  --mint-3: #ddf9f2;
  --mint-4: #c8f4e9;
  --mint-5: #b3ecde;
  --mint-6: #9ce0d0;
  --mint-7: #7ecfbd;
  --mint-8: #4cbba5;
  --mint-9: #86ead4;
  --mint-10: #7de0cb;
  --mint-11: #027864;
  --mint-12: #16433c;
  --mint-a1: rgba(0,213,170,0.02353);
  --mint-a2: rgba(0,177,138,0.05098);
  --mint-a3: rgba(0,210,158,0.13333);
  --mint-a4: rgba(0,204,153,0.21569);
  --mint-a5: rgba(0,192,145,0.29804);
  --mint-a6: rgba(0,176,134,0.38824);
  --mint-a7: rgba(0,161,125,0.50588);
  --mint-a8: rgba(0,158,127,0.70196);
  --mint-a9: rgba(0,211,165,0.47451);
  --mint-a10: rgba(0,195,153,0.5098);
  --mint-a11: rgba(0,119,99,0.99216);
  --mint-a12: rgba(0,49,42,0.91373);
  --orange-1: #fefcfb;
  --orange-2: #fff7ed;
  --orange-3: #ffefd6;
  --orange-4: #ffdfb5;
  --orange-5: #ffd19a;
  --orange-6: #ffc182;
  --orange-7: #f5ae73;
  --orange-8: #ec9455;
  --orange-9: #f76b15;
  --orange-10: #ef5f00;
  --orange-11: #cc4e00;
  --orange-12: #582d1d;
  --orange-a1: rgba(192,64,0,0.01569);
  --orange-a2: rgba(255,142,0,0.07059);
  --orange-a3: rgba(255,156,0,0.16078);
  --orange-a4: rgba(255,145,1,0.2902);
  --orange-a5: rgba(255,139,0,0.39608);
  --orange-a6: rgba(255,129,0,0.4902);
  --orange-a7: rgba(237,108,0,0.54902);
  --orange-a8: rgba(227,95,0,0.66667);
  --orange-a9: rgba(246,94,0,0.91765);
  --orange-a10: #ef5f00;
  --orange-a11: #cc4e00;
  --orange-a12: rgba(67,18,0,0.88627);
  --pink-1: #fffcfe;
  --pink-2: #fef7fb;
  --pink-3: #fee9f5;
  --pink-4: #fbdcef;
  --pink-5: #f6cee7;
  --pink-6: #efbfdd;
  --pink-7: #e7acd0;
  --pink-8: #dd93c2;
  --pink-9: #d6409f;
  --pink-10: #cf3897;
  --pink-11: #c2298a;
  --pink-12: #651249;
  --pink-a1: rgba(255,0,170,0.01176);
  --pink-a2: rgba(224,0,128,0.03137);
  --pink-a3: rgba(244,0,140,0.08627);
  --pink-a4: rgba(226,0,139,0.13725);
  --pink-a5: rgba(209,0,131,0.19216);
  --pink-a6: rgba(192,0,120,0.25098);
  --pink-a7: rgba(182,0,111,0.32549);
  --pink-a8: rgba(175,0,111,0.42353);
  --pink-a9: rgba(200,0,127,0.74902);
  --pink-a10: rgba(194,0,122,0.78039);
  --pink-a11: rgba(182,0,116,0.83922);
  --pink-a12: rgba(89,0,59,0.92941);
  --plum-1: #fefcff;
  --plum-2: #fdf7fd;
  --plum-3: #fbebfb;
  --plum-4: #f7def8;
  --plum-5: #f2d1f3;
  --plum-6: #e9c2ec;
  --plum-7: #deade3;
  --plum-8: #cf91d8;
  --plum-9: #ab4aba;
  --plum-10: #a144af;
  --plum-11: #953ea3;
  --plum-12: #53195d;
  --plum-a1: rgba(170,0,255,0.01176);
  --plum-a2: rgba(192,0,192,0.03137);
  --plum-a3: rgba(204,0,204,0.07843);
  --plum-a4: rgba(194,0,201,0.12941);
  --plum-a5: rgba(183,0,189,0.18039);
  --plum-a6: rgba(164,0,176,0.23922);
  --plum-a7: rgba(153,0,168,0.32157);
  --plum-a8: rgba(144,0,165,0.43137);
  --plum-a9: rgba(137,0,158,0.7098);
  --plum-a10: rgba(127,0,146,0.73333);
  --plum-a11: rgba(115,0,134,0.75686);
  --plum-a12: rgba(64,0,75,0.90196);
  --purple-1: #fefcfe;
  --purple-2: #fbf7fe;
  --purple-3: #f7edfe;
  --purple-4: #f2e2fc;
  --purple-5: #ead5f9;
  --purple-6: #e0c4f4;
  --purple-7: #d1afec;
  --purple-8: #be93e4;
  --purple-9: #8e4ec6;
  --purple-10: #8347b9;
  --purple-11: #8145b5;
  --purple-12: #402060;
  --purple-a1: rgba(170,0,170,0.01176);
  --purple-a2: rgba(128,0,224,0.03137);
  --purple-a3: rgba(142,0,241,0.07059);
  --purple-a4: rgba(141,0,229,0.11373);
  --purple-a5: rgba(128,0,219,0.16471);
  --purple-a6: rgba(122,1,208,0.23137);
  --purple-a7: rgba(109,0,195,0.31373);
  --purple-a8: rgba(102,0,192,0.42353);
  --purple-a9: rgba(92,0,173,0.69412);
  --purple-a10: rgba(83,0,158,0.72157);
  --purple-a11: rgba(82,0,154,0.72941);
  --purple-a12: rgba(37,0,73,0.87451);
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;
  --red-12: #641723;
  --red-a1: rgba(255,0,0,0.01176);
  --red-a2: rgba(255,0,0,0.03137);
  --red-a3: rgba(243,0,13,0.07843);
  --red-a4: rgba(255,0,8,0.14118);
  --red-a5: rgba(255,0,6,0.19608);
  --red-a6: rgba(248,0,4,0.25882);
  --red-a7: rgba(223,0,3,0.33725);
  --red-a8: rgba(210,0,5,0.44314);
  --red-a9: rgba(219,0,7,0.71765);
  --red-a10: rgba(209,0,5,0.75686);
  --red-a11: rgba(196,0,6,0.82745);
  --red-a12: rgba(85,0,13,0.9098);
  --ruby-1: #fffcfd;
  --ruby-2: #fff7f8;
  --ruby-3: #feeaed;
  --ruby-4: #ffdce1;
  --ruby-5: #ffced6;
  --ruby-6: #f8bfc8;
  --ruby-7: #efacb8;
  --ruby-8: #e592a3;
  --ruby-9: #e54666;
  --ruby-10: #dc3b5d;
  --ruby-11: #ca244d;
  --ruby-12: #64172b;
  --ruby-a1: rgba(255,0,85,0.01176);
  --ruby-a2: rgba(255,0,32,0.03137);
  --ruby-a3: rgba(243,0,37,0.08235);
  --ruby-a4: rgba(255,0,37,0.13725);
  --ruby-a5: rgba(255,0,42,0.19216);
  --ruby-a6: rgba(228,0,36,0.25098);
  --ruby-a7: rgba(206,0,37,0.32549);
  --ruby-a8: rgba(195,0,40,0.42745);
  --ruby-a9: rgba(219,0,44,0.72549);
  --ruby-a10: rgba(210,0,44,0.76863);
  --ruby-a11: rgba(193,0,48,0.85882);
  --ruby-a12: rgba(85,0,22,0.9098);
  --sky-1: #f9feff;
  --sky-2: #f1fafd;
  --sky-3: #e1f6fd;
  --sky-4: #d1f0fa;
  --sky-5: #bee7f5;
  --sky-6: #a9daed;
  --sky-7: #8dcae3;
  --sky-8: #60b3d7;
  --sky-9: #7ce2fe;
  --sky-10: #74daf8;
  --sky-11: #00749e;
  --sky-12: #1d3e56;
  --sky-a1: rgba(0,213,255,0.02353);
  --sky-a2: rgba(0,164,219,0.0549);
  --sky-a3: rgba(0,179,238,0.11765);
  --sky-a4: rgba(0,172,228,0.18039);
  --sky-a5: rgba(0,161,216,0.2549);
  --sky-a6: rgba(0,146,202,0.33725);
  --sky-a7: rgba(0,137,193,0.44706);
  --sky-a8: rgba(0,133,191,0.62353);
  --sky-a9: rgba(0,199,254,0.51373);
  --sky-a10: rgba(0,188,243,0.5451);
  --sky-a11: #00749e;
  --sky-a12: rgba(0,37,64,0.88627);
  --teal-1: #fafefd;
  --teal-2: #f3fbf9;
  --teal-3: #e0f8f3;
  --teal-4: #ccf3ea;
  --teal-5: #b8eae0;
  --teal-6: #a1ded2;
  --teal-7: #83cdc1;
  --teal-8: #53b9ab;
  --teal-9: #12a594;
  --teal-10: #0d9b8a;
  --teal-11: #008573;
  --teal-12: #0d3d38;
  --teal-a1: rgba(0,204,153,0.01961);
  --teal-a2: rgba(0,170,128,0.04706);
  --teal-a3: rgba(0,198,157,0.12157);
  --teal-a4: rgba(0,195,150,0.2);
  --teal-a5: rgba(0,180,144,0.27843);
  --teal-a6: rgba(0,166,133,0.36863);
  --teal-a7: rgba(0,153,128,0.48627);
  --teal-a8: rgba(0,151,131,0.67451);
  --teal-a9: rgba(0,158,140,0.92941);
  --teal-a10: rgba(0,150,132,0.94902);
  --teal-a11: #008573;
  --teal-a12: rgba(0,51,45,0.94902);
  --tomato-1: #fffcfc;
  --tomato-2: #fff8f7;
  --tomato-3: #feebe7;
  --tomato-4: #ffdcd3;
  --tomato-5: #ffcdc2;
  --tomato-6: #fdbdaf;
  --tomato-7: #f5a898;
  --tomato-8: #ec8e7b;
  --tomato-9: #e54d2e;
  --tomato-10: #dd4425;
  --tomato-11: #d13415;
  --tomato-12: #5c271f;
  --tomato-a1: rgba(255,0,0,0.01176);
  --tomato-a2: rgba(255,32,0,0.03137);
  --tomato-a3: rgba(245,43,0,0.09412);
  --tomato-a4: rgba(255,53,0,0.17255);
  --tomato-a5: rgba(255,46,0,0.23922);
  --tomato-a6: rgba(249,45,0,0.31373);
  --tomato-a7: rgba(231,40,0,0.40392);
  --tomato-a8: rgba(219,37,0,0.51765);
  --tomato-a9: rgba(223,38,0,0.81961);
  --tomato-a10: rgba(215,36,0,0.8549);
  --tomato-a11: rgba(205,34,0,0.91765);
  --tomato-a12: rgba(70,9,0,0.87843);
  --violet-1: #fdfcfe;
  --violet-2: #faf8ff;
  --violet-3: #f4f0fe;
  --violet-4: #ebe4ff;
  --violet-5: #e1d9ff;
  --violet-6: #d4cafe;
  --violet-7: #c2b5f5;
  --violet-8: #aa99ec;
  --violet-9: #6e56cf;
  --violet-10: #654dc4;
  --violet-11: #6550b9;
  --violet-12: #2f265f;
  --violet-a1: rgba(85,0,170,0.01176);
  --violet-a2: rgba(73,0,255,0.02745);
  --violet-a3: rgba(68,0,238,0.05882);
  --violet-a4: rgba(67,0,255,0.10588);
  --violet-a5: rgba(54,0,255,0.14902);
  --violet-a6: rgba(49,0,251,0.20784);
  --violet-a7: rgba(45,1,221,0.2902);
  --violet-a8: rgba(43,0,208,0.4);
  --violet-a9: rgba(36,0,183,0.66275);
  --violet-a10: rgba(35,0,171,0.69804);
  --violet-a11: rgba(31,0,153,0.68627);
  --violet-a12: rgba(11,0,67,0.85098);
  --yellow-1: #fdfdf9;
  --yellow-2: #fefce9;
  --yellow-3: #fffab8;
  --yellow-4: #fff394;
  --yellow-5: #ffe770;
  --yellow-6: #f3d768;
  --yellow-7: #e4c767;
  --yellow-8: #d5ae39;
  --yellow-9: #ffe629;
  --yellow-10: #ffdc00;
  --yellow-11: #9e6c00;
  --yellow-12: #473b1f;
  --yellow-a1: rgba(170,170,0,0.02353);
  --yellow-a2: rgba(244,221,0,0.08627);
  --yellow-a3: rgba(255,238,0,0.27843);
  --yellow-a4: rgba(255,227,1,0.41961);
  --yellow-a5: rgba(255,213,0,0.56078);
  --yellow-a6: rgba(235,188,0,0.59216);
  --yellow-a7: rgba(210,161,0,0.59608);
  --yellow-a8: rgba(201,151,0,0.77647);
  --yellow-a9: rgba(255,225,0,0.83922);
  --yellow-a10: #ffdc00;
  --yellow-a11: #9e6c00;
  --yellow-a12: rgba(46,32,0,0.87843);
  --gray-surface: rgba(255,255,255,0.8);
  --gray-indicator: var(--gray-9);
  --gray-track: var(--gray-9);
  --mauve-surface: rgba(255,255,255,0.8);
  --mauve-indicator: var(--mauve-9);
  --mauve-track: var(--mauve-9);
  --slate-surface: rgba(255,255,255,0.8);
  --slate-indicator: var(--slate-9);
  --slate-track: var(--slate-9);
  --sage-surface: rgba(255,255,255,0.8);
  --sage-indicator: var(--sage-9);
  --sage-track: var(--sage-9);
  --olive-surface: rgba(255,255,255,0.8);
  --olive-indicator: var(--olive-9);
  --olive-track: var(--olive-9);
  --sand-surface: rgba(255,255,255,0.8);
  --sand-indicator: var(--sand-9);
  --sand-track: var(--sand-9);
  --amber-surface: rgba(254,250,228,0.8);
  --amber-indicator: var(--amber-9);
  --amber-track: var(--amber-9);
  --blue-surface: rgba(241,249,255,0.8);
  --blue-indicator: var(--blue-9);
  --blue-track: var(--blue-9);
  --bronze-surface: rgba(253,245,243,0.8);
  --bronze-indicator: var(--bronze-9);
  --bronze-track: var(--bronze-9);
  --brown-surface: rgba(251,248,244,0.8);
  --brown-indicator: var(--brown-9);
  --brown-track: var(--brown-9);
  --crimson-surface: rgba(254,245,248,0.8);
  --crimson-indicator: var(--crimson-9);
  --crimson-track: var(--crimson-9);
  --cyan-surface: rgba(239,249,250,0.8);
  --cyan-indicator: var(--cyan-9);
  --cyan-track: var(--cyan-9);
  --gold-surface: rgba(249,248,239,0.8);
  --gold-indicator: var(--gold-9);
  --gold-track: var(--gold-9);
  --grass-surface: rgba(243,250,243,0.8);
  --grass-indicator: var(--grass-9);
  --grass-track: var(--grass-9);
  --green-surface: rgba(241,250,244,0.8);
  --green-indicator: var(--green-9);
  --green-track: var(--green-9);
  --indigo-surface: rgba(245,248,255,0.8);
  --indigo-indicator: var(--indigo-9);
  --indigo-track: var(--indigo-9);
  --iris-surface: rgba(246,246,255,0.8);
  --iris-indicator: var(--iris-9);
  --iris-track: var(--iris-9);
  --jade-surface: rgba(241,250,245,0.8);
  --jade-indicator: var(--jade-9);
  --jade-track: var(--jade-9);
  --lime-surface: rgba(246,249,240,0.8);
  --lime-indicator: var(--lime-9);
  --lime-track: var(--lime-9);
  --mint-surface: rgba(239,250,248,0.8);
  --mint-indicator: var(--mint-9);
  --mint-track: var(--mint-9);
  --orange-surface: rgba(255,245,233,0.8);
  --orange-indicator: var(--orange-9);
  --orange-track: var(--orange-9);
  --pink-surface: rgba(254,245,250,0.8);
  --pink-indicator: var(--pink-9);
  --pink-track: var(--pink-9);
  --plum-surface: rgba(253,245,253,0.8);
  --plum-indicator: var(--plum-9);
  --plum-track: var(--plum-9);
  --purple-surface: rgba(250,245,254,0.8);
  --purple-indicator: var(--purple-9);
  --purple-track: var(--purple-9);
  --red-surface: rgba(255,245,245,0.8);
  --red-indicator: var(--red-9);
  --red-track: var(--red-9);
  --ruby-surface: rgba(255,245,246,0.8);
  --ruby-indicator: var(--ruby-9);
  --ruby-track: var(--ruby-9);
  --sky-surface: rgba(238,249,253,0.8);
  --sky-indicator: var(--sky-9);
  --sky-track: var(--sky-9);
  --teal-surface: rgba(240,250,248,0.8);
  --teal-indicator: var(--teal-9);
  --teal-track: var(--teal-9);
  --tomato-surface: rgba(255,246,245,0.8);
  --tomato-indicator: var(--tomato-9);
  --tomato-track: var(--tomato-9);
  --violet-surface: rgba(249,246,255,0.8);
  --violet-indicator: var(--violet-9);
  --violet-track: var(--violet-9);
  --yellow-surface: rgba(254,251,228,0.8);
  --yellow-indicator: var(--yellow-10);
  --yellow-track: var(--yellow-10);
}

.dark, .dark-theme {
  --gray-1: #111111;
  --gray-2: #191919;
  --gray-3: #222222;
  --gray-4: #2a2a2a;
  --gray-5: #313131;
  --gray-6: #3a3a3a;
  --gray-7: #484848;
  --gray-8: #606060;
  --gray-9: #6e6e6e;
  --gray-10: #7b7b7b;
  --gray-11: #b4b4b4;
  --gray-12: #eeeeee;
  --gray-a1: rgba(0,0,0,0);
  --gray-a2: rgba(255,255,255,0.03529);
  --gray-a3: rgba(255,255,255,0.07059);
  --gray-a4: rgba(255,255,255,0.10588);
  --gray-a5: rgba(255,255,255,0.13333);
  --gray-a6: rgba(255,255,255,0.17255);
  --gray-a7: rgba(255,255,255,0.23137);
  --gray-a8: rgba(255,255,255,0.33333);
  --gray-a9: rgba(255,255,255,0.39216);
  --gray-a10: rgba(255,255,255,0.44706);
  --gray-a11: rgba(255,255,255,0.68627);
  --gray-a12: rgba(255,255,255,0.92941);
  --mauve-1: #121113;
  --mauve-2: #1a191b;
  --mauve-3: #232225;
  --mauve-4: #2b292d;
  --mauve-5: #323035;
  --mauve-6: #3c393f;
  --mauve-7: #49474e;
  --mauve-8: #625f69;
  --mauve-9: #6f6d78;
  --mauve-10: #7c7a85;
  --mauve-11: #b5b2bc;
  --mauve-12: #eeeef0;
  --mauve-a1: rgba(0,0,0,0);
  --mauve-a2: rgba(245,244,246,0.03529);
  --mauve-a3: rgba(235,234,248,0.07843);
  --mauve-a4: rgba(238,229,248,0.11373);
  --mauve-a5: rgba(239,230,254,0.1451);
  --mauve-a6: rgba(241,230,253,0.18824);
  --mauve-a7: rgba(238,233,255,0.25098);
  --mauve-a8: rgba(238,231,255,0.36471);
  --mauve-a9: rgba(234,230,253,0.43137);
  --mauve-a10: rgba(236,233,253,0.48627);
  --mauve-a11: rgba(245,241,255,0.71765);
  --mauve-a12: rgba(253,253,255,0.93725);
  --slate-1: #111113;
  --slate-2: #18191b;
  --slate-3: #212225;
  --slate-4: #272a2d;
  --slate-5: #2e3135;
  --slate-6: #363a3f;
  --slate-7: #43484e;
  --slate-8: #5a6169;
  --slate-9: #696e77;
  --slate-10: #777b84;
  --slate-11: #b0b4ba;
  --slate-12: #edeef0;
  --slate-a1: rgba(0,0,0,0);
  --slate-a2: rgba(216,244,246,0.03529);
  --slate-a3: rgba(221,234,248,0.07843);
  --slate-a4: rgba(211,237,248,0.11373);
  --slate-a5: rgba(217,237,254,0.1451);
  --slate-a6: rgba(214,235,253,0.18824);
  --slate-a7: rgba(217,237,255,0.25098);
  --slate-a8: rgba(217,237,255,0.36471);
  --slate-a9: rgba(223,235,253,0.42745);
  --slate-a10: rgba(229,237,253,0.48235);
  --slate-a11: rgba(241,247,254,0.7098);
  --slate-a12: rgba(252,253,255,0.93725);
  --sage-1: #101211;
  --sage-2: #171918;
  --sage-3: #202221;
  --sage-4: #272a29;
  --sage-5: #2e3130;
  --sage-6: #373b39;
  --sage-7: #444947;
  --sage-8: #5b625f;
  --sage-9: #63706b;
  --sage-10: #717d79;
  --sage-11: #adb5b2;
  --sage-12: #eceeed;
  --sage-a1: rgba(0,0,0,0);
  --sage-a2: rgba(240,242,241,0.03137);
  --sage-a3: rgba(243,245,244,0.07059);
  --sage-a4: rgba(242,254,253,0.10196);
  --sage-a5: rgba(241,251,250,0.13333);
  --sage-a6: rgba(237,251,244,0.17647);
  --sage-a7: rgba(237,252,247,0.23529);
  --sage-a8: rgba(235,253,246,0.34118);
  --sage-a9: rgba(223,253,242,0.4);
  --sage-a10: rgba(229,253,246,0.4549);
  --sage-a11: rgba(244,254,251,0.6902);
  --sage-a12: rgba(253,255,254,0.92941);
  --olive-1: #111210;
  --olive-2: #181917;
  --olive-3: #212220;
  --olive-4: #282a27;
  --olive-5: #2f312e;
  --olive-6: #383a36;
  --olive-7: #454843;
  --olive-8: #5c625b;
  --olive-9: #687066;
  --olive-10: #767d74;
  --olive-11: #afb5ad;
  --olive-12: #eceeec;
  --olive-a1: rgba(0,0,0,0);
  --olive-a2: rgba(241,242,240,0.03137);
  --olive-a3: rgba(244,245,243,0.07059);
  --olive-a4: rgba(243,254,242,0.10196);
  --olive-a5: rgba(242,251,241,0.13333);
  --olive-a6: rgba(244,250,237,0.17255);
  --olive-a7: rgba(242,252,237,0.23137);
  --olive-a8: rgba(237,253,235,0.34118);
  --olive-a9: rgba(235,253,231,0.4);
  --olive-a10: rgba(240,253,236,0.4549);
  --olive-a11: rgba(246,254,244,0.6902);
  --olive-a12: rgba(253,255,253,0.92941);
  --sand-1: #111110;
  --sand-2: #191918;
  --sand-3: #222221;
  --sand-4: #2a2a28;
  --sand-5: #31312e;
  --sand-6: #3b3a37;
  --sand-7: #494844;
  --sand-8: #62605b;
  --sand-9: #6f6d66;
  --sand-10: #7c7b74;
  --sand-11: #b5b3ad;
  --sand-12: #eeeeec;
  --sand-a1: rgba(0,0,0,0);
  --sand-a2: rgba(244,244,243,0.03529);
  --sand-a3: rgba(246,246,245,0.07451);
  --sand-a4: rgba(254,254,243,0.10588);
  --sand-a5: rgba(251,251,235,0.13725);
  --sand-a6: rgba(255,250,237,0.17647);
  --sand-a7: rgba(255,251,237,0.23529);
  --sand-a8: rgba(255,249,235,0.34118);
  --sand-a9: rgba(255,250,233,0.39608);
  --sand-a10: rgba(255,253,238,0.45098);
  --sand-a11: rgba(255,252,244,0.6902);
  --sand-a12: rgba(255,255,253,0.92941);
  --amber-1: #16120c;
  --amber-2: #1d180f;
  --amber-3: #302008;
  --amber-4: #3f2700;
  --amber-5: #4d3000;
  --amber-6: #5c3d05;
  --amber-7: #714f19;
  --amber-8: #8f6424;
  --amber-9: #ffc53d;
  --amber-10: #ffd60a;
  --amber-11: #ffca16;
  --amber-12: #ffe7b3;
  --amber-a1: rgba(230,60,0,0.02353);
  --amber-a2: rgba(253,155,0,0.05098);
  --amber-a3: rgba(250,130,0,0.13333);
  --amber-a4: rgba(252,130,0,0.19608);
  --amber-a5: rgba(253,139,0,0.2549);
  --amber-a6: rgba(253,155,0,0.31765);
  --amber-a7: rgba(255,171,37,0.40392);
  --amber-a8: rgba(255,174,53,0.52941);
  --amber-a9: #ffc53d;
  --amber-a10: #ffd60a;
  --amber-a11: #ffca16;
  --amber-a12: #ffe7b3;
  --blue-1: #0d1520;
  --blue-2: #111927;
  --blue-3: #0d2847;
  --blue-4: #003362;
  --blue-5: #004074;
  --blue-6: #104d87;
  --blue-7: #205d9e;
  --blue-8: #2870bd;
  --blue-9: #0090ff;
  --blue-10: #3b9eff;
  --blue-11: #70b8ff;
  --blue-12: #c2e6ff;
  --blue-a1: rgba(0,77,242,0.06667);
  --blue-a2: rgba(17,102,251,0.09412);
  --blue-a3: rgba(0,119,255,0.22745);
  --blue-a4: rgba(0,117,255,0.34118);
  --blue-a5: rgba(0,129,253,0.41961);
  --blue-a6: rgba(15,137,253,0.49804);
  --blue-a7: rgba(42,145,254,0.59608);
  --blue-a8: rgba(48,148,254,0.72549);
  --blue-a9: #0090ff;
  --blue-a10: #3b9eff;
  --blue-a11: #70b8ff;
  --blue-a12: #c2e6ff;
  --bronze-1: #141110;
  --bronze-2: #1c1917;
  --bronze-3: #262220;
  --bronze-4: #302a27;
  --bronze-5: #3b3330;
  --bronze-6: #493e3a;
  --bronze-7: #5a4c47;
  --bronze-8: #6f5f58;
  --bronze-9: #a18072;
  --bronze-10: #ae8c7e;
  --bronze-11: #d4b3a5;
  --bronze-12: #ede0d9;
  --bronze-a1: rgba(209,17,0,0.01569);
  --bronze-a2: rgba(251,188,145,0.04706);
  --bronze-a3: rgba(250,206,184,0.0902);
  --bronze-a4: rgba(250,205,182,0.13333);
  --bronze-a5: rgba(255,210,193,0.17647);
  --bronze-a6: rgba(255,209,192,0.23529);
  --bronze-a7: rgba(253,208,192,0.3098);
  --bronze-a8: rgba(255,214,197,0.39608);
  --bronze-a9: rgba(254,199,176,0.60784);
  --bronze-a10: rgba(254,202,181,0.66275);
  --bronze-a11: rgba(255,215,198,0.81961);
  --bronze-a12: rgba(255,241,233,0.92549);
  --brown-1: #12110f;
  --brown-2: #1c1816;
  --brown-3: #28211d;
  --brown-4: #322922;
  --brown-5: #3e3128;
  --brown-6: #4d3c2f;
  --brown-7: #614a39;
  --brown-8: #7c5f46;
  --brown-9: #ad7f58;
  --brown-10: #b88c67;
  --brown-11: #dbb594;
  --brown-12: #f2e1ca;
  --brown-a1: rgba(145,17,0,0.00784);
  --brown-a2: rgba(251,166,124,0.04706);
  --brown-a3: rgba(252,181,140,0.09804);
  --brown-a4: rgba(251,187,138,0.14118);
  --brown-a5: rgba(252,184,137,0.19216);
  --brown-a6: rgba(253,186,135,0.2549);
  --brown-a7: rgba(255,187,136,0.33725);
  --brown-a8: rgba(255,190,135,0.45098);
  --brown-a9: rgba(254,184,125,0.65882);
  --brown-a10: rgba(255,193,140,0.70196);
  --brown-a11: rgba(254,209,170,0.85098);
  --brown-a12: rgba(254,236,212,0.94902);
  --crimson-1: #191114;
  --crimson-2: #201318;
  --crimson-3: #381525;
  --crimson-4: #4d122f;
  --crimson-5: #5c1839;
  --crimson-6: #6d2545;
  --crimson-7: #873356;
  --crimson-8: #b0436e;
  --crimson-9: #e93d82;
  --crimson-10: #ee518a;
  --crimson-11: #ff92ad;
  --crimson-12: #fdd3e8;
  --crimson-a1: rgba(244,18,103,0.03529);
  --crimson-a2: rgba(242,47,122,0.06667);
  --crimson-a3: rgba(254,42,139,0.16471);
  --crimson-a4: rgba(253,21,135,0.2549);
  --crimson-a5: rgba(253,39,143,0.31765);
  --crimson-a6: rgba(254,69,151,0.38824);
  --crimson-a7: rgba(253,85,155,0.49804);
  --crimson-a8: rgba(254,91,155,0.67059);
  --crimson-a9: rgba(254,65,141,0.9098);
  --crimson-a10: rgba(255,86,147,0.92941);
  --crimson-a11: #ff92ad;
  --crimson-a12: rgba(255,213,234,0.99216);
  --cyan-1: #0b161a;
  --cyan-2: #101b20;
  --cyan-3: #082c36;
  --cyan-4: #003848;
  --cyan-5: #004558;
  --cyan-6: #045468;
  --cyan-7: #12677e;
  --cyan-8: #11809c;
  --cyan-9: #00a2c7;
  --cyan-10: #23afd0;
  --cyan-11: #4ccce6;
  --cyan-12: #b6ecf7;
  --cyan-a1: rgba(0,145,247,0.03922);
  --cyan-a2: rgba(2,167,242,0.06667);
  --cyan-a3: rgba(0,190,253,0.15686);
  --cyan-a4: rgba(0,186,255,0.23137);
  --cyan-a5: rgba(0,190,253,0.30196);
  --cyan-a6: rgba(0,199,253,0.36863);
  --cyan-a7: rgba(20,205,255,0.45882);
  --cyan-a8: rgba(17,207,255,0.58431);
  --cyan-a9: rgba(0,207,255,0.76471);
  --cyan-a10: rgba(40,214,255,0.80392);
  --cyan-a11: rgba(82,225,254,0.89804);
  --cyan-a12: rgba(187,243,254,0.96863);
  --gold-1: #121211;
  --gold-2: #1b1a17;
  --gold-3: #24231f;
  --gold-4: #2d2b26;
  --gold-5: #38352e;
  --gold-6: #444039;
  --gold-7: #544f46;
  --gold-8: #696256;
  --gold-9: #978365;
  --gold-10: #a39073;
  --gold-11: #cbb99f;
  --gold-12: #e8e2d9;
  --gold-a1: rgba(145,145,17,0.00784);
  --gold-a2: rgba(249,226,157,0.04314);
  --gold-a3: rgba(248,236,187,0.08235);
  --gold-a4: rgba(255,238,196,0.11765);
  --gold-a5: rgba(254,236,194,0.16471);
  --gold-a6: rgba(254,235,203,0.21569);
  --gold-a7: rgba(255,237,205,0.28235);
  --gold-a8: rgba(253,234,202,0.37255);
  --gold-a9: rgba(255,219,166,0.56471);
  --gold-a10: rgba(254,223,176,0.61569);
  --gold-a11: rgba(254,231,198,0.78431);
  --gold-a12: rgba(254,247,237,0.90588);
  --grass-1: #0e1511;
  --grass-2: #141a15;
  --grass-3: #1b2a1e;
  --grass-4: #1d3a24;
  --grass-5: #25482d;
  --grass-6: #2d5736;
  --grass-7: #366740;
  --grass-8: #3e7949;
  --grass-9: #46a758;
  --grass-10: #53b365;
  --grass-11: #71d083;
  --grass-12: #c2f0c2;
  --grass-a1: rgba(0,222,18,0.01961);
  --grass-a2: rgba(94,247,120,0.03922);
  --grass-a3: rgba(112,254,140,0.10588);
  --grass-a4: rgba(87,255,128,0.17255);
  --grass-a5: rgba(104,255,139,0.23137);
  --grass-a6: rgba(113,255,143,0.29412);
  --grass-a7: rgba(119,253,146,0.36471);
  --grass-a8: rgba(119,253,144,0.43922);
  --grass-a9: rgba(101,255,130,0.63137);
  --grass-a10: rgba(114,255,141,0.68235);
  --grass-a11: rgba(137,255,159,0.80392);
  --grass-a12: rgba(206,255,206,0.93725);
  --green-1: #0e1512;
  --green-2: #121b17;
  --green-3: #132d21;
  --green-4: #113b29;
  --green-5: #174933;
  --green-6: #20573e;
  --green-7: #28684a;
  --green-8: #2f7c57;
  --green-9: #30a46c;
  --green-10: #33b074;
  --green-11: #3dd68c;
  --green-12: #b1f1cb;
  --green-a1: rgba(0,222,69,0.01961);
  --green-a2: rgba(41,249,157,0.04314);
  --green-a3: rgba(34,255,153,0.11765);
  --green-a4: rgba(17,255,153,0.17647);
  --green-a5: rgba(43,255,162,0.23529);
  --green-a6: rgba(68,255,170,0.29412);
  --green-a7: rgba(80,253,172,0.36863);
  --green-a8: rgba(84,255,173,0.45098);
  --green-a9: rgba(68,255,164,0.61961);
  --green-a10: rgba(67,254,164,0.67059);
  --green-a11: rgba(70,254,165,0.83137);
  --green-a12: rgba(187,255,215,0.94118);
  --indigo-1: #11131f;
  --indigo-2: #141726;
  --indigo-3: #182449;
  --indigo-4: #1d2e62;
  --indigo-5: #253974;
  --indigo-6: #304384;
  --indigo-7: #3a4f97;
  --indigo-8: #435db1;
  --indigo-9: #3e63dd;
  --indigo-10: #5472e4;
  --indigo-11: #9eb1ff;
  --indigo-12: #d6e1ff;
  --indigo-a1: rgba(17,51,255,0.05882);
  --indigo-a2: rgba(51,84,250,0.0902);
  --indigo-a3: rgba(47,98,255,0.23529);
  --indigo-a4: rgba(53,102,255,0.34118);
  --indigo-a5: rgba(65,113,253,0.41961);
  --indigo-a6: rgba(81,120,253,0.48627);
  --indigo-a7: rgba(90,127,255,0.56471);
  --indigo-a8: rgba(91,129,254,0.67451);
  --indigo-a9: rgba(70,113,255,0.85882);
  --indigo-a10: rgba(92,126,254,0.8902);
  --indigo-a11: #9eb1ff;
  --indigo-a12: #d6e1ff;
  --iris-1: #13131e;
  --iris-2: #171625;
  --iris-3: #202248;
  --iris-4: #262a65;
  --iris-5: #303374;
  --iris-6: #3d3e82;
  --iris-7: #4a4a95;
  --iris-8: #5958b1;
  --iris-9: #5b5bd6;
  --iris-10: #6e6ade;
  --iris-11: #b1a9ff;
  --iris-12: #e0dffe;
  --iris-a1: rgba(54,54,254,0.0549);
  --iris-a2: rgba(86,75,249,0.08627);
  --iris-a3: rgba(82,91,255,0.23137);
  --iris-a4: rgba(77,88,255,0.35294);
  --iris-a5: rgba(91,98,253,0.41961);
  --iris-a6: rgba(109,111,253,0.47843);
  --iris-a7: rgba(119,119,254,0.55686);
  --iris-a8: rgba(123,122,254,0.67451);
  --iris-a9: rgba(106,106,254,0.83137);
  --iris-a10: rgba(125,121,255,0.86275);
  --iris-a11: #b1a9ff;
  --iris-a12: rgba(225,224,255,0.99608);
  --jade-1: #0d1512;
  --jade-2: #121c18;
  --jade-3: #0f2e22;
  --jade-4: #0b3b2c;
  --jade-5: #114837;
  --jade-6: #1b5745;
  --jade-7: #246854;
  --jade-8: #2a7e68;
  --jade-9: #29a383;
  --jade-10: #27b08b;
  --jade-11: #1fd8a4;
  --jade-12: #adf0d4;
  --jade-a1: rgba(0,222,69,0.01961);
  --jade-a2: rgba(39,251,166,0.04706);
  --jade-a3: rgba(2,249,153,0.12549);
  --jade-a4: rgba(0,255,170,0.17647);
  --jade-a5: rgba(17,255,182,0.23137);
  --jade-a6: rgba(52,255,194,0.29412);
  --jade-a7: rgba(69,253,199,0.36863);
  --jade-a8: rgba(72,255,207,0.45882);
  --jade-a9: rgba(56,254,202,0.61569);
  --jade-a10: rgba(49,254,199,0.67059);
  --jade-a11: rgba(33,254,192,0.83922);
  --jade-a12: rgba(184,255,225,0.93725);
  --lime-1: #11130c;
  --lime-2: #151a10;
  --lime-3: #1f2917;
  --lime-4: #29371d;
  --lime-5: #334423;
  --lime-6: #3d522a;
  --lime-7: #496231;
  --lime-8: #577538;
  --lime-9: #bdee63;
  --lime-10: #d4ff70;
  --lime-11: #bde56c;
  --lime-12: #e3f7ba;
  --lime-a1: rgba(17,187,0,0.01176);
  --lime-a2: rgba(120,247,0,0.03922);
  --lime-a3: rgba(155,253,76,0.10196);
  --lime-a4: rgba(167,254,92,0.16078);
  --lime-a5: rgba(175,254,101,0.21569);
  --lime-a6: rgba(178,254,109,0.27451);
  --lime-a7: rgba(182,255,111,0.34118);
  --lime-a8: rgba(182,253,109,0.42353);
  --lime-a9: rgba(202,255,105,0.92941);
  --lime-a10: #d4ff70;
  --lime-a11: rgba(209,254,119,0.89412);
  --lime-a12: rgba(233,254,191,0.96863);
  --mint-1: #0e1515;
  --mint-2: #0f1b1b;
  --mint-3: #092c2b;
  --mint-4: #003a38;
  --mint-5: #004744;
  --mint-6: #105650;
  --mint-7: #1e685f;
  --mint-8: #277f70;
  --mint-9: #86ead4;
  --mint-10: #a8f5e5;
  --mint-11: #58d5ba;
  --mint-12: #c4f5e1;
  --mint-a1: rgba(0,222,222,0.01961);
  --mint-a2: rgba(0,249,249,0.04314);
  --mint-a3: rgba(0,255,246,0.11373);
  --mint-a4: rgba(0,255,244,0.17255);
  --mint-a5: rgba(0,255,242,0.22745);
  --mint-a6: rgba(14,255,235,0.2902);
  --mint-a7: rgba(52,253,229,0.36863);
  --mint-a8: rgba(65,255,223,0.46275);
  --mint-a9: rgba(146,255,231,0.91373);
  --mint-a10: rgba(174,254,237,0.96078);
  --mint-a11: rgba(103,255,222,0.82353);
  --mint-a12: rgba(203,254,233,0.96078);
  --orange-1: #17120e;
  --orange-2: #1e160f;
  --orange-3: #331e0b;
  --orange-4: #462100;
  --orange-5: #562800;
  --orange-6: #66350c;
  --orange-7: #7e451d;
  --orange-8: #a35829;
  --orange-9: #f76b15;
  --orange-10: #ff801f;
  --orange-11: #ffa057;
  --orange-12: #ffe0c2;
  --orange-a1: rgba(236,54,0,0.02745);
  --orange-a2: rgba(254,109,0,0.0549);
  --orange-a3: rgba(251,106,0,0.1451);
  --orange-a4: rgba(255,89,0,0.22353);
  --orange-a5: rgba(255,97,0,0.2902);
  --orange-a6: rgba(253,117,4,0.36078);
  --orange-a7: rgba(255,131,44,0.45882);
  --orange-a8: rgba(254,132,56,0.61569);
  --orange-a9: rgba(254,109,21,0.96863);
  --orange-a10: #ff801f;
  --orange-a11: #ffa057;
  --orange-a12: #ffe0c2;
  --pink-1: #191117;
  --pink-2: #21121d;
  --pink-3: #37172f;
  --pink-4: #4b143d;
  --pink-5: #591c47;
  --pink-6: #692955;
  --pink-7: #833869;
  --pink-8: #a84885;
  --pink-9: #d6409f;
  --pink-10: #de51a8;
  --pink-11: #ff8dcc;
  --pink-12: #fdd1ea;
  --pink-a1: rgba(244,18,188,0.03529);
  --pink-a2: rgba(244,32,187,0.07059);
  --pink-a3: rgba(254,55,204,0.16078);
  --pink-a4: rgba(252,30,196,0.24706);
  --pink-a5: rgba(253,53,194,0.30588);
  --pink-a6: rgba(253,81,199,0.37255);
  --pink-a7: rgba(253,98,200,0.48235);
  --pink-a8: rgba(255,104,200,0.63529);
  --pink-a9: rgba(254,73,188,0.83137);
  --pink-a10: rgba(255,92,192,0.86275);
  --pink-a11: #ff8dcc;
  --pink-a12: rgba(255,211,236,0.99216);
  --plum-1: #181118;
  --plum-2: #201320;
  --plum-3: #351a35;
  --plum-4: #451d47;
  --plum-5: #512454;
  --plum-6: #5e3061;
  --plum-7: #734079;
  --plum-8: #92549c;
  --plum-9: #ab4aba;
  --plum-10: #b658c4;
  --plum-11: #e796f3;
  --plum-12: #f4d4f4;
  --plum-a1: rgba(241,18,241,0.03137);
  --plum-a2: rgba(242,47,242,0.06667);
  --plum-a3: rgba(253,76,253,0.15294);
  --plum-a4: rgba(246,70,255,0.22745);
  --plum-a5: rgba(244,85,255,0.28235);
  --plum-a6: rgba(246,109,255,0.33725);
  --plum-a7: rgba(240,124,253,0.43922);
  --plum-a8: rgba(238,132,255,0.58431);
  --plum-a9: rgba(233,97,254,0.71373);
  --plum-a10: rgba(237,112,255,0.75294);
  --plum-a11: rgba(241,156,254,0.95294);
  --plum-a12: rgba(254,221,254,0.95686);
  --purple-1: #18111b;
  --purple-2: #1e1523;
  --purple-3: #301c3b;
  --purple-4: #3d224e;
  --purple-5: #48295c;
  --purple-6: #54346b;
  --purple-7: #664282;
  --purple-8: #8457aa;
  --purple-9: #8e4ec6;
  --purple-10: #9a5cd0;
  --purple-11: #d19dff;
  --purple-12: #ecd9fa;
  --purple-a1: rgba(180,18,249,0.04314);
  --purple-a2: rgba(183,68,247,0.07843);
  --purple-a3: rgba(193,80,255,0.17647);
  --purple-a4: rgba(187,83,253,0.25882);
  --purple-a5: rgba(190,92,253,0.31765);
  --purple-a6: rgba(193,109,253,0.38039);
  --purple-a7: rgba(195,120,253,0.47843);
  --purple-a8: rgba(196,126,255,0.64314);
  --purple-a9: rgba(182,97,255,0.76078);
  --purple-a10: rgba(188,111,255,0.80392);
  --purple-a11: #d19dff;
  --purple-a12: rgba(241,221,255,0.98039);
  --red-1: #191111;
  --red-2: #201314;
  --red-3: #3b1219;
  --red-4: #500f1c;
  --red-5: #611623;
  --red-6: #72232d;
  --red-7: #8c333a;
  --red-8: #b54548;
  --red-9: #e5484d;
  --red-10: #ec5d5e;
  --red-11: #ff9592;
  --red-12: #ffd1d9;
  --red-a1: rgba(244,18,18,0.03529);
  --red-a2: rgba(242,47,62,0.06667);
  --red-a3: rgba(255,23,63,0.17647);
  --red-a4: rgba(254,10,59,0.26667);
  --red-a5: rgba(255,32,71,0.33725);
  --red-a6: rgba(255,62,86,0.40784);
  --red-a7: rgba(255,83,97,0.51765);
  --red-a8: rgba(255,93,97,0.6902);
  --red-a9: rgba(254,78,84,0.89412);
  --red-a10: rgba(255,100,101,0.92157);
  --red-a11: #ff9592;
  --red-a12: #ffd1d9;
  --ruby-1: #191113;
  --ruby-2: #1e1517;
  --ruby-3: #3a141e;
  --ruby-4: #4e1325;
  --ruby-5: #5e1a2e;
  --ruby-6: #6f2539;
  --ruby-7: #883447;
  --ruby-8: #b3445a;
  --ruby-9: #e54666;
  --ruby-10: #ec5a72;
  --ruby-11: #ff949d;
  --ruby-12: #fed2e1;
  --ruby-a1: rgba(244,18,74,0.03529);
  --ruby-a2: rgba(254,90,127,0.0549);
  --ruby-a3: rgba(255,35,93,0.17255);
  --ruby-a4: rgba(253,25,94,0.25882);
  --ruby-a5: rgba(254,45,107,0.32549);
  --ruby-a6: rgba(255,68,118,0.39608);
  --ruby-a7: rgba(255,87,125,0.50196);
  --ruby-a8: rgba(255,92,124,0.68235);
  --ruby-a9: rgba(254,76,112,0.89412);
  --ruby-a10: rgba(255,97,123,0.92157);
  --ruby-a11: #ff949d;
  --ruby-a12: rgba(255,211,226,0.99608);
  --sky-1: #0d141f;
  --sky-2: #111a27;
  --sky-3: #112840;
  --sky-4: #113555;
  --sky-5: #154467;
  --sky-6: #1b537b;
  --sky-7: #1f6692;
  --sky-8: #197cae;
  --sky-9: #7ce2fe;
  --sky-10: #a8eeff;
  --sky-11: #75c7f0;
  --sky-12: #c2f3ff;
  --sky-a1: rgba(0,68,255,0.05882);
  --sky-a2: rgba(17,113,251,0.09412);
  --sky-a3: rgba(17,132,252,0.2);
  --sky-a4: rgba(18,143,255,0.28627);
  --sky-a5: rgba(28,157,253,0.36471);
  --sky-a6: rgba(40,165,255,0.44706);
  --sky-a7: rgba(43,173,254,0.5451);
  --sky-a8: rgba(29,178,254,0.66275);
  --sky-a9: rgba(124,227,255,0.99608);
  --sky-a10: #a8eeff;
  --sky-a11: rgba(124,211,255,0.93725);
  --sky-a12: #c2f3ff;
  --teal-1: #0d1514;
  --teal-2: #111c1b;
  --teal-3: #0d2d2a;
  --teal-4: #023b37;
  --teal-5: #084843;
  --teal-6: #145750;
  --teal-7: #1c6961;
  --teal-8: #207e73;
  --teal-9: #12a594;
  --teal-10: #0eb39e;
  --teal-11: #0bd8b6;
  --teal-12: #adf0dd;
  --teal-a1: rgba(0,222,171,0.01961);
  --teal-a2: rgba(18,251,230,0.04706);
  --teal-a3: rgba(0,255,230,0.11765);
  --teal-a4: rgba(0,255,233,0.17647);
  --teal-a5: rgba(0,255,234,0.23137);
  --teal-a6: rgba(28,255,232,0.29412);
  --teal-a7: rgba(46,253,232,0.37255);
  --teal-a8: rgba(50,255,231,0.45882);
  --teal-a9: rgba(19,255,228,0.62353);
  --teal-a10: rgba(13,255,224,0.68235);
  --teal-a11: rgba(10,254,213,0.83922);
  --teal-a12: rgba(184,255,235,0.93725);
  --tomato-1: #181111;
  --tomato-2: #1f1513;
  --tomato-3: #391714;
  --tomato-4: #4e1511;
  --tomato-5: #5e1c16;
  --tomato-6: #6e2920;
  --tomato-7: #853a2d;
  --tomato-8: #ac4d39;
  --tomato-9: #e54d2e;
  --tomato-10: #ec6142;
  --tomato-11: #ff977d;
  --tomato-12: #fbd3cb;
  --tomato-a1: rgba(241,18,18,0.03137);
  --tomato-a2: rgba(255,85,51,0.05882);
  --tomato-a3: rgba(255,53,35,0.16863);
  --tomato-a4: rgba(253,32,17,0.25882);
  --tomato-a5: rgba(254,51,33,0.32549);
  --tomato-a6: rgba(255,79,56,0.39216);
  --tomato-a7: rgba(253,100,74,0.4902);
  --tomato-a8: rgba(254,109,78,0.6549);
  --tomato-a9: rgba(254,84,49,0.89412);
  --tomato-a10: rgba(255,104,71,0.92157);
  --tomato-a11: #ff977d;
  --tomato-a12: rgba(255,214,206,0.98431);
  --violet-1: #14121f;
  --violet-2: #1b1525;
  --violet-3: #291f43;
  --violet-4: #33255b;
  --violet-5: #3c2e69;
  --violet-6: #473876;
  --violet-7: #56468b;
  --violet-8: #6958ad;
  --violet-9: #6e56cf;
  --violet-10: #7d66d9;
  --violet-11: #baa7ff;
  --violet-12: #e2ddfe;
  --violet-a1: rgba(68,34,255,0.05882);
  --violet-a2: rgba(133,63,249,0.08627);
  --violet-a3: rgba(131,84,254,0.21176);
  --violet-a4: rgba(125,81,253,0.31373);
  --violet-a5: rgba(132,95,253,0.37255);
  --violet-a6: rgba(143,108,253,0.42745);
  --violet-a7: rgba(152,121,255,0.51373);
  --violet-a8: rgba(151,125,254,0.65882);
  --violet-a9: rgba(134,104,255,0.8);
  --violet-a10: rgba(145,118,254,0.84314);
  --violet-a11: #baa7ff;
  --violet-a12: rgba(227,222,255,0.99608);
  --yellow-1: #14120b;
  --yellow-2: #1b180f;
  --yellow-3: #2d2305;
  --yellow-4: #362b00;
  --yellow-5: #433500;
  --yellow-6: #524202;
  --yellow-7: #665417;
  --yellow-8: #836a21;
  --yellow-9: #ffe629;
  --yellow-10: #ffff57;
  --yellow-11: #f5e147;
  --yellow-12: #f6eeb4;
  --yellow-a1: rgba(209,81,0,0.01569);
  --yellow-a2: rgba(249,180,0,0.04314);
  --yellow-a3: rgba(255,170,0,0.11765);
  --yellow-a4: rgba(253,183,0,0.15686);
  --yellow-a5: rgba(254,187,0,0.21176);
  --yellow-a6: rgba(254,196,0,0.27451);
  --yellow-a7: rgba(253,203,34,0.36078);
  --yellow-a8: rgba(253,202,50,0.48235);
  --yellow-a9: #ffe629;
  --yellow-a10: #ffff57;
  --yellow-a11: rgba(254,233,73,0.96078);
  --yellow-a12: rgba(254,246,186,0.96471);
  --gray-surface: rgba(33,33,33,0.50196);
  --gray-indicator: var(--gray-9);
  --gray-track: var(--gray-9);
  --mauve-surface: rgba(34,33,35,0.50196);
  --mauve-indicator: var(--mauve-9);
  --mauve-track: var(--mauve-9);
  --slate-surface: rgba(31,33,35,0.50196);
  --slate-indicator: var(--slate-9);
  --slate-track: var(--slate-9);
  --sage-surface: rgba(30,32,31,0.50196);
  --sage-indicator: var(--sage-9);
  --sage-track: var(--sage-9);
  --olive-surface: rgba(31,32,30,0.50196);
  --olive-indicator: var(--olive-9);
  --olive-track: var(--olive-9);
  --sand-surface: rgba(33,33,32,0.50196);
  --sand-indicator: var(--sand-9);
  --sand-track: var(--sand-9);
  --amber-surface: rgba(39,31,19,0.50196);
  --amber-indicator: var(--amber-9);
  --amber-track: var(--amber-9);
  --blue-surface: rgba(17,33,61,0.50196);
  --blue-indicator: var(--blue-9);
  --blue-track: var(--blue-9);
  --bronze-surface: rgba(39,33,29,0.50196);
  --bronze-indicator: var(--bronze-9);
  --bronze-track: var(--bronze-9);
  --brown-surface: rgba(39,31,27,0.50196);
  --brown-indicator: var(--brown-9);
  --brown-track: var(--brown-9);
  --crimson-surface: rgba(47,21,31,0.50196);
  --crimson-indicator: var(--crimson-9);
  --crimson-track: var(--crimson-9);
  --cyan-surface: rgba(17,37,45,0.50196);
  --cyan-indicator: var(--cyan-9);
  --cyan-track: var(--cyan-9);
  --gold-surface: rgba(37,35,29,0.50196);
  --gold-indicator: var(--gold-9);
  --gold-track: var(--gold-9);
  --grass-surface: rgba(25,35,27,0.50196);
  --grass-indicator: var(--grass-9);
  --grass-track: var(--grass-9);
  --green-surface: rgba(21,37,29,0.50196);
  --green-indicator: var(--green-9);
  --green-track: var(--green-9);
  --indigo-surface: rgba(23,29,59,0.50196);
  --indigo-indicator: var(--indigo-9);
  --indigo-track: var(--indigo-9);
  --iris-surface: rgba(29,27,57,0.50196);
  --iris-indicator: var(--iris-9);
  --iris-track: var(--iris-9);
  --jade-surface: rgba(19,39,31,0.50196);
  --jade-indicator: var(--jade-9);
  --jade-track: var(--jade-9);
  --lime-surface: rgba(27,33,21,0.50196);
  --lime-indicator: var(--lime-9);
  --lime-track: var(--lime-9);
  --mint-surface: rgba(21,39,39,0.50196);
  --mint-indicator: var(--mint-9);
  --mint-track: var(--mint-9);
  --orange-surface: rgba(39,29,19,0.50196);
  --orange-indicator: var(--orange-9);
  --orange-track: var(--orange-9);
  --pink-surface: rgba(49,19,41,0.50196);
  --pink-indicator: var(--pink-9);
  --pink-track: var(--pink-9);
  --plum-surface: rgba(47,21,47,0.50196);
  --plum-indicator: var(--plum-9);
  --plum-track: var(--plum-9);
  --purple-surface: rgba(43,23,53,0.50196);
  --purple-indicator: var(--purple-9);
  --purple-track: var(--purple-9);
  --red-surface: rgba(47,21,23,0.50196);
  --red-indicator: var(--red-9);
  --red-track: var(--red-9);
  --ruby-surface: rgba(43,25,29,0.50196);
  --ruby-indicator: var(--ruby-9);
  --ruby-track: var(--ruby-9);
  --sky-surface: rgba(19,35,59,0.50196);
  --sky-indicator: var(--sky-9);
  --sky-track: var(--sky-9);
  --teal-surface: rgba(19,39,37,0.50196);
  --teal-indicator: var(--teal-9);
  --teal-track: var(--teal-9);
  --tomato-surface: rgba(45,25,21,0.50196);
  --tomato-indicator: var(--tomato-9);
  --tomato-track: var(--tomato-9);
  --violet-surface: rgba(37,25,57,0.50196);
  --violet-indicator: var(--violet-9);
  --violet-track: var(--violet-9);
  --yellow-surface: rgba(35,31,19,0.50196);
  --yellow-indicator: var(--yellow-9);
  --yellow-track: var(--yellow-9);
}

:root {
  --gray-contrast: white;
  --mauve-contrast: white;
  --slate-contrast: white;
  --sage-contrast: white;
  --olive-contrast: white;
  --sand-contrast: white;
  --amber-contrast: #21201c;
  --blue-contrast: white;
  --bronze-contrast: white;
  --brown-contrast: white;
  --crimson-contrast: white;
  --cyan-contrast: white;
  --gold-contrast: white;
  --grass-contrast: white;
  --green-contrast: white;
  --indigo-contrast: white;
  --iris-contrast: white;
  --jade-contrast: white;
  --lime-contrast: #1d211c;
  --mint-contrast: #1a211e;
  --orange-contrast: white;
  --pink-contrast: white;
  --plum-contrast: white;
  --purple-contrast: white;
  --red-contrast: white;
  --ruby-contrast: white;
  --sky-contrast: #1c2024;
  --teal-contrast: white;
  --tomato-contrast: white;
  --violet-contrast: white;
  --yellow-contrast: #21201c;
  --black-a1: rgba(0, 0, 0, 0.05);
  --black-a2: rgba(0, 0, 0, 0.1);
  --black-a3: rgba(0, 0, 0, 0.15);
  --black-a4: rgba(0, 0, 0, 0.2);
  --black-a5: rgba(0, 0, 0, 0.3);
  --black-a6: rgba(0, 0, 0, 0.4);
  --black-a7: rgba(0, 0, 0, 0.5);
  --black-a8: rgba(0, 0, 0, 0.6);
  --black-a9: rgba(0, 0, 0, 0.7);
  --black-a10: rgba(0, 0, 0, 0.8);
  --black-a11: rgba(0, 0, 0, 0.9);
  --black-a12: rgba(0, 0, 0, 0.95);
  --white-a1: rgba(255, 255, 255, 0.05);
  --white-a2: rgba(255, 255, 255, 0.1);
  --white-a3: rgba(255, 255, 255, 0.15);
  --white-a4: rgba(255, 255, 255, 0.2);
  --white-a5: rgba(255, 255, 255, 0.3);
  --white-a6: rgba(255, 255, 255, 0.4);
  --white-a7: rgba(255, 255, 255, 0.5);
  --white-a8: rgba(255, 255, 255, 0.6);
  --white-a9: rgba(255, 255, 255, 0.7);
  --white-a10: rgba(255, 255, 255, 0.8);
  --white-a11: rgba(255, 255, 255, 0.9);
  --white-a12: rgba(255, 255, 255, 0.95);
}

@supports (color: color-mix(in oklab, white, black)) {
  .dark,
.dark-theme {
    --amber-track: color-mix(in oklab, var(--amber-8), var(--amber-9) 75%);
    --lime-track: color-mix(in oklab, var(--lime-8), var(--lime-9) 65%);
    --mint-track: color-mix(in oklab, var(--mint-8), var(--mint-9) 65%);
    --sky-track: color-mix(in oklab, var(--sky-8), var(--sky-9) 65%);
    --yellow-track: color-mix(in oklab, var(--yellow-8), var(--yellow-9) 65%);
  }
}

:where(.radix-themes) {
  --color-background: white;
  --color-overlay: var(--black-a6);
  --color-panel-solid: white;
  --color-panel-translucent: rgba(255, 255, 255, 0.7);
  --color-surface: rgba(255, 255, 255, 0.85);
  --color-transparent: rgba(0, 0, 0, 0);
  --shadow-1: inset 0 0 0 1px var(--gray-a5), inset 0 1.5px 2px 0 var(--gray-a2), inset 0 1.5px 2px 0 var(--black-a2);
  --shadow-2: 0 0 0 1px var(--gray-a3), 0 0 0 0.5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --shadow-3: 0 0 0 1px var(--gray-a3), 0 2px 3px -2px var(--gray-a3), 0 3px 12px -4px var(--black-a2), 0 4px 16px -8px var(--black-a2);
  --shadow-4: 0 0 0 1px var(--gray-a3), 0 8px 40px var(--black-a1), 0 12px 32px -16px var(--gray-a3);
  --shadow-5: 0 0 0 1px var(--gray-a3), 0 12px 60px var(--black-a3), 0 12px 32px -16px var(--gray-a5);
  --shadow-6: 0 0 0 1px var(--gray-a3), 0 12px 60px var(--black-a3), 0 16px 64px var(--gray-a2), 0 16px 36px -20px var(--gray-a7);
  --base-button-classic-after-inset: 2px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--gray-a4), inset 0 -2px 1px var(--gray-a3);
  --base-button-classic-box-shadow-bottom: inset 0 4px 2px -2px var(--white-a9), inset 0 2px 1px -1px var(--white-a9);
  --base-button-classic-disabled-box-shadow: var(--base-button-classic-box-shadow-top), var(--base-button-classic-box-shadow-bottom);
  --base-button-classic-active-filter: brightness(0.92) saturate(1.1);
  --base-button-classic-high-contrast-hover-filter: contrast(0.88) saturate(1.1) brightness(1.1);
  --base-button-classic-high-contrast-active-filter: contrast(0.82) saturate(1.2) brightness(1.16);
  --base-button-solid-active-filter: brightness(0.92) saturate(1.1);
  --base-button-solid-high-contrast-hover-filter: contrast(0.88) saturate(1.1) brightness(1.1);
  --base-button-solid-high-contrast-active-filter: contrast(0.82) saturate(1.2) brightness(1.16);
  --kbd-box-shadow: inset 0 -0.05em 0.5em var(--gray-a2), inset 0 0.05em var(--white-a12), inset 0 0.25em 0.5em var(--gray-a2), inset 0 -0.05em var(--gray-a6), 0 0 0 0.05em var(--gray-a5), 0 0.08em 0.17em var(--gray-a7);
  --progress-indicator-after-linear-gradient: var(--white-a5), var(--white-a9), var(--white-a5);
  --segmented-control-indicator-background-color: var(--color-background);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 2px 1px var(--white-a11), inset 0 -2px 1px var(--gray-a4) ;
  --slider-range-high-contrast-background-image: linear-gradient(var(--black-a8), var(--black-a8));
  --slider-disabled-blend-mode: multiply;
  --switch-disabled-blend-mode: multiply;
  --switch-high-contrast-checked-color-overlay: var(--black-a8);
  --switch-high-contrast-checked-active-before-filter: contrast(0.82) saturate(1.2) brightness(1.16);
  --switch-surface-checked-active-filter: brightness(0.92) saturate(1.1);
  --base-card-surface-box-shadow: 0 0 0 1px var(--gray-a5);
  --base-card-surface-hover-box-shadow: 0 0 0 1px var(--gray-a7);
  --base-card-surface-active-box-shadow: 0 0 0 1px var(--gray-a6);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a1), 0 1px 1px -1px var(--gray-a2), 0 2px 1px -2px var(--black-a1), 0 1px 3px -1px var(--black-a1);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 1px 1px 1px var(--black-a1), 0 2px 1px -1px var(--gray-a3), 0 2px 3px -2px var(--black-a1), 0 3px 12px -4px var(--gray-a3), 0 4px 16px -8px var(--black-a1);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 1px 1px 0 var(--black-a1), 0 2px 1px -2px var(--gray-a3), 0 2px 3px -3px var(--black-a1), 0 3px 12px -5px var(--gray-a3), 0 4px 16px -9px var(--black-a1);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a1), 0 1px 1px 0 var(--gray-a4), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a1), 0 1px 1px -1px var(--gray-a4), 0 2px 1px -2px var(--black-a1), 0 1px 3px -1px var(--black-a1);
  --base-card-classic-border-color: var(--gray-a3);
  --base-card-classic-hover-border-color: var(--gray-a3);
  --base-card-classic-active-border-color: var(--gray-a4);
}

.dark :where(.radix-themes:not(.light):not(.light-theme)) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, 0.25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(0.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(0.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(0.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(0.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -0.05em 0.5em var(--gray-a3), inset 0 0.05em var(--gray-a11), inset 0 0.25em 0.5em var(--gray-a2), inset 0 -0.1em var(--black-a11), 0 0 0 0.075em var(--gray-a7), 0 0.08em 0.17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9) ;
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

.dark-theme :where(.radix-themes:not(.light):not(.light-theme)) {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, 0.25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(0.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(0.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(0.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(0.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -0.05em 0.5em var(--gray-a3), inset 0 0.05em var(--gray-a11), inset 0 0.25em 0.5em var(--gray-a2), inset 0 -0.1em var(--black-a11), 0 0 0 0.075em var(--gray-a7), 0 0.08em 0.17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9) ;
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

.dark, .dark-theme {
  --color-background: var(--gray-1);
  --color-overlay: var(--black-a8);
  --color-panel-solid: var(--gray-2);
  --color-panel-translucent: var(--gray-a2);
  --color-surface: rgba(0, 0, 0, 0.25);
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  --base-button-classic-after-inset: 1px;
  --base-button-classic-box-shadow-top: inset 0 0 0 1px var(--white-a2), inset 0 4px 2px -2px var(--white-a3), inset 0 1px 1px var(--white-a6), inset 0 -1px 1px var(--black-a6);
  --base-button-classic-box-shadow-bottom: 0 0 transparent;
  --base-button-classic-disabled-box-shadow: inset 0 0 0 1px var(--gray-a5), inset 0 4px 2px -2px var(--gray-a2), inset 0 1px 1px var(--gray-a5), inset 0 -1px 1px var(--black-a3), inset 0 0 0 1px var(--gray-a2);
  --base-button-classic-active-filter: brightness(1.08);
  --base-button-classic-high-contrast-hover-filter: contrast(0.88) saturate(1.3) brightness(1.14);
  --base-button-classic-high-contrast-active-filter: brightness(0.95) saturate(1.2);
  --base-button-solid-active-filter: brightness(1.08);
  --base-button-solid-high-contrast-hover-filter: contrast(0.88) saturate(1.3) brightness(1.18);
  --base-button-solid-high-contrast-active-filter: brightness(0.95) saturate(1.2);
  --kbd-box-shadow: inset 0 -0.05em 0.5em var(--gray-a3), inset 0 0.05em var(--gray-a11), inset 0 0.25em 0.5em var(--gray-a2), inset 0 -0.1em var(--black-a11), 0 0 0 0.075em var(--gray-a7), 0 0.08em 0.17em var(--black-a12);
  --progress-indicator-after-linear-gradient: var(--white-a3), var(--white-a6), var(--white-a3);
  --segmented-control-indicator-background-color: var(--gray-a3);
  --select-trigger-classic-box-shadow: inset 0 0 0 1px var(--white-a4), inset 0 1px 1px var(--white-a4), inset 0 -1px 1px var(--black-a9) ;
  --slider-range-high-contrast-background-image: none;
  --slider-disabled-blend-mode: screen;
  --switch-disabled-blend-mode: screen;
  --switch-high-contrast-checked-color-overlay: transparent;
  --switch-high-contrast-checked-active-before-filter: brightness(1.08);
  --switch-surface-checked-active-filter: brightness(1.08);
  --base-card-classic-box-shadow-inner: 0 0 0 1px var(--base-card-classic-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-box-shadow-outer: 0 0 0 0 var(--base-card-classic-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-hover-box-shadow-inner: 0 0 0 1px var(--base-card-classic-hover-border-color), 0 0 1px 1px var(--gray-a4), 0 0 1px -1px var(--gray-a4), 0 0 3px -2px var(--gray-a3), 0 0 12px -2px var(--gray-a3), 0 0 16px -8px var(--gray-a7);
  --base-card-classic-hover-box-shadow-outer: 0 0 0 0 var(--base-card-classic-hover-border-color), 0 0 1px 0 var(--gray-a4), 0 0 1px -2px var(--gray-a4), 0 0 3px -3px var(--gray-a3), 0 0 12px -3px var(--gray-a3), 0 0 16px -9px var(--gray-a7);
  --base-card-classic-active-box-shadow-inner: 0 0 0 1px var(--base-card-classic-active-border-color), 0 0 0 1px var(--color-transparent), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --base-card-classic-active-box-shadow-outer: 0 0 0 0 var(--base-card-classic-active-border-color), 0 0 0 0 var(--color-transparent), 0 0 0 0 var(--black-a3), 0 1px 1px -1px var(--black-a6), 0 2px 1px -2px var(--black-a6), 0 1px 3px -1px var(--black-a5);
  --base-card-classic-border-color: var(--gray-a6);
  --base-card-classic-hover-border-color: var(--gray-a6);
  --base-card-classic-active-border-color: var(--gray-a6);
}

.radix-themes:where(.light, .light-theme),
  :root:where(:has(.radix-themes[data-is-root-theme='true']:where(.light, .light-theme))) {
  --csstools-color-scheme--dark:  ;
  color-scheme: light;
}

.radix-themes:where(.dark, .dark-theme),
  :root:where(:has(.radix-themes[data-is-root-theme='true']:where(.dark, .dark-theme))) {
  --csstools-color-scheme--dark: initial;
  color-scheme: dark;
}

.radix-themes,
[data-accent-color]:where(:not([data-accent-color='']):not([data-accent-color='gray'])) {
  --focus-1: var(--accent-1);
  --focus-2: var(--accent-2);
  --focus-3: var(--accent-3);
  --focus-4: var(--accent-4);
  --focus-5: var(--accent-5);
  --focus-6: var(--accent-6);
  --focus-7: var(--accent-7);
  --focus-8: var(--accent-8);
  --focus-9: var(--accent-9);
  --focus-10: var(--accent-10);
  --focus-11: var(--accent-11);
  --focus-12: var(--accent-12);
  --focus-a1: var(--accent-a1);
  --focus-a2: var(--accent-a2);
  --focus-a3: var(--accent-a3);
  --focus-a4: var(--accent-a4);
  --focus-a5: var(--accent-a5);
  --focus-a6: var(--accent-a6);
  --focus-a7: var(--accent-a7);
  --focus-a8: var(--accent-a8);
  --focus-a9: var(--accent-a9);
  --focus-a10: var(--accent-a10);
  --focus-a11: var(--accent-a11);
  --focus-a12: var(--accent-a12);
}

.radix-themes ::-moz-selection {
  background-color: var(--focus-a5);
}

.radix-themes ::selection {
  background-color: var(--focus-a5);
}

.radix-themes:where([data-has-background='true']) {
  background-color: var(--color-background);
}

.radix-themes:where([data-panel-background='solid']) {
  --color-panel: var(--color-panel-solid);
  --backdrop-filter-panel: none;
}

.radix-themes:where([data-panel-background='translucent']) {
  --color-panel: var(--color-panel-translucent);
  --backdrop-filter-panel: blur(64px);
}

[data-accent-color='amber'] {
  --accent-1: var(--amber-1);
  --accent-2: var(--amber-2);
  --accent-3: var(--amber-3);
  --accent-4: var(--amber-4);
  --accent-5: var(--amber-5);
  --accent-6: var(--amber-6);
  --accent-7: var(--amber-7);
  --accent-8: var(--amber-8);
  --accent-9: var(--amber-9);
  --accent-10: var(--amber-10);
  --accent-11: var(--amber-11);
  --accent-12: var(--amber-12);
  --accent-a1: var(--amber-a1);
  --accent-a2: var(--amber-a2);
  --accent-a3: var(--amber-a3);
  --accent-a4: var(--amber-a4);
  --accent-a5: var(--amber-a5);
  --accent-a6: var(--amber-a6);
  --accent-a7: var(--amber-a7);
  --accent-a8: var(--amber-a8);
  --accent-a9: var(--amber-a9);
  --accent-a10: var(--amber-a10);
  --accent-a11: var(--amber-a11);
  --accent-a12: var(--amber-a12);
  --accent-contrast: var(--amber-contrast);
  --accent-surface: var(--amber-surface);
  --accent-indicator: var(--amber-indicator);
  --accent-track: var(--amber-track);
}

[data-accent-color='blue'] {
  --accent-1: var(--blue-1);
  --accent-2: var(--blue-2);
  --accent-3: var(--blue-3);
  --accent-4: var(--blue-4);
  --accent-5: var(--blue-5);
  --accent-6: var(--blue-6);
  --accent-7: var(--blue-7);
  --accent-8: var(--blue-8);
  --accent-9: var(--blue-9);
  --accent-10: var(--blue-10);
  --accent-11: var(--blue-11);
  --accent-12: var(--blue-12);
  --accent-a1: var(--blue-a1);
  --accent-a2: var(--blue-a2);
  --accent-a3: var(--blue-a3);
  --accent-a4: var(--blue-a4);
  --accent-a5: var(--blue-a5);
  --accent-a6: var(--blue-a6);
  --accent-a7: var(--blue-a7);
  --accent-a8: var(--blue-a8);
  --accent-a9: var(--blue-a9);
  --accent-a10: var(--blue-a10);
  --accent-a11: var(--blue-a11);
  --accent-a12: var(--blue-a12);
  --accent-contrast: var(--blue-contrast);
  --accent-surface: var(--blue-surface);
  --accent-indicator: var(--blue-indicator);
  --accent-track: var(--blue-track);
}

[data-accent-color='bronze'] {
  --accent-1: var(--bronze-1);
  --accent-2: var(--bronze-2);
  --accent-3: var(--bronze-3);
  --accent-4: var(--bronze-4);
  --accent-5: var(--bronze-5);
  --accent-6: var(--bronze-6);
  --accent-7: var(--bronze-7);
  --accent-8: var(--bronze-8);
  --accent-9: var(--bronze-9);
  --accent-10: var(--bronze-10);
  --accent-11: var(--bronze-11);
  --accent-12: var(--bronze-12);
  --accent-a1: var(--bronze-a1);
  --accent-a2: var(--bronze-a2);
  --accent-a3: var(--bronze-a3);
  --accent-a4: var(--bronze-a4);
  --accent-a5: var(--bronze-a5);
  --accent-a6: var(--bronze-a6);
  --accent-a7: var(--bronze-a7);
  --accent-a8: var(--bronze-a8);
  --accent-a9: var(--bronze-a9);
  --accent-a10: var(--bronze-a10);
  --accent-a11: var(--bronze-a11);
  --accent-a12: var(--bronze-a12);
  --accent-contrast: var(--bronze-contrast);
  --accent-surface: var(--bronze-surface);
  --accent-indicator: var(--bronze-indicator);
  --accent-track: var(--bronze-track);
}

[data-accent-color='brown'] {
  --accent-1: var(--brown-1);
  --accent-2: var(--brown-2);
  --accent-3: var(--brown-3);
  --accent-4: var(--brown-4);
  --accent-5: var(--brown-5);
  --accent-6: var(--brown-6);
  --accent-7: var(--brown-7);
  --accent-8: var(--brown-8);
  --accent-9: var(--brown-9);
  --accent-10: var(--brown-10);
  --accent-11: var(--brown-11);
  --accent-12: var(--brown-12);
  --accent-a1: var(--brown-a1);
  --accent-a2: var(--brown-a2);
  --accent-a3: var(--brown-a3);
  --accent-a4: var(--brown-a4);
  --accent-a5: var(--brown-a5);
  --accent-a6: var(--brown-a6);
  --accent-a7: var(--brown-a7);
  --accent-a8: var(--brown-a8);
  --accent-a9: var(--brown-a9);
  --accent-a10: var(--brown-a10);
  --accent-a11: var(--brown-a11);
  --accent-a12: var(--brown-a12);
  --accent-contrast: var(--brown-contrast);
  --accent-surface: var(--brown-surface);
  --accent-indicator: var(--brown-indicator);
  --accent-track: var(--brown-track);
}

[data-accent-color='crimson'] {
  --accent-1: var(--crimson-1);
  --accent-2: var(--crimson-2);
  --accent-3: var(--crimson-3);
  --accent-4: var(--crimson-4);
  --accent-5: var(--crimson-5);
  --accent-6: var(--crimson-6);
  --accent-7: var(--crimson-7);
  --accent-8: var(--crimson-8);
  --accent-9: var(--crimson-9);
  --accent-10: var(--crimson-10);
  --accent-11: var(--crimson-11);
  --accent-12: var(--crimson-12);
  --accent-a1: var(--crimson-a1);
  --accent-a2: var(--crimson-a2);
  --accent-a3: var(--crimson-a3);
  --accent-a4: var(--crimson-a4);
  --accent-a5: var(--crimson-a5);
  --accent-a6: var(--crimson-a6);
  --accent-a7: var(--crimson-a7);
  --accent-a8: var(--crimson-a8);
  --accent-a9: var(--crimson-a9);
  --accent-a10: var(--crimson-a10);
  --accent-a11: var(--crimson-a11);
  --accent-a12: var(--crimson-a12);
  --accent-contrast: var(--crimson-contrast);
  --accent-surface: var(--crimson-surface);
  --accent-indicator: var(--crimson-indicator);
  --accent-track: var(--crimson-track);
}

[data-accent-color='cyan'] {
  --accent-1: var(--cyan-1);
  --accent-2: var(--cyan-2);
  --accent-3: var(--cyan-3);
  --accent-4: var(--cyan-4);
  --accent-5: var(--cyan-5);
  --accent-6: var(--cyan-6);
  --accent-7: var(--cyan-7);
  --accent-8: var(--cyan-8);
  --accent-9: var(--cyan-9);
  --accent-10: var(--cyan-10);
  --accent-11: var(--cyan-11);
  --accent-12: var(--cyan-12);
  --accent-a1: var(--cyan-a1);
  --accent-a2: var(--cyan-a2);
  --accent-a3: var(--cyan-a3);
  --accent-a4: var(--cyan-a4);
  --accent-a5: var(--cyan-a5);
  --accent-a6: var(--cyan-a6);
  --accent-a7: var(--cyan-a7);
  --accent-a8: var(--cyan-a8);
  --accent-a9: var(--cyan-a9);
  --accent-a10: var(--cyan-a10);
  --accent-a11: var(--cyan-a11);
  --accent-a12: var(--cyan-a12);
  --accent-contrast: var(--cyan-contrast);
  --accent-surface: var(--cyan-surface);
  --accent-indicator: var(--cyan-indicator);
  --accent-track: var(--cyan-track);
}

[data-accent-color='gold'] {
  --accent-1: var(--gold-1);
  --accent-2: var(--gold-2);
  --accent-3: var(--gold-3);
  --accent-4: var(--gold-4);
  --accent-5: var(--gold-5);
  --accent-6: var(--gold-6);
  --accent-7: var(--gold-7);
  --accent-8: var(--gold-8);
  --accent-9: var(--gold-9);
  --accent-10: var(--gold-10);
  --accent-11: var(--gold-11);
  --accent-12: var(--gold-12);
  --accent-a1: var(--gold-a1);
  --accent-a2: var(--gold-a2);
  --accent-a3: var(--gold-a3);
  --accent-a4: var(--gold-a4);
  --accent-a5: var(--gold-a5);
  --accent-a6: var(--gold-a6);
  --accent-a7: var(--gold-a7);
  --accent-a8: var(--gold-a8);
  --accent-a9: var(--gold-a9);
  --accent-a10: var(--gold-a10);
  --accent-a11: var(--gold-a11);
  --accent-a12: var(--gold-a12);
  --accent-contrast: var(--gold-contrast);
  --accent-surface: var(--gold-surface);
  --accent-indicator: var(--gold-indicator);
  --accent-track: var(--gold-track);
}

[data-accent-color='grass'] {
  --accent-1: var(--grass-1);
  --accent-2: var(--grass-2);
  --accent-3: var(--grass-3);
  --accent-4: var(--grass-4);
  --accent-5: var(--grass-5);
  --accent-6: var(--grass-6);
  --accent-7: var(--grass-7);
  --accent-8: var(--grass-8);
  --accent-9: var(--grass-9);
  --accent-10: var(--grass-10);
  --accent-11: var(--grass-11);
  --accent-12: var(--grass-12);
  --accent-a1: var(--grass-a1);
  --accent-a2: var(--grass-a2);
  --accent-a3: var(--grass-a3);
  --accent-a4: var(--grass-a4);
  --accent-a5: var(--grass-a5);
  --accent-a6: var(--grass-a6);
  --accent-a7: var(--grass-a7);
  --accent-a8: var(--grass-a8);
  --accent-a9: var(--grass-a9);
  --accent-a10: var(--grass-a10);
  --accent-a11: var(--grass-a11);
  --accent-a12: var(--grass-a12);
  --accent-contrast: var(--grass-contrast);
  --accent-surface: var(--grass-surface);
  --accent-indicator: var(--grass-indicator);
  --accent-track: var(--grass-track);
}

[data-accent-color='gray'] {
  --accent-1: var(--gray-1);
  --accent-2: var(--gray-2);
  --accent-3: var(--gray-3);
  --accent-4: var(--gray-4);
  --accent-5: var(--gray-5);
  --accent-6: var(--gray-6);
  --accent-7: var(--gray-7);
  --accent-8: var(--gray-8);
  --accent-9: var(--gray-9);
  --accent-10: var(--gray-10);
  --accent-11: var(--gray-11);
  --accent-12: var(--gray-12);
  --accent-a1: var(--gray-a1);
  --accent-a2: var(--gray-a2);
  --accent-a3: var(--gray-a3);
  --accent-a4: var(--gray-a4);
  --accent-a5: var(--gray-a5);
  --accent-a6: var(--gray-a6);
  --accent-a7: var(--gray-a7);
  --accent-a8: var(--gray-a8);
  --accent-a9: var(--gray-a9);
  --accent-a10: var(--gray-a10);
  --accent-a11: var(--gray-a11);
  --accent-a12: var(--gray-a12);
  --accent-contrast: var(--gray-contrast);
  --accent-surface: var(--gray-surface);
  --accent-indicator: var(--gray-indicator);
  --accent-track: var(--gray-track);
}

[data-accent-color='green'] {
  --accent-1: var(--green-1);
  --accent-2: var(--green-2);
  --accent-3: var(--green-3);
  --accent-4: var(--green-4);
  --accent-5: var(--green-5);
  --accent-6: var(--green-6);
  --accent-7: var(--green-7);
  --accent-8: var(--green-8);
  --accent-9: var(--green-9);
  --accent-10: var(--green-10);
  --accent-11: var(--green-11);
  --accent-12: var(--green-12);
  --accent-a1: var(--green-a1);
  --accent-a2: var(--green-a2);
  --accent-a3: var(--green-a3);
  --accent-a4: var(--green-a4);
  --accent-a5: var(--green-a5);
  --accent-a6: var(--green-a6);
  --accent-a7: var(--green-a7);
  --accent-a8: var(--green-a8);
  --accent-a9: var(--green-a9);
  --accent-a10: var(--green-a10);
  --accent-a11: var(--green-a11);
  --accent-a12: var(--green-a12);
  --accent-contrast: var(--green-contrast);
  --accent-surface: var(--green-surface);
  --accent-indicator: var(--green-indicator);
  --accent-track: var(--green-track);
}

[data-accent-color='indigo'] {
  --accent-1: var(--indigo-1);
  --accent-2: var(--indigo-2);
  --accent-3: var(--indigo-3);
  --accent-4: var(--indigo-4);
  --accent-5: var(--indigo-5);
  --accent-6: var(--indigo-6);
  --accent-7: var(--indigo-7);
  --accent-8: var(--indigo-8);
  --accent-9: var(--indigo-9);
  --accent-10: var(--indigo-10);
  --accent-11: var(--indigo-11);
  --accent-12: var(--indigo-12);
  --accent-a1: var(--indigo-a1);
  --accent-a2: var(--indigo-a2);
  --accent-a3: var(--indigo-a3);
  --accent-a4: var(--indigo-a4);
  --accent-a5: var(--indigo-a5);
  --accent-a6: var(--indigo-a6);
  --accent-a7: var(--indigo-a7);
  --accent-a8: var(--indigo-a8);
  --accent-a9: var(--indigo-a9);
  --accent-a10: var(--indigo-a10);
  --accent-a11: var(--indigo-a11);
  --accent-a12: var(--indigo-a12);
  --accent-contrast: var(--indigo-contrast);
  --accent-surface: var(--indigo-surface);
  --accent-indicator: var(--indigo-indicator);
  --accent-track: var(--indigo-track);
}

[data-accent-color='iris'] {
  --accent-1: var(--iris-1);
  --accent-2: var(--iris-2);
  --accent-3: var(--iris-3);
  --accent-4: var(--iris-4);
  --accent-5: var(--iris-5);
  --accent-6: var(--iris-6);
  --accent-7: var(--iris-7);
  --accent-8: var(--iris-8);
  --accent-9: var(--iris-9);
  --accent-10: var(--iris-10);
  --accent-11: var(--iris-11);
  --accent-12: var(--iris-12);
  --accent-a1: var(--iris-a1);
  --accent-a2: var(--iris-a2);
  --accent-a3: var(--iris-a3);
  --accent-a4: var(--iris-a4);
  --accent-a5: var(--iris-a5);
  --accent-a6: var(--iris-a6);
  --accent-a7: var(--iris-a7);
  --accent-a8: var(--iris-a8);
  --accent-a9: var(--iris-a9);
  --accent-a10: var(--iris-a10);
  --accent-a11: var(--iris-a11);
  --accent-a12: var(--iris-a12);
  --accent-contrast: var(--iris-contrast);
  --accent-surface: var(--iris-surface);
  --accent-indicator: var(--iris-indicator);
  --accent-track: var(--iris-track);
}

[data-accent-color='jade'] {
  --accent-1: var(--jade-1);
  --accent-2: var(--jade-2);
  --accent-3: var(--jade-3);
  --accent-4: var(--jade-4);
  --accent-5: var(--jade-5);
  --accent-6: var(--jade-6);
  --accent-7: var(--jade-7);
  --accent-8: var(--jade-8);
  --accent-9: var(--jade-9);
  --accent-10: var(--jade-10);
  --accent-11: var(--jade-11);
  --accent-12: var(--jade-12);
  --accent-a1: var(--jade-a1);
  --accent-a2: var(--jade-a2);
  --accent-a3: var(--jade-a3);
  --accent-a4: var(--jade-a4);
  --accent-a5: var(--jade-a5);
  --accent-a6: var(--jade-a6);
  --accent-a7: var(--jade-a7);
  --accent-a8: var(--jade-a8);
  --accent-a9: var(--jade-a9);
  --accent-a10: var(--jade-a10);
  --accent-a11: var(--jade-a11);
  --accent-a12: var(--jade-a12);
  --accent-contrast: var(--jade-contrast);
  --accent-surface: var(--jade-surface);
  --accent-indicator: var(--jade-indicator);
  --accent-track: var(--jade-track);
}

[data-accent-color='lime'] {
  --accent-1: var(--lime-1);
  --accent-2: var(--lime-2);
  --accent-3: var(--lime-3);
  --accent-4: var(--lime-4);
  --accent-5: var(--lime-5);
  --accent-6: var(--lime-6);
  --accent-7: var(--lime-7);
  --accent-8: var(--lime-8);
  --accent-9: var(--lime-9);
  --accent-10: var(--lime-10);
  --accent-11: var(--lime-11);
  --accent-12: var(--lime-12);
  --accent-a1: var(--lime-a1);
  --accent-a2: var(--lime-a2);
  --accent-a3: var(--lime-a3);
  --accent-a4: var(--lime-a4);
  --accent-a5: var(--lime-a5);
  --accent-a6: var(--lime-a6);
  --accent-a7: var(--lime-a7);
  --accent-a8: var(--lime-a8);
  --accent-a9: var(--lime-a9);
  --accent-a10: var(--lime-a10);
  --accent-a11: var(--lime-a11);
  --accent-a12: var(--lime-a12);
  --accent-contrast: var(--lime-contrast);
  --accent-surface: var(--lime-surface);
  --accent-indicator: var(--lime-indicator);
  --accent-track: var(--lime-track);
}

[data-accent-color='mint'] {
  --accent-1: var(--mint-1);
  --accent-2: var(--mint-2);
  --accent-3: var(--mint-3);
  --accent-4: var(--mint-4);
  --accent-5: var(--mint-5);
  --accent-6: var(--mint-6);
  --accent-7: var(--mint-7);
  --accent-8: var(--mint-8);
  --accent-9: var(--mint-9);
  --accent-10: var(--mint-10);
  --accent-11: var(--mint-11);
  --accent-12: var(--mint-12);
  --accent-a1: var(--mint-a1);
  --accent-a2: var(--mint-a2);
  --accent-a3: var(--mint-a3);
  --accent-a4: var(--mint-a4);
  --accent-a5: var(--mint-a5);
  --accent-a6: var(--mint-a6);
  --accent-a7: var(--mint-a7);
  --accent-a8: var(--mint-a8);
  --accent-a9: var(--mint-a9);
  --accent-a10: var(--mint-a10);
  --accent-a11: var(--mint-a11);
  --accent-a12: var(--mint-a12);
  --accent-contrast: var(--mint-contrast);
  --accent-surface: var(--mint-surface);
  --accent-indicator: var(--mint-indicator);
  --accent-track: var(--mint-track);
}

[data-accent-color='orange'] {
  --accent-1: var(--orange-1);
  --accent-2: var(--orange-2);
  --accent-3: var(--orange-3);
  --accent-4: var(--orange-4);
  --accent-5: var(--orange-5);
  --accent-6: var(--orange-6);
  --accent-7: var(--orange-7);
  --accent-8: var(--orange-8);
  --accent-9: var(--orange-9);
  --accent-10: var(--orange-10);
  --accent-11: var(--orange-11);
  --accent-12: var(--orange-12);
  --accent-a1: var(--orange-a1);
  --accent-a2: var(--orange-a2);
  --accent-a3: var(--orange-a3);
  --accent-a4: var(--orange-a4);
  --accent-a5: var(--orange-a5);
  --accent-a6: var(--orange-a6);
  --accent-a7: var(--orange-a7);
  --accent-a8: var(--orange-a8);
  --accent-a9: var(--orange-a9);
  --accent-a10: var(--orange-a10);
  --accent-a11: var(--orange-a11);
  --accent-a12: var(--orange-a12);
  --accent-contrast: var(--orange-contrast);
  --accent-surface: var(--orange-surface);
  --accent-indicator: var(--orange-indicator);
  --accent-track: var(--orange-track);
}

[data-accent-color='pink'] {
  --accent-1: var(--pink-1);
  --accent-2: var(--pink-2);
  --accent-3: var(--pink-3);
  --accent-4: var(--pink-4);
  --accent-5: var(--pink-5);
  --accent-6: var(--pink-6);
  --accent-7: var(--pink-7);
  --accent-8: var(--pink-8);
  --accent-9: var(--pink-9);
  --accent-10: var(--pink-10);
  --accent-11: var(--pink-11);
  --accent-12: var(--pink-12);
  --accent-a1: var(--pink-a1);
  --accent-a2: var(--pink-a2);
  --accent-a3: var(--pink-a3);
  --accent-a4: var(--pink-a4);
  --accent-a5: var(--pink-a5);
  --accent-a6: var(--pink-a6);
  --accent-a7: var(--pink-a7);
  --accent-a8: var(--pink-a8);
  --accent-a9: var(--pink-a9);
  --accent-a10: var(--pink-a10);
  --accent-a11: var(--pink-a11);
  --accent-a12: var(--pink-a12);
  --accent-contrast: var(--pink-contrast);
  --accent-surface: var(--pink-surface);
  --accent-indicator: var(--pink-indicator);
  --accent-track: var(--pink-track);
}

[data-accent-color='plum'] {
  --accent-1: var(--plum-1);
  --accent-2: var(--plum-2);
  --accent-3: var(--plum-3);
  --accent-4: var(--plum-4);
  --accent-5: var(--plum-5);
  --accent-6: var(--plum-6);
  --accent-7: var(--plum-7);
  --accent-8: var(--plum-8);
  --accent-9: var(--plum-9);
  --accent-10: var(--plum-10);
  --accent-11: var(--plum-11);
  --accent-12: var(--plum-12);
  --accent-a1: var(--plum-a1);
  --accent-a2: var(--plum-a2);
  --accent-a3: var(--plum-a3);
  --accent-a4: var(--plum-a4);
  --accent-a5: var(--plum-a5);
  --accent-a6: var(--plum-a6);
  --accent-a7: var(--plum-a7);
  --accent-a8: var(--plum-a8);
  --accent-a9: var(--plum-a9);
  --accent-a10: var(--plum-a10);
  --accent-a11: var(--plum-a11);
  --accent-a12: var(--plum-a12);
  --accent-contrast: var(--plum-contrast);
  --accent-surface: var(--plum-surface);
  --accent-indicator: var(--plum-indicator);
  --accent-track: var(--plum-track);
}

[data-accent-color='purple'] {
  --accent-1: var(--purple-1);
  --accent-2: var(--purple-2);
  --accent-3: var(--purple-3);
  --accent-4: var(--purple-4);
  --accent-5: var(--purple-5);
  --accent-6: var(--purple-6);
  --accent-7: var(--purple-7);
  --accent-8: var(--purple-8);
  --accent-9: var(--purple-9);
  --accent-10: var(--purple-10);
  --accent-11: var(--purple-11);
  --accent-12: var(--purple-12);
  --accent-a1: var(--purple-a1);
  --accent-a2: var(--purple-a2);
  --accent-a3: var(--purple-a3);
  --accent-a4: var(--purple-a4);
  --accent-a5: var(--purple-a5);
  --accent-a6: var(--purple-a6);
  --accent-a7: var(--purple-a7);
  --accent-a8: var(--purple-a8);
  --accent-a9: var(--purple-a9);
  --accent-a10: var(--purple-a10);
  --accent-a11: var(--purple-a11);
  --accent-a12: var(--purple-a12);
  --accent-contrast: var(--purple-contrast);
  --accent-surface: var(--purple-surface);
  --accent-indicator: var(--purple-indicator);
  --accent-track: var(--purple-track);
}

[data-accent-color='red'] {
  --accent-1: var(--red-1);
  --accent-2: var(--red-2);
  --accent-3: var(--red-3);
  --accent-4: var(--red-4);
  --accent-5: var(--red-5);
  --accent-6: var(--red-6);
  --accent-7: var(--red-7);
  --accent-8: var(--red-8);
  --accent-9: var(--red-9);
  --accent-10: var(--red-10);
  --accent-11: var(--red-11);
  --accent-12: var(--red-12);
  --accent-a1: var(--red-a1);
  --accent-a2: var(--red-a2);
  --accent-a3: var(--red-a3);
  --accent-a4: var(--red-a4);
  --accent-a5: var(--red-a5);
  --accent-a6: var(--red-a6);
  --accent-a7: var(--red-a7);
  --accent-a8: var(--red-a8);
  --accent-a9: var(--red-a9);
  --accent-a10: var(--red-a10);
  --accent-a11: var(--red-a11);
  --accent-a12: var(--red-a12);
  --accent-contrast: var(--red-contrast);
  --accent-surface: var(--red-surface);
  --accent-indicator: var(--red-indicator);
  --accent-track: var(--red-track);
}

[data-accent-color='ruby'] {
  --accent-1: var(--ruby-1);
  --accent-2: var(--ruby-2);
  --accent-3: var(--ruby-3);
  --accent-4: var(--ruby-4);
  --accent-5: var(--ruby-5);
  --accent-6: var(--ruby-6);
  --accent-7: var(--ruby-7);
  --accent-8: var(--ruby-8);
  --accent-9: var(--ruby-9);
  --accent-10: var(--ruby-10);
  --accent-11: var(--ruby-11);
  --accent-12: var(--ruby-12);
  --accent-a1: var(--ruby-a1);
  --accent-a2: var(--ruby-a2);
  --accent-a3: var(--ruby-a3);
  --accent-a4: var(--ruby-a4);
  --accent-a5: var(--ruby-a5);
  --accent-a6: var(--ruby-a6);
  --accent-a7: var(--ruby-a7);
  --accent-a8: var(--ruby-a8);
  --accent-a9: var(--ruby-a9);
  --accent-a10: var(--ruby-a10);
  --accent-a11: var(--ruby-a11);
  --accent-a12: var(--ruby-a12);
  --accent-contrast: var(--ruby-contrast);
  --accent-surface: var(--ruby-surface);
  --accent-indicator: var(--ruby-indicator);
  --accent-track: var(--ruby-track);
}

[data-accent-color='sky'] {
  --accent-1: var(--sky-1);
  --accent-2: var(--sky-2);
  --accent-3: var(--sky-3);
  --accent-4: var(--sky-4);
  --accent-5: var(--sky-5);
  --accent-6: var(--sky-6);
  --accent-7: var(--sky-7);
  --accent-8: var(--sky-8);
  --accent-9: var(--sky-9);
  --accent-10: var(--sky-10);
  --accent-11: var(--sky-11);
  --accent-12: var(--sky-12);
  --accent-a1: var(--sky-a1);
  --accent-a2: var(--sky-a2);
  --accent-a3: var(--sky-a3);
  --accent-a4: var(--sky-a4);
  --accent-a5: var(--sky-a5);
  --accent-a6: var(--sky-a6);
  --accent-a7: var(--sky-a7);
  --accent-a8: var(--sky-a8);
  --accent-a9: var(--sky-a9);
  --accent-a10: var(--sky-a10);
  --accent-a11: var(--sky-a11);
  --accent-a12: var(--sky-a12);
  --accent-contrast: var(--sky-contrast);
  --accent-surface: var(--sky-surface);
  --accent-indicator: var(--sky-indicator);
  --accent-track: var(--sky-track);
}

[data-accent-color='teal'] {
  --accent-1: var(--teal-1);
  --accent-2: var(--teal-2);
  --accent-3: var(--teal-3);
  --accent-4: var(--teal-4);
  --accent-5: var(--teal-5);
  --accent-6: var(--teal-6);
  --accent-7: var(--teal-7);
  --accent-8: var(--teal-8);
  --accent-9: var(--teal-9);
  --accent-10: var(--teal-10);
  --accent-11: var(--teal-11);
  --accent-12: var(--teal-12);
  --accent-a1: var(--teal-a1);
  --accent-a2: var(--teal-a2);
  --accent-a3: var(--teal-a3);
  --accent-a4: var(--teal-a4);
  --accent-a5: var(--teal-a5);
  --accent-a6: var(--teal-a6);
  --accent-a7: var(--teal-a7);
  --accent-a8: var(--teal-a8);
  --accent-a9: var(--teal-a9);
  --accent-a10: var(--teal-a10);
  --accent-a11: var(--teal-a11);
  --accent-a12: var(--teal-a12);
  --accent-contrast: var(--teal-contrast);
  --accent-surface: var(--teal-surface);
  --accent-indicator: var(--teal-indicator);
  --accent-track: var(--teal-track);
}

[data-accent-color='tomato'] {
  --accent-1: var(--tomato-1);
  --accent-2: var(--tomato-2);
  --accent-3: var(--tomato-3);
  --accent-4: var(--tomato-4);
  --accent-5: var(--tomato-5);
  --accent-6: var(--tomato-6);
  --accent-7: var(--tomato-7);
  --accent-8: var(--tomato-8);
  --accent-9: var(--tomato-9);
  --accent-10: var(--tomato-10);
  --accent-11: var(--tomato-11);
  --accent-12: var(--tomato-12);
  --accent-a1: var(--tomato-a1);
  --accent-a2: var(--tomato-a2);
  --accent-a3: var(--tomato-a3);
  --accent-a4: var(--tomato-a4);
  --accent-a5: var(--tomato-a5);
  --accent-a6: var(--tomato-a6);
  --accent-a7: var(--tomato-a7);
  --accent-a8: var(--tomato-a8);
  --accent-a9: var(--tomato-a9);
  --accent-a10: var(--tomato-a10);
  --accent-a11: var(--tomato-a11);
  --accent-a12: var(--tomato-a12);
  --accent-contrast: var(--tomato-contrast);
  --accent-surface: var(--tomato-surface);
  --accent-indicator: var(--tomato-indicator);
  --accent-track: var(--tomato-track);
}

[data-accent-color='violet'] {
  --accent-1: var(--violet-1);
  --accent-2: var(--violet-2);
  --accent-3: var(--violet-3);
  --accent-4: var(--violet-4);
  --accent-5: var(--violet-5);
  --accent-6: var(--violet-6);
  --accent-7: var(--violet-7);
  --accent-8: var(--violet-8);
  --accent-9: var(--violet-9);
  --accent-10: var(--violet-10);
  --accent-11: var(--violet-11);
  --accent-12: var(--violet-12);
  --accent-a1: var(--violet-a1);
  --accent-a2: var(--violet-a2);
  --accent-a3: var(--violet-a3);
  --accent-a4: var(--violet-a4);
  --accent-a5: var(--violet-a5);
  --accent-a6: var(--violet-a6);
  --accent-a7: var(--violet-a7);
  --accent-a8: var(--violet-a8);
  --accent-a9: var(--violet-a9);
  --accent-a10: var(--violet-a10);
  --accent-a11: var(--violet-a11);
  --accent-a12: var(--violet-a12);
  --accent-contrast: var(--violet-contrast);
  --accent-surface: var(--violet-surface);
  --accent-indicator: var(--violet-indicator);
  --accent-track: var(--violet-track);
}

[data-accent-color='yellow'] {
  --accent-1: var(--yellow-1);
  --accent-2: var(--yellow-2);
  --accent-3: var(--yellow-3);
  --accent-4: var(--yellow-4);
  --accent-5: var(--yellow-5);
  --accent-6: var(--yellow-6);
  --accent-7: var(--yellow-7);
  --accent-8: var(--yellow-8);
  --accent-9: var(--yellow-9);
  --accent-10: var(--yellow-10);
  --accent-11: var(--yellow-11);
  --accent-12: var(--yellow-12);
  --accent-a1: var(--yellow-a1);
  --accent-a2: var(--yellow-a2);
  --accent-a3: var(--yellow-a3);
  --accent-a4: var(--yellow-a4);
  --accent-a5: var(--yellow-a5);
  --accent-a6: var(--yellow-a6);
  --accent-a7: var(--yellow-a7);
  --accent-a8: var(--yellow-a8);
  --accent-a9: var(--yellow-a9);
  --accent-a10: var(--yellow-a10);
  --accent-a11: var(--yellow-a11);
  --accent-a12: var(--yellow-a12);
  --accent-contrast: var(--yellow-contrast);
  --accent-surface: var(--yellow-surface);
  --accent-indicator: var(--yellow-indicator);
  --accent-track: var(--yellow-track);
}

.radix-themes:where([data-gray-color='mauve']) {
  --gray-1: var(--mauve-1);
  --gray-2: var(--mauve-2);
  --gray-3: var(--mauve-3);
  --gray-4: var(--mauve-4);
  --gray-5: var(--mauve-5);
  --gray-6: var(--mauve-6);
  --gray-7: var(--mauve-7);
  --gray-8: var(--mauve-8);
  --gray-9: var(--mauve-9);
  --gray-10: var(--mauve-10);
  --gray-11: var(--mauve-11);
  --gray-12: var(--mauve-12);
  --gray-a1: var(--mauve-a1);
  --gray-a2: var(--mauve-a2);
  --gray-a3: var(--mauve-a3);
  --gray-a4: var(--mauve-a4);
  --gray-a5: var(--mauve-a5);
  --gray-a6: var(--mauve-a6);
  --gray-a7: var(--mauve-a7);
  --gray-a8: var(--mauve-a8);
  --gray-a9: var(--mauve-a9);
  --gray-a10: var(--mauve-a10);
  --gray-a11: var(--mauve-a11);
  --gray-a12: var(--mauve-a12);
  --gray-contrast: var(--mauve-contrast);
  --gray-surface: var(--mauve-surface);
  --gray-indicator: var(--mauve-indicator);
  --gray-track: var(--mauve-track);
}

.radix-themes:where([data-gray-color='olive']) {
  --gray-1: var(--olive-1);
  --gray-2: var(--olive-2);
  --gray-3: var(--olive-3);
  --gray-4: var(--olive-4);
  --gray-5: var(--olive-5);
  --gray-6: var(--olive-6);
  --gray-7: var(--olive-7);
  --gray-8: var(--olive-8);
  --gray-9: var(--olive-9);
  --gray-10: var(--olive-10);
  --gray-11: var(--olive-11);
  --gray-12: var(--olive-12);
  --gray-a1: var(--olive-a1);
  --gray-a2: var(--olive-a2);
  --gray-a3: var(--olive-a3);
  --gray-a4: var(--olive-a4);
  --gray-a5: var(--olive-a5);
  --gray-a6: var(--olive-a6);
  --gray-a7: var(--olive-a7);
  --gray-a8: var(--olive-a8);
  --gray-a9: var(--olive-a9);
  --gray-a10: var(--olive-a10);
  --gray-a11: var(--olive-a11);
  --gray-a12: var(--olive-a12);
  --gray-contrast: var(--olive-contrast);
  --gray-surface: var(--olive-surface);
  --gray-indicator: var(--olive-indicator);
  --gray-track: var(--olive-track);
}

.radix-themes:where([data-gray-color='sage']) {
  --gray-1: var(--sage-1);
  --gray-2: var(--sage-2);
  --gray-3: var(--sage-3);
  --gray-4: var(--sage-4);
  --gray-5: var(--sage-5);
  --gray-6: var(--sage-6);
  --gray-7: var(--sage-7);
  --gray-8: var(--sage-8);
  --gray-9: var(--sage-9);
  --gray-10: var(--sage-10);
  --gray-11: var(--sage-11);
  --gray-12: var(--sage-12);
  --gray-a1: var(--sage-a1);
  --gray-a2: var(--sage-a2);
  --gray-a3: var(--sage-a3);
  --gray-a4: var(--sage-a4);
  --gray-a5: var(--sage-a5);
  --gray-a6: var(--sage-a6);
  --gray-a7: var(--sage-a7);
  --gray-a8: var(--sage-a8);
  --gray-a9: var(--sage-a9);
  --gray-a10: var(--sage-a10);
  --gray-a11: var(--sage-a11);
  --gray-a12: var(--sage-a12);
  --gray-contrast: var(--sage-contrast);
  --gray-surface: var(--sage-surface);
  --gray-indicator: var(--sage-indicator);
  --gray-track: var(--sage-track);
}

.radix-themes:where([data-gray-color='sand']) {
  --gray-1: var(--sand-1);
  --gray-2: var(--sand-2);
  --gray-3: var(--sand-3);
  --gray-4: var(--sand-4);
  --gray-5: var(--sand-5);
  --gray-6: var(--sand-6);
  --gray-7: var(--sand-7);
  --gray-8: var(--sand-8);
  --gray-9: var(--sand-9);
  --gray-10: var(--sand-10);
  --gray-11: var(--sand-11);
  --gray-12: var(--sand-12);
  --gray-a1: var(--sand-a1);
  --gray-a2: var(--sand-a2);
  --gray-a3: var(--sand-a3);
  --gray-a4: var(--sand-a4);
  --gray-a5: var(--sand-a5);
  --gray-a6: var(--sand-a6);
  --gray-a7: var(--sand-a7);
  --gray-a8: var(--sand-a8);
  --gray-a9: var(--sand-a9);
  --gray-a10: var(--sand-a10);
  --gray-a11: var(--sand-a11);
  --gray-a12: var(--sand-a12);
  --gray-contrast: var(--sand-contrast);
  --gray-surface: var(--sand-surface);
  --gray-indicator: var(--sand-indicator);
  --gray-track: var(--sand-track);
}

.radix-themes:where([data-gray-color='slate']) {
  --gray-1: var(--slate-1);
  --gray-2: var(--slate-2);
  --gray-3: var(--slate-3);
  --gray-4: var(--slate-4);
  --gray-5: var(--slate-5);
  --gray-6: var(--slate-6);
  --gray-7: var(--slate-7);
  --gray-8: var(--slate-8);
  --gray-9: var(--slate-9);
  --gray-10: var(--slate-10);
  --gray-11: var(--slate-11);
  --gray-12: var(--slate-12);
  --gray-a1: var(--slate-a1);
  --gray-a2: var(--slate-a2);
  --gray-a3: var(--slate-a3);
  --gray-a4: var(--slate-a4);
  --gray-a5: var(--slate-a5);
  --gray-a6: var(--slate-a6);
  --gray-a7: var(--slate-a7);
  --gray-a8: var(--slate-a8);
  --gray-a9: var(--slate-a9);
  --gray-a10: var(--slate-a10);
  --gray-a11: var(--slate-a11);
  --gray-a12: var(--slate-a12);
  --gray-contrast: var(--slate-contrast);
  --gray-surface: var(--slate-surface);
  --gray-indicator: var(--slate-indicator);
  --gray-track: var(--slate-track);
}

.radix-themes {
  --cursor-button: default;
  --cursor-checkbox: default;
  --cursor-disabled: not-allowed;
  --cursor-link: pointer;
  --cursor-menu-item: default;
  --cursor-radio: default;
  --cursor-slider-thumb: default;
  --cursor-slider-thumb-active: default;
  --cursor-switch: default;
  --space-1: calc(4px * var(--scaling));
  --space-2: calc(8px * var(--scaling));
  --space-3: calc(12px * var(--scaling));
  --space-4: calc(16px * var(--scaling));
  --space-5: calc(24px * var(--scaling));
  --space-6: calc(32px * var(--scaling));
  --space-7: calc(40px * var(--scaling));
  --space-8: calc(48px * var(--scaling));
  --space-9: calc(64px * var(--scaling));
  --font-size-1: calc(12px * var(--scaling));
  --font-size-2: calc(14px * var(--scaling));
  --font-size-3: calc(16px * var(--scaling));
  --font-size-4: calc(18px * var(--scaling));
  --font-size-5: calc(20px * var(--scaling));
  --font-size-6: calc(24px * var(--scaling));
  --font-size-7: calc(28px * var(--scaling));
  --font-size-8: calc(35px * var(--scaling));
  --font-size-9: calc(60px * var(--scaling));
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --line-height-1: calc(16px * var(--scaling));
  --line-height-2: calc(20px * var(--scaling));
  --line-height-3: calc(24px * var(--scaling));
  --line-height-4: calc(26px * var(--scaling));
  --line-height-5: calc(28px * var(--scaling));
  --line-height-6: calc(30px * var(--scaling));
  --line-height-7: calc(36px * var(--scaling));
  --line-height-8: calc(40px * var(--scaling));
  --line-height-9: calc(60px * var(--scaling));
  --letter-spacing-1: 0.0025em;
  --letter-spacing-2: 0em;
  --letter-spacing-3: 0em;
  --letter-spacing-4: -0.0025em;
  --letter-spacing-5: -0.005em;
  --letter-spacing-6: -0.00625em;
  --letter-spacing-7: -0.0075em;
  --letter-spacing-8: -0.01em;
  --letter-spacing-9: -0.025em;
  --default-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI (Custom)', Roboto, 'Helvetica Neue', 'Open Sans (Custom)', system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  --default-font-size: var(--font-size-3);
  --default-font-style: normal;
  --default-font-weight: var(--font-weight-regular);
  --default-line-height: 1.5;
  --default-letter-spacing: 0em;
  --default-leading-trim-start: 0.42em;
  --default-leading-trim-end: 0.36em;
  --heading-font-family: var(--default-font-family);
  --heading-font-size-adjust: 1;
  --heading-font-style: normal;
  --heading-leading-trim-start: var(--default-leading-trim-start);
  --heading-leading-trim-end: var(--default-leading-trim-end);
  --heading-letter-spacing: 0em;
  --heading-line-height-1: calc(16px * var(--scaling));
  --heading-line-height-2: calc(18px * var(--scaling));
  --heading-line-height-3: calc(22px * var(--scaling));
  --heading-line-height-4: calc(24px * var(--scaling));
  --heading-line-height-5: calc(26px * var(--scaling));
  --heading-line-height-6: calc(30px * var(--scaling));
  --heading-line-height-7: calc(36px * var(--scaling));
  --heading-line-height-8: calc(40px * var(--scaling));
  --heading-line-height-9: calc(60px * var(--scaling));
  --code-font-family: 'Menlo', 'Consolas (Custom)', 'Bitstream Vera Sans Mono', monospace, 'Apple Color Emoji', 'Segoe UI Emoji';
  --code-font-size-adjust: 0.95;
  --code-font-style: normal;
  --code-font-weight: inherit;
  --code-letter-spacing: -0.007em;
  --code-padding-top: 0.1em;
  --code-padding-bottom: 0.1em;
  --code-padding-left: 0.25em;
  --code-padding-right: 0.25em;
  --strong-font-family: var(--default-font-family);
  --strong-font-size-adjust: 1;
  --strong-font-style: inherit;
  --strong-font-weight: var(--font-weight-bold);
  --strong-letter-spacing: 0em;
  --em-font-family: 'Times New Roman', 'Times', serif;
  --em-font-size-adjust: 1.18;
  --em-font-style: italic;
  --em-font-weight: inherit;
  --em-letter-spacing: -0.025em;
  --quote-font-family: 'Times New Roman', 'Times', serif;
  --quote-font-size-adjust: 1.18;
  --quote-font-style: italic;
  --quote-font-weight: inherit;
  --quote-letter-spacing: -0.025em;
  --tab-active-letter-spacing: -0.01em;
  --tab-active-word-spacing: 0em;
  --tab-inactive-letter-spacing: 0em;
  --tab-inactive-word-spacing: 0em;
  word-wrap: break-word;
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--default-font-weight);
  font-style: var(--default-font-style);
  line-height: var(--default-line-height);
  letter-spacing: var(--default-letter-spacing);
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --container-1: 448px;
  --container-2: 688px;
  --container-3: 880px;
  --container-4: 1136px;
  --scrollarea-scrollbar-horizontal-margin-top: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-bottom: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-left: var(--space-1);
  --scrollarea-scrollbar-horizontal-margin-right: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-top: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-left: var(--space-1);
  --scrollarea-scrollbar-vertical-margin-right: var(--space-1);
  --segmented-control-transition-duration: 100ms;
  --spinner-animation-duration: 800ms;
  --spinner-opacity: 0.65;
  color: var(--gray-12);
}

.radix-themes:where([data-scaling='90%']) {
  --scaling: 0.9;
}

.radix-themes:where([data-scaling='95%']) {
  --scaling: 0.95;
}

.radix-themes:where([data-scaling='100%']) {
  --scaling: 1;
}

.radix-themes:where([data-scaling='105%']) {
  --scaling: 1.05;
}

.radix-themes:where([data-scaling='110%']) {
  --scaling: 1.1;
}

[data-radius] {
  --radius-1: calc(3px * var(--scaling) * var(--radius-factor));
  --radius-2: calc(4px * var(--scaling) * var(--radius-factor));
  --radius-3: calc(6px * var(--scaling) * var(--radius-factor));
  --radius-4: calc(8px * var(--scaling) * var(--radius-factor));
  --radius-5: calc(12px * var(--scaling) * var(--radius-factor));
  --radius-6: calc(16px * var(--scaling) * var(--radius-factor));
}

[data-radius='none'] {
  --radius-factor: 0;
  --radius-full: 0px;
  --radius-thumb: 0.5px;
}

[data-radius='small'] {
  --radius-factor: 0.75;
  --radius-full: 0px;
  --radius-thumb: 0.5px;
}

[data-radius='medium'] {
  --radius-factor: 1;
  --radius-full: 0px;
  --radius-thumb: 9999px;
}

[data-radius='large'] {
  --radius-factor: 1.5;
  --radius-full: 0px;
  --radius-thumb: 9999px;
}

[data-radius='full'] {
  --radius-factor: 1.5;
  --radius-full: 9999px;
  --radius-thumb: 9999px;
}

@supports (color: color-mix(in oklab, white, black)) {
  :where(.radix-themes) {
    --shadow-1: inset 0 0 0 1px var(--gray-a5), inset 0 1.5px 2px 0 var(--gray-a2), inset 0 1.5px 2px 0 var(--black-a2);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 0 0 0.5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 2px 3px -2px var(--gray-a3), 0 3px 12px -4px var(--black-a2), 0 4px 16px -8px var(--black-a2);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 8px 40px var(--black-a1), 0 12px 32px -16px var(--gray-a3);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 12px 60px var(--black-a3), 0 12px 32px -16px var(--gray-a5);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 12px 60px var(--black-a3), 0 16px 64px var(--gray-a2), 0 16px 36px -20px var(--gray-a7);
    --base-card-surface-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a5), var(--gray-5) 25%);
    --base-card-surface-hover-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a7), var(--gray-7) 25%);
    --base-card-surface-active-box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a4), var(--gray-4) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%);
  }

  .dark :where(.radix-themes:not(.light):not(.light-theme)) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }

  .dark-theme :where(.radix-themes:not(.light):not(.light-theme)) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }

  .dark, .dark-theme {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 0.5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
    --base-card-classic-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-hover-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
    --base-card-classic-active-border-color: color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
  }
}

@font-face {
  font-family: 'Segoe UI (Custom)';

  font-weight: 300;

  size-adjust: 103%;

  descent-override: 35%;

  ascent-override: 105%;

  src: local('Segoe UI Semilight'), local('Segoe UI');
}

@font-face {
  font-family: 'Segoe UI (Custom)';

  font-weight: 300;

  font-style: italic;

  size-adjust: 103%;

  descent-override: 35%;

  ascent-override: 105%;

  src: local('Segoe UI Semilight Italic'), local('Segoe UI Italic');
}

@font-face {
  font-family: 'Segoe UI (Custom)';

  font-weight: 400;

  size-adjust: 103%;

  descent-override: 35%;

  ascent-override: 105%;

  src: local('Segoe UI');
}

@font-face {
  font-family: 'Segoe UI (Custom)';

  font-weight: 400;

  font-style: italic;

  size-adjust: 103%;

  descent-override: 35%;

  ascent-override: 105%;

  src: local('Segoe UI Italic');
}

@font-face {
  font-family: 'Segoe UI (Custom)';

  font-weight: 500;

  size-adjust: 103%;

  descent-override: 35%;

  ascent-override: 105%;

  src: local('Segoe UI Semibold'), local('Segoe UI');
}

@font-face {
  font-family: 'Segoe UI (Custom)';

  font-weight: 500;

  font-style: italic;

  size-adjust: 103%;

  descent-override: 35%;

  ascent-override: 105%;

  src: local('Segoe UI Semibold Italic'), local('Segoe UI Italic');
}

@font-face {
  font-family: 'Segoe UI (Custom)';

  font-weight: 700;

  size-adjust: 103%;

  descent-override: 35%;

  ascent-override: 105%;

  src: local('Segoe UI Bold');
}

@font-face {
  font-family: 'Segoe UI (Custom)';

  font-weight: 700;

  font-style: italic;

  size-adjust: 103%;

  descent-override: 35%;

  ascent-override: 105%;

  src: local('Segoe UI Bold Italic');
}

@font-face {
  font-family: 'Open Sans (Custom)';

  font-weight: 300;

  descent-override: 35%;

  src: local('Open Sans Light'), local('Open Sans Regular');
}

@font-face {
  font-family: 'Open Sans (Custom)';

  font-weight: 300;

  font-style: italic;

  descent-override: 35%;

  src: local('Open Sans Light Italic'), local('Open Sans Italic');
}

@font-face {
  font-family: 'Open Sans (Custom)';

  font-weight: 400;

  descent-override: 35%;

  src: local('Open Sans Regular');
}

@font-face {
  font-family: 'Open Sans (Custom)';

  font-weight: 400;

  font-style: italic;

  descent-override: 35%;

  src: local('Open Sans Italic');
}

@font-face {
  font-family: 'Open Sans (Custom)';

  font-weight: 500;

  descent-override: 35%;

  src: local('Open Sans Medium'), local('Open Sans Regular');
}

@font-face {
  font-family: 'Open Sans (Custom)';

  font-weight: 500;

  font-style: italic;

  descent-override: 35%;

  src: local('Open Sans Medium Italic'), local('Open Sans Italic');
}

@font-face {
  font-family: 'Open Sans (Custom)';

  font-weight: 700;

  descent-override: 35%;

  src: local('Open Sans Bold');
}

@font-face {
  font-family: 'Open Sans (Custom)';

  font-weight: 700;

  font-style: italic;

  descent-override: 35%;

  src: local('Open Sans Bold Italic');
}

@font-face {
  font-family: 'Consolas (Custom)';

  font-weight: 400;

  size-adjust: 110%;

  ascent-override: 85%;

  descent-override: 22%;

  src: local('Consolas');
}

@font-face {
  font-family: 'Consolas (Custom)';

  font-weight: 400;

  font-style: italic;

  size-adjust: 110%;

  ascent-override: 85%;

  descent-override: 22%;

  src: local('Consolas Italic');
}

@font-face {
  font-family: 'Consolas (Custom)';

  font-weight: 700;

  size-adjust: 110%;

  ascent-override: 85%;

  descent-override: 22%;

  src: local('Consolas Bold');
}

@font-face {
  font-family: 'Consolas (Custom)';

  font-weight: 700;

  font-style: italic;

  size-adjust: 110%;

  ascent-override: 85%;

  descent-override: 22%;

  src: local('Consolas Bold Italic');
}

.rt-reset:where(body, blockquote, dl, dd, figure, p) {
  margin: 0;
}

.rt-reset:where(address, b, cite, code, dfn, em, i, kbd, q, samp, small, strong, var) {
  font: inherit;
}

.rt-reset:where(h1, h2, h3, h4, h5, h6) {
  font: inherit;
  margin: 0;
}

.rt-reset:where(a) {
  all: unset;
  -webkit-tap-highlight-color: transparent;
}

.rt-reset:where(
      button,
      select,

      
      [type='button'],
      [type='image'],
      [type='reset'],
      [type='submit'],

      
      [type='checkbox'],
      [type='color'],
      [type='radio'],
      [type='range']
    ) {
  all: unset;
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  text-indent: 0;
  text-indent: initial;
  -webkit-tap-highlight-color: transparent;
}

.rt-reset:where(label) {
  -webkit-tap-highlight-color: transparent;
}

.rt-reset:where(select) {
  font-weight: normal;
  font-style: normal;
  text-align: left;
}

.rt-reset:where(
      textarea,
      input:not([type='button']):not([type='image']):not([type='reset']):not([type='submit']):not([type='checkbox']):not([type='color']):not([type='radio']):not([type='range'])) {
  all: unset;
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  text-indent: 0;
  text-indent: initial;
  -webkit-tap-highlight-color: transparent;
  cursor: text;
  white-space: pre-wrap;
}

.rt-reset:where(:focus) {
  outline: none;
}

.rt-reset::-moz-placeholder {
  color: inherit;
  opacity: 1;
  opacity: initial;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}

.rt-reset::placeholder {
  color: inherit;
  opacity: 1;
  opacity: initial;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}

.rt-reset:where(table) {
  all: unset;
  display: table;
  text-indent: 0;
  text-indent: initial;
}

.rt-reset:where(caption) {
  text-align: inherit;
}

.rt-reset:where(td) {
  padding: 0;
}

.rt-reset:where(th) {
  font-weight: inherit;
  text-align: inherit;
  padding: 0;
}

.rt-reset:where(abbr, acronym) {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.rt-reset:where(canvas, object, picture, summary) {
  display: block;
}

.rt-reset:where(del, s) {
  text-decoration: none;
  text-decoration: initial;
}

.rt-reset:where(fieldset, hr) {
  all: unset;
  display: block;
}

.rt-reset:where(legend) {
  padding: 0;
  border: none;
  cursor: default;
}

.rt-reset:where(li) {
  display: block;
  text-align: inherit;
}

.rt-reset:where(ol, ul) {
  list-style: none;
  margin: 0;
  padding: 0;
}

.rt-reset:where(iframe) {
  display: block;
  border: none;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}

.rt-reset:where(ins, u) {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.rt-reset:where(img) {
  display: block;
  max-width: 100%;
}

.rt-reset:where(svg) {
  display: block;
  max-width: 100%;
  flex-shrink: 0;
}

.rt-reset:where(mark) {
  all: unset;
}

.rt-reset:where(pre) {
  font: inherit;
  margin: 0;
  margin: initial;
}

.rt-reset:where(q)::before,
  .rt-reset:where(q)::after {
  content: '';
}

.rt-reset:where(sub, sup) {
  font: inherit;
  vertical-align: baseline;
  vertical-align: initial;
}

.rt-reset:where(details) ::marker,
  .rt-reset:where(summary)::marker {
  content: none;
}

.rt-reset:where(video) {
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}

.rt-reset:where(:link), .rt-reset:where(:visited) {
  cursor: var(--cursor-link);
}

.rt-reset:where(:-moz-any-link) {
  cursor: var(--cursor-link);
}

.rt-reset:where(:any-link) {
  cursor: var(--cursor-link);
}

.rt-reset:where(button) {
  cursor: var(--cursor-button);
}

.rt-reset:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}

.rt-reset:where(input[type='checkbox']) {
  cursor: var(--cursor-checkbox);
}

.rt-reset:where(input[type='radio']) {
  cursor: var(--cursor-radio);
}

.rt-reset,
  .rt-reset::before,
  .rt-reset::after {
  box-sizing: border-box;
}

@keyframes rt-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rt-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes rt-slide-from-top {
  from {
    transform: translateY(4px) scale(0.97);
  }

  to {
    transform: translateY(0) scale(1);
  }
}

@keyframes rt-slide-to-top {
  from {
    transform: translateY(0) scale(1);
  }

  to {
    transform: translateY(4px) scale(0.97);
  }
}

@keyframes rt-slide-from-bottom {
  from {
    transform: translateY(-4px) scale(0.97);
  }

  to {
    transform: translateY(0) scale(1);
  }
}

@keyframes rt-slide-to-bottom {
  from {
    transform: translateY(0) scale(1);
  }

  to {
    transform: translateY(-4px) scale(0.97);
  }
}

@keyframes rt-slide-from-left {
  from {
    transform: translateX(4px) scale(0.97);
  }

  to {
    transform: translateX(0) scale(1);
  }
}

@keyframes rt-slide-to-left {
  from {
    transform: translateX(0) scale(1);
  }

  to {
    transform: translateX(4px) scale(0.97);
  }
}

@keyframes rt-slide-from-right {
  from {
    transform: translateX(-4px) scale(0.97);
  }

  to {
    transform: translateX(0) scale(1);
  }
}

@keyframes rt-slide-to-right {
  from {
    transform: translateX(0) scale(1);
  }

  to {
    transform: translateX(-4px) scale(0.97);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .rt-PopperContent {
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }

  .rt-PopperContent:where([data-state='open']) {
    animation-duration: 160ms;
  }

  .rt-PopperContent:where([data-state='open']):where([data-side='top']) {
    animation-name: rt-slide-from-top, rt-fade-in;
  }

  .rt-PopperContent:where([data-state='open']):where([data-side='bottom']) {
    animation-name: rt-slide-from-bottom, rt-fade-in;
  }

  .rt-PopperContent:where([data-state='open']):where([data-side='left']) {
    animation-name: rt-slide-from-left, rt-fade-in;
  }

  .rt-PopperContent:where([data-state='open']):where([data-side='right']) {
    animation-name: rt-slide-from-right, rt-fade-in;
  }

  .rt-PopperContent:where([data-state='closed']) {
    animation-duration: 100ms;
  }

  .rt-PopperContent:where([data-state='closed']):where([data-side='top']) {
    animation-name: rt-slide-to-top, rt-fade-out;
  }

  .rt-PopperContent:where([data-state='closed']):where([data-side='bottom']) {
    animation-name: rt-slide-to-bottom, rt-fade-out;
  }

  .rt-PopperContent:where([data-state='closed']):where([data-side='left']) {
    animation-name: rt-slide-to-left, rt-fade-out;
  }

  .rt-PopperContent:where([data-state='closed']):where([data-side='right']) {
    animation-name: rt-slide-to-right, rt-fade-out;
  }
}

.rt-Box {
  box-sizing: border-box;
  display: block;
}

.rt-Flex {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}

.rt-Grid {
  box-sizing: border-box;
  display: grid;
  align-items: stretch;
  justify-content: flex-start;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: none;
}

.rt-Section {
  box-sizing: border-box;
  flex-shrink: 0;
}

.rt-Section:where(.rt-r-size-1) {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.rt-Section:where(.rt-r-size-2) {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}

.rt-Section:where(.rt-r-size-3) {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}

.rt-Section:where(.rt-r-size-4) {
  padding-top: calc(80px * var(--scaling));
  padding-bottom: calc(80px * var(--scaling));
}

@media (min-width: 640px) {
  .rt-Section:where(.xs\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.xs\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.xs\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.xs\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 768px) {
  .rt-Section:where(.sm\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.sm\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.sm\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.sm\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1024px) {
  .rt-Section:where(.md\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.md\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.md\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.md\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1280px) {
  .rt-Section:where(.lg\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.lg\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.lg\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.lg\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1536px) {
  .rt-Section:where(.xl\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.xl\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.xl\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.xl\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

.rt-Container {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
}

.rt-ContainerInner {
  width: 100%;
}

:where(.rt-Container.rt-r-size-1) .rt-ContainerInner {
  max-width: var(--container-1);
}

:where(.rt-Container.rt-r-size-2) .rt-ContainerInner {
  max-width: var(--container-2);
}

:where(.rt-Container.rt-r-size-3) .rt-ContainerInner {
  max-width: var(--container-3);
}

:where(.rt-Container.rt-r-size-4) .rt-ContainerInner {
  max-width: var(--container-4);
}

@media (min-width: 640px) {
  :where(.rt-Container.xs\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.xs\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.xs\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.xs\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 768px) {
  :where(.rt-Container.sm\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.sm\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.sm\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.sm\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1024px) {
  :where(.rt-Container.md\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.md\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.md\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.md\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1280px) {
  :where(.rt-Container.lg\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.lg\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.lg\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.lg\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1536px) {
  :where(.rt-Container.xl\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.xl\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.xl\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.xl\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

.rt-Skeleton {
  --skeleton-radius: var(--skeleton-radius-override);
  --skeleton-height: var(--skeleton-height-override);
  border-radius: var(--radius-1);
  animation: rt-skeleton-pulse 1000ms infinite alternate-reverse !important;
  background-image: none !important;
  background-clip: border-box !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-box-decoration-break: clone !important;
  box-decoration-break: clone !important;
  color: transparent !important;
  outline: none !important;
  pointer-events: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
       user-select: none !important;
  cursor: default !important;
}

.rt-Skeleton:where([data-inline-skeleton]) {
  line-height: 0;
  font-family: Arial, sans-serif !important;
}

:where(.rt-Skeleton:empty) {
  display: block;
  height: var(--space-3);
}

.rt-Skeleton > *,
.rt-Skeleton::after,
.rt-Skeleton::before {
  visibility: hidden !important;
}

@keyframes rt-skeleton-pulse {
  from {
    background-color: var(--gray-a3);
  }

  to {
    background-color: var(--gray-a4);
  }
}

.rt-Text {
  line-height: var(--line-height, var(--default-line-height));
  letter-spacing: var(--letter-spacing, inherit);
}

:where(.rt-Text) {
  margin: 0;
}

.rt-Text:where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-Text:where([data-accent-color].rt-high-contrast),
  :where([data-accent-color]:not(.radix-themes)) .rt-Text:where(.rt-high-contrast) {
  color: var(--accent-12);
}

@media (pointer: coarse) {
  .rt-Text:where(label) {
    -webkit-tap-highlight-color: transparent;
  }

  .rt-Text:where(label):where(:active) {
    outline: 0.75em solid var(--gray-a4);
    outline-offset: -0.6em;
  }
}

.rt-Text:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  --line-height: var(--line-height-1);
  --letter-spacing: var(--letter-spacing-1);
}

.rt-Text:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
}

.rt-Text:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
}

.rt-Text:where(.rt-r-size-4) {
  font-size: var(--font-size-4);
  --line-height: var(--line-height-4);
  --letter-spacing: var(--letter-spacing-4);
}

.rt-Text:where(.rt-r-size-5) {
  font-size: var(--font-size-5);
  --line-height: var(--line-height-5);
  --letter-spacing: var(--letter-spacing-5);
}

.rt-Text:where(.rt-r-size-6) {
  font-size: var(--font-size-6);
  --line-height: var(--line-height-6);
  --letter-spacing: var(--letter-spacing-6);
}

.rt-Text:where(.rt-r-size-7) {
  font-size: var(--font-size-7);
  --line-height: var(--line-height-7);
  --letter-spacing: var(--letter-spacing-7);
}

.rt-Text:where(.rt-r-size-8) {
  font-size: var(--font-size-8);
  --line-height: var(--line-height-8);
  --letter-spacing: var(--letter-spacing-8);
}

.rt-Text:where(.rt-r-size-9) {
  font-size: var(--font-size-9);
  --line-height: var(--line-height-9);
  --letter-spacing: var(--letter-spacing-9);
}

@media (min-width: 640px) {
  .rt-Text:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.xs\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.xs\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.xs\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.xs\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.xs\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.xs\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 768px) {
  .rt-Text:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.sm\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.sm\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.sm\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.sm\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.sm\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.sm\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1024px) {
  .rt-Text:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.md\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.md\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.md\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.md\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.md\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.md\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1280px) {
  .rt-Text:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.lg\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.lg\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.lg\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.lg\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.lg\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.lg\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1536px) {
  .rt-Text:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    --line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Text:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Text:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Text:where(.xl\:rt-r-size-4) {
    font-size: var(--font-size-4);
    --line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Text:where(.xl\:rt-r-size-5) {
    font-size: var(--font-size-5);
    --line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Text:where(.xl\:rt-r-size-6) {
    font-size: var(--font-size-6);
    --line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Text:where(.xl\:rt-r-size-7) {
    font-size: var(--font-size-7);
    --line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Text:where(.xl\:rt-r-size-8) {
    font-size: var(--font-size-8);
    --line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Text:where(.xl\:rt-r-size-9) {
    font-size: var(--font-size-9);
    --line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

.rt-BaseDialogOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.rt-BaseDialogOverlay::before {
  position: fixed;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-overlay);
}

.rt-BaseDialogScroll {
  display: flex;
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.rt-BaseDialogScrollPadding {
  flex-grow: 1;
  margin: auto;
  padding-top: var(--space-6);
  padding-bottom: max(var(--space-6), 6vh);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.rt-BaseDialogScrollPadding:where(.rt-r-align-start) {
  margin-top: 0;
}

.rt-BaseDialogScrollPadding:where(.rt-r-align-center) {
  margin-top: auto;
}

@media (min-width: 640px) {
  .rt-BaseDialogScrollPadding:where(.xs\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.xs\:rt-r-align-center) {
    margin-top: auto;
  }
}

@media (min-width: 768px) {
  .rt-BaseDialogScrollPadding:where(.sm\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.sm\:rt-r-align-center) {
    margin-top: auto;
  }
}

@media (min-width: 1024px) {
  .rt-BaseDialogScrollPadding:where(.md\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.md\:rt-r-align-center) {
    margin-top: auto;
  }
}

@media (min-width: 1280px) {
  .rt-BaseDialogScrollPadding:where(.lg\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.lg\:rt-r-align-center) {
    margin-top: auto;
  }
}

@media (min-width: 1536px) {
  .rt-BaseDialogScrollPadding:where(.xl\:rt-r-align-start) {
    margin-top: 0;
  }

  .rt-BaseDialogScrollPadding:where(.xl\:rt-r-align-center) {
    margin-top: auto;
  }
}

.rt-BaseDialogContent {
  margin: auto;
  width: 100%;
  z-index: 1;
  position: relative;
  overflow: auto;
  --inset-padding-top: var(--dialog-content-padding);
  --inset-padding-right: var(--dialog-content-padding);
  --inset-padding-bottom: var(--dialog-content-padding);
  --inset-padding-left: var(--dialog-content-padding);
  padding: var(--dialog-content-padding);
  box-sizing: border-box;
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-6);
  outline: none;
}

.rt-BaseDialogContent:where(.rt-r-size-1) {
  --dialog-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}

.rt-BaseDialogContent:where(.rt-r-size-2) {
  --dialog-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}

.rt-BaseDialogContent:where(.rt-r-size-3) {
  --dialog-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}

.rt-BaseDialogContent:where(.rt-r-size-4) {
  --dialog-content-padding: var(--space-6);
  border-radius: var(--radius-5);
}

@media (min-width: 640px) {
  .rt-BaseDialogContent:where(.xs\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.xs\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.xs\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.xs\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 768px) {
  .rt-BaseDialogContent:where(.sm\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.sm\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.sm\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.sm\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1024px) {
  .rt-BaseDialogContent:where(.md\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.md\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.md\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.md\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1280px) {
  .rt-BaseDialogContent:where(.lg\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.lg\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.lg\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.lg\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1536px) {
  .rt-BaseDialogContent:where(.xl\:rt-r-size-1) {
    --dialog-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.xl\:rt-r-size-2) {
    --dialog-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-BaseDialogContent:where(.xl\:rt-r-size-3) {
    --dialog-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-BaseDialogContent:where(.xl\:rt-r-size-4) {
    --dialog-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes rt-dialog-overlay-no-op {
    from {
      opacity: 1;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes rt-dialog-content-show {
    from {
      opacity: 0;
      transform: translateY(5px) scale(0.97);
    }

    to {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
  }

  @keyframes rt-dialog-content-hide {
    from {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }

    to {
      opacity: 0;
      transform: translateY(5px) scale(0.99);
    }
  }

  .rt-BaseDialogOverlay:where([data-state='closed']) {
    animation: rt-dialog-overlay-no-op 160ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  .rt-BaseDialogOverlay:where([data-state='open'])::before {
    animation: rt-fade-in 200ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  .rt-BaseDialogOverlay:where([data-state='closed'])::before {
    animation: rt-fade-out 160ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  .rt-BaseDialogContent:where([data-state='open']) {
    animation: rt-dialog-content-show 200ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  .rt-BaseDialogContent:where([data-state='closed']) {
    animation: rt-dialog-content-hide 100ms cubic-bezier(0.16, 1, 0.3, 1);
  }
}

.rt-AvatarRoot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  width: var(--avatar-size);
  height: var(--avatar-size);
  flex-shrink: 0;
}

.rt-AvatarImage {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: inherit;
}

.rt-AvatarFallback {
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: inherit;
  text-transform: uppercase;
}

.rt-AvatarFallback:where(.rt-one-letter) {
  font-size: var(--avatar-fallback-one-letter-font-size);
}

.rt-AvatarFallback:where(.rt-two-letters) {
  font-size: var(--avatar-fallback-two-letters-font-size, var(--avatar-fallback-one-letter-font-size));
}

.rt-AvatarRoot:where(.rt-r-size-1) {
  --avatar-size: var(--space-5);
  --avatar-fallback-one-letter-font-size: var(--font-size-2);
  --avatar-fallback-two-letters-font-size: var(--font-size-1);
  border-radius: max(var(--radius-2), var(--radius-full));
  letter-spacing: var(--letter-spacing-1);
}

.rt-AvatarRoot:where(.rt-r-size-2) {
  --avatar-size: var(--space-6);
  --avatar-fallback-one-letter-font-size: var(--font-size-3);
  --avatar-fallback-two-letters-font-size: var(--font-size-2);
  border-radius: max(var(--radius-2), var(--radius-full));
  letter-spacing: var(--letter-spacing-2);
}

.rt-AvatarRoot:where(.rt-r-size-3) {
  --avatar-size: var(--space-7);
  --avatar-fallback-one-letter-font-size: var(--font-size-4);
  --avatar-fallback-two-letters-font-size: var(--font-size-3);
  border-radius: max(var(--radius-3), var(--radius-full));
  letter-spacing: var(--letter-spacing-3);
}

.rt-AvatarRoot:where(.rt-r-size-4) {
  --avatar-size: var(--space-8);
  --avatar-fallback-one-letter-font-size: var(--font-size-5);
  --avatar-fallback-two-letters-font-size: var(--font-size-4);
  border-radius: max(var(--radius-3), var(--radius-full));
  letter-spacing: var(--letter-spacing-4);
}

.rt-AvatarRoot:where(.rt-r-size-5) {
  --avatar-size: var(--space-9);
  --avatar-fallback-one-letter-font-size: var(--font-size-6);
  border-radius: max(var(--radius-4), var(--radius-full));
  letter-spacing: var(--letter-spacing-6);
}

.rt-AvatarRoot:where(.rt-r-size-6) {
  --avatar-size: 80px;
  --avatar-fallback-one-letter-font-size: var(--font-size-7);
  border-radius: max(var(--radius-5), var(--radius-full));
  letter-spacing: var(--letter-spacing-7);
}

.rt-AvatarRoot:where(.rt-r-size-7) {
  --avatar-size: 96px;
  --avatar-fallback-one-letter-font-size: var(--font-size-7);
  border-radius: max(var(--radius-5), var(--radius-full));
  letter-spacing: var(--letter-spacing-7);
}

.rt-AvatarRoot:where(.rt-r-size-8) {
  --avatar-size: 128px;
  --avatar-fallback-one-letter-font-size: var(--font-size-8);
  border-radius: max(var(--radius-6), var(--radius-full));
  letter-spacing: var(--letter-spacing-8);
}

.rt-AvatarRoot:where(.rt-r-size-9) {
  --avatar-size: 160px;
  --avatar-fallback-one-letter-font-size: var(--font-size-9);
  border-radius: max(var(--radius-6), var(--radius-full));
  letter-spacing: var(--letter-spacing-9);
}

@media (min-width: 640px) {
  .rt-AvatarRoot:where(.xs\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.xs\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 768px) {
  .rt-AvatarRoot:where(.sm\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.sm\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1024px) {
  .rt-AvatarRoot:where(.md\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.md\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1280px) {
  .rt-AvatarRoot:where(.lg\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.lg\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1536px) {
  .rt-AvatarRoot:where(.xl\:rt-r-size-1) {
    --avatar-size: var(--space-5);
    --avatar-fallback-one-letter-font-size: var(--font-size-2);
    --avatar-fallback-two-letters-font-size: var(--font-size-1);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-2) {
    --avatar-size: var(--space-6);
    --avatar-fallback-one-letter-font-size: var(--font-size-3);
    --avatar-fallback-two-letters-font-size: var(--font-size-2);
    border-radius: max(var(--radius-2), var(--radius-full));
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-3) {
    --avatar-size: var(--space-7);
    --avatar-fallback-one-letter-font-size: var(--font-size-4);
    --avatar-fallback-two-letters-font-size: var(--font-size-3);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-4) {
    --avatar-size: var(--space-8);
    --avatar-fallback-one-letter-font-size: var(--font-size-5);
    --avatar-fallback-two-letters-font-size: var(--font-size-4);
    border-radius: max(var(--radius-3), var(--radius-full));
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-5) {
    --avatar-size: var(--space-9);
    --avatar-fallback-one-letter-font-size: var(--font-size-6);
    border-radius: max(var(--radius-4), var(--radius-full));
    letter-spacing: var(--letter-spacing-6);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-6) {
    --avatar-size: 80px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-7) {
    --avatar-size: 96px;
    --avatar-fallback-one-letter-font-size: var(--font-size-7);
    border-radius: max(var(--radius-5), var(--radius-full));
    letter-spacing: var(--letter-spacing-7);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-8) {
    --avatar-size: 128px;
    --avatar-fallback-one-letter-font-size: var(--font-size-8);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-8);
  }

  .rt-AvatarRoot:where(.xl\:rt-r-size-9) {
    --avatar-size: 160px;
    --avatar-fallback-one-letter-font-size: var(--font-size-9);
    border-radius: max(var(--radius-6), var(--radius-full));
    letter-spacing: var(--letter-spacing-9);
  }
}

.rt-AvatarRoot:where(.rt-variant-solid) :where(.rt-AvatarFallback) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-AvatarRoot:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-AvatarFallback) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-AvatarRoot:where(.rt-variant-soft) :where(.rt-AvatarFallback) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}

.rt-AvatarRoot:where(.rt-variant-soft):where(.rt-high-contrast) :where(.rt-AvatarFallback) {
  color: var(--accent-12);
}

.rt-Badge {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  flex-shrink: 0;
  line-height: 1;
  height: -moz-fit-content;
  height: fit-content;
}

.rt-Badge:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
  gap: calc(var(--space-1) * 1.5);
  border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-Badge:where(.rt-r-size-2) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  padding: var(--space-1) var(--space-2);
  gap: calc(var(--space-1) * 1.5);
  border-radius: max(var(--radius-2), var(--radius-full));
}

.rt-Badge:where(.rt-r-size-3) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  padding: var(--space-1) calc(var(--space-2) * 1.25);
  gap: var(--space-2);
  border-radius: max(var(--radius-2), var(--radius-full));
}

@media (min-width: 640px) {
  .rt-Badge:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

@media (min-width: 768px) {
  .rt-Badge:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

@media (min-width: 1024px) {
  .rt-Badge:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

@media (min-width: 1280px) {
  .rt-Badge:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

@media (min-width: 1536px) {
  .rt-Badge:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: calc(var(--space-1) * 0.5) calc(var(--space-1) * 1.5);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-Badge:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    padding: var(--space-1) var(--space-2);
    gap: calc(var(--space-1) * 1.5);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-Badge:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    padding: var(--space-1) calc(var(--space-2) * 1.25);
    gap: var(--space-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }
}

.rt-Badge:where(.rt-variant-solid) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-Badge:where(.rt-variant-solid)::-moz-selection {
  background-color: var(--accent-7);
  color: var(--accent-12);
}

.rt-Badge:where(.rt-variant-solid)::selection {
  background-color: var(--accent-7);
  color: var(--accent-12);
}

.rt-Badge:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-Badge:where(.rt-variant-solid):where(.rt-high-contrast)::-moz-selection {
  background-color: var(--accent-a11);
  color: var(--accent-1);
}

.rt-Badge:where(.rt-variant-solid):where(.rt-high-contrast)::selection {
  background-color: var(--accent-a11);
  color: var(--accent-1);
}

.rt-Badge:where(.rt-variant-surface) {
  background-color: var(--accent-surface);
  box-shadow: inset 0 0 0 1px var(--accent-a6);
  color: var(--accent-a11);
}

.rt-Badge:where(.rt-variant-surface):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Badge:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}

.rt-Badge:where(.rt-variant-soft):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Badge:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
  color: var(--accent-a11);
}

.rt-Badge:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--accent-a7), inset 0 0 0 1px var(--gray-a11);
  color: var(--accent-12);
}

.rt-Blockquote {
  box-sizing: border-box;
  border-left: max(var(--space-1), 0.25em) solid var(--accent-a6);
  padding-left: min(var(--space-5), max(var(--space-3), 0.5em));
}

.rt-BaseButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  vertical-align: top;
  font-family: var(--default-font-family);
  font-style: normal;
  text-align: center;
}

.rt-BaseButton:where([data-disabled]) {
  --spinner-opacity: 1;
}

.rt-BaseButton:where(.rt-loading) {
  position: relative;
}

.rt-BaseButton:where(:not(.rt-variant-ghost)) {
  height: var(--base-button-height);
}

.rt-BaseButton:where(.rt-variant-ghost) {
  box-sizing: content-box;
  height: -moz-fit-content;
  height: fit-content;
}

.rt-BaseButton:where(.rt-r-size-1) {
  --base-button-classic-active-padding-top: 1px;
  --base-button-height: var(--space-5);
  border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-BaseButton:where(.rt-r-size-2) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-6);
  border-radius: max(var(--radius-2), var(--radius-full));
}

.rt-BaseButton:where(.rt-r-size-3) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-7);
  border-radius: max(var(--radius-3), var(--radius-full));
}

.rt-BaseButton:where(.rt-r-size-4) {
  --base-button-classic-active-padding-top: 2px;
  --base-button-height: var(--space-8);
  border-radius: max(var(--radius-4), var(--radius-full));
}

@media (min-width: 640px) {
  .rt-BaseButton:where(.xs\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.xs\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.xs\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.xs\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

@media (min-width: 768px) {
  .rt-BaseButton:where(.sm\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.sm\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.sm\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.sm\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

@media (min-width: 1024px) {
  .rt-BaseButton:where(.md\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.md\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.md\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.md\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

@media (min-width: 1280px) {
  .rt-BaseButton:where(.lg\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.lg\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.lg\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.lg\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

@media (min-width: 1536px) {
  .rt-BaseButton:where(.xl\:rt-r-size-1) {
    --base-button-classic-active-padding-top: 1px;
    --base-button-height: var(--space-5);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-BaseButton:where(.xl\:rt-r-size-2) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-6);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-BaseButton:where(.xl\:rt-r-size-3) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-7);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-BaseButton:where(.xl\:rt-r-size-4) {
    --base-button-classic-active-padding-top: 2px;
    --base-button-height: var(--space-8);
    border-radius: max(var(--radius-4), var(--radius-full));
  }
}

.rt-BaseButton:where(.rt-variant-classic) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
  position: relative;
  z-index: 0;
  background-image: linear-gradient(to bottom, transparent 50%, var(--gray-a4)), linear-gradient(to bottom, transparent 50%, var(--accent-9) 80%);
  box-shadow: var(--base-button-classic-box-shadow-top), inset 0 0 0 1px var(--accent-9), var(--base-button-classic-box-shadow-bottom);
}

.rt-BaseButton:where(.rt-variant-classic)::after {
  content: '';
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border: var(--base-button-classic-after-inset) solid transparent;
  background-clip: content-box;
  background-color: inherit;
  background-image: linear-gradient(var(--black-a1), transparent, var(--white-a2));
  box-shadow: inset 0 2px 3px -1px var(--white-a4);
}

.rt-BaseButton:where(.rt-variant-classic):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--gray-1);
  background-image: linear-gradient(to bottom, transparent 50%, var(--gray-a4)), linear-gradient(to bottom, transparent 50%, var(--accent-12) 80%);
  box-shadow: var(--base-button-classic-box-shadow-top), inset 0 0 0 1px var(--accent-12), var(--base-button-classic-box-shadow-bottom);
}

.rt-BaseButton:where(.rt-variant-classic):where(.rt-high-contrast)::after {
  background-image: linear-gradient(var(--black-a3), transparent, var(--white-a2));
}

@media (pointer: coarse) {
  .rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open'])) {
    outline: 0.5em solid var(--accent-a4);
    outline-offset: 0;
  }
}

.rt-BaseButton:where(.rt-variant-classic):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-classic):where(:hover)::after {
    background-color: var(--accent-10);
    background-image: linear-gradient(var(--black-a2) -15%, transparent, var(--white-a3));
  }

  .rt-BaseButton:where(.rt-variant-classic):where(:hover):where(.rt-high-contrast) {
    filter: var(--base-button-classic-high-contrast-hover-filter);
  }

  .rt-BaseButton:where(.rt-variant-classic):where(:hover):where(.rt-high-contrast)::after {
    background-color: var(--accent-12);
    background-image: linear-gradient(var(--black-a5), transparent, var(--white-a2));
  }
}

.rt-BaseButton:where(.rt-variant-classic):where([data-state='open'])::after {
  background-color: var(--accent-10);
  background-image: linear-gradient(var(--black-a2) -15%, transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where([data-state='open']):where(.rt-high-contrast) {
  filter: var(--base-button-classic-high-contrast-hover-filter);
}

.rt-BaseButton:where(.rt-variant-classic):where([data-state='open']):where(.rt-high-contrast)::after {
  background-color: var(--accent-12);
  background-image: linear-gradient(var(--black-a5), transparent, var(--white-a2));
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open']):not([data-disabled])) {
  background-color: var(--accent-9);
  background-image: linear-gradient(var(--black-a1), transparent);
  padding-top: var(--base-button-classic-active-padding-top);
  box-shadow: inset 0 4px 2px -2px var(--gray-a4), inset 0 1px 1px var(--gray-a7), inset 0 0 0 1px var(--gray-a5), inset 0 0 0 1px var(--accent-9), inset 0 3px 2px var(--gray-a3), inset 0 0 0 1px var(--white-a7), inset 0 -2px 1px var(--white-a5);
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open']):not([data-disabled]))::after {
  box-shadow: none;
  background-color: inherit;
  background-image: linear-gradient(var(--black-a2), transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open']):not([data-disabled])):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  filter: var(--base-button-classic-high-contrast-active-filter);
  box-shadow: var(--base-button__classic-active__shadow-front-layer), inset 0 0 0 1px var(--accent-12), var(--base-button__classic-active__shadow-bottom-layer);
}

.rt-BaseButton:where(.rt-variant-classic):where(:active:not([data-state='open']):not([data-disabled])):where(.rt-high-contrast)::after {
  background-image: linear-gradient(var(--black-a5), transparent, var(--white-a3));
}

.rt-BaseButton:where(.rt-variant-classic):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-2);
  background-image: none;
  box-shadow: var(--base-button-classic-disabled-box-shadow);
  outline: none;
  filter: none;
}

.rt-BaseButton:where(.rt-variant-classic):where([data-disabled])::after {
  box-shadow: none;
  background-color: var(--gray-a2);
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1));
}

.rt-BaseButton:where(.rt-variant-solid) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-solid):where(:hover) {
    background-color: var(--accent-10);
  }
}

.rt-BaseButton:where(.rt-variant-solid):where([data-state='open']) {
  background-color: var(--accent-10);
}

.rt-BaseButton:where(.rt-variant-solid):where(:active:not([data-state='open'])) {
  background-color: var(--accent-10);
  filter: var(--base-button-solid-active-filter);
}

@media (pointer: coarse) {
  .rt-BaseButton:where(.rt-variant-solid):where(:active:not([data-state='open'])) {
    outline: 0.5em solid var(--accent-a4);
    outline-offset: 0;
  }
}

.rt-BaseButton:where(.rt-variant-solid):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--gray-1);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where(:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }
}

.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where([data-state='open']) {
  background-color: var(--accent-12);
  filter: var(--base-button-solid-high-contrast-hover-filter);
}

.rt-BaseButton:where(.rt-variant-solid):where(.rt-high-contrast):where(:active:not([data-state='open'])) {
  background-color: var(--accent-12);
  filter: var(--base-button-solid-high-contrast-active-filter);
}

.rt-BaseButton:where(.rt-variant-solid):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
  outline: none;
  filter: none;
}

.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost) {
  color: var(--accent-a11);
}

.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-BaseButton:where(.rt-variant-soft, .rt-variant-ghost):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
}

.rt-BaseButton:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}

.rt-BaseButton:where(.rt-variant-soft):where(:focus-visible) {
  outline: 2px solid var(--accent-8);
  outline-offset: -1px;
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-soft):where(:hover) {
    background-color: var(--accent-a4);
  }
}

.rt-BaseButton:where(.rt-variant-soft):where([data-state='open']) {
  background-color: var(--accent-a4);
}

.rt-BaseButton:where(.rt-variant-soft):where(:active:not([data-state='open'])) {
  background-color: var(--accent-a5);
}

.rt-BaseButton:where(.rt-variant-soft):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: var(--gray-a3);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-ghost):where(:hover) {
    background-color: var(--accent-a3);
  }
}

.rt-BaseButton:where(.rt-variant-ghost):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-BaseButton:where(.rt-variant-ghost):where([data-state='open']) {
  background-color: var(--accent-a3);
}

.rt-BaseButton:where(.rt-variant-ghost):where(:active:not([data-state='open'])) {
  background-color: var(--accent-a4);
}

.rt-BaseButton:where(.rt-variant-ghost):where([data-disabled]) {
  color: var(--gray-a8);
  background-color: transparent;
}

.rt-BaseButton:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
  color: var(--accent-a11);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-outline):where(:hover) {
    background-color: var(--accent-a2);
  }
}

.rt-BaseButton:where(.rt-variant-outline):where([data-state='open']) {
  background-color: var(--accent-a2);
}

.rt-BaseButton:where(.rt-variant-outline):where(:active:not([data-state='open'])) {
  background-color: var(--accent-a3);
}

.rt-BaseButton:where(.rt-variant-outline):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-BaseButton:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--accent-a7), inset 0 0 0 1px var(--gray-a11);
  color: var(--accent-12);
}

.rt-BaseButton:where(.rt-variant-outline):where([data-disabled]) {
  color: var(--gray-a8);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
  background-color: transparent;
}

.rt-BaseButton:where(.rt-variant-surface) {
  background-color: var(--accent-surface);
  box-shadow: inset 0 0 0 1px var(--accent-a7);
  color: var(--accent-a11);
}

@media (hover: hover) {
  .rt-BaseButton:where(.rt-variant-surface):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--accent-a8);
  }
}

.rt-BaseButton:where(.rt-variant-surface):where([data-state='open']) {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
}

.rt-BaseButton:where(.rt-variant-surface):where(:active:not([data-state='open'])) {
  background-color: var(--accent-a3);
  box-shadow: inset 0 0 0 1px var(--accent-a8);
}

.rt-BaseButton:where(.rt-variant-surface):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-BaseButton:where(.rt-variant-surface):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-BaseButton:where(.rt-variant-surface):where([data-disabled]) {
  color: var(--gray-a8);
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: var(--gray-a2);
}

.rt-Button:where(:not(.rt-variant-ghost)) :where(svg) {
  opacity: 0.9;
}

.rt-Button:where(.rt-variant-ghost) {
  padding: var(--button-ghost-padding-y) var(--button-ghost-padding-x);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top) - var(--button-ghost-padding-y));
  --margin-right-override: calc(var(--margin-right) - var(--button-ghost-padding-x));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--button-ghost-padding-y));
  --margin-left-override: calc(var(--margin-left) - var(--button-ghost-padding-x));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}

:where(.rt-Button:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

.rt-Button:where(.rt-r-size-1) {
  gap: var(--space-1);
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}

.rt-Button:where(.rt-r-size-1):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-Button:where(.rt-r-size-1):where(.rt-variant-ghost) {
  gap: var(--space-1);
  --button-ghost-padding-x: var(--space-2);
  --button-ghost-padding-y: var(--space-1);
}

.rt-Button:where(.rt-r-size-2) {
  gap: var(--space-2);
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-Button:where(.rt-r-size-2):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.rt-Button:where(.rt-r-size-2):where(.rt-variant-ghost) {
  gap: var(--space-1);
  --button-ghost-padding-x: var(--space-2);
  --button-ghost-padding-y: var(--space-1);
}

.rt-Button:where(.rt-r-size-3) {
  gap: var(--space-3);
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
}

.rt-Button:where(.rt-r-size-3):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.rt-Button:where(.rt-r-size-3):where(.rt-variant-ghost) {
  gap: var(--space-2);
  --button-ghost-padding-x: var(--space-3);
  --button-ghost-padding-y: calc(var(--space-1) * 1.5);
}

.rt-Button:where(.rt-r-size-4) {
  gap: var(--space-3);
  font-size: var(--font-size-4);
  line-height: var(--line-height-4);
  letter-spacing: var(--letter-spacing-4);
}

.rt-Button:where(.rt-r-size-4):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}

.rt-Button:where(.rt-r-size-4):where(.rt-variant-ghost) {
  gap: var(--space-2);
  --button-ghost-padding-x: var(--space-4);
  --button-ghost-padding-y: var(--space-2);
}

@media (min-width: 640px) {
  .rt-Button:where(.xs\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.xs\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.xs\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.xs\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.xs\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.xs\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.xs\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.xs\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.xs\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.xs\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.xs\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.xs\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

@media (min-width: 768px) {
  .rt-Button:where(.sm\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.sm\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.sm\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.sm\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.sm\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.sm\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.sm\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.sm\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.sm\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.sm\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.sm\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.sm\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

@media (min-width: 1024px) {
  .rt-Button:where(.md\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.md\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.md\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.md\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.md\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.md\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.md\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.md\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.md\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.md\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.md\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.md\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

@media (min-width: 1280px) {
  .rt-Button:where(.lg\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.lg\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.lg\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.lg\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.lg\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.lg\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.lg\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.lg\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.lg\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.lg\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.lg\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.lg\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

@media (min-width: 1536px) {
  .rt-Button:where(.xl\:rt-r-size-1) {
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-Button:where(.xl\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-Button:where(.xl\:rt-r-size-1):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.xl\:rt-r-size-2) {
    gap: var(--space-2);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-Button:where(.xl\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-Button:where(.xl\:rt-r-size-2):where(.rt-variant-ghost) {
    gap: var(--space-1);
    --button-ghost-padding-x: var(--space-2);
    --button-ghost-padding-y: var(--space-1);
  }

  .rt-Button:where(.xl\:rt-r-size-3) {
    gap: var(--space-3);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-Button:where(.xl\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-Button:where(.xl\:rt-r-size-3):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-3);
    --button-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-Button:where(.xl\:rt-r-size-4) {
    gap: var(--space-3);
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
  }

  .rt-Button:where(.xl\:rt-r-size-4):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .rt-Button:where(.xl\:rt-r-size-4):where(.rt-variant-ghost) {
    gap: var(--space-2);
    --button-ghost-padding-x: var(--space-4);
    --button-ghost-padding-y: var(--space-2);
  }
}

.rt-Button:where(:not(.rt-variant-ghost)) {
  font-weight: var(--font-weight-medium);
}

.rt-CalloutRoot {
  box-sizing: border-box;
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: var(--accent-a11);
}

.rt-CalloutRoot:where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-CalloutIcon {
  display: flex;
  align-items: center;
  grid-column-start: -2;
  height: var(--callout-icon-height);
}

.rt-CalloutRoot > :where(:not(.rt-CalloutIcon)) {
  grid-column-start: -1;
}

.rt-CalloutRoot:where(.rt-r-size-1) {
  row-gap: var(--space-2);
  -moz-column-gap: var(--space-2);
       column-gap: var(--space-2);
  padding: var(--space-3);
  border-radius: var(--radius-3);
  --callout-icon-height: var(--line-height-2);
}

.rt-CalloutRoot:where(.rt-r-size-2) {
  row-gap: var(--space-2);
  -moz-column-gap: var(--space-3);
       column-gap: var(--space-3);
  padding: var(--space-4);
  border-radius: var(--radius-4);
  --callout-icon-height: var(--line-height-2);
}

.rt-CalloutRoot:where(.rt-r-size-3) {
  row-gap: var(--space-3);
  -moz-column-gap: var(--space-4);
       column-gap: var(--space-4);
  padding: var(--space-5);
  border-radius: var(--radius-5);
  --callout-icon-height: var(--line-height-3);
}

@media (min-width: 640px) {
  .rt-CalloutRoot:where(.xs\:rt-r-size-1) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.xs\:rt-r-size-2) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.xs\:rt-r-size-3) {
    row-gap: var(--space-3);
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

@media (min-width: 768px) {
  .rt-CalloutRoot:where(.sm\:rt-r-size-1) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.sm\:rt-r-size-2) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.sm\:rt-r-size-3) {
    row-gap: var(--space-3);
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

@media (min-width: 1024px) {
  .rt-CalloutRoot:where(.md\:rt-r-size-1) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.md\:rt-r-size-2) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.md\:rt-r-size-3) {
    row-gap: var(--space-3);
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

@media (min-width: 1280px) {
  .rt-CalloutRoot:where(.lg\:rt-r-size-1) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.lg\:rt-r-size-2) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.lg\:rt-r-size-3) {
    row-gap: var(--space-3);
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

@media (min-width: 1536px) {
  .rt-CalloutRoot:where(.xl\:rt-r-size-1) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
    padding: var(--space-3);
    border-radius: var(--radius-3);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.xl\:rt-r-size-2) {
    row-gap: var(--space-2);
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
    padding: var(--space-4);
    border-radius: var(--radius-4);
    --callout-icon-height: var(--line-height-2);
  }

  .rt-CalloutRoot:where(.xl\:rt-r-size-3) {
    row-gap: var(--space-3);
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
    padding: var(--space-5);
    border-radius: var(--radius-5);
    --callout-icon-height: var(--line-height-3);
  }
}

.rt-CalloutRoot:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}

.rt-CalloutRoot:where(.rt-variant-surface) {
  box-shadow: inset 0 0 0 1px var(--accent-a6);
  background-color: var(--accent-a2);
}

.rt-CalloutRoot:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 1px var(--accent-a7);
}

.rt-BaseCard {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: var(--base-card-border-radius);
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-normal);
  font-style: normal;
  text-align: left;
  --inset-border-width: var(--base-card-border-width);
  --inset-border-radius: var(--base-card-border-radius);
  padding-top: var(--base-card-padding-top);
  padding-right: var(--base-card-padding-right);
  padding-bottom: var(--base-card-padding-bottom);
  padding-left: var(--base-card-padding-left);
  box-sizing: border-box;
  --inset-padding-top: calc(var(--base-card-padding-top) - var(--base-card-border-width));
  --inset-padding-right: calc(var(--base-card-padding-right) - var(--base-card-border-width));
  --inset-padding-bottom: calc(var(--base-card-padding-bottom) - var(--base-card-border-width));
  --inset-padding-left: calc(var(--base-card-padding-left) - var(--base-card-border-width));
  contain: paint;
}

.rt-BaseCard::before,
  .rt-BaseCard::after {
  content: '';
  position: absolute;
  pointer-events: none;
  transition: inherit;
  border-radius: calc(var(--base-card-border-radius) - var(--base-card-border-width));
  top: var(--base-card-border-width);
  right: var(--base-card-border-width);
  bottom: var(--base-card-border-width);
  left: var(--base-card-border-width);
}

.rt-BaseCard::before {
  z-index: -1;
}

.rt-Card {
  --base-card-padding-top: var(--card-padding);
  --base-card-padding-right: var(--card-padding);
  --base-card-padding-bottom: var(--card-padding);
  --base-card-padding-left: var(--card-padding);
  --base-card-border-radius: var(--card-border-radius);
  --base-card-border-width: var(--card-border-width);
}

.rt-Card:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-Card:where(:focus-visible)::after {
  outline: inherit;
}

.rt-Card:where(:focus-visible):where(:active:not([data-state='open']))::before {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
}

.rt-Card:where(.rt-r-size-1) {
  --card-padding: var(--space-3);
  --card-border-radius: var(--radius-4);
}

.rt-Card:where(.rt-r-size-2) {
  --card-padding: var(--space-4);
  --card-border-radius: var(--radius-4);
}

.rt-Card:where(.rt-r-size-3) {
  --card-padding: var(--space-5);
  --card-border-radius: var(--radius-5);
}

.rt-Card:where(.rt-r-size-4) {
  --card-padding: var(--space-6);
  --card-border-radius: var(--radius-5);
}

.rt-Card:where(.rt-r-size-5) {
  --card-padding: var(--space-8);
  --card-border-radius: var(--radius-6);
}

@media (min-width: 640px) {
  .rt-Card:where(.xs\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.xs\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.xs\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.xs\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.xs\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

@media (min-width: 768px) {
  .rt-Card:where(.sm\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.sm\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.sm\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.sm\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.sm\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

@media (min-width: 1024px) {
  .rt-Card:where(.md\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.md\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.md\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.md\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.md\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

@media (min-width: 1280px) {
  .rt-Card:where(.lg\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.lg\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.lg\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.lg\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.lg\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

@media (min-width: 1536px) {
  .rt-Card:where(.xl\:rt-r-size-1) {
    --card-padding: var(--space-3);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.xl\:rt-r-size-2) {
    --card-padding: var(--space-4);
    --card-border-radius: var(--radius-4);
  }

  .rt-Card:where(.xl\:rt-r-size-3) {
    --card-padding: var(--space-5);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.xl\:rt-r-size-4) {
    --card-padding: var(--space-6);
    --card-border-radius: var(--radius-5);
  }

  .rt-Card:where(.xl\:rt-r-size-5) {
    --card-padding: var(--space-8);
    --card-border-radius: var(--radius-6);
  }
}

.rt-Card:where(.rt-variant-surface) {
  --card-border-width: 1px;
  --card-background-color: var(--color-panel);
}

.rt-Card:where(.rt-variant-surface)::before {
  background-color: var(--card-background-color);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
  backdrop-filter: var(--backdrop-filter-panel);
}

.rt-Card:where(.rt-variant-surface)::after {
  box-shadow: var(--base-card-surface-box-shadow);
}

@media (hover: hover) {
  .rt-Card:where(.rt-variant-surface):where(:link, button, label):where(:hover)::after, .rt-Card:where(.rt-variant-surface):where(:visited, button, label):where(:hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
  .rt-Card:where(.rt-variant-surface):where(:-moz-any-link, button, label):where(:hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
  .rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where(:hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}

.rt-Card:where(.rt-variant-surface):where(:link, button, label):where([data-state='open'])::after, .rt-Card:where(.rt-variant-surface):where(:visited, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-surface-hover-box-shadow);
}

.rt-Card:where(.rt-variant-surface):where(:-moz-any-link, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-surface-hover-box-shadow);
}

.rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-surface-hover-box-shadow);
}

.rt-Card:where(.rt-variant-surface):where(:link, button, label):where(:active:not([data-state='open']))::after, .rt-Card:where(.rt-variant-surface):where(:visited, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-surface-active-box-shadow);
}

.rt-Card:where(.rt-variant-surface):where(:-moz-any-link, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-surface-active-box-shadow);
}

.rt-Card:where(.rt-variant-surface):where(:any-link, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-surface-active-box-shadow);
}

.rt-Card:where(.rt-variant-classic) {
  --card-border-width: 1px;
  --card-background-color: var(--color-panel);
  transition: box-shadow 120ms;
  box-shadow: var(--base-card-classic-box-shadow-outer);
}

.rt-Card:where(.rt-variant-classic)::before {
  background-color: var(--card-background-color);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
  backdrop-filter: var(--backdrop-filter-panel);
}

.rt-Card:where(.rt-variant-classic)::after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}

@media (hover: hover) {
  .rt-Card:where(.rt-variant-classic):where(:link, button, label):where(:hover), .rt-Card:where(.rt-variant-classic):where(:visited, button, label):where(:hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }
  .rt-Card:where(.rt-variant-classic):where(:-moz-any-link, button, label):where(:hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }
  .rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }

  .rt-Card:where(.rt-variant-classic):where(:link, button, label):where(:hover)::after, .rt-Card:where(.rt-variant-classic):where(:visited, button, label):where(:hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }

  .rt-Card:where(.rt-variant-classic):where(:-moz-any-link, button, label):where(:hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }

  .rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}

.rt-Card:where(.rt-variant-classic):where(:link, button, label):where([data-state='open']), .rt-Card:where(.rt-variant-classic):where(:visited, button, label):where([data-state='open']) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-hover-box-shadow-outer);
}

.rt-Card:where(.rt-variant-classic):where(:-moz-any-link, button, label):where([data-state='open']) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-hover-box-shadow-outer);
}

.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where([data-state='open']) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-hover-box-shadow-outer);
}

.rt-Card:where(.rt-variant-classic):where(:link, button, label):where([data-state='open'])::after, .rt-Card:where(.rt-variant-classic):where(:visited, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-classic-hover-box-shadow-inner);
}

.rt-Card:where(.rt-variant-classic):where(:-moz-any-link, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-classic-hover-box-shadow-inner);
}

.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where([data-state='open'])::after {
  box-shadow: var(--base-card-classic-hover-box-shadow-inner);
}

.rt-Card:where(.rt-variant-classic):where(:link, button, label):where(:active:not([data-state='open'])), .rt-Card:where(.rt-variant-classic):where(:visited, button, label):where(:active:not([data-state='open'])) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-active-box-shadow-outer);
}

.rt-Card:where(.rt-variant-classic):where(:-moz-any-link, button, label):where(:active:not([data-state='open'])) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-active-box-shadow-outer);
}

.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:active:not([data-state='open'])) {
  transition-duration: 40ms;
  box-shadow: var(--base-card-classic-active-box-shadow-outer);
}

.rt-Card:where(.rt-variant-classic):where(:link, button, label):where(:active:not([data-state='open']))::after, .rt-Card:where(.rt-variant-classic):where(:visited, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-classic-active-box-shadow-inner);
}

.rt-Card:where(.rt-variant-classic):where(:-moz-any-link, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-classic-active-box-shadow-inner);
}

.rt-Card:where(.rt-variant-classic):where(:any-link, button, label):where(:active:not([data-state='open']))::after {
  box-shadow: var(--base-card-classic-active-box-shadow-inner);
}

.rt-Card:where(.rt-variant-ghost) {
  --card-border-width: 0px;
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top) - var(--card-padding));
  --margin-right-override: calc(var(--margin-right) - var(--card-padding));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--card-padding));
  --margin-left-override: calc(var(--margin-left) - var(--card-padding));
  margin-top: var(--margin-top-override);
  margin-right: var(--margin-right-override);
  margin-bottom: var(--margin-bottom-override);
  margin-left: var(--margin-left-override);
}

:where(.rt-Card:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

@media (hover: hover) {
  .rt-Card:where(.rt-variant-ghost):where(:link, button, label):where(:hover), .rt-Card:where(.rt-variant-ghost):where(:visited, button, label):where(:hover) {
    background-color: var(--gray-a3);
  }
  .rt-Card:where(.rt-variant-ghost):where(:-moz-any-link, button, label):where(:hover) {
    background-color: var(--gray-a3);
  }
  .rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:hover) {
    background-color: var(--gray-a3);
  }

  .rt-Card:where(.rt-variant-ghost):where(:link, button, label):where(:hover):where(:focus-visible), .rt-Card:where(.rt-variant-ghost):where(:visited, button, label):where(:hover):where(:focus-visible) {
    background-color: var(--focus-a2);
  }

  .rt-Card:where(.rt-variant-ghost):where(:-moz-any-link, button, label):where(:hover):where(:focus-visible) {
    background-color: var(--focus-a2);
  }

  .rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:hover):where(:focus-visible) {
    background-color: var(--focus-a2);
  }
}

.rt-Card:where(.rt-variant-ghost):where(:link, button, label):where([data-state='open']), .rt-Card:where(.rt-variant-ghost):where(:visited, button, label):where([data-state='open']) {
  background-color: var(--gray-a3);
}

.rt-Card:where(.rt-variant-ghost):where(:-moz-any-link, button, label):where([data-state='open']) {
  background-color: var(--gray-a3);
}

.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where([data-state='open']) {
  background-color: var(--gray-a3);
}

.rt-Card:where(.rt-variant-ghost):where(:link, button, label):where([data-state='open']):where(:focus-visible), .rt-Card:where(.rt-variant-ghost):where(:visited, button, label):where([data-state='open']):where(:focus-visible) {
  background-color: var(--focus-a2);
}

.rt-Card:where(.rt-variant-ghost):where(:-moz-any-link, button, label):where([data-state='open']):where(:focus-visible) {
  background-color: var(--focus-a2);
}

.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where([data-state='open']):where(:focus-visible) {
  background-color: var(--focus-a2);
}

.rt-Card:where(.rt-variant-ghost):where(:link, button, label):where(:active:not([data-state='open'])), .rt-Card:where(.rt-variant-ghost):where(:visited, button, label):where(:active:not([data-state='open'])) {
  background-color: var(--gray-a4);
}

.rt-Card:where(.rt-variant-ghost):where(:-moz-any-link, button, label):where(:active:not([data-state='open'])) {
  background-color: var(--gray-a4);
}

.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:active:not([data-state='open'])) {
  background-color: var(--gray-a4);
}

.rt-Card:where(.rt-variant-ghost):where(:link, button, label):where(:active:not([data-state='open'])):where(:focus-visible), .rt-Card:where(.rt-variant-ghost):where(:visited, button, label):where(:active:not([data-state='open'])):where(:focus-visible) {
  background-color: var(--focus-a2);
}

.rt-Card:where(.rt-variant-ghost):where(:-moz-any-link, button, label):where(:active:not([data-state='open'])):where(:focus-visible) {
  background-color: var(--focus-a2);
}

.rt-Card:where(.rt-variant-ghost):where(:any-link, button, label):where(:active:not([data-state='open'])):where(:focus-visible) {
  background-color: var(--focus-a2);
}

@media (pointer: coarse) {
  .rt-Card:where(:link, button, label):where(:active:not(:focus-visible):not([data-state='open']))::before, .rt-Card:where(:visited, button, label):where(:active:not(:focus-visible):not([data-state='open']))::before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
  .rt-Card:where(:link, button, label):where(:active:not(:focus-visible):not([data-state='open']))::before, .rt-Card:where(:visited, button, label):where(:active:not(:focus-visible):not([data-state='open']))::before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
  .rt-Card:where(:-moz-any-link, button, label):where(:active:not(:focus-visible):not([data-state='open']))::before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
  .rt-Card:where(:any-link, button, label):where(:active:not(:focus-visible):not([data-state='open']))::before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
}

.rt-BaseCheckboxRoot {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  flex-shrink: 0;
  cursor: var(--cursor-checkbox);
  height: var(--skeleton-height, var(--line-height, var(--checkbox-size)));
  --skeleton-height-override: var(--checkbox-size);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: var(--checkbox-border-radius);
}

.rt-BaseCheckboxRoot::before {
  content: '';
  display: block;
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  border-radius: var(--checkbox-border-radius);
}

.rt-BaseCheckboxIndicator {
  position: absolute;
  width: var(--checkbox-indicator-size);
  height: var(--checkbox-indicator-size);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.rt-BaseCheckboxRoot:where(.rt-r-size-1) {
  --checkbox-size: calc(var(--space-4) * 0.875);
  --checkbox-indicator-size: calc(9px * var(--scaling));
  --checkbox-border-radius: calc(var(--radius-1) * 0.875);
}

.rt-BaseCheckboxRoot:where(.rt-r-size-2) {
  --checkbox-size: var(--space-4);
  --checkbox-indicator-size: calc(10px * var(--scaling));
  --checkbox-border-radius: var(--radius-1);
}

.rt-BaseCheckboxRoot:where(.rt-r-size-3) {
  --checkbox-size: calc(var(--space-4) * 1.25);
  --checkbox-indicator-size: calc(12px * var(--scaling));
  --checkbox-border-radius: calc(var(--radius-1) * 1.25);
}

@media (min-width: 640px) {
  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }

  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.xs\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }

  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.sm\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }

  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.md\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }

  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.lg\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

@media (min-width: 1536px) {
  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-1) {
    --checkbox-size: calc(var(--space-4) * 0.875);
    --checkbox-indicator-size: calc(9px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 0.875);
  }

  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-2) {
    --checkbox-size: var(--space-4);
    --checkbox-indicator-size: calc(10px * var(--scaling));
    --checkbox-border-radius: var(--radius-1);
  }

  .rt-BaseCheckboxRoot:where(.xl\:rt-r-size-3) {
    --checkbox-size: calc(var(--space-4) * 1.25);
    --checkbox-indicator-size: calc(12px * var(--scaling));
    --checkbox-border-radius: calc(var(--radius-1) * 1.25);
  }
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='unchecked'])::before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='checked'], [data-state='indeterminate'])::before {
  background-color: var(--accent-indicator);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='checked'], [data-state='indeterminate']) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-contrast);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast)::before {
  background-color: var(--accent-12);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-1);
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where(:disabled)::before {
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: transparent;
}

.rt-BaseCheckboxRoot:where(.rt-variant-surface):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='unchecked'])::before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a3), var(--shadow-1);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='checked'], [data-state='indeterminate'])::before {
  background-color: var(--accent-indicator);
  background-image: linear-gradient(to bottom, var(--white-a3), transparent, var(--black-a1));
  box-shadow: inset 0 0.5px 0.5px var(--white-a4), inset 0 -0.5px 0.5px var(--black-a4);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='checked'], [data-state='indeterminate']) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-contrast);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast)::before {
  background-color: var(--accent-12);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-1);
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where(:disabled)::before {
  box-shadow: var(--shadow-1);
  background-color: transparent;
  background-image: none;
}

.rt-BaseCheckboxRoot:where(.rt-variant-classic):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft)::before {
  background-color: var(--accent-a5);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):where([data-state='checked'], [data-state='indeterminate']) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-a11);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):where([data-state='checked'], [data-state='indeterminate']):where(.rt-high-contrast) :where(.rt-BaseCheckboxIndicator) {
  color: var(--accent-12);
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):where(:disabled)::before {
  background-color: transparent;
}

.rt-BaseCheckboxRoot:where(.rt-variant-soft):where(:disabled) :where(.rt-BaseCheckboxIndicator) {
  color: var(--gray-a8);
}

.rt-CheckboxCardsRoot {
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  cursor: default;
}

.rt-CheckboxCardsItem:where(:has(:focus-visible)) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-CheckboxCardsItem:where(:has(:focus-visible))::after {
  outline: inherit;
}

.rt-CheckboxCardsItem > * {
  pointer-events: none;
}

.rt-CheckboxCardsItem > :where(svg) {
  flex-shrink: 0;
}

.rt-CheckboxCardCheckbox {
  position: absolute;
  right: var(--checkbox-cards-item-padding-left);
}

.rt-CheckboxCardsItem {
  --checkbox-cards-item-padding-right: calc(var(--checkbox-cards-item-padding-left) * 2 + var(--checkbox-cards-item-checkbox-size));
  --base-card-padding-top: var(--checkbox-cards-item-padding-top);
  --base-card-padding-right: var(--checkbox-cards-item-padding-right);
  --base-card-padding-bottom: var(--checkbox-cards-item-padding-bottom);
  --base-card-padding-left: var(--checkbox-cards-item-padding-left);
  --base-card-border-radius: var(--checkbox-cards-item-border-radius);
  --base-card-border-width: var(--checkbox-cards-item-border-width);
  display: flex;
  align-items: center;
  gap: var(--space-2);
  cursor: var(--cursor-button);
  -webkit-tap-highlight-color: transparent;
}

.rt-CheckboxCardsRoot:where(.rt-r-size-1) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
  --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
  --checkbox-cards-item-padding-left: var(--space-3);
  --checkbox-cards-item-border-radius: var(--radius-3);
  --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
}

.rt-CheckboxCardsRoot:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
  --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
  --checkbox-cards-item-padding-left: var(--space-4);
  --checkbox-cards-item-border-radius: var(--radius-3);
  --checkbox-cards-item-checkbox-size: var(--space-4);
}

.rt-CheckboxCardsRoot:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
  --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
  --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
  --checkbox-cards-item-padding-left: var(--space-5);
  --checkbox-cards-item-border-radius: var(--radius-4);
  --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
}

@media (min-width: 640px) {
  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }

  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }

  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }

  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }

  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1536px) {
  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-3) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-3);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 0.875);
  }

  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --checkbox-cards-item-padding-top: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-bottom: calc(var(--space-4) * 0.875);
    --checkbox-cards-item-padding-left: var(--space-4);
    --checkbox-cards-item-border-radius: var(--radius-3);
    --checkbox-cards-item-checkbox-size: var(--space-4);
  }

  .rt-CheckboxCardsRoot:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --checkbox-cards-item-padding-top: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-bottom: calc(var(--space-5) / 1.2);
    --checkbox-cards-item-padding-left: var(--space-5);
    --checkbox-cards-item-border-radius: var(--radius-4);
    --checkbox-cards-item-checkbox-size: calc(var(--space-4) * 1.25);
  }
}

:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem {
  --checkbox-cards-item-border-width: 1px;
  --checkbox-cards-item-background-color: var(--color-surface);
}

:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem::before {
  background-color: var(--checkbox-cards-item-background-color);
}

:where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem::after {
  box-shadow: var(--base-card-surface-box-shadow);
}

@media (hover: hover) {
  :where(.rt-CheckboxCardsRoot.rt-variant-surface) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}

:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem {
  --checkbox-cards-item-border-width: 1px;
  --checkbox-cards-item-background-color: var(--color-surface);
  transition: box-shadow 120ms;
  box-shadow: var(--base-card-classic-box-shadow-outer);
}

:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem::before {
  background-color: var(--checkbox-cards-item-background-color);
}

:where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem::after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}

@media (hover: hover) {
  :where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }

  :where(.rt-CheckboxCardsRoot.rt-variant-classic) .rt-CheckboxCardsItem:where(:not(:has(:disabled)):hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}

@media (pointer: coarse) {
  .rt-CheckboxCardsItem:where(:active:not(:focus-visible))::before {
    background-image: linear-gradient(var(--gray-a4), var(--gray-a4));
  }
}

.rt-CheckboxCardsItem:where(:has(:disabled)) {
  cursor: var(--cursor-disabled);
  color: var(--gray-a9);
}

.rt-CheckboxCardsItem:where(:has(:disabled))::before {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-CheckboxCardsItem:where(:has(:disabled))::-moz-selection {
  background-color: var(--gray-a5);
}

.rt-CheckboxCardsItem:where(:has(:disabled))::selection {
  background-color: var(--gray-a5);
}

.rt-CheckboxGroupRoot {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.rt-CheckboxGroupItem {
  display: flex;
  gap: 0.5em;
  width: -moz-fit-content;
  width: fit-content;
}

.rt-CheckboxGroupItemCheckbox:where(:focus-visible)::before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-CheckboxGroupItemCheckbox:where(:disabled) {
  cursor: var(--cursor-disabled);
}

.rt-CheckboxGroupItemCheckbox:where(:disabled)::before {
  background-color: var(--gray-a3);
}

.rt-CheckboxGroupItemInner {
  min-width: 0;
}

.rt-CheckboxRoot:where(:focus-visible)::before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-CheckboxRoot:where(:disabled) {
  cursor: var(--cursor-disabled);
}

.rt-CheckboxRoot:where(:disabled)::before {
  background-color: var(--gray-a3);
}

.rt-Code {
  --code-variant-font-size-adjust: calc(var(--code-font-size-adjust) * 0.95);
  font-family: var(--code-font-family);
  font-size: calc(var(--code-variant-font-size-adjust) * 1em);
  font-style: var(--code-font-style);
  font-weight: var(--code-font-weight);
  line-height: 1.25;
  letter-spacing: calc(var(--code-letter-spacing) + var(--letter-spacing, var(--default-letter-spacing)));
  border-radius: calc((0.5px + 0.2em) * var(--radius-factor));
  box-sizing: border-box;
  padding-top: var(--code-padding-top);
  padding-left: var(--code-padding-left);
  padding-bottom: var(--code-padding-bottom);
  padding-right: var(--code-padding-right);
  height: -moz-fit-content;
  height: fit-content;
}

.rt-Code :where(.rt-Code) {
  font-size: inherit;
}

.rt-Code:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-1);
  --letter-spacing: var(--letter-spacing-1);
}

.rt-Code:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
}

.rt-Code:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
}

.rt-Code:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-4);
  --letter-spacing: var(--letter-spacing-4);
}

.rt-Code:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-5);
  --letter-spacing: var(--letter-spacing-5);
}

.rt-Code:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-6);
  --letter-spacing: var(--letter-spacing-6);
}

.rt-Code:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-7);
  --letter-spacing: var(--letter-spacing-7);
}

.rt-Code:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-8);
  --letter-spacing: var(--letter-spacing-8);
}

.rt-Code:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
  line-height: var(--line-height-9);
  --letter-spacing: var(--letter-spacing-9);
}

@media (min-width: 640px) {
  .rt-Code:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 768px) {
  .rt-Code:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1024px) {
  .rt-Code:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1280px) {
  .rt-Code:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1536px) {
  .rt-Code:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-1);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Code:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Code:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Code:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-4);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Code:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-5);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Code:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-6);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Code:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-7);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Code:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Code:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--code-variant-font-size-adjust));
    line-height: var(--line-height-9);
    --letter-spacing: var(--letter-spacing-9);
  }
}

.rt-Code:where(.rt-variant-ghost) {
  --code-variant-font-size-adjust: var(--code-font-size-adjust);
  padding: 0;
}

.rt-Code:where(.rt-variant-ghost):where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-Code:where(.rt-variant-ghost):where([data-accent-color].rt-high-contrast),
  :where([data-accent-color]:not(.radix-themes)) .rt-Code:where(.rt-variant-ghost):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Code:where(.rt-variant-solid) {
  background-color: var(--accent-a9);
  color: var(--accent-contrast);
}

.rt-Code:where(.rt-variant-solid)::-moz-selection {
  background-color: var(--accent-7);
  color: var(--accent-12);
}

.rt-Code:where(.rt-variant-solid)::selection {
  background-color: var(--accent-7);
  color: var(--accent-12);
}

.rt-Code:where(.rt-variant-solid):where(.rt-high-contrast) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-Code:where(.rt-variant-solid):where(.rt-high-contrast)::-moz-selection {
  background-color: var(--accent-a11);
  color: var(--accent-1);
}

.rt-Code:where(.rt-variant-solid):where(.rt-high-contrast)::selection {
  background-color: var(--accent-a11);
  color: var(--accent-1);
}

.rt-Code:where(.rt-variant-solid):where(:link, button),
  .rt-Code:where(.rt-variant-solid):where(:visited, button) {
  isolation: isolate;
}

:where(.rt-Link) .rt-Code:where(.rt-variant-solid) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-solid):where(:-moz-any-link, button) {
  isolation: isolate;
}

:where(.rt-Link) .rt-Code:where(.rt-variant-solid),
  .rt-Code:where(.rt-variant-solid):where(:any-link, button) {
  isolation: isolate;
}

@media (hover: hover) {
  .rt-Code:where(.rt-variant-solid):where(:link, button):where(:hover), .rt-Code:where(.rt-variant-solid):where(:visited, button):where(:hover) {
    background-color: var(--accent-10);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-solid):where(:hover) {
    background-color: var(--accent-10);
  }
  .rt-Code:where(.rt-variant-solid):where(:-moz-any-link, button):where(:hover) {
    background-color: var(--accent-10);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-solid):where(:hover), .rt-Code:where(.rt-variant-solid):where(:any-link, button):where(:hover) {
    background-color: var(--accent-10);
  }

  .rt-Code:where(.rt-variant-solid):where(:link, button):where(.rt-high-contrast:hover), .rt-Code:where(.rt-variant-solid):where(:visited, button):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }

  :where(.rt-Link) .rt-Code:where(.rt-variant-solid):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }

  .rt-Code:where(.rt-variant-solid):where(:-moz-any-link, button):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }

  :where(.rt-Link) .rt-Code:where(.rt-variant-solid):where(.rt-high-contrast:hover), .rt-Code:where(.rt-variant-solid):where(:any-link, button):where(.rt-high-contrast:hover) {
    background-color: var(--accent-12);
    filter: var(--base-button-solid-high-contrast-hover-filter);
  }
}

.rt-Code:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
  color: var(--accent-a11);
}

.rt-Code:where(.rt-variant-soft):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Code:where(.rt-variant-soft):where(:link, button),
  .rt-Code:where(.rt-variant-soft):where(:visited, button) {
  isolation: isolate;
}

:where(.rt-Link) .rt-Code:where(.rt-variant-soft) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-soft):where(:-moz-any-link, button) {
  isolation: isolate;
}

:where(.rt-Link) .rt-Code:where(.rt-variant-soft),
  .rt-Code:where(.rt-variant-soft):where(:any-link, button) {
  isolation: isolate;
}

@media (hover: hover) {
  .rt-Code:where(.rt-variant-soft):where(:link, button):where(:hover), .rt-Code:where(.rt-variant-soft):where(:visited, button):where(:hover) {
    background-color: var(--accent-a4);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-soft):where(:hover) {
    background-color: var(--accent-a4);
  }
  .rt-Code:where(.rt-variant-soft):where(:-moz-any-link, button):where(:hover) {
    background-color: var(--accent-a4);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-soft):where(:hover), .rt-Code:where(.rt-variant-soft):where(:any-link, button):where(:hover) {
    background-color: var(--accent-a4);
  }
}

.rt-Code:where(.rt-variant-outline) {
  box-shadow: inset 0 0 0 max(1px, 0.033em) var(--accent-a8);
  color: var(--accent-a11);
}

.rt-Code:where(.rt-variant-outline):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 max(1px, 0.033em) var(--accent-a7), inset 0 0 0 max(1px, 0.033em) var(--gray-a11);
  color: var(--accent-12);
}

.rt-Code:where(.rt-variant-outline):where(:link, button),
  .rt-Code:where(.rt-variant-outline):where(:visited, button) {
  isolation: isolate;
}

:where(.rt-Link) .rt-Code:where(.rt-variant-outline) {
  isolation: isolate;
}

.rt-Code:where(.rt-variant-outline):where(:-moz-any-link, button) {
  isolation: isolate;
}

:where(.rt-Link) .rt-Code:where(.rt-variant-outline),
  .rt-Code:where(.rt-variant-outline):where(:any-link, button) {
  isolation: isolate;
}

@media (hover: hover) {
  .rt-Code:where(.rt-variant-outline):where(:link, button):where(:hover), .rt-Code:where(.rt-variant-outline):where(:visited, button):where(:hover) {
    background-color: var(--accent-a2);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-outline):where(:hover) {
    background-color: var(--accent-a2);
  }
  .rt-Code:where(.rt-variant-outline):where(:-moz-any-link, button):where(:hover) {
    background-color: var(--accent-a2);
  }
  :where(.rt-Link) .rt-Code:where(.rt-variant-outline):where(:hover), .rt-Code:where(.rt-variant-outline):where(:any-link, button):where(:hover) {
    background-color: var(--accent-a2);
  }
}

.rt-BaseMenuContent {
  --scrollarea-scrollbar-vertical-margin-top: var(--base-menu-content-padding);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--base-menu-content-padding);
  --scrollarea-scrollbar-horizontal-margin-left: var(--base-menu-content-padding);
  --scrollarea-scrollbar-horizontal-margin-right: var(--base-menu-content-padding);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--base-menu-bg);
  --base-menu-bg: var(--color-panel-solid);
  box-shadow: var(--shadow-5);
}

.rt-BaseMenuViewport {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: var(--base-menu-content-padding);
  box-sizing: border-box;
}

:where(.rt-BaseMenuContent:has(.rt-ScrollAreaScrollbar[data-orientation='vertical'])) .rt-BaseMenuViewport {
  padding-right: var(--space-3);
}

.rt-BaseMenuItem {
  display: flex;
  align-items: center;
  gap: var(--space-2);
  height: var(--base-menu-item-height);
  padding-left: var(--base-menu-item-padding-left);
  padding-right: var(--base-menu-item-padding-right);
  box-sizing: border-box;
  position: relative;
  outline: none;
  scroll-margin: var(--base-menu-content-padding) 0;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  cursor: var(--cursor-menu-item);
}

.rt-BaseMenuShortcut {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: var(--space-4);
  color: var(--gray-a11);
}

.rt-BaseMenuSubTriggerIcon {
  color: var(--gray-12);
  margin-right: calc(-2px * var(--scaling));
}

.rt-BaseMenuItemIndicator {
  position: absolute;
  left: 0;
  width: var(--base-menu-item-padding-left);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.rt-BaseMenuSeparator {
  height: 1px;
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
  margin-left: var(--base-menu-item-padding-left);
  margin-right: var(--base-menu-item-padding-right);
  background-color: var(--gray-a6);
}

.rt-BaseMenuLabel {
  display: flex;
  align-items: center;
  height: var(--base-menu-item-height);
  padding-left: var(--base-menu-item-padding-left);
  padding-right: var(--base-menu-item-padding-right);
  box-sizing: border-box;
  color: var(--gray-a10);
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  cursor: default;
}

:where(.rt-BaseMenuItem) + .rt-BaseMenuLabel {
  margin-top: var(--space-2);
}

.rt-BaseMenuArrow {
  fill: var(--base-menu-bg);
}

.rt-BaseMenuContent:where(.rt-r-size-1) {
  --base-menu-content-padding: var(--space-1);
  --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  --base-menu-item-padding-right: var(--space-2);
  --base-menu-item-height: var(--space-5);
  border-radius: var(--radius-3);
}

.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuItem) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: var(--radius-1);
}

.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuLabel) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}

.rt-BaseMenuContent:where(.rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
  width: calc(8px * var(--scaling));
  height: calc(8px * var(--scaling));
}

.rt-BaseMenuContent:where(.rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
  --base-menu-item-padding-left: var(--space-2);
}

.rt-BaseMenuContent:where(.rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
  --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
}

.rt-BaseMenuContent:where(.rt-r-size-2) {
  --base-menu-content-padding: var(--space-2);
  --base-menu-item-padding-left: var(--space-3);
  --base-menu-item-padding-right: var(--space-3);
  --base-menu-item-height: var(--space-6);
  border-radius: var(--radius-4);
}

.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuItem) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  border-radius: var(--radius-2);
}

.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuLabel) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-BaseMenuContent:where(.rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}

.rt-BaseMenuContent:where(.rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
  --base-menu-item-padding-left: var(--space-3);
}

.rt-BaseMenuContent:where(.rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
  --base-menu-item-padding-left: var(--space-5);
}

@media (min-width: 640px) {
  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.xs\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

@media (min-width: 768px) {
  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.sm\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

@media (min-width: 1024px) {
  .rt-BaseMenuContent:where(.md\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.md\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

@media (min-width: 1280px) {
  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.lg\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

@media (min-width: 1536px) {
  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) {
    --base-menu-content-padding: var(--space-1);
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
    --base-menu-item-padding-right: var(--space-2);
    --base-menu-item-height: var(--space-5);
    border-radius: var(--radius-3);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-2);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-1):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: calc(var(--space-5) / 1.2);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) {
    --base-menu-content-padding: var(--space-2);
    --base-menu-item-padding-left: var(--space-3);
    --base-menu-item-padding-right: var(--space-3);
    --base-menu-item-height: var(--space-6);
    border-radius: var(--radius-4);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuItem) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: var(--radius-2);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuLabel) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2) :where(.rt-BaseMenuItemIndicatorIcon, .rt-BaseMenuSubTriggerIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2):where(:not(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem))) {
    --base-menu-item-padding-left: var(--space-3);
  }

  .rt-BaseMenuContent:where(.xl\:rt-r-size-2):where(:has(.rt-BaseMenuCheckboxItem, .rt-BaseMenuRadioItem)) {
    --base-menu-item-padding-left: var(--space-5);
  }
}

.rt-BaseMenuItem:where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-BaseMenuItem:where([data-disabled]) {
  color: var(--gray-a8);
  cursor: default;
}

.rt-BaseMenuItem:where([data-disabled], [data-highlighted]) :where(.rt-BaseMenuShortcut), .rt-BaseMenuSubTrigger:where([data-state='open']) :where(.rt-BaseMenuShortcut) {
  color: inherit;
}

.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuSubTrigger[data-state='open']) {
  background-color: var(--gray-a3);
}

.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuItem[data-highlighted]) :where(.rt-BaseMenuSubTriggerIcon) {
  color: var(--accent-contrast);
}

.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]) :where(.rt-BaseMenuSubTriggerIcon) {
  color: var(--accent-1);
}

.rt-BaseMenuContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-BaseMenuItem[data-highlighted]):where([data-accent-color]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-BaseMenuContent:where(.rt-variant-soft) :where(.rt-BaseMenuSubTrigger[data-state='open']) {
  background-color: var(--accent-a3);
}

.rt-BaseMenuContent:where(.rt-variant-soft) :where(.rt-BaseMenuItem[data-highlighted]) {
  background-color: var(--accent-a4);
}

.rt-ContextMenuContent {
  max-height: var(--radix-context-menu-content-available-height);
  transform-origin: var(--radix-context-menu-content-transform-origin);
}

.rt-DataListRoot {
  word-wrap: anywhere;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-normal);
  font-style: normal;
  text-align: left;
  --data-list-leading-trim-start: calc(var(--default-leading-trim-start) - var(--line-height) / 2);
  --data-list-leading-trim-end: calc(var(--default-leading-trim-end) - var(--line-height) / 2);
}

.rt-DataListLabel {
  display: flex;
  color: var(--gray-a11);
}

.rt-DataListLabel:where(.rt-high-contrast) {
  color: var(--gray-12);
}

.rt-DataListLabel:where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-DataListLabel:where([data-accent-color]):where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-DataListValue {
  display: flex;
  margin: 0;
  min-width: 0px;
  margin-top: var(--data-list-value-margin-top);
  margin-bottom: var(--data-list-value-margin-bottom);
}

.rt-DataListItem {
  --data-list-value-margin-top: 0px;
  --data-list-value-margin-bottom: 0px;
  --data-list-first-item-value-margin-top: 0px;
  --data-list-last-item-value-margin-bottom: 0px;
  --data-list-value-trim-start: -0.25em;
  --data-list-value-trim-end: -0.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

:where(.rt-DataListItem:first-child) .rt-DataListValue {
  margin-top: var(--data-list-first-item-value-margin-top);
}

:where(.rt-DataListItem:last-child) .rt-DataListValue {
  margin-bottom: var(--data-list-last-item-value-margin-bottom);
}

.rt-DataListRoot:where(.rt-r-size-1) {
  gap: var(--space-3);
}

.rt-DataListRoot:where(.rt-r-size-2) {
  gap: var(--space-4);
}

.rt-DataListRoot:where(.rt-r-size-3) {
  gap: calc(var(--space-4) * 1.25);
}

@media (min-width: 640px) {
  .rt-DataListRoot:where(.xs\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.xs\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.xs\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.sm\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.sm\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.md\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.md\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.lg\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.lg\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1536px) {
  .rt-DataListRoot:where(.xl\:rt-r-size-1) {
    gap: var(--space-3);
  }

  .rt-DataListRoot:where(.xl\:rt-r-size-2) {
    gap: var(--space-4);
  }

  .rt-DataListRoot:where(.xl\:rt-r-size-3) {
    gap: calc(var(--space-4) * 1.25);
  }
}

.rt-DataListRoot:where(.rt-r-orientation-vertical) {
  display: flex;
  flex-direction: column;
}

.rt-DataListRoot:where(.rt-r-orientation-vertical) :where(.rt-DataListItem) {
  --data-list-value-margin-top: 0px;
  --data-list-value-margin-bottom: 0px;
  --data-list-first-item-value-margin-top: 0px;
  --data-list-last-item-value-margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.rt-DataListRoot:where(.rt-r-orientation-vertical) :where(.rt-DataListLabel) {
  min-width: 0px;
}

.rt-DataListRoot:where(.rt-r-orientation-horizontal) {
  display: grid;
  grid-template-columns: auto 1fr;
}

.rt-DataListRoot:where(.rt-r-orientation-horizontal) :where(.rt-DataListItem) {
  --data-list-value-margin-top: var(--data-list-value-trim-start);
  --data-list-value-margin-bottom: var(--data-list-value-trim-end);
  --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
  --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
  display: grid;
  grid-template-columns: inherit;
  grid-template-columns: subgrid;
  grid-gap: inherit;
  gap: inherit;
  grid-column: span 2;
  align-items: baseline;
}

.rt-DataListRoot:where(.rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
  min-width: 120px;
}

@media (min-width: 640px) {
  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }

  .rt-DataListRoot:where(.xs\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }

  .rt-DataListRoot:where(.sm\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }

  .rt-DataListRoot:where(.md\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }

  .rt-DataListRoot:where(.lg\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

@media (min-width: 1536px) {
  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) {
    display: flex;
    flex-direction: column;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) :where(.rt-DataListItem) {
    --data-list-value-margin-top: 0px;
    --data-list-value-margin-bottom: 0px;
    --data-list-first-item-value-margin-top: 0px;
    --data-list-last-item-value-margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-vertical) :where(.rt-DataListLabel) {
    min-width: 0px;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) :where(.rt-DataListItem) {
    --data-list-value-margin-top: var(--data-list-value-trim-start);
    --data-list-value-margin-bottom: var(--data-list-value-trim-end);
    --data-list-first-item-value-margin-top: var(--data-list-first-item-value-trim-start);
    --data-list-last-item-value-margin-bottom: var(--data-list-last-item-value-trim-end);
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    grid-gap: inherit;
    gap: inherit;
    grid-column: span 2;
    align-items: baseline;
  }

  .rt-DataListRoot:where(.xl\:rt-r-orientation-horizontal) :where(.rt-DataListLabel) {
    min-width: 120px;
  }
}

.rt-DataListLabel::before, .rt-DataListValue::before {
  content: '‍';
}

.rt-DataListItem:where(.rt-r-ai-baseline) {
  --data-list-value-trim-start: -0.25em;
  --data-list-value-trim-end: -0.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

.rt-DataListItem:where(.rt-r-ai-start) {
  --data-list-value-trim-start: 0px;
  --data-list-value-trim-end: -0.25em;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

.rt-DataListItem:where(.rt-r-ai-center) {
  --data-list-value-trim-start: -0.25em;
  --data-list-value-trim-end: -0.25em;
  --data-list-first-item-value-trim-start: -0.25em;
  --data-list-last-item-value-trim-end: -0.25em;
}

.rt-DataListItem:where(.rt-r-ai-end) {
  --data-list-value-trim-start: -0.25em;
  --data-list-value-trim-end: 0px;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

.rt-DataListItem:where(.rt-r-ai-stretch) {
  --data-list-value-trim-start: 0px;
  --data-list-value-trim-end: 0px;
  --data-list-first-item-value-trim-start: 0px;
  --data-list-last-item-value-trim-end: 0px;
}

@media (min-width: 640px) {
  .rt-DataListItem:where(.xs\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xs\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xs\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }

  .rt-DataListItem:where(.xs\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xs\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

@media (min-width: 768px) {
  .rt-DataListItem:where(.sm\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.sm\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.sm\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }

  .rt-DataListItem:where(.sm\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.sm\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

@media (min-width: 1024px) {
  .rt-DataListItem:where(.md\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.md\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.md\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }

  .rt-DataListItem:where(.md\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.md\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

@media (min-width: 1280px) {
  .rt-DataListItem:where(.lg\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.lg\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.lg\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }

  .rt-DataListItem:where(.lg\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.lg\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

@media (min-width: 1536px) {
  .rt-DataListItem:where(.xl\:rt-r-ai-baseline) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xl\:rt-r-ai-start) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xl\:rt-r-ai-center) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: -0.25em;
    --data-list-first-item-value-trim-start: -0.25em;
    --data-list-last-item-value-trim-end: -0.25em;
  }

  .rt-DataListItem:where(.xl\:rt-r-ai-end) {
    --data-list-value-trim-start: -0.25em;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }

  .rt-DataListItem:where(.xl\:rt-r-ai-stretch) {
    --data-list-value-trim-start: 0px;
    --data-list-value-trim-end: 0px;
    --data-list-first-item-value-trim-start: 0px;
    --data-list-last-item-value-trim-end: 0px;
  }
}

.rt-DataListItem:where(:first-child) {
  margin-top: var(--leading-trim-start);
}

.rt-DataListItem:where(:last-child) {
  margin-bottom: var(--leading-trim-end);
}

.rt-DataListRoot:where(.rt-r-trim-normal) {
  --leading-trim-start: initial;
  --leading-trim-end: initial;
}

.rt-DataListRoot:where(.rt-r-trim-start) {
  --leading-trim-start: var(--data-list-leading-trim-start);
  --leading-trim-end: initial;
}

.rt-DataListRoot:where(.rt-r-trim-end) {
  --leading-trim-start: initial;
  --leading-trim-end: var(--data-list-leading-trim-end);
}

.rt-DataListRoot:where(.rt-r-trim-both) {
  --leading-trim-start: var(--data-list-leading-trim-start);
  --leading-trim-end: var(--data-list-leading-trim-end);
}

@media (min-width: 640px) {
  .rt-DataListRoot:where(.xs\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.xs\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.xs\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.xs\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

@media (min-width: 768px) {
  .rt-DataListRoot:where(.sm\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.sm\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.sm\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.sm\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

@media (min-width: 1024px) {
  .rt-DataListRoot:where(.md\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.md\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.md\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.md\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

@media (min-width: 1280px) {
  .rt-DataListRoot:where(.lg\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.lg\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.lg\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.lg\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

@media (min-width: 1536px) {
  .rt-DataListRoot:where(.xl\:rt-r-trim-normal) {
    --leading-trim-start: initial;
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.xl\:rt-r-trim-start) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: initial;
  }

  .rt-DataListRoot:where(.xl\:rt-r-trim-end) {
    --leading-trim-start: initial;
    --leading-trim-end: var(--data-list-leading-trim-end);
  }

  .rt-DataListRoot:where(.xl\:rt-r-trim-both) {
    --leading-trim-start: var(--data-list-leading-trim-start);
    --leading-trim-end: var(--data-list-leading-trim-end);
  }
}

.rt-DropdownMenuContent {
  max-height: var(--radix-dropdown-menu-content-available-height);
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
}

.rt-Em {
  box-sizing: border-box;
  font-family: var(--em-font-family);
  font-size: calc(var(--em-font-size-adjust) * 1em);
  font-style: var(--em-font-style);
  font-weight: var(--em-font-weight);
  line-height: 1.25;
  letter-spacing: calc(var(--em-letter-spacing) + var(--letter-spacing, var(--default-letter-spacing)));
  color: inherit;
}

.rt-Em :where(.rt-Em) {
  font-size: inherit;
}

.rt-Heading {
  --leading-trim-start: var(--heading-leading-trim-start);
  --leading-trim-end: var(--heading-leading-trim-end);
  font-family: var(--heading-font-family);
  font-style: var(--heading-font-style);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height);
}

:where(.rt-Heading) {
  margin: 0;
}

.rt-Heading:where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-Heading:where([data-accent-color].rt-high-contrast),
  :where([data-accent-color]:not(.radix-themes)) .rt-Heading:where(.rt-high-contrast) {
  color: var(--accent-12);
}

.rt-Heading:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-1);
  letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-2);
  letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-3);
  letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-4);
  letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-5);
  letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-6);
  letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-7);
  letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-8);
  letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
}

.rt-Heading:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
  --line-height: var(--heading-line-height-9);
  letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
}

@media (min-width: 640px) {
  .rt-Heading:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}

@media (min-width: 768px) {
  .rt-Heading:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}

@media (min-width: 1024px) {
  .rt-Heading:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}

@media (min-width: 1280px) {
  .rt-Heading:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}

@media (min-width: 1536px) {
  .rt-Heading:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-1);
    letter-spacing: calc(var(--letter-spacing-1) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-2);
    letter-spacing: calc(var(--letter-spacing-2) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-3);
    letter-spacing: calc(var(--letter-spacing-3) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-4);
    letter-spacing: calc(var(--letter-spacing-4) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-5);
    letter-spacing: calc(var(--letter-spacing-5) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-6);
    letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-7);
    letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-8);
    letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));
  }

  .rt-Heading:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * var(--heading-font-size-adjust));
    --line-height: var(--heading-line-height-9);
    letter-spacing: calc(var(--letter-spacing-9) + var(--heading-letter-spacing));
  }
}

.rt-HoverCardContent {
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-4);
  overflow: auto;
  position: relative;
  --inset-padding-top: var(--hover-card-content-padding);
  --inset-padding-right: var(--hover-card-content-padding);
  --inset-padding-bottom: var(--hover-card-content-padding);
  --inset-padding-left: var(--hover-card-content-padding);
  padding: var(--hover-card-content-padding);
  box-sizing: border-box;
  transform-origin: var(--radix-hover-card-content-transform-origin);
}

.rt-HoverCardContent:where(.rt-r-size-1) {
  --hover-card-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}

.rt-HoverCardContent:where(.rt-r-size-2) {
  --hover-card-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}

.rt-HoverCardContent:where(.rt-r-size-3) {
  --hover-card-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}

@media (min-width: 640px) {
  .rt-HoverCardContent:where(.xs\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.xs\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.xs\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 768px) {
  .rt-HoverCardContent:where(.sm\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.sm\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.sm\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1024px) {
  .rt-HoverCardContent:where(.md\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.md\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.md\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1280px) {
  .rt-HoverCardContent:where(.lg\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.lg\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.lg\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1536px) {
  .rt-HoverCardContent:where(.xl\:rt-r-size-1) {
    --hover-card-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.xl\:rt-r-size-2) {
    --hover-card-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-HoverCardContent:where(.xl\:rt-r-size-3) {
    --hover-card-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }
}

.rt-IconButton:where(:not(.rt-variant-ghost)) {
  height: var(--base-button-height);
  width: var(--base-button-height);
}

.rt-IconButton:where(.rt-variant-ghost) {
  padding: var(--icon-button-ghost-padding);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top) - var(--icon-button-ghost-padding));
  --margin-right-override: calc(var(--margin-right) - var(--icon-button-ghost-padding));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--icon-button-ghost-padding));
  --margin-left-override: calc(var(--margin-left) - var(--icon-button-ghost-padding));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}

:where(.rt-IconButton:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-1) {
  --icon-button-ghost-padding: var(--space-1);
}

.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-2) {
  --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
}

.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-3) {
  --icon-button-ghost-padding: var(--space-2);
}

.rt-IconButton:where(.rt-variant-ghost):where(.rt-r-size-4) {
  --icon-button-ghost-padding: var(--space-3);
}

@media (min-width: 640px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xs\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

@media (min-width: 768px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.sm\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

@media (min-width: 1024px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.md\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

@media (min-width: 1280px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.lg\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

@media (min-width: 1536px) {
  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-1) {
    --icon-button-ghost-padding: var(--space-1);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-2) {
    --icon-button-ghost-padding: calc(var(--space-1) * 1.5);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-3) {
    --icon-button-ghost-padding: var(--space-2);
  }

  .rt-IconButton:where(.rt-variant-ghost):where(.xl\:rt-r-size-4) {
    --icon-button-ghost-padding: var(--space-3);
  }
}

.rt-Inset {
  box-sizing: border-box;
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  overflow: hidden;
  margin-top: var(--margin-top-override);
  margin-right: var(--margin-right-override);
  margin-bottom: var(--margin-bottom-override);
  margin-left: var(--margin-left-override);
}

:where(.rt-Inset) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

.rt-Inset:where(.rt-r-clip-border-box) {
  --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
  --inset-padding-top-calc: var(--inset-padding-top, 0px);
  --inset-padding-right-calc: var(--inset-padding-right, 0px);
  --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
  --inset-padding-left-calc: var(--inset-padding-left, 0px);
}

.rt-Inset:where(.rt-r-clip-padding-box) {
  --inset-border-radius-calc: var(--inset-border-radius, 0px);
  --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
  --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
  --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
  --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
}

.rt-Inset:where(.rt-r-side-top) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: var(--margin-bottom);
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  border-top-left-radius: var(--inset-border-radius-calc);
  border-top-right-radius: var(--inset-border-radius-calc);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rt-Inset:where(.rt-r-side-bottom) {
  --margin-top-override: var(--margin-top);
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: var(--inset-border-radius-calc);
  border-bottom-right-radius: var(--inset-border-radius-calc);
}

.rt-Inset:where(.rt-r-side-left) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  --margin-right-override: var(--margin-right);
  border-top-left-radius: var(--inset-border-radius-calc);
  border-top-right-radius: 0px;
  border-bottom-left-radius: var(--inset-border-radius-calc);
  border-bottom-right-radius: 0px;
}

.rt-Inset:where(.rt-r-side-right) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: var(--margin-left);
  border-top-left-radius: 0px;
  border-top-right-radius: var(--inset-border-radius-calc);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: var(--inset-border-radius-calc);
}

.rt-Inset:where(.rt-r-side-x) {
  --margin-top-override: var(--margin-top);
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: var(--margin-bottom);
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  border-radius: 0px;
}

.rt-Inset:where(.rt-r-side-y) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-right-override: var(--margin-right);
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: var(--margin-left);
  border-radius: 0px;
}

.rt-Inset:where(.rt-r-side-all) {
  --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
  --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
  --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
  border-radius: var(--inset-border-radius-calc);
}

@media (min-width: 640px) {
  .rt-Inset:where(.xs\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.xs\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.xs\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.xs\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.xs\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.xs\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.xs\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }

  .rt-Inset:where(.xs\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }

  .rt-Inset:where(.xs\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

@media (min-width: 768px) {
  .rt-Inset:where(.sm\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.sm\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.sm\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.sm\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.sm\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.sm\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.sm\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }

  .rt-Inset:where(.sm\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }

  .rt-Inset:where(.sm\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

@media (min-width: 1024px) {
  .rt-Inset:where(.md\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.md\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.md\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.md\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.md\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.md\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.md\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }

  .rt-Inset:where(.md\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }

  .rt-Inset:where(.md\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

@media (min-width: 1280px) {
  .rt-Inset:where(.lg\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.lg\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.lg\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.lg\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.lg\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.lg\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.lg\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }

  .rt-Inset:where(.lg\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }

  .rt-Inset:where(.lg\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

@media (min-width: 1536px) {
  .rt-Inset:where(.xl\:rt-r-clip-border-box) {
    --inset-border-radius-calc: calc(var(--inset-border-radius, 0px) - var(--inset-border-width, 0px));
    --inset-padding-top-calc: var(--inset-padding-top, 0px);
    --inset-padding-right-calc: var(--inset-padding-right, 0px);
    --inset-padding-bottom-calc: var(--inset-padding-bottom, 0px);
    --inset-padding-left-calc: var(--inset-padding-left, 0px);
  }

  .rt-Inset:where(.xl\:rt-r-clip-padding-box) {
    --inset-border-radius-calc: var(--inset-border-radius, 0px);
    --inset-padding-top-calc: calc(var(--inset-padding-top, 0px) + var(--inset-border-width, 0px));
    --inset-padding-right-calc: calc(var(--inset-padding-right, 0px) + var(--inset-border-width, 0px));
    --inset-padding-bottom-calc: calc(var(--inset-padding-bottom, 0px) + var(--inset-border-width, 0px));
    --inset-padding-left-calc: calc(var(--inset-padding-left, 0px) + var(--inset-border-width, 0px));
  }

  .rt-Inset:where(.xl\:rt-r-side-top) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.xl\:rt-r-side-bottom) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.xl\:rt-r-side-left) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    --margin-right-override: var(--margin-right);
    border-top-left-radius: var(--inset-border-radius-calc);
    border-top-right-radius: 0px;
    border-bottom-left-radius: var(--inset-border-radius-calc);
    border-bottom-right-radius: 0px;
  }

  .rt-Inset:where(.xl\:rt-r-side-right) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-top-left-radius: 0px;
    border-top-right-radius: var(--inset-border-radius-calc);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: var(--inset-border-radius-calc);
  }

  .rt-Inset:where(.xl\:rt-r-side-x) {
    --margin-top-override: var(--margin-top);
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: var(--margin-bottom);
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: 0px;
  }

  .rt-Inset:where(.xl\:rt-r-side-y) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: var(--margin-right);
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: var(--margin-left);
    border-radius: 0px;
  }

  .rt-Inset:where(.xl\:rt-r-side-all) {
    --margin-top-override: calc(var(--margin-top) - var(--inset-padding-top-calc));
    --margin-right-override: calc(var(--margin-right) - var(--inset-padding-right-calc));
    --margin-bottom-override: calc(var(--margin-bottom) - var(--inset-padding-bottom-calc));
    --margin-left-override: calc(var(--margin-left) - var(--inset-padding-left-calc));
    border-radius: var(--inset-border-radius-calc);
  }
}

.rt-Kbd {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: var(--default-font-family);
  font-weight: normal;
  vertical-align: text-top;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  position: relative;
  top: -0.03em;
  font-size: 0.75em;
  min-width: 1.75em;
  line-height: 1.7em;
  box-sizing: border-box;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.05em;
  word-spacing: -0.1em;
  border-radius: calc(var(--radius-factor) * 0.35em);
  letter-spacing: var(--letter-spacing, var(--default-letter-spacing));
  height: -moz-fit-content;
  height: fit-content;
  color: var(--gray-12);
  background-color: var(--gray-1);
  box-shadow: var(--kbd-box-shadow);
  transition: box-shadow 120ms, background-color 120ms;
}

@media (hover: hover) {
  .rt-Kbd:where(:link, button):where(:hover), .rt-Kbd:where(:visited, button):where(:hover) {
    transition-duration: 40ms, 40ms;
    background-color: var(--color-background);
    box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
  }
  .rt-Kbd:where(:-moz-any-link, button):where(:hover) {
    transition-duration: 40ms, 40ms;
    background-color: var(--color-background);
    box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
  }
  .rt-Kbd:where(:any-link, button):where(:hover) {
    transition-duration: 40ms, 40ms;
    background-color: var(--color-background);
    box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
  }
}

.rt-Kbd:where(:link, button):where([data-state='open']), .rt-Kbd:where(:visited, button):where([data-state='open']) {
  transition-duration: 40ms, 40ms;
  background-color: var(--color-background);
  box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
}

.rt-Kbd:where(:-moz-any-link, button):where([data-state='open']) {
  transition-duration: 40ms, 40ms;
  background-color: var(--color-background);
  box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
}

.rt-Kbd:where(:any-link, button):where([data-state='open']) {
  transition-duration: 40ms, 40ms;
  background-color: var(--color-background);
  box-shadow: var(--kbd-box-shadow), 0 0 0 0.05em var(--gray-a5);
}

.rt-Kbd:where(:link, button):where(:active:not([data-state='open'])), .rt-Kbd:where(:visited, button):where(:active:not([data-state='open'])) {
  padding-top: 0.05em;
  padding-bottom: 0;
  transition-duration: 40ms, 40ms;
  background-color: var(--gray-2);
  box-shadow: inset 0 0.05em var(--black-a3), 0 0 0 0.05em var(--gray-a7);
}

.rt-Kbd:where(:-moz-any-link, button):where(:active:not([data-state='open'])) {
  padding-top: 0.05em;
  padding-bottom: 0;
  transition-duration: 40ms, 40ms;
  background-color: var(--gray-2);
  box-shadow: inset 0 0.05em var(--black-a3), 0 0 0 0.05em var(--gray-a7);
}

.rt-Kbd:where(:any-link, button):where(:active:not([data-state='open'])) {
  padding-top: 0.05em;
  padding-bottom: 0;
  transition-duration: 40ms, 40ms;
  background-color: var(--gray-2);
  box-shadow: inset 0 0.05em var(--black-a3), 0 0 0 0.05em var(--gray-a7);
}

.rt-Kbd:where(:link, button):where(:focus-visible), .rt-Kbd:where(:visited, button):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}

.rt-Kbd:where(:-moz-any-link, button):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}

.rt-Kbd:where(:any-link, button):where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}

.rt-Kbd:where(.rt-r-size-1) {
  font-size: calc(var(--font-size-1) * 0.8);
  --letter-spacing: var(--letter-spacing-1);
}

.rt-Kbd:where(.rt-r-size-2) {
  font-size: calc(var(--font-size-2) * 0.8);
  --letter-spacing: var(--letter-spacing-2);
}

.rt-Kbd:where(.rt-r-size-3) {
  font-size: calc(var(--font-size-3) * 0.8);
  --letter-spacing: var(--letter-spacing-3);
}

.rt-Kbd:where(.rt-r-size-4) {
  font-size: calc(var(--font-size-4) * 0.8);
  --letter-spacing: var(--letter-spacing-4);
}

.rt-Kbd:where(.rt-r-size-5) {
  font-size: calc(var(--font-size-5) * 0.8);
  --letter-spacing: var(--letter-spacing-5);
}

.rt-Kbd:where(.rt-r-size-6) {
  font-size: calc(var(--font-size-6) * 0.8);
  --letter-spacing: var(--letter-spacing-6);
}

.rt-Kbd:where(.rt-r-size-7) {
  font-size: calc(var(--font-size-7) * 0.8);
  --letter-spacing: var(--letter-spacing-7);
}

.rt-Kbd:where(.rt-r-size-8) {
  font-size: calc(var(--font-size-8) * 0.8);
  --letter-spacing: var(--letter-spacing-8);
}

.rt-Kbd:where(.rt-r-size-9) {
  font-size: calc(var(--font-size-9) * 0.8);
  --letter-spacing: var(--letter-spacing-9);
}

@media (min-width: 640px) {
  .rt-Kbd:where(.xs\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.xs\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.xs\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.xs\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.xs\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.xs\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.xs\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.xs\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.xs\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 768px) {
  .rt-Kbd:where(.sm\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.sm\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.sm\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.sm\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.sm\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.sm\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.sm\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.sm\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.sm\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1024px) {
  .rt-Kbd:where(.md\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.md\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.md\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.md\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.md\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.md\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.md\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.md\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.md\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1280px) {
  .rt-Kbd:where(.lg\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.lg\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.lg\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.lg\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.lg\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.lg\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.lg\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.lg\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.lg\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

@media (min-width: 1536px) {
  .rt-Kbd:where(.xl\:rt-r-size-1) {
    font-size: calc(var(--font-size-1) * 0.8);
    --letter-spacing: var(--letter-spacing-1);
  }

  .rt-Kbd:where(.xl\:rt-r-size-2) {
    font-size: calc(var(--font-size-2) * 0.8);
    --letter-spacing: var(--letter-spacing-2);
  }

  .rt-Kbd:where(.xl\:rt-r-size-3) {
    font-size: calc(var(--font-size-3) * 0.8);
    --letter-spacing: var(--letter-spacing-3);
  }

  .rt-Kbd:where(.xl\:rt-r-size-4) {
    font-size: calc(var(--font-size-4) * 0.8);
    --letter-spacing: var(--letter-spacing-4);
  }

  .rt-Kbd:where(.xl\:rt-r-size-5) {
    font-size: calc(var(--font-size-5) * 0.8);
    --letter-spacing: var(--letter-spacing-5);
  }

  .rt-Kbd:where(.xl\:rt-r-size-6) {
    font-size: calc(var(--font-size-6) * 0.8);
    --letter-spacing: var(--letter-spacing-6);
  }

  .rt-Kbd:where(.xl\:rt-r-size-7) {
    font-size: calc(var(--font-size-7) * 0.8);
    --letter-spacing: var(--letter-spacing-7);
  }

  .rt-Kbd:where(.xl\:rt-r-size-8) {
    font-size: calc(var(--font-size-8) * 0.8);
    --letter-spacing: var(--letter-spacing-8);
  }

  .rt-Kbd:where(.xl\:rt-r-size-9) {
    font-size: calc(var(--font-size-9) * 0.8);
    --letter-spacing: var(--letter-spacing-9);
  }
}

.rt-Link:where(:link, button), .rt-Link:where(:visited, button) {
  cursor: var(--cursor-link);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: min(2px, max(1px, 0.05em));
  text-underline-offset: calc(0.025em + 2px);
  text-decoration-color: var(--accent-a5);
}

.rt-Link:where(:-moz-any-link, button) {
  cursor: var(--cursor-link);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: min(2px, max(1px, 0.05em));
  text-underline-offset: calc(0.025em + 2px);
  text-decoration-color: var(--accent-a5);
}

.rt-Link:where(:any-link, button) {
  cursor: var(--cursor-link);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: min(2px, max(1px, 0.05em));
  text-underline-offset: calc(0.025em + 2px);
  text-decoration-color: var(--accent-a5);
}

.rt-Link:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}

:where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where([data-accent-color='']) {
  color: var(--accent-12);
}

@supports (color: color-mix(in oklab, white, black)) {
  .rt-Link:where(:link, button), .rt-Link:where(:visited, button) {
    text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
  }
  .rt-Link:where(:-moz-any-link, button) {
    text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
  }
  .rt-Link:where(:any-link, button) {
    text-decoration-color: color-mix(in oklab, var(--accent-a5), var(--gray-a6));
  }
}

@media (pointer: coarse) {
  .rt-Link:where(:link, button):where(:active:not(:focus-visible):not([data-state='open'])), .rt-Link:where(:visited, button):where(:active:not(:focus-visible):not([data-state='open'])) {
    outline: 0.75em solid var(--accent-a4);
    outline-offset: -0.6em;
  }
  .rt-Link:where(:link, button):where(:active:not(:focus-visible):not([data-state='open'])), .rt-Link:where(:visited, button):where(:active:not(:focus-visible):not([data-state='open'])) {
    outline: 0.75em solid var(--accent-a4);
    outline-offset: -0.6em;
  }
  .rt-Link:where(:-moz-any-link, button):where(:active:not(:focus-visible):not([data-state='open'])) {
    outline: 0.75em solid var(--accent-a4);
    outline-offset: -0.6em;
  }
  .rt-Link:where(:any-link, button):where(:active:not(:focus-visible):not([data-state='open'])) {
    outline: 0.75em solid var(--accent-a4);
    outline-offset: -0.6em;
  }
}

@media (hover: hover) {
  .rt-Link:where(:link, button):where(.rt-underline-auto):where(:hover), .rt-Link:where(:visited, button):where(.rt-underline-auto):where(:hover) {
    text-decoration-line: underline;
  }
  .rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where(:hover) {
    text-decoration-line: underline;
  }
  .rt-Link:where(:any-link, button):where(.rt-underline-auto):where(:hover) {
    text-decoration-line: underline;
  }
}

.rt-Link:where(:link, button):where(.rt-underline-auto):where(.rt-high-contrast), .rt-Link:where(:visited, button):where(.rt-underline-auto):where(.rt-high-contrast) {
  text-decoration-line: underline;
  text-decoration-color: var(--accent-a6);
}

:where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
  text-decoration-line: underline;
  text-decoration-color: var(--accent-a6);
}

:where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
  text-decoration-line: underline;
  text-decoration-color: var(--accent-a6);
}

.rt-Link:where(:link, button):where(.rt-underline-auto):where(.rt-high-contrast), .rt-Link:where(:visited, button):where(.rt-underline-auto):where(.rt-high-contrast) {
  text-decoration-line: underline;
  text-decoration-color: var(--accent-a6);
}

:where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
  text-decoration-line: underline;
  text-decoration-color: var(--accent-a6);
}

:where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
  text-decoration-line: underline;
  text-decoration-color: var(--accent-a6);
}

.rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where(.rt-high-contrast), :where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
  text-decoration-line: underline;
  text-decoration-color: var(--accent-a6);
}

.rt-Link:where(:any-link, button):where(.rt-underline-auto):where(.rt-high-contrast),
      :where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
  text-decoration-line: underline;
  text-decoration-color: var(--accent-a6);
}

@supports (color: color-mix(in oklab, white, black)) {
  .rt-Link:where(:link, button):where(.rt-underline-auto):where(.rt-high-contrast), .rt-Link:where(:visited, button):where(.rt-underline-auto):where(.rt-high-contrast) {
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
  :where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
  :where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
  .rt-Link:where(:link, button):where(.rt-underline-auto):where(.rt-high-contrast), .rt-Link:where(:visited, button):where(.rt-underline-auto):where(.rt-high-contrast) {
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
  :where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
  :where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
  .rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where(.rt-high-contrast), :where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:-moz-any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
  .rt-Link:where(:any-link, button):where(.rt-underline-auto):where(.rt-high-contrast),
      :where([data-accent-color]:not(.radix-themes):not(.rt-high-contrast)) .rt-Link:where(:any-link, button):where(.rt-underline-auto):where([data-accent-color='']) {
    text-decoration-color: color-mix(in oklab, var(--accent-a6), var(--gray-a6));
  }
}

@media (hover: hover) {
  .rt-Link:where(:link, button):where(.rt-underline-hover):where(:hover), .rt-Link:where(:visited, button):where(.rt-underline-hover):where(:hover) {
    text-decoration-line: underline;
  }
  .rt-Link:where(:-moz-any-link, button):where(.rt-underline-hover):where(:hover) {
    text-decoration-line: underline;
  }
  .rt-Link:where(:any-link, button):where(.rt-underline-hover):where(:hover) {
    text-decoration-line: underline;
  }
}

.rt-Link:where(:link, button):where(.rt-underline-always), .rt-Link:where(:visited, button):where(.rt-underline-always) {
  text-decoration-line: underline;
}

.rt-Link:where(:-moz-any-link, button):where(.rt-underline-always) {
  text-decoration-line: underline;
}

.rt-Link:where(:any-link, button):where(.rt-underline-always) {
  text-decoration-line: underline;
}

.rt-Link:where(:focus-visible) {
  text-decoration-line: none;
  border-radius: calc(0.07em * var(--radius-factor));
  outline-color: var(--focus-8);
  outline-width: 2px;
  outline-style: solid;
  outline-offset: 2px;
}

.rt-Link:where(:has(.rt-Code:not(.rt-variant-ghost):only-child)) {
  text-decoration-color: transparent;
}

.rt-PopoverContent {
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-5);
  min-width: var(--radix-popover-trigger-width);
  outline: 0;
  overflow: auto;
  position: relative;
  --inset-padding-top: var(--popover-content-padding);
  --inset-padding-right: var(--popover-content-padding);
  --inset-padding-bottom: var(--popover-content-padding);
  --inset-padding-left: var(--popover-content-padding);
  padding: var(--popover-content-padding);
  box-sizing: border-box;
  transform-origin: var(--radix-popover-content-transform-origin);
}

.rt-PopoverContent:where(.rt-r-size-1) {
  --popover-content-padding: var(--space-3);
  border-radius: var(--radius-4);
}

.rt-PopoverContent:where(.rt-r-size-2) {
  --popover-content-padding: var(--space-4);
  border-radius: var(--radius-4);
}

.rt-PopoverContent:where(.rt-r-size-3) {
  --popover-content-padding: var(--space-5);
  border-radius: var(--radius-5);
}

.rt-PopoverContent:where(.rt-r-size-4) {
  --popover-content-padding: var(--space-6);
  border-radius: var(--radius-5);
}

@media (min-width: 640px) {
  .rt-PopoverContent:where(.xs\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.xs\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.xs\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.xs\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 768px) {
  .rt-PopoverContent:where(.sm\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.sm\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.sm\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.sm\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1024px) {
  .rt-PopoverContent:where(.md\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.md\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.md\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.md\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1280px) {
  .rt-PopoverContent:where(.lg\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.lg\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.lg\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.lg\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

@media (min-width: 1536px) {
  .rt-PopoverContent:where(.xl\:rt-r-size-1) {
    --popover-content-padding: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.xl\:rt-r-size-2) {
    --popover-content-padding: var(--space-4);
    border-radius: var(--radius-4);
  }

  .rt-PopoverContent:where(.xl\:rt-r-size-3) {
    --popover-content-padding: var(--space-5);
    border-radius: var(--radius-5);
  }

  .rt-PopoverContent:where(.xl\:rt-r-size-4) {
    --popover-content-padding: var(--space-6);
    border-radius: var(--radius-5);
  }
}

.rt-ProgressRoot {
  --progress-value: 0;
  --progress-max: 100;
  --progress-duration: 5s;
  pointer-events: none;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  height: var(--progress-height);
  border-radius: max(calc(var(--radius-factor) * var(--progress-height) / 3), calc(var(--radius-factor) * var(--radius-thumb)));
}

.rt-ProgressRoot::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  border-radius: inherit;
}

.rt-ProgressIndicator {
  display: block;
  height: 100%;
  width: 100%;
  transform: scaleX(calc(var(--progress-value) / var(--progress-max)));
  transform-origin: left center;
  transition: transform 120ms;
}

.rt-ProgressIndicator:where([data-state='indeterminate']) {
  animation-name: rt-progress-indicator-indeterminate-grow, var(--progress-indicator-indeterminate-animation-start), var(--progress-indicator-indeterminate-animation-repeat);
  animation-delay: 0s, calc(var(--progress-duration) + 5s), calc(var(--progress-duration) + 7.5s);
  animation-duration: var(--progress-duration), 2.5s, 5s;
  animation-iteration-count: 1, 1, infinite;
  animation-fill-mode: both, none, none;
  animation-direction: normal, normal, alternate;
}

.rt-ProgressIndicator:where([data-state='indeterminate'])::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 400%;
  animation-name: rt-progress-indicator-indeterminate-shine-from-left;
  animation-delay: calc(var(--progress-duration) + 5s);
  animation-duration: 5s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  background-image: linear-gradient(to right, transparent 25%, var(--progress-indicator-after-linear-gradient), transparent 75%);
}

@keyframes rt-progress-indicator-indeterminate-grow {
  0% {
    transform: scaleX(0.01);
  }

  20% {
    transform: scaleX(0.1);
  }

  30% {
    transform: scaleX(0.6);
  }

  40%,
  50% {
    transform: scaleX(0.9);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes rt-progress-indicator-indeterminate-shine-from-left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.rt-ProgressRoot:where(.rt-r-size-1) {
  --progress-height: var(--space-1);
}

.rt-ProgressRoot:where(.rt-r-size-2) {
  --progress-height: calc(var(--space-2) * 0.75);
}

.rt-ProgressRoot:where(.rt-r-size-3) {
  --progress-height: var(--space-2);
}

@media (min-width: 640px) {
  .rt-ProgressRoot:where(.xs\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.xs\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }

  .rt-ProgressRoot:where(.xs\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

@media (min-width: 768px) {
  .rt-ProgressRoot:where(.sm\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.sm\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }

  .rt-ProgressRoot:where(.sm\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

@media (min-width: 1024px) {
  .rt-ProgressRoot:where(.md\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.md\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }

  .rt-ProgressRoot:where(.md\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

@media (min-width: 1280px) {
  .rt-ProgressRoot:where(.lg\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.lg\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }

  .rt-ProgressRoot:where(.lg\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

@media (min-width: 1536px) {
  .rt-ProgressRoot:where(.xl\:rt-r-size-1) {
    --progress-height: var(--space-1);
  }

  .rt-ProgressRoot:where(.xl\:rt-r-size-2) {
    --progress-height: calc(var(--space-2) * 0.75);
  }

  .rt-ProgressRoot:where(.xl\:rt-r-size-3) {
    --progress-height: var(--space-2);
  }
}

.rt-ProgressRoot:where(.rt-variant-surface) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-surface-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-surface-indeterminate-pulse;
  background-color: var(--gray-a3);
}

.rt-ProgressRoot:where(.rt-variant-surface)::after {
  box-shadow: inset 0 0 0 1px var(--gray-a4);
}

.rt-ProgressRoot:where(.rt-variant-surface) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-track);
}

@keyframes rt-progress-indicator-surface-indeterminate-fade {
  100% {
    background-color: var(--accent-7);
  }
}

@keyframes rt-progress-indicator-surface-indeterminate-pulse {
  0% {
    background-color: var(--accent-7);
  }

  100% {
    background-color: var(--accent-track);
  }
}

.rt-ProgressRoot:where(.rt-variant-classic) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-classic-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-classic-indeterminate-pulse;
  background-color: var(--gray-a3);
}

.rt-ProgressRoot:where(.rt-variant-classic)::after {
  box-shadow: var(--shadow-1);
}

.rt-ProgressRoot:where(.rt-variant-classic) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-track);
}

@keyframes rt-progress-indicator-classic-indeterminate-fade {
  100% {
    background-color: var(--accent-7);
  }
}

@keyframes rt-progress-indicator-classic-indeterminate-pulse {
  0% {
    background-color: var(--accent-7);
  }

  100% {
    background-color: var(--accent-track);
  }
}

.rt-ProgressRoot:where(.rt-variant-soft) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-soft-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-soft-indeterminate-pulse;
  background-color: var(--gray-a4);
  background-image: linear-gradient(var(--white-a1), var(--white-a1));
}

.rt-ProgressRoot:where(.rt-variant-soft) :where(.rt-ProgressIndicator) {
  background-image: linear-gradient(var(--accent-a5), var(--accent-a5));
  background-color: var(--accent-8);
}

.rt-ProgressRoot:where(.rt-variant-soft) :where(.rt-ProgressIndicator)::after {
  opacity: 0.75;
}

@keyframes rt-progress-indicator-soft-indeterminate-fade {
  100% {
    background-color: var(--accent-5);
  }
}

@keyframes rt-progress-indicator-soft-indeterminate-pulse {
  0% {
    background-color: var(--accent-5);
  }

  100% {
    background-color: var(--accent-7);
  }
}

.rt-ProgressRoot:where(.rt-high-contrast) {
  --progress-indicator-indeterminate-animation-start: rt-progress-indicator-high-contrast-indeterminate-fade;
  --progress-indicator-indeterminate-animation-repeat: rt-progress-indicator-high-contrast-indeterminate-pulse;
}

.rt-ProgressRoot:where(.rt-high-contrast) :where(.rt-ProgressIndicator) {
  background-color: var(--accent-12);
}

.rt-ProgressRoot:where(.rt-high-contrast) :where(.rt-ProgressIndicator)::after {
  opacity: 0.75;
}

@keyframes rt-progress-indicator-high-contrast-indeterminate-fade {
  100% {
    opacity: 0.8;
  }
}

@keyframes rt-progress-indicator-high-contrast-indeterminate-pulse {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.rt-Quote {
  box-sizing: border-box;
  font-family: var(--quote-font-family);
  font-size: calc(var(--quote-font-size-adjust) * 1em);
  font-style: var(--quote-font-style);
  font-weight: var(--quote-font-weight);
  line-height: 1.25;
  letter-spacing: calc(var(--quote-letter-spacing) + var(--letter-spacing, var(--default-letter-spacing)));
  color: inherit;
}

.rt-Quote :where(.rt-Quote) {
  font-size: inherit;
}

.rt-RadioCardsRoot {
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  cursor: default;
}

.rt-RadioCardsItem {
  --base-card-padding-top: var(--radio-cards-item-padding-y);
  --base-card-padding-right: var(--radio-cards-item-padding-x);
  --base-card-padding-bottom: var(--radio-cards-item-padding-y);
  --base-card-padding-left: var(--radio-cards-item-padding-x);
  --base-card-border-radius: var(--radio-cards-item-border-radius);
  --base-card-border-width: var(--radio-cards-item-border-width);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-2);
}

.rt-RadioCardsItem > * {
  pointer-events: none;
}

.rt-RadioCardsItem > :where(svg) {
  flex-shrink: 0;
}

.rt-RadioCardsItem::after {
  outline-offset: -1px;
}

.rt-RadioCardsRoot:where(.rt-r-size-1) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --radio-cards-item-padding-x: var(--space-3);
  --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
  --radio-cards-item-border-radius: var(--radius-3);
}

.rt-RadioCardsRoot:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  --line-height: var(--line-height-2);
  --letter-spacing: var(--letter-spacing-2);
  --radio-cards-item-padding-x: var(--space-4);
  --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
  --radio-cards-item-border-radius: var(--radius-3);
}

.rt-RadioCardsRoot:where(.rt-r-size-3) {
  font-size: var(--font-size-3);
  --line-height: var(--line-height-3);
  --letter-spacing: var(--letter-spacing-3);
  --radio-cards-item-padding-x: var(--space-5);
  --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
  --radio-cards-item-border-radius: var(--radius-4);
}

@media (min-width: 640px) {
  .rt-RadioCardsRoot:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.xs\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

@media (min-width: 768px) {
  .rt-RadioCardsRoot:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.sm\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

@media (min-width: 1024px) {
  .rt-RadioCardsRoot:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.md\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

@media (min-width: 1280px) {
  .rt-RadioCardsRoot:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.lg\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

@media (min-width: 1536px) {
  .rt-RadioCardsRoot:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-3);
    --radio-cards-item-padding-y: calc(var(--space-3) / 1.2);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    --line-height: var(--line-height-2);
    --letter-spacing: var(--letter-spacing-2);
    --radio-cards-item-padding-x: var(--space-4);
    --radio-cards-item-padding-y: calc(var(--space-4) * 0.875);
    --radio-cards-item-border-radius: var(--radius-3);
  }

  .rt-RadioCardsRoot:where(.xl\:rt-r-size-3) {
    font-size: var(--font-size-3);
    --line-height: var(--line-height-3);
    --letter-spacing: var(--letter-spacing-3);
    --radio-cards-item-padding-x: var(--space-5);
    --radio-cards-item-padding-y: calc(var(--space-5) / 1.2);
    --radio-cards-item-border-radius: var(--radius-4);
  }
}

:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem {
  --radio-cards-item-border-width: 1px;
  --radio-cards-item-background-color: var(--color-surface);
}

:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem::before {
  background-color: var(--radio-cards-item-background-color);
}

:where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem::after {
  box-shadow: var(--base-card-surface-box-shadow);
}

@media (hover: hover) {
  :where(.rt-RadioCardsRoot.rt-variant-surface) .rt-RadioCardsItem:where(:not(:disabled):not([data-state='checked']):not([aria-checked='true']):hover)::after {
    box-shadow: var(--base-card-surface-hover-box-shadow);
  }
}

:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem {
  --radio-cards-item-border-width: 1px;
  --radio-cards-item-background-color: var(--color-surface);
  transition: box-shadow 120ms;
  box-shadow: var(--base-card-classic-box-shadow-outer);
}

:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem::before {
  background-color: var(--radio-cards-item-background-color);
}

:where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem::after {
  box-shadow: var(--base-card-classic-box-shadow-inner);
}

@media (hover: hover) {
  :where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem:where(:not(:disabled):not([data-state='checked']):not([aria-checked='true']):hover) {
    transition-duration: 40ms;
    box-shadow: var(--base-card-classic-hover-box-shadow-outer);
  }

  :where(.rt-RadioCardsRoot.rt-variant-classic) .rt-RadioCardsItem:where(:not(:disabled):not([data-state='checked']):not([aria-checked='true']):hover)::after {
    box-shadow: var(--base-card-classic-hover-box-shadow-inner);
  }
}

.rt-RadioCardsItem:where([data-state='checked'],[aria-checked='true'])::after {
  outline: 2px solid var(--accent-indicator);
}

:where(.rt-RadioCardsRoot.rt-high-contrast) .rt-RadioCardsItem:where([data-state='checked'],[aria-checked='true'])::after {
  outline-color: var(--accent-12);
}

.rt-RadioCardsItem:where(:focus-visible)::after {
  outline: 2px solid var(--focus-8);
}

.rt-RadioCardsItem:where(:focus-visible):where([data-state='checked'],[aria-checked='true'])::before {
  background-image: linear-gradient(var(--focus-a3), var(--focus-a3));
}

.rt-RadioCardsItem:where(:focus-visible):where([data-state='checked'],[aria-checked='true'])::after {
  outline-color: var(--focus-10);
}

.rt-RadioCardsItem:where(:disabled) {
  cursor: var(--cursor-disabled);
  color: var(--gray-a9);
}

.rt-RadioCardsItem:where(:disabled)::-moz-selection {
  background-color: var(--gray-a5);
}

.rt-RadioCardsItem:where(:disabled)::selection {
  background-color: var(--gray-a5);
}

.rt-RadioCardsItem:where(:disabled)::before {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-RadioCardsItem:where(:disabled)::after {
  outline-color: var(--gray-8);
}

.rt-RadioGroupRoot {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.rt-RadioGroupItem {
  display: flex;
  gap: 0.5em;
  width: -moz-fit-content;
  width: fit-content;
}

.rt-RadioGroupItemInner {
  min-width: 0;
}

.rt-BaseRadioRoot {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  flex-shrink: 0;
  cursor: var(--cursor-radio);
  height: var(--skeleton-height, var(--line-height, var(--radio-size)));
  --skeleton-height-override: var(--radio-size);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: 100%;
}

.rt-BaseRadioRoot:where(:disabled, [data-disabled]) {
  cursor: var(--cursor-disabled);
}

.rt-BaseRadioRoot::before {
  content: '';
  display: block;
  height: var(--radio-size);
  width: var(--radio-size);
  border-radius: 100%;
}

.rt-BaseRadioRoot::after {
  pointer-events: none;
  position: absolute;
  height: var(--radio-size);
  width: var(--radio-size);
  border-radius: 100%;
  transform: scale(0.4);
}

.rt-BaseRadioRoot:where(:checked, [data-state='checked'])::after {
  content: '';
}

.rt-BaseRadioRoot:where(:focus-visible)::before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-BaseRadioRoot:where(.rt-r-size-1) {
  --radio-size: calc(var(--space-4) * 0.875);
}

.rt-BaseRadioRoot:where(.rt-r-size-2) {
  --radio-size: var(--space-4);
}

.rt-BaseRadioRoot:where(.rt-r-size-3) {
  --radio-size: calc(var(--space-4) * 1.25);
}

@media (min-width: 640px) {
  .rt-BaseRadioRoot:where(.xs\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }

  .rt-BaseRadioRoot:where(.xs\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.xs\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-BaseRadioRoot:where(.sm\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }

  .rt-BaseRadioRoot:where(.sm\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.sm\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-BaseRadioRoot:where(.md\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }

  .rt-BaseRadioRoot:where(.md\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.md\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-BaseRadioRoot:where(.lg\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }

  .rt-BaseRadioRoot:where(.lg\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.lg\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

@media (min-width: 1536px) {
  .rt-BaseRadioRoot:where(.xl\:rt-r-size-1) {
    --radio-size: calc(var(--space-4) * 0.875);
  }

  .rt-BaseRadioRoot:where(.xl\:rt-r-size-2) {
    --radio-size: var(--space-4);
  }

  .rt-BaseRadioRoot:where(.xl\:rt-r-size-3) {
    --radio-size: calc(var(--space-4) * 1.25);
  }
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(:not(:checked), [data-state='unchecked'])::before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(:checked, [data-state='checked'])::before {
  background-color: var(--accent-indicator);
}

.rt-BaseRadioRoot:where(.rt-variant-surface)::after {
  background-color: var(--accent-contrast);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(.rt-high-contrast):where(:checked, [data-state='checked'])::before {
  background-color: var(--accent-12);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(.rt-high-contrast)::after {
  background-color: var(--accent-1);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(:disabled, [data-disabled])::before {
  box-shadow: inset 0 0 0 1px var(--gray-a6);
  background-color: var(--gray-a3);
}

.rt-BaseRadioRoot:where(.rt-variant-surface):where(:disabled, [data-disabled])::after {
  background-color: var(--gray-a8);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(:not(:checked), [data-state='unchecked'])::before {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-7), var(--shadow-1);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(:checked, [data-state='checked'])::before {
  background-color: var(--accent-indicator);
  background-image: linear-gradient(to bottom, var(--white-a3), transparent, var(--black-a3));
  box-shadow: inset 0 0.5px 0.5px var(--white-a4), inset 0 -0.5px 0.5px var(--black-a4);
}

.rt-BaseRadioRoot:where(.rt-variant-classic)::after {
  background-color: var(--accent-contrast);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(.rt-high-contrast):where(:checked, [data-state='checked'])::before {
  background-color: var(--accent-12);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(.rt-high-contrast)::after {
  background-color: var(--accent-1);
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(:disabled, [data-disabled])::before {
  box-shadow: var(--shadow-1);
  background-color: var(--gray-a3);
  background-image: none;
}

.rt-BaseRadioRoot:where(.rt-variant-classic):where(:disabled, [data-disabled])::after {
  background-color: var(--gray-a8);
}

.rt-BaseRadioRoot:where(.rt-variant-soft)::before {
  background-color: var(--accent-a4);
}

.rt-BaseRadioRoot:where(.rt-variant-soft)::after {
  background-color: var(--accent-a11);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):where(.rt-high-contrast)::after {
  background-color: var(--accent-12);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):where(:focus-visible)::before {
  outline-color: var(--accent-a8);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):where(:disabled, [data-disabled])::before {
  background-color: var(--gray-a3);
}

.rt-BaseRadioRoot:where(.rt-variant-soft):where(:disabled, [data-disabled])::after {
  background-color: var(--gray-a8);
}

.rt-ScrollAreaRoot {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.rt-ScrollAreaViewport {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.rt-ScrollAreaViewport:where(:focus-visible) + :where(.rt-ScrollAreaViewportFocusRing) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  outline: 2px solid var(--focus-8);
  outline-offset: -2px;
}

.rt-ScrollAreaViewport:where(:has(.rt-ScrollAreaScrollbar[data-orientation='horizontal'])) {
  overscroll-behavior-x: contain;
}

.rt-ScrollAreaViewport > * {
  display: block !important;
  width: -moz-fit-content;
  width: fit-content;
  flex-grow: 1;
}

.rt-ScrollAreaScrollbar {
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  touch-action: none;
  background-color: var(--gray-a3);
  border-radius: var(--scrollarea-scrollbar-border-radius);
  animation-duration: 120ms;
  animation-timing-function: ease-out;
}

.rt-ScrollAreaScrollbar:where([data-orientation='vertical']) {
  flex-direction: column;
  width: var(--scrollarea-scrollbar-size);
  margin-top: var(--scrollarea-scrollbar-vertical-margin-top);
  margin-bottom: var(--scrollarea-scrollbar-vertical-margin-bottom);
  margin-left: var(--scrollarea-scrollbar-vertical-margin-left);
  margin-right: var(--scrollarea-scrollbar-vertical-margin-right);
}

.rt-ScrollAreaScrollbar:where([data-orientation='horizontal']) {
  flex-direction: row;
  height: var(--scrollarea-scrollbar-size);
  margin-top: var(--scrollarea-scrollbar-horizontal-margin-top);
  margin-bottom: var(--scrollarea-scrollbar-horizontal-margin-bottom);
  margin-left: var(--scrollarea-scrollbar-horizontal-margin-left);
  margin-right: var(--scrollarea-scrollbar-horizontal-margin-right);
}

.rt-ScrollAreaThumb {
  position: relative;
  background-color: var(--gray-a8);
  border-radius: inherit;
  transition: background-color 100ms;
}

.rt-ScrollAreaThumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: var(--space-4);
  min-height: var(--space-4);
}

.rt-ScrollAreaScrollbar:where(.rt-r-size-1) {
  --scrollarea-scrollbar-size: var(--space-1);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-ScrollAreaScrollbar:where(.rt-r-size-2) {
  --scrollarea-scrollbar-size: var(--space-2);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-ScrollAreaScrollbar:where(.rt-r-size-3) {
  --scrollarea-scrollbar-size: var(--space-3);
  --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
}

@media (min-width: 640px) {
  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.xs\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

@media (min-width: 768px) {
  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.sm\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

@media (min-width: 1024px) {
  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.md\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

@media (min-width: 1280px) {
  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.lg\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

@media (min-width: 1536px) {
  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-1) {
    --scrollarea-scrollbar-size: var(--space-1);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-2) {
    --scrollarea-scrollbar-size: var(--space-2);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-ScrollAreaScrollbar:where(.xl\:rt-r-size-3) {
    --scrollarea-scrollbar-size: var(--space-3);
    --scrollarea-scrollbar-border-radius: max(var(--radius-1), var(--radius-full));
  }
}

.rt-ScrollAreaScrollbar:where([data-state='visible']) {
  animation-name: rt-fade-in;
}

.rt-ScrollAreaScrollbar:where([data-state='hidden']) {
  animation-name: rt-fade-out;
}

@media (hover: hover) {
  .rt-ScrollAreaThumb:where(:hover) {
    background-color: var(--gray-a9);
  }
}

.rt-SegmentedControlRoot {
  display: inline-grid;
  vertical-align: top;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: stretch;
  color: var(--gray-12);
  background-color: var(--color-surface);
  background-image: linear-gradient(var(--gray-a3), var(--gray-a3));
  position: relative;
  min-width: -moz-max-content;
  min-width: max-content;
  font-family: var(--default-font-family);
  font-style: normal;
  text-align: center;
  isolation: isolate;
  border-radius: var(--segmented-control-border-radius);
}

.rt-SegmentedControlItem {
  display: flex;
  align-items: stretch;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}

.rt-SegmentedControlItem:where(:first-child) {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.rt-SegmentedControlItem:where(:nth-last-child(2)) {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.rt-SegmentedControlItem:where(:focus-visible) {
  border-radius: inherit;
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-SegmentedControlItemLabel :where(svg) {
  flex-shrink: 0;
}

@media (hover: hover) {
  :where(.rt-SegmentedControlItem[data-state='off']:hover) .rt-SegmentedControlItemLabel {
    background-color: var(--gray-a2);
  }
}

.rt-SegmentedControlItemLabelInactive {
  position: absolute;
  transition: opacity calc(0.8 * var(--segmented-control-transition-duration));
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--tab-inactive-letter-spacing);
  word-spacing: var(--tab-inactive-word-spacing);
  opacity: 1;
  transition-timing-function: ease-out;
}

:where(.rt-SegmentedControlItem[data-state='on']) .rt-SegmentedControlItemLabelInactive {
  opacity: 0;
  transition-timing-function: ease-in;
}

.rt-SegmentedControlItemLabelActive {
  transition: opacity calc(0.8 * var(--segmented-control-transition-duration));
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
  opacity: 0;
  transition-timing-function: ease-in;
}

:where(.rt-SegmentedControlItem[data-state='on']) .rt-SegmentedControlItemLabelActive {
  opacity: 1;
  transition-timing-function: ease-out;
}

.rt-SegmentedControlItemSeparator {
  z-index: -1;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  width: 1px;
  background-color: var(--gray-a4);
  transition: opacity calc(0.8 * var(--segmented-control-transition-duration));
  transition-timing-function: ease-out;
}

:where(.rt-SegmentedControlItem:first-child) .rt-SegmentedControlItemSeparator,
  :where(.rt-SegmentedControlItem:where([data-state='on'], :focus-visible)) .rt-SegmentedControlItemSeparator,
  :where(.rt-SegmentedControlItem:where([data-state='on'], :focus-visible)) + * .rt-SegmentedControlItemSeparator {
  opacity: 0;
  transition-timing-function: ease-in;
}

:where(.rt-SegmentedControlRoot:has(:focus-visible)) .rt-SegmentedControlItemSeparator {
  transition-duration: 0ms;
}

.rt-SegmentedControlIndicator {
  display: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-duration: var(--segmented-control-transition-duration);
}

.rt-SegmentedControlIndicator::before {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  position: absolute;
  border-radius: max(0.5px, calc(var(--segmented-control-border-radius) - 1px));
  background-color: var(--segmented-control-indicator-background-color);
  content: '';
}

:where(.rt-SegmentedControlItem[data-state='on']) ~ .rt-SegmentedControlIndicator {
  display: block;
}

.rt-SegmentedControlIndicator:where(:nth-child(2)) {
  width: calc(100% / 1);
}

.rt-SegmentedControlIndicator:where(:nth-child(3)) {
  width: calc(100% / 2);
}

.rt-SegmentedControlIndicator:where(:nth-child(4)) {
  width: calc(100% / 3);
}

.rt-SegmentedControlIndicator:where(:nth-child(5)) {
  width: calc(100% / 4);
}

.rt-SegmentedControlIndicator:where(:nth-child(6)) {
  width: calc(100% / 5);
}

.rt-SegmentedControlIndicator:where(:nth-child(7)) {
  width: calc(100% / 6);
}

.rt-SegmentedControlIndicator:where(:nth-child(8)) {
  width: calc(100% / 7);
}

.rt-SegmentedControlIndicator:where(:nth-child(9)) {
  width: calc(100% / 8);
}

.rt-SegmentedControlIndicator:where(:nth-child(10)) {
  width: calc(100% / 9);
}

.rt-SegmentedControlIndicator:where(:nth-child(11)) {
  width: calc(100% / 10);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(1)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(0%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(2)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(100%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(3)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(200%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(4)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(300%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(5)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(400%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(6)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(500%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(7)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(600%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(8)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(700%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(9)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(800%);
}

:where(.rt-SegmentedControlItem[data-state='on']:nth-child(10)) ~ .rt-SegmentedControlIndicator {
  transform: translateX(900%);
}

.rt-SegmentedControlItemLabel {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
}

.rt-SegmentedControlRoot:where(.rt-r-size-1) {
  --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
  height: var(--space-5);
}

.rt-SegmentedControlRoot:where(.rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  padding-left: var(--space-3);
  padding-right: var(--space-3);
  gap: var(--space-1);
}

.rt-SegmentedControlRoot:where(.rt-r-size-2) {
  --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
  height: var(--space-6);
}

.rt-SegmentedControlRoot:where(.rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
  gap: var(--space-2);
}

.rt-SegmentedControlRoot:where(.rt-r-size-3) {
  --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
  height: var(--space-7);
}

.rt-SegmentedControlRoot:where(.rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
  padding-left: var(--space-4);
  padding-right: var(--space-4);
  gap: var(--space-3);
}

@media (min-width: 640px) {
  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.xs\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

@media (min-width: 768px) {
  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.sm\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

@media (min-width: 1024px) {
  .rt-SegmentedControlRoot:where(.md\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.md\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

@media (min-width: 1280px) {
  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.lg\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

@media (min-width: 1536px) {
  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-1) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-5);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    gap: var(--space-1);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-2) {
    --segmented-control-border-radius: max(var(--radius-2), var(--radius-full));
    height: var(--space-6);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-2);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-3) {
    --segmented-control-border-radius: max(var(--radius-3), var(--radius-full));
    height: var(--space-7);
  }

  .rt-SegmentedControlRoot:where(.xl\:rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
    padding-left: var(--space-4);
    padding-right: var(--space-4);
    gap: var(--space-3);
  }
}

.rt-SegmentedControlRoot:where(.rt-variant-surface) :where(.rt-SegmentedControlIndicator)::before {
  box-shadow: 0 0 0 1px var(--gray-a4);
}

.rt-SegmentedControlRoot:where(.rt-variant-classic) :where(.rt-SegmentedControlIndicator)::before {
  box-shadow: var(--shadow-2);
}

.rt-SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  vertical-align: top;
  line-height: var(--height);
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: left;
  color: var(--gray-12);
}

.rt-SelectTrigger:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-SelectTriggerInner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rt-SelectIcon {
  flex-shrink: 0;
}

:where(.rt-SelectTrigger:not(.rt-variant-ghost)) .rt-SelectIcon {
  opacity: 0.9;
}

.rt-SelectContent:where([data-side]) {
  min-width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
  transform-origin: var(--radix-select-content-transform-origin);
}

.rt-SelectViewport {
  box-sizing: border-box;
  padding: var(--select-content-padding);
}

:where(.rt-SelectContent:has(.rt-ScrollAreaScrollbar[data-orientation='vertical'])) .rt-SelectViewport {
  padding-right: var(--space-3);
}

.rt-SelectItem {
  display: flex;
  align-items: center;
  height: var(--select-item-height);
  padding-left: var(--select-item-indicator-width);
  padding-right: var(--select-item-indicator-width);
  position: relative;
  box-sizing: border-box;
  outline: none;
  scroll-margin: var(--select-content-padding) 0;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  cursor: var(--cursor-menu-item);
}

.rt-SelectItemIndicator {
  position: absolute;
  left: 0;
  width: var(--select-item-indicator-width);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.rt-SelectSeparator {
  height: 1px;
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
  margin-left: var(--select-item-indicator-width);
  margin-right: var(--select-separator-margin-right);
  background-color: var(--gray-a6);
}

.rt-SelectLabel {
  display: flex;
  align-items: center;
  height: var(--select-item-height);
  padding-left: var(--select-item-indicator-width);
  padding-right: var(--select-item-indicator-width);
  color: var(--gray-a10);
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  cursor: default;
}

:where(.rt-SelectItem) + .rt-SelectLabel {
  margin-top: var(--space-2);
}

.rt-SelectTrigger:where(:not(.rt-variant-ghost)) {
  box-sizing: border-box;
  height: var(--select-trigger-height);
}

.rt-SelectTrigger:where(.rt-variant-ghost) {
  box-sizing: content-box;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--select-trigger-ghost-padding-y) var(--select-trigger-ghost-padding-x);
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
  --margin-top-override: calc(var(--margin-top) - var(--select-trigger-ghost-padding-y));
  --margin-right-override: calc(var(--margin-right) - var(--select-trigger-ghost-padding-x));
  --margin-bottom-override: calc(var(--margin-bottom) - var(--select-trigger-ghost-padding-y));
  --margin-left-override: calc(var(--margin-left) - var(--select-trigger-ghost-padding-x));
  margin: var(--margin-top-override) var(--margin-right-override) var(--margin-bottom-override) var(--margin-left-override);
}

:where(.rt-SelectTrigger:where(.rt-variant-ghost)) > * {
  --margin-top-override: initial;
  --margin-right-override: initial;
  --margin-bottom-override: initial;
  --margin-left-override: initial;
}

.rt-SelectTrigger:where(.rt-r-size-1) {
  --select-trigger-height: var(--space-5);
  gap: var(--space-1);
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: max(var(--radius-1), var(--radius-full));
}

.rt-SelectTrigger:where(.rt-r-size-1):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-SelectTrigger:where(.rt-r-size-1):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-2);
  --select-trigger-ghost-padding-y: var(--space-1);
}

.rt-SelectTrigger:where(.rt-r-size-2) {
  --select-trigger-height: var(--space-6);
  gap: calc(var(--space-1) * 1.5);
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  border-radius: max(var(--radius-2), var(--radius-full));
}

.rt-SelectTrigger:where(.rt-r-size-2):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.rt-SelectTrigger:where(.rt-r-size-2):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-2);
  --select-trigger-ghost-padding-y: var(--space-1);
}

.rt-SelectTrigger:where(.rt-r-size-3) {
  --select-trigger-height: var(--space-7);
  gap: var(--space-2);
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
  border-radius: max(var(--radius-3), var(--radius-full));
}

.rt-SelectTrigger:where(.rt-r-size-3):where(:not(.rt-variant-ghost)) {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.rt-SelectTrigger:where(.rt-r-size-3):where(.rt-variant-ghost) {
  --select-trigger-ghost-padding-x: var(--space-3);
  --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
}

.rt-SelectTrigger:where(.rt-r-size-3) :where(.rt-SelectIcon) {
  width: 11px;
  height: 11px;
}

@media (min-width: 640px) {
  .rt-SelectTrigger:where(.xs\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.xs\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

@media (min-width: 768px) {
  .rt-SelectTrigger:where(.sm\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.sm\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

@media (min-width: 1024px) {
  .rt-SelectTrigger:where(.md\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.md\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

@media (min-width: 1280px) {
  .rt-SelectTrigger:where(.lg\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.lg\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

@media (min-width: 1536px) {
  .rt-SelectTrigger:where(.xl\:rt-r-size-1) {
    --select-trigger-height: var(--space-5);
    gap: var(--space-1);
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: max(var(--radius-1), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-1):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-1):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-2) {
    --select-trigger-height: var(--space-6);
    gap: calc(var(--space-1) * 1.5);
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    border-radius: max(var(--radius-2), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-2):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-2):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-2);
    --select-trigger-ghost-padding-y: var(--space-1);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-3) {
    --select-trigger-height: var(--space-7);
    gap: var(--space-2);
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-3):where(:not(.rt-variant-ghost)) {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-3):where(.rt-variant-ghost) {
    --select-trigger-ghost-padding-x: var(--space-3);
    --select-trigger-ghost-padding-y: calc(var(--space-1) * 1.5);
  }

  .rt-SelectTrigger:where(.xl\:rt-r-size-3) :where(.rt-SelectIcon) {
    width: 11px;
    height: 11px;
  }
}

.rt-SelectContent:where(.rt-r-size-1) {
  --select-content-padding: var(--space-1);
  --select-item-height: var(--space-5);
  --select-item-indicator-width: calc(var(--space-5) / 1.2);
  --select-separator-margin-right: var(--space-2);
  border-radius: var(--radius-3);
}

.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectLabel) {
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-1);
}

.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectItem) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  border-radius: var(--radius-1);
}

.rt-SelectContent:where(.rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(8px * var(--scaling));
  height: calc(8px * var(--scaling));
}

.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) {
  --select-content-padding: var(--space-2);
  --select-item-height: var(--space-6);
  --select-item-indicator-width: var(--space-5);
  --select-separator-margin-right: var(--space-3);
  border-radius: var(--radius-4);
}

.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) :where(.rt-SelectLabel) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-2);
}

.rt-SelectContent:where(.rt-r-size-2, .rt-r-size-3) :where(.rt-SelectItem) {
  line-height: var(--line-height-2);
  border-radius: var(--radius-2);
}

.rt-SelectContent:where(.rt-r-size-2) :where(.rt-SelectItem) {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-SelectContent:where(.rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}

.rt-SelectContent:where(.rt-r-size-3) :where(.rt-SelectItem) {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
}

.rt-SelectContent:where(.rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
  width: calc(10px * var(--scaling));
  height: calc(10px * var(--scaling));
}

@media (min-width: 640px) {
  .rt-SelectContent:where(.xs\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2, .xs\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.xs\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.xs\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

@media (min-width: 768px) {
  .rt-SelectContent:where(.sm\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2, .sm\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.sm\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.sm\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

@media (min-width: 1024px) {
  .rt-SelectContent:where(.md\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.md\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.md\:rt-r-size-2, .md\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.md\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.md\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.md\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.md\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

@media (min-width: 1280px) {
  .rt-SelectContent:where(.lg\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2, .lg\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.lg\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.lg\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

@media (min-width: 1536px) {
  .rt-SelectContent:where(.xl\:rt-r-size-1) {
    --select-content-padding: var(--space-1);
    --select-item-height: var(--space-5);
    --select-item-indicator-width: calc(var(--space-5) / 1.2);
    --select-separator-margin-right: var(--space-2);
    border-radius: var(--radius-3);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectLabel) {
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
    line-height: var(--line-height-1);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectItem) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    border-radius: var(--radius-1);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-1) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(8px * var(--scaling));
    height: calc(8px * var(--scaling));
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) {
    --select-content-padding: var(--space-2);
    --select-item-height: var(--space-6);
    --select-item-indicator-width: var(--space-5);
    --select-separator-margin-right: var(--space-3);
    border-radius: var(--radius-4);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) :where(.rt-SelectLabel) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
    line-height: var(--line-height-2);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2, .xl\:rt-r-size-3) :where(.rt-SelectItem) {
    line-height: var(--line-height-2);
    border-radius: var(--radius-2);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2) :where(.rt-SelectItem) {
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-2) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }

  .rt-SelectContent:where(.xl\:rt-r-size-3) :where(.rt-SelectItem) {
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-SelectContent:where(.xl\:rt-r-size-3) :where(.rt-SelectItemIndicatorIcon) {
    width: calc(10px * var(--scaling));
    height: calc(10px * var(--scaling));
  }
}

.rt-SelectTrigger:where(.rt-variant-surface) {
  color: var(--gray-12);
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);
}

@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-surface):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--gray-a8);
  }
}

.rt-SelectTrigger:where(.rt-variant-surface):where([data-state='open']) {
  box-shadow: inset 0 0 0 1px var(--gray-a8);
}

.rt-SelectTrigger:where(.rt-variant-surface):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-a2);
  box-shadow: inset 0 0 0 1px var(--gray-a6);
}

.rt-SelectTrigger:where(.rt-variant-surface):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--gray-a10);
}

.rt-SelectTrigger:where(.rt-variant-classic) {
  color: var(--gray-12);
  background-image: linear-gradient(var(--gray-2), var(--gray-1));
  box-shadow: var(--select-trigger-classic-box-shadow);
  position: relative;
  z-index: 0;
}

.rt-SelectTrigger:where(.rt-variant-classic)::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid transparent;
  background-clip: content-box;
  border-radius: inherit;
  pointer-events: none;
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1) 130%), linear-gradient(var(--color-surface), transparent);
}

@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-classic):where(:hover) {
    box-shadow: inset 0 0 0 1px var(--gray-a3), var(--select-trigger-classic-box-shadow);
  }

  .rt-SelectTrigger:where(.rt-variant-classic):where(:hover)::before {
    background-image: linear-gradient(var(--black-a1) -15%, transparent, var(--white-a1) 120%), linear-gradient(var(--gray-2), var(--gray-1));
  }
}

.rt-SelectTrigger:where(.rt-variant-classic):where([data-state='open']) {
  box-shadow: inset 0 0 0 1px var(--gray-a3), var(--select-trigger-classic-box-shadow);
}

.rt-SelectTrigger:where(.rt-variant-classic):where([data-state='open'])::before {
  background-image: linear-gradient(var(--black-a1) -15%, transparent, var(--white-a1) 120%), linear-gradient(var(--gray-2), var(--gray-1));
}

.rt-SelectTrigger:where(.rt-variant-classic):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-2);
  background-image: none;
  box-shadow: var(--base-button-classic-disabled-box-shadow);
}

.rt-SelectTrigger:where(.rt-variant-classic):where(:disabled)::before {
  background-color: var(--gray-a2);
  background-image: linear-gradient(var(--black-a1) -20%, transparent, var(--white-a1));
}

.rt-SelectTrigger:where(.rt-variant-classic):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--gray-a10);
}

.rt-SelectTrigger:where(.rt-variant-soft),
.rt-SelectTrigger:where(.rt-variant-ghost) {
  color: var(--accent-12);
}

.rt-SelectTrigger:where(.rt-variant-soft):where([data-placeholder]) :where(.rt-SelectTriggerInner), .rt-SelectTrigger:where(.rt-variant-ghost):where([data-placeholder]) :where(.rt-SelectTriggerInner) {
  color: var(--accent-12);
  opacity: 0.6;
}

.rt-SelectTrigger:where(.rt-variant-soft) {
  background-color: var(--accent-a3);
}

@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-soft):where(:hover) {
    background-color: var(--accent-a4);
  }
}

.rt-SelectTrigger:where(.rt-variant-soft):where([data-state='open']) {
  background-color: var(--accent-a4);
}

.rt-SelectTrigger:where(.rt-variant-soft):where(:focus-visible) {
  outline-color: var(--accent-8);
}

.rt-SelectTrigger:where(.rt-variant-soft):where(:disabled) {
  color: var(--gray-a11);
  background-color: var(--gray-a3);
}

@media (hover: hover) {
  .rt-SelectTrigger:where(.rt-variant-ghost):where(:hover) {
    background-color: var(--accent-a3);
  }
}

.rt-SelectTrigger:where(.rt-variant-ghost):where([data-state='open']) {
  background-color: var(--accent-a3);
}

.rt-SelectTrigger:where(.rt-variant-ghost):where(:disabled) {
  color: var(--gray-a11);
  background-color: transparent;
}

.rt-SelectTrigger:where(:disabled) :where(.rt-SelectIcon) {
  color: var(--gray-a9);
}

.rt-SelectContent {
  box-shadow: var(--shadow-5);
  --scrollarea-scrollbar-vertical-margin-top: var(--select-content-padding);
  --scrollarea-scrollbar-vertical-margin-bottom: var(--select-content-padding);
  --scrollarea-scrollbar-horizontal-margin-left: var(--select-content-padding);
  --scrollarea-scrollbar-horizontal-margin-right: var(--select-content-padding);
  overflow: hidden;
  background-color: var(--color-panel-solid);
}

.rt-SelectItem:where([data-disabled]) {
  color: var(--gray-a8);
  cursor: default;
}

.rt-SelectContent:where(.rt-variant-solid) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-9);
  color: var(--accent-contrast);
}

.rt-SelectContent:where(.rt-variant-solid):where(.rt-high-contrast) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-12);
  color: var(--accent-1);
}

.rt-SelectContent:where(.rt-variant-soft) :where(.rt-SelectItem[data-highlighted]) {
  background-color: var(--accent-a4);
}

.rt-Separator {
  display: block;
  background-color: var(--accent-a6);
}

.rt-Separator:where(.rt-r-orientation-horizontal) {
  width: var(--separator-size);
  height: 1px;
}

.rt-Separator:where(.rt-r-orientation-vertical) {
  width: 1px;
  height: var(--separator-size);
}

@media (min-width: 640px) {
  .rt-Separator:where(.xs\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.xs\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

@media (min-width: 768px) {
  .rt-Separator:where(.sm\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.sm\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

@media (min-width: 1024px) {
  .rt-Separator:where(.md\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.md\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

@media (min-width: 1280px) {
  .rt-Separator:where(.lg\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.lg\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

@media (min-width: 1536px) {
  .rt-Separator:where(.xl\:rt-r-orientation-horizontal) {
    width: var(--separator-size);
    height: 1px;
  }

  .rt-Separator:where(.xl\:rt-r-orientation-vertical) {
    width: 1px;
    height: var(--separator-size);
  }
}

.rt-Separator:where(.rt-r-size-1) {
  --separator-size: var(--space-4);
}

.rt-Separator:where(.rt-r-size-2) {
  --separator-size: var(--space-6);
}

.rt-Separator:where(.rt-r-size-3) {
  --separator-size: var(--space-9);
}

.rt-Separator:where(.rt-r-size-4) {
  --separator-size: 100%;
}

@media (min-width: 640px) {
  .rt-Separator:where(.xs\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.xs\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.xs\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.xs\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

@media (min-width: 768px) {
  .rt-Separator:where(.sm\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.sm\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.sm\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.sm\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

@media (min-width: 1024px) {
  .rt-Separator:where(.md\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.md\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.md\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.md\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

@media (min-width: 1280px) {
  .rt-Separator:where(.lg\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.lg\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.lg\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.lg\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

@media (min-width: 1536px) {
  .rt-Separator:where(.xl\:rt-r-size-1) {
    --separator-size: var(--space-4);
  }

  .rt-Separator:where(.xl\:rt-r-size-2) {
    --separator-size: var(--space-6);
  }

  .rt-Separator:where(.xl\:rt-r-size-3) {
    --separator-size: var(--space-9);
  }

  .rt-Separator:where(.xl\:rt-r-size-4) {
    --separator-size: 100%;
  }
}

.rt-SliderRoot {
  --slider-thumb-size: calc(var(--slider-track-size) + var(--space-1));
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-radius: max(calc(var(--radius-factor) * var(--slider-track-size) / 3), calc(var(--radius-factor) * var(--radius-thumb)));
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  touch-action: none;
}

.rt-SliderRoot:where([data-orientation='horizontal']) {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  height: var(--slider-track-size);
}

.rt-SliderRoot:where([data-orientation='vertical']) {
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  flex-direction: column;
  width: var(--slider-track-size);
}

.rt-SliderTrack {
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  border-radius: inherit;
}

.rt-SliderTrack:where([data-orientation='horizontal']) {
  height: var(--slider-track-size);
}

.rt-SliderTrack:where([data-orientation='vertical']) {
  width: var(--slider-track-size);
}

.rt-SliderRange {
  position: absolute;
  border-radius: inherit;
}

.rt-SliderRange:where([data-orientation='horizontal']) {
  height: 100%;
}

.rt-SliderRange:where([data-orientation='vertical']) {
  width: 100%;
}

.rt-SliderThumb {
  display: block;
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  outline: 0;
}

.rt-SliderThumb::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: calc(var(--slider-thumb-size) * 3);
  height: calc(var(--slider-thumb-size) * 3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rt-SliderThumb::after {
  content: '';
  position: absolute;
  top: calc(-0.25 * var(--slider-track-size));
  right: calc(-0.25 * var(--slider-track-size));
  bottom: calc(-0.25 * var(--slider-track-size));
  left: calc(-0.25 * var(--slider-track-size));
  background-color: white;
  border-radius: max(var(--radius-1), var(--radius-thumb));
  box-shadow: var(--slider-thumb-box-shadow);
  cursor: var(--cursor-slider-thumb);
}

.rt-SliderThumb:where(:focus-visible)::after {
  box-shadow: var(--slider-thumb-box-shadow), 0 0 0 3px var(--accent-3), 0 0 0 5px var(--focus-8);
}

.rt-SliderThumb:where(:active) {
  cursor: var(--cursor-slider-thumb-active);
}

.rt-SliderRoot:where(.rt-r-size-1) {
  --slider-track-size: calc(var(--space-2) * 0.75);
}

.rt-SliderRoot:where(.rt-r-size-2) {
  --slider-track-size: var(--space-2);
}

.rt-SliderRoot:where(.rt-r-size-3) {
  --slider-track-size: calc(var(--space-2) * 1.25);
}

@media (min-width: 640px) {
  .rt-SliderRoot:where(.xs\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }

  .rt-SliderRoot:where(.xs\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.xs\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

@media (min-width: 768px) {
  .rt-SliderRoot:where(.sm\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }

  .rt-SliderRoot:where(.sm\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.sm\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

@media (min-width: 1024px) {
  .rt-SliderRoot:where(.md\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }

  .rt-SliderRoot:where(.md\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.md\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

@media (min-width: 1280px) {
  .rt-SliderRoot:where(.lg\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }

  .rt-SliderRoot:where(.lg\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.lg\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

@media (min-width: 1536px) {
  .rt-SliderRoot:where(.xl\:rt-r-size-1) {
    --slider-track-size: calc(var(--space-2) * 0.75);
  }

  .rt-SliderRoot:where(.xl\:rt-r-size-2) {
    --slider-track-size: var(--space-2);
  }

  .rt-SliderRoot:where(.xl\:rt-r-size-3) {
    --slider-track-size: calc(var(--space-2) * 1.25);
  }
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderTrack) {
  background-color: var(--gray-a3);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderTrack):where([data-disabled]) {
  box-shadow: inset 0 0 0 1px var(--gray-a4);
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderRange) {
  background-color: var(--accent-track);
  background-image: var(--slider-range-high-contrast-background-image);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a4);
}

.rt-SliderRoot:where(.rt-variant-surface) :where(.rt-SliderThumb):where([data-disabled])::after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-6);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack) {
  background-color: var(--gray-a3);
  position: relative;
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack)::before {
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  border-radius: inherit;
  box-shadow: var(--shadow-1);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderTrack):where([data-disabled])::before {
  opacity: 0.5;
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderRange) {
  background-color: var(--accent-track);
  background-image: var(--slider-range-high-contrast-background-image);
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--accent-a4), inset 0 0 0 1px var(--black-a1), inset 0 1.5px 2px 0 var(--black-a2);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderRange):where(.rt-high-contrast) {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--black-a2), inset 0 1.5px 2px 0 var(--black-a2);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a3), 0 1px 3px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}

.rt-SliderRoot:where(.rt-variant-classic) :where(.rt-SliderThumb):where([data-disabled])::after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-6);
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderTrack) {
  background-color: var(--gray-a4);
  background-image: linear-gradient(var(--white-a1), var(--white-a1));
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderTrack):where([data-disabled]) {
  background-color: var(--gray-a4);
  background-image: none;
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderRange) {
  background-image: linear-gradient(var(--accent-a5), var(--accent-a5)), var(--slider-range-high-contrast-background-image);
  background-color: var(--accent-6);
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderThumb) {
  --slider-thumb-box-shadow: 0 0 0 1px var(--black-a3), 0 0 0 1px var(--gray-a2), 0 0 0 1px var(--accent-a2), 0 1px 2px var(--gray-a4), 0 1px 3px -0.5px var(--gray-a3);
}

.rt-SliderRoot:where(.rt-variant-soft) :where(.rt-SliderThumb):where([data-disabled])::after {
  background-color: var(--gray-1);
  box-shadow: 0 0 0 1px var(--gray-5);
}

.rt-SliderRoot:where(:not(.rt-high-contrast)) {
  --slider-range-high-contrast-background-image: none;
}

.rt-SliderRoot:where([data-disabled]) {
  cursor: var(--cursor-disabled);
  mix-blend-mode: var(--slider-disabled-blend-mode);
}

.rt-SliderRange:where([data-disabled]) {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}

.rt-SliderThumb:where([data-disabled]),
  .rt-SliderThumb:where([data-disabled])::after {
  cursor: var(--cursor-disabled);
}

.rt-Spinner {
  display: block;
  position: relative;
  opacity: var(--spinner-opacity);
}

.rt-SpinnerLeaf {
  position: absolute;
  top: 0;
  left: calc(50% - 12.5% / 2);
  width: 12.5%;
  height: 100%;
  animation: rt-spinner-leaf-fade var(--spinner-animation-duration) linear infinite;
}

.rt-SpinnerLeaf::before {
  content: '';
  display: block;
  width: 100%;
  height: 30%;
  border-radius: var(--radius-1);
  background-color: currentColor;
}

.rt-SpinnerLeaf:where(:nth-child(1)) {
  transform: rotate(0deg);
  animation-delay: calc(-8 / 8 * var(--spinner-animation-duration));
}

.rt-SpinnerLeaf:where(:nth-child(2)) {
  transform: rotate(45deg);
  animation-delay: calc(-7 / 8 * var(--spinner-animation-duration));
}

.rt-SpinnerLeaf:where(:nth-child(3)) {
  transform: rotate(90deg);
  animation-delay: calc(-6 / 8 * var(--spinner-animation-duration));
}

.rt-SpinnerLeaf:where(:nth-child(4)) {
  transform: rotate(135deg);
  animation-delay: calc(-5 / 8 * var(--spinner-animation-duration));
}

.rt-SpinnerLeaf:where(:nth-child(5)) {
  transform: rotate(180deg);
  animation-delay: calc(-4 / 8 * var(--spinner-animation-duration));
}

.rt-SpinnerLeaf:where(:nth-child(6)) {
  transform: rotate(225deg);
  animation-delay: calc(-3 / 8 * var(--spinner-animation-duration));
}

.rt-SpinnerLeaf:where(:nth-child(7)) {
  transform: rotate(270deg);
  animation-delay: calc(-2 / 8 * var(--spinner-animation-duration));
}

.rt-SpinnerLeaf:where(:nth-child(8)) {
  transform: rotate(315deg);
  animation-delay: calc(-1 / 8 * var(--spinner-animation-duration));
}

@keyframes rt-spinner-leaf-fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.rt-Spinner:where(.rt-r-size-1) {
  width: var(--space-3);
  height: var(--space-3);
}

.rt-Spinner:where(.rt-r-size-2) {
  width: var(--space-4);
  height: var(--space-4);
}

.rt-Spinner:where(.rt-r-size-3) {
  width: calc(1.25 * var(--space-4));
  height: calc(1.25 * var(--space-4));
}

@media (min-width: 640px) {
  .rt-Spinner:where(.xs\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.xs\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.xs\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

@media (min-width: 768px) {
  .rt-Spinner:where(.sm\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.sm\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.sm\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

@media (min-width: 1024px) {
  .rt-Spinner:where(.md\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.md\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.md\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

@media (min-width: 1280px) {
  .rt-Spinner:where(.lg\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.lg\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.lg\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

@media (min-width: 1536px) {
  .rt-Spinner:where(.xl\:rt-r-size-1) {
    width: var(--space-3);
    height: var(--space-3);
  }

  .rt-Spinner:where(.xl\:rt-r-size-2) {
    width: var(--space-4);
    height: var(--space-4);
  }

  .rt-Spinner:where(.xl\:rt-r-size-3) {
    width: calc(1.25 * var(--space-4));
    height: calc(1.25 * var(--space-4));
  }
}

.rt-Strong {
  font-family: var(--strong-font-family);
  font-size: calc(var(--strong-font-size-adjust) * 1em);
  font-style: var(--strong-font-style);
  font-weight: var(--strong-font-weight);
  letter-spacing: calc(var(--strong-letter-spacing) + var(--letter-spacing, var(--default-letter-spacing)));
}

.rt-Strong :where(.rt-Strong) {
  font-size: inherit;
}

.rt-SwitchRoot {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  flex-shrink: 0;
  height: var(--skeleton-height, var(--line-height, var(--switch-height)));
  --skeleton-height-override: var(--switch-height);
  border-radius: var(--skeleton-radius);
  --skeleton-radius-override: var(--switch-border-radius);
  --switch-width: calc(var(--switch-height) * 1.75);
  --switch-thumb-inset: 1px;
  --switch-thumb-size: calc(var(--switch-height) - var(--switch-thumb-inset) * 2);
  --switch-thumb-translate-x: calc(var(--switch-width) - var(--switch-height));
}

.rt-SwitchRoot::before {
  content: '';
  display: block;
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-border-radius);
  transition: background-position, background-color, box-shadow, filter;
  transition-timing-function: linear, ease-in-out, ease-in-out, ease-in-out;
  background-repeat: no-repeat;
  background-size: calc(var(--switch-width) * 2 + var(--switch-height)) 100%;
  cursor: var(--cursor-switch);
}

.rt-SwitchRoot:where([data-state='unchecked'])::before {
  transition-duration: 120ms, 140ms, 140ms, 140ms;
  background-position-x: 100%;
}

.rt-SwitchRoot:where([data-state='checked'])::before {
  transition-duration: 160ms, 140ms, 140ms, 140ms;
  background-position: 0%;
}

.rt-SwitchRoot:where(:active)::before {
  transition-duration: 30ms;
}

.rt-SwitchRoot:where(:focus-visible)::before {
  outline: 2px solid var(--focus-8);
  outline-offset: 2px;
}

.rt-SwitchRoot:where([data-disabled])::before {
  cursor: var(--cursor-disabled);
}

.rt-SwitchThumb {
  background-color: white;
  position: absolute;
  left: var(--switch-thumb-inset);
  width: var(--switch-thumb-size);
  height: var(--switch-thumb-size);
  border-radius: calc(var(--switch-border-radius) - var(--switch-thumb-inset));
  transition: transform 140ms cubic-bezier(0.45, 0.05, 0.55, 0.95), box-shadow 140ms ease-in-out;
}

.rt-SwitchThumb:where([data-state='checked']) {
  transform: translateX(var(--switch-thumb-translate-x));
}

.rt-SwitchRoot:where(.rt-r-size-1) {
  --switch-height: var(--space-4);
  --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
}

.rt-SwitchRoot:where(.rt-r-size-2) {
  --switch-height: calc(var(--space-5) * 5 / 6);
  --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
}

.rt-SwitchRoot:where(.rt-r-size-3) {
  --switch-height: var(--space-5);
  --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
}

@media (min-width: 640px) {
  .rt-SwitchRoot:where(.xs\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.xs\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.xs\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

@media (min-width: 768px) {
  .rt-SwitchRoot:where(.sm\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.sm\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.sm\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

@media (min-width: 1024px) {
  .rt-SwitchRoot:where(.md\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.md\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.md\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

@media (min-width: 1280px) {
  .rt-SwitchRoot:where(.lg\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.lg\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.lg\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

@media (min-width: 1536px) {
  .rt-SwitchRoot:where(.xl\:rt-r-size-1) {
    --switch-height: var(--space-4);
    --switch-border-radius: max(var(--radius-1), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.xl\:rt-r-size-2) {
    --switch-height: calc(var(--space-5) * 5 / 6);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }

  .rt-SwitchRoot:where(.xl\:rt-r-size-3) {
    --switch-height: var(--space-5);
    --switch-border-radius: max(var(--radius-2), var(--radius-thumb));
  }
}

.rt-SwitchRoot:where(.rt-variant-surface)::before {
  background-color: var(--gray-a3);
  background-image: linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
  box-shadow: inset 0 0 0 1px var(--gray-a5);
}

.rt-SwitchRoot:where(.rt-variant-surface):where(:active)::before {
  background-color: var(--gray-a4);
}

.rt-SwitchRoot:where(.rt-variant-surface):where([data-state='checked']:active)::before {
  filter: var(--switch-surface-checked-active-filter);
}

.rt-SwitchRoot:where(.rt-variant-surface):where(.rt-high-contrast)::before {
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
}

.rt-SwitchRoot:where(.rt-variant-surface):where(.rt-high-contrast):where([data-state='checked']:active)::before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}

.rt-SwitchRoot:where(.rt-variant-surface):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}

.rt-SwitchRoot:where(.rt-variant-surface):where([data-disabled])::before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a3);
  box-shadow: inset 0 0 0 1px var(--gray-a3);
}

.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state='unchecked']) {
  box-shadow: 0 0 1px 1px var(--black-a2), 0 1px 1px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}

.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state='checked']) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a1), 0 0 0 1px var(--accent-a4), -1px 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-state='checked']):where(.rt-high-contrast) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2), -1px 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-surface) :where(.rt-SwitchThumb):where([data-disabled]) {
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}

.rt-SwitchRoot:where(.rt-variant-classic)::before {
  background-image: linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
  background-color: var(--gray-a4);
  box-shadow: var(--shadow-1);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-state='unchecked']:active)::before {
  background-color: var(--gray-a5);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-state='checked'])::before {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--accent-a4), inset 0 0 0 1px var(--black-a1), inset 0 1.5px 2px 0 var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-state='checked']:active)::before {
  filter: var(--switch-surface-checked-active-filter);
}

.rt-SwitchRoot:where(.rt-variant-classic):where(.rt-high-contrast)::before {
  box-shadow: inset 0 0 0 1px var(--gray-a3), inset 0 0 0 1px var(--black-a2), inset 0 1.5px 2px 0 var(--black-a2);
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-track) 40%, transparent 60%);
}

.rt-SwitchRoot:where(.rt-variant-classic):where(.rt-high-contrast):where([data-state='checked']:active)::before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}

.rt-SwitchRoot:where(.rt-variant-classic):where([data-disabled])::before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a5);
  box-shadow: var(--shadow-1);
  opacity: 0.5;
}

.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state='unchecked']) {
  box-shadow: 0 1px 3px var(--black-a3), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state='checked']) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a1), 0 0 0 1px var(--accent-a4), -1px 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-state='checked']):where(.rt-high-contrast) {
  box-shadow: 0 1px 3px var(--black-a2), 0 2px 4px -1px var(--black-a1), 0 0 0 1px var(--black-a2), -1px 0 1px var(--black-a2);
}

.rt-SwitchRoot:where(.rt-variant-classic) :where(.rt-SwitchThumb):where([data-disabled]) {
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}

.rt-SwitchRoot:where(.rt-variant-soft)::before {
  background-image: linear-gradient(to right, var(--accent-a4) 40%, transparent 60%), linear-gradient(to right, var(--accent-a4) 40%, transparent 60%), linear-gradient(to right, var(--accent-a4) 40%, var(--white-a1) 60%), linear-gradient(to right, var(--gray-a2) 40%, var(--gray-a3) 60%);
}

.rt-SwitchRoot:where(.rt-variant-soft):where([data-state='unchecked'])::before {
  background-color: var(--gray-a3);
}

.rt-SwitchRoot:where(.rt-variant-soft):where(:active)::before {
  background-color: var(--gray-a4);
}

.rt-SwitchRoot:where(.rt-variant-soft):where(.rt-high-contrast)::before {
  background-image: linear-gradient(to right, var(--switch-high-contrast-checked-color-overlay) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, transparent 60%), linear-gradient(to right, var(--accent-a6) 40%, var(--white-a1) 60%), linear-gradient(to right, var(--accent-a3) 40%, var(--gray-a3) 60%);
}

.rt-SwitchRoot:where(.rt-variant-soft):where(.rt-high-contrast):where([data-state='checked']:active)::before {
  filter: var(--switch-high-contrast-checked-active-before-filter);
}

.rt-SwitchRoot:where(.rt-variant-soft):where([data-disabled]) {
  mix-blend-mode: var(--switch-disabled-blend-mode);
}

.rt-SwitchRoot:where(.rt-variant-soft):where([data-disabled])::before {
  filter: none;
  background-image: none;
  background-color: var(--gray-a4);
}

.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb) {
  filter: saturate(0.45);
}

.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-state='unchecked']) {
  box-shadow: 0 0 0 1px var(--black-a1), 0 1px 3px var(--black-a1), 0 1px 3px var(--black-a1), 0 2px 4px -1px var(--black-a1);
}

.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-state='checked']) {
  box-shadow: 0 0 0 1px var(--black-a1), 0 1px 3px var(--black-a2), 0 1px 3px var(--accent-a3), 0 2px 4px -1px var(--accent-a3);
}

.rt-SwitchRoot:where(.rt-variant-soft) :where(.rt-SwitchThumb):where([data-disabled]) {
  filter: none;
  background-color: var(--gray-2);
  box-shadow: 0 0 0 1px var(--gray-a2), 0 1px 3px var(--black-a1);
  transition: none;
}

.rt-BaseTabList::-webkit-scrollbar {
  display: none;
}

.rt-BaseTabListTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  box-sizing: border-box;
  height: var(--tab-height);
  padding-left: var(--tab-padding-x);
  padding-right: var(--tab-padding-x);
  color: var(--gray-a11);
}

.rt-BaseTabListTriggerInner,
.rt-BaseTabListTriggerInnerHidden {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: var(--tab-inner-padding-y) var(--tab-inner-padding-x);
  border-radius: var(--tab-inner-border-radius);
}

.rt-BaseTabListTriggerInner {
  position: absolute;
}

:where(.rt-BaseTabListTrigger[data-state='inactive'], .rt-TabNavLink:not([data-active])) .rt-BaseTabListTriggerInner {
  letter-spacing: var(--tab-inactive-letter-spacing);
  word-spacing: var(--tab-inactive-word-spacing);
}

:where(.rt-BaseTabListTrigger[data-state='active'], .rt-TabNavLink[data-active]) .rt-BaseTabListTriggerInner {
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
}

.rt-BaseTabListTriggerInnerHidden {
  visibility: hidden;
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tab-active-letter-spacing);
  word-spacing: var(--tab-active-word-spacing);
}

.rt-BaseTabList:where(.rt-r-size-1) {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
  --tab-height: var(--space-6);
  --tab-padding-x: var(--space-1);
  --tab-inner-padding-x: var(--space-1);
  --tab-inner-padding-y: calc(var(--space-1) * 0.5);
  --tab-inner-border-radius: var(--radius-1);
}

.rt-BaseTabList:where(.rt-r-size-2) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
  --tab-height: var(--space-7);
  --tab-padding-x: var(--space-2);
  --tab-inner-padding-x: var(--space-2);
  --tab-inner-padding-y: var(--space-1);
  --tab-inner-border-radius: var(--radius-2);
}

@media (min-width: 640px) {
  .rt-BaseTabList:where(.xs\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.xs\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

@media (min-width: 768px) {
  .rt-BaseTabList:where(.sm\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.sm\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

@media (min-width: 1024px) {
  .rt-BaseTabList:where(.md\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.md\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

@media (min-width: 1280px) {
  .rt-BaseTabList:where(.lg\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.lg\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

@media (min-width: 1536px) {
  .rt-BaseTabList:where(.xl\:rt-r-size-1) {
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
    --tab-height: var(--space-6);
    --tab-padding-x: var(--space-1);
    --tab-inner-padding-x: var(--space-1);
    --tab-inner-padding-y: calc(var(--space-1) * 0.5);
    --tab-inner-border-radius: var(--radius-1);
  }

  .rt-BaseTabList:where(.xl\:rt-r-size-2) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
    --tab-height: var(--space-7);
    --tab-padding-x: var(--space-2);
    --tab-inner-padding-x: var(--space-2);
    --tab-inner-padding-y: var(--space-1);
    --tab-inner-border-radius: var(--radius-2);
  }
}

.rt-BaseTabList {
  box-shadow: inset 0 -1px 0 0 var(--gray-a5);
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  font-family: var(--default-font-family);
  font-style: normal;
  scrollbar-width: none;
}

@media (hover: hover) {
  .rt-BaseTabListTrigger:where(:hover) {
    color: var(--gray-12);
  }

  .rt-BaseTabListTrigger:where(:hover) :where(.rt-BaseTabListTriggerInner) {
    background-color: var(--gray-a3);
  }

  .rt-BaseTabListTrigger:where(:focus-visible:hover) :where(.rt-BaseTabListTriggerInner) {
    background-color: var(--accent-a3);
  }
}

.rt-BaseTabListTrigger:where([data-state='active'], [data-active]) {
  color: var(--gray-12);
}

.rt-BaseTabListTrigger:where(:focus-visible) :where(.rt-BaseTabListTriggerInner) {
  outline: 2px solid var(--focus-8);
  outline-offset: -2px;
}

.rt-BaseTabListTrigger:where([data-state='active'], [data-active])::before {
  box-sizing: border-box;
  content: '';
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--accent-indicator);
}

:where(.rt-BaseTabList.rt-high-contrast) .rt-BaseTabListTrigger:where([data-state='active'], [data-active])::before {
  background-color: var(--accent-12);
}

.rt-TabNavItem {
  display: flex;
}

.rt-TableRootTable {
  --table-row-background-color: transparent;
  --table-row-box-shadow: inset 0 -1px var(--gray-a5);
  width: 100%;
  text-align: left;
  vertical-align: top;
  border-collapse: collapse;
  border-radius: calc(var(--table-border-radius) - 1px);
  border-spacing: 0;
  box-sizing: border-box;
  height: 0;
}

.rt-TableHeader {
  vertical-align: inherit;
}

.rt-TableBody {
  vertical-align: inherit;
}

.rt-TableRow {
  vertical-align: inherit;
  color: var(--gray-12);
}

.rt-TableCell {
  background-color: var(--table-row-background-color);
  box-shadow: var(--table-row-box-shadow);
  box-sizing: border-box;
  vertical-align: inherit;
  padding: var(--table-cell-padding);
  height: var(--table-cell-min-height);
}

.rt-Inset :where(.rt-TableCell:first-child) {
  padding-left: var(--inset-padding-left, var(--table-cell-padding));
}

.rt-Inset :where(.rt-TableCell:last-child) {
  padding-right: var(--inset-padding-right, var(--table-cell-padding));
}

.rt-TableColumnHeaderCell {
  font-weight: bold;
}

.rt-TableRowHeaderCell {
  font-weight: normal;
}

.rt-TableRoot:where(.rt-r-size-1) {
  --table-border-radius: var(--radius-3);
  --table-cell-padding: var(--space-2);
  --table-cell-min-height: calc(36px * var(--scaling));
}

.rt-TableRoot:where(.rt-r-size-1) :where(.rt-TableRootTable) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
}

.rt-TableRoot:where(.rt-r-size-2) {
  --table-border-radius: var(--radius-4);
  --table-cell-padding: var(--space-3);
  --table-cell-min-height: calc(44px * var(--scaling));
}

.rt-TableRoot:where(.rt-r-size-2) :where(.rt-TableRootTable) {
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
}

.rt-TableRoot:where(.rt-r-size-3) {
  --table-border-radius: var(--radius-4);
  --table-cell-padding: var(--space-3) var(--space-4);
  --table-cell-min-height: var(--space-8);
}

.rt-TableRoot:where(.rt-r-size-3) :where(.rt-TableRootTable) {
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
}

@media (min-width: 640px) {
  .rt-TableRoot:where(.xs\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.xs\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.xs\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.xs\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.xs\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.xs\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

@media (min-width: 768px) {
  .rt-TableRoot:where(.sm\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.sm\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.sm\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.sm\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.sm\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.sm\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

@media (min-width: 1024px) {
  .rt-TableRoot:where(.md\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.md\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.md\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.md\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.md\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.md\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

@media (min-width: 1280px) {
  .rt-TableRoot:where(.lg\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.lg\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.lg\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.lg\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.lg\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.lg\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

@media (min-width: 1536px) {
  .rt-TableRoot:where(.xl\:rt-r-size-1) {
    --table-border-radius: var(--radius-3);
    --table-cell-padding: var(--space-2);
    --table-cell-min-height: calc(36px * var(--scaling));
  }

  .rt-TableRoot:where(.xl\:rt-r-size-1) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.xl\:rt-r-size-2) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3);
    --table-cell-min-height: calc(44px * var(--scaling));
  }

  .rt-TableRoot:where(.xl\:rt-r-size-2) :where(.rt-TableRootTable) {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
  }

  .rt-TableRoot:where(.xl\:rt-r-size-3) {
    --table-border-radius: var(--radius-4);
    --table-cell-padding: var(--space-3) var(--space-4);
    --table-cell-min-height: var(--space-8);
  }

  .rt-TableRoot:where(.xl\:rt-r-size-3) :where(.rt-TableRootTable) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
  }
}

.rt-TableRoot:where(.rt-variant-surface) {
  box-sizing: border-box;
  border: 1px solid var(--gray-a5);
  border-radius: var(--table-border-radius);
  background-color: var(--color-panel);
  -webkit-backdrop-filter: var(--backdrop-filter-panel);
  backdrop-filter: var(--backdrop-filter-panel);
  background-clip: padding-box;
  position: relative;
}

@supports (box-shadow: 0 0 0 1px color-mix(in oklab, white, black)) {
  .rt-TableRoot:where(.rt-variant-surface) {
    border-color: color-mix(in oklab, var(--gray-a5), var(--gray-6));
  }
}

.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) {
  overflow: hidden;
}

.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) :where(.rt-TableHeader) {
  --table-row-background-color: var(--gray-a2);
}

.rt-TableRoot:where(.rt-variant-surface) :where(.rt-TableRootTable) :where(.rt-TableBody) :where(.rt-TableRow:last-child) {
  --table-row-box-shadow: none;
}

.rt-TableRoot:where(.rt-variant-ghost) {
  --scrollarea-scrollbar-horizontal-margin-left: 0;
  --scrollarea-scrollbar-horizontal-margin-right: 0;
}

.rt-TabsContent {
  position: relative;
  outline: 0;
}

.rt-TabsContent:where(:focus-visible) {
  outline: 2px solid var(--focus-8);
}

.rt-TextAreaRoot:where(:focus-within) {
  outline: 2px solid var(--focus-8);
  outline-offset: -1px;
}

.rt-TextAreaInput::-webkit-scrollbar {
  width: var(--space-3);
  height: var(--space-3);
}

.rt-TextAreaInput::-webkit-scrollbar-track,
  .rt-TextAreaInput::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: var(--space-1) solid transparent;
  border-radius: var(--space-3);
}

.rt-TextAreaInput::-webkit-scrollbar-track {
  background-color: var(--gray-a3);
}

.rt-TextAreaInput::-webkit-scrollbar-thumb {
  background-color: var(--gray-a8);
}

@media (hover: hover) {
  :where(.rt-TextAreaInput:not(:disabled))::-webkit-scrollbar-thumb:hover {
    background-color: var(--gray-a9);
  }
}

.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]) {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: var(--gray-12);
}

.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]) {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: var(--gray-12);
}

.rt-TextAreaRoot {
  padding: var(--text-area-border-width);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: left;
  overflow: hidden;
}

.rt-TextAreaInput {
  padding: var(--text-area-padding-y) var(--text-area-padding-x);
  border-radius: inherit;
  resize: none;
  display: block;
  width: 100%;
  flex-grow: 1;
  cursor: auto;
}

.rt-TextAreaRoot:where(.rt-r-size-1) {
  min-height: var(--space-8);
  border-radius: var(--radius-2);
}

.rt-TextAreaRoot:where(.rt-r-size-1) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  letter-spacing: var(--letter-spacing-1);
}

.rt-TextAreaRoot:where(.rt-r-size-2) {
  min-height: var(--space-9);
  border-radius: var(--radius-2);
}

.rt-TextAreaRoot:where(.rt-r-size-2) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
  font-size: var(--font-size-2);
  line-height: var(--line-height-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-TextAreaRoot:where(.rt-r-size-3) {
  min-height: 80px;
  border-radius: var(--radius-3);
}

.rt-TextAreaRoot:where(.rt-r-size-3) :where(.rt-TextAreaInput) {
  --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
  --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);
}

@media (min-width: 640px) {
  .rt-TextAreaRoot:where(.xs\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }

  .rt-TextAreaRoot:where(.xs\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

@media (min-width: 768px) {
  .rt-TextAreaRoot:where(.sm\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }

  .rt-TextAreaRoot:where(.sm\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

@media (min-width: 1024px) {
  .rt-TextAreaRoot:where(.md\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }

  .rt-TextAreaRoot:where(.md\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

@media (min-width: 1280px) {
  .rt-TextAreaRoot:where(.lg\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }

  .rt-TextAreaRoot:where(.lg\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

@media (min-width: 1536px) {
  .rt-TextAreaRoot:where(.xl\:rt-r-size-1) {
    min-height: var(--space-8);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-1) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    font-size: var(--font-size-1);
    line-height: var(--line-height-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-2) {
    min-height: var(--space-9);
    border-radius: var(--radius-2);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-2) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-1) * 1.5 - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-2) - var(--text-area-border-width));
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-3) {
    min-height: 80px;
    border-radius: var(--radius-3);
  }

  .rt-TextAreaRoot:where(.xl\:rt-r-size-3) :where(.rt-TextAreaInput) {
    --text-area-padding-y: calc(var(--space-2) - var(--text-area-border-width));
    --text-area-padding-x: calc(var(--space-3) - var(--text-area-border-width));
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
  }
}

.rt-TextAreaRoot:where(.rt-variant-surface) {
  --text-area-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a7);
  color: var(--gray-12);
}

.rt-TextAreaRoot:where(.rt-variant-surface) :where(.rt-TextAreaInput)::-moz-placeholder {
  color: var(--gray-a10);
}

.rt-TextAreaRoot:where(.rt-variant-surface) :where(.rt-TextAreaInput)::placeholder {
  color: var(--gray-a10);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a6);
}

.rt-TextAreaRoot:where(.rt-variant-surface):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-area-border-width) var(--gray-a6);
}

.rt-TextAreaRoot:where(.rt-variant-classic) {
  --text-area-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: var(--shadow-1);
  color: var(--gray-12);
}

.rt-TextAreaRoot:where(.rt-variant-classic) :where(.rt-TextAreaInput)::-moz-placeholder {
  color: var(--gray-a10);
}

.rt-TextAreaRoot:where(.rt-variant-classic) :where(.rt-TextAreaInput)::placeholder {
  color: var(--gray-a10);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-TextAreaRoot:where(.rt-variant-classic):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-TextAreaRoot:where(.rt-variant-soft) {
  --text-area-border-width: 0px;
  background-color: var(--accent-a3);
  color: var(--accent-12);
}

.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput)::-moz-selection {
  background-color: var(--accent-a5);
}

.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput)::selection {
  background-color: var(--accent-a5);
}

.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput)::-moz-placeholder {
  color: var(--accent-12);
  opacity: 0.65;
}

.rt-TextAreaRoot:where(.rt-variant-soft) :where(.rt-TextAreaInput)::placeholder {
  color: var(--accent-12);
  opacity: 0.65;
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:focus-within) {
  outline-color: var(--accent-8);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  background-color: var(--gray-a3);
}

.rt-TextAreaRoot:where(.rt-variant-soft):where(:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  background-color: var(--gray-a3);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.rt-TextAreaInput:where(:disabled, :read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::-moz-placeholder {
  opacity: 0.5;
}

.rt-TextAreaInput:where(:disabled, :read-only)::-moz-placeholder {
  opacity: 0.5;
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: 0.5;
}

.rt-TextAreaInput:where(:disabled, :read-only)::-moz-placeholder {
  opacity: 0.5;
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: 0.5;
}

.rt-TextAreaInput:where(:disabled, :read-only)::-moz-placeholder {
  opacity: 0.5;
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: 0.5;
}

.rt-TextAreaInput:where(:disabled, :read-only)::placeholder {
  opacity: 0.5;
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only):where(:-moz-placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::-moz-selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaInput:where(:disabled, :read-only)::-moz-selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaInput:where(:disabled, :read-only)::-moz-selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaInput:where(:disabled, :read-only)::-moz-selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaInput:where(:disabled, :-moz-read-only)::selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaInput:where(:disabled, :read-only)::selection {
  background-color: var(--gray-a5);
}

.rt-TextAreaRoot:where(:focus-within:has(.rt-TextAreaInput:where(:disabled, :-moz-read-only))) {
  outline-color: var(--gray-8);
}

.rt-TextAreaRoot:where(:focus-within:has(.rt-TextAreaInput:where(:disabled, :read-only))) {
  outline-color: var(--gray-8);
}

@supports selector(:has(*)) {
  .rt-TextFieldRoot:where(:has(.rt-TextFieldInput:focus)) {
    outline: 2px solid var(--text-field-focus-color);
    outline-offset: -1px;
  }
}

@supports not selector(:has(*)) {
  .rt-TextFieldRoot:where(:focus-within) {
    outline: 2px solid var(--text-field-focus-color);
    outline-offset: -1px;
  }
}

.rt-TextFieldRoot::-moz-selection {
  background-color: var(--text-field-selection-color);
}

.rt-TextFieldRoot::selection {
  background-color: var(--text-field-selection-color);
}

.rt-TextFieldInput {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: inherit;
  border-radius: calc(var(--text-field-border-radius) - var(--text-field-border-width));
  text-indent: var(--text-field-padding);
}

.rt-TextFieldInput:where([type='number']) {
  -moz-appearance: textfield;
}

.rt-TextFieldInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.rt-TextFieldInput::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.rt-TextFieldInput::-moz-selection {
  background-color: var(--text-field-selection-color);
}

.rt-TextFieldInput::selection {
  background-color: var(--text-field-selection-color);
}

.rt-TextFieldInput::-webkit-calendar-picker-indicator {
  box-sizing: content-box;
  width: var(--text-field-native-icon-size);
  height: var(--text-field-native-icon-size);
  padding: var(--space-1);
  margin-left: 0;
  margin-right: calc(var(--space-1) * -1);
  border-radius: calc(var(--text-field-border-radius) - 2px);
}

.rt-TextFieldInput:where(:not([type='time']))::-webkit-calendar-picker-indicator {
  margin-left: var(--space-1);
}

.rt-TextFieldInput::-webkit-calendar-picker-indicator:where(:hover) {
  background-color: var(--gray-a3);
}

.rt-TextFieldInput::-webkit-calendar-picker-indicator:where(:focus-visible) {
  outline: 2px solid var(--text-field-focus-color);
}

.rt-TextFieldInput::-webkit-datetime-edit-ampm-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-day-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-hour-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-millisecond-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-minute-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-month-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-second-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-week-field:where(:focus), .rt-TextFieldInput::-webkit-datetime-edit-year-field:where(:focus) {
  background-color: var(--text-field-selection-color);
  color: inherit;
  outline: none;
}

@supports selector(:has(*)) {
  .rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]) {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: var(--gray-12);
  }

  .rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]) {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: var(--gray-12);
  }
}

.rt-TextFieldSlot {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: text;
}

.rt-TextFieldSlot:where(:not([data-side='right'])) {
  order: -1;
  margin-left: calc(var(--text-field-border-width) * -1);
  margin-right: 0;
}

.rt-TextFieldSlot:where([data-side='right']),
  
  :where(.rt-TextFieldSlot:not([data-side='right'])) ~ .rt-TextFieldSlot:where(:not([data-side='left'])) {
  order: 0;
  margin-left: 0;
  margin-right: calc(var(--text-field-border-width) * -1);
}

.rt-TextFieldRoot {
  box-sizing: border-box;
  height: var(--text-field-height);
  padding: var(--text-field-border-width);
  border-radius: var(--text-field-border-radius);
  display: flex;
  align-items: stretch;
  font-family: var(--default-font-family);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: left;
}

.rt-TextFieldInput:where([type='date'], [type='datetime-local'], [type='time'], [type='week'], [type='month']) {
  text-indent: 0;
  padding-left: var(--text-field-padding);
  padding-right: var(--text-field-padding);
}

.rt-TextFieldInput:where(:has(~ .rt-TextFieldSlot:not([data-side='right']))) {
  text-indent: 0;
  padding-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rt-TextFieldInput:where(:has(
    ~ .rt-TextFieldSlot[data-side='right'],
    ~ .rt-TextFieldSlot:not([data-side='right']) ~ .rt-TextFieldSlot:not([data-side='left'])
  )) {
  padding-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rt-TextFieldRoot:where(.rt-r-size-1) {
  --text-field-height: var(--space-5);
  --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-2), var(--radius-full));
  --text-field-native-icon-size: var(--space-3);
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
}

.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldSlot) {
  gap: var(--space-2);
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}

.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldInput) {
  padding-bottom: 0px;
}

.rt-TextFieldRoot:where(.rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  padding-right: 0px;
  margin-right: -2px;
}

.rt-TextFieldRoot:where(.rt-r-size-2) {
  --text-field-height: var(--space-6);
  --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-2), var(--radius-full));
  --text-field-native-icon-size: var(--space-4);
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
}

.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldInput) {
  padding-bottom: 0.5px;
}

.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  padding-right: 2px;
  margin-right: 0px;
}

.rt-TextFieldRoot:where(.rt-r-size-2) :where(.rt-TextFieldSlot) {
  gap: var(--space-2);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-TextFieldRoot:where(.rt-r-size-3) {
  --text-field-height: var(--space-7);
  --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
  --text-field-border-radius: max(var(--radius-3), var(--radius-full));
  --text-field-native-icon-size: var(--space-4);
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
}

.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldInput) {
  padding-bottom: 0px;
}

.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
  padding-right: 5px;
  margin-right: 0px;
}

.rt-TextFieldRoot:where(.rt-r-size-3) :where(.rt-TextFieldSlot) {
  gap: var(--space-3);
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

@media (min-width: 640px) {
  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.xs\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (min-width: 768px) {
  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.sm\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (min-width: 1024px) {
  .rt-TextFieldRoot:where(.md\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.md\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (min-width: 1280px) {
  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.lg\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

@media (min-width: 1536px) {
  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) {
    --text-field-height: var(--space-5);
    --text-field-padding: calc(var(--space-1) * 1.5 - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-3);
    font-size: var(--font-size-1);
    letter-spacing: var(--letter-spacing-1);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-1) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 0px;
    margin-right: -2px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) {
    --text-field-height: var(--space-6);
    --text-field-padding: calc(var(--space-2) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-2), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-2);
    letter-spacing: var(--letter-spacing-2);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldInput) {
    padding-bottom: 0.5px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 2px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-2) :where(.rt-TextFieldSlot) {
    gap: var(--space-2);
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) {
    --text-field-height: var(--space-7);
    --text-field-padding: calc(var(--space-3) - var(--text-field-border-width));
    --text-field-border-radius: max(var(--radius-3), var(--radius-full));
    --text-field-native-icon-size: var(--space-4);
    font-size: var(--font-size-3);
    letter-spacing: var(--letter-spacing-3);
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldInput) {
    padding-bottom: 0px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldInput)::-webkit-textfield-decoration-container {
    padding-right: 5px;
    margin-right: 0px;
  }

  .rt-TextFieldRoot:where(.xl\:rt-r-size-3) :where(.rt-TextFieldSlot) {
    gap: var(--space-3);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}

.rt-TextFieldRoot:where(.rt-variant-surface) {
  --text-field-selection-color: var(--focus-a5);
  --text-field-focus-color: var(--focus-8);
  --text-field-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a7);
  color: var(--gray-12);
}

.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldInput)::-moz-placeholder {
  color: var(--gray-a10);
}

.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldInput)::placeholder {
  color: var(--gray-a10);
}

.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldSlot) {
  color: var(--gray-a11);
}

.rt-TextFieldRoot:where(.rt-variant-surface) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a6);
}

.rt-TextFieldRoot:where(.rt-variant-surface):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
  box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a6);
}

.rt-TextFieldRoot:where(.rt-variant-classic) {
  --text-field-selection-color: var(--focus-a5);
  --text-field-focus-color: var(--focus-8);
  --text-field-border-width: 1px;
  background-clip: content-box;
  background-color: var(--color-surface);
  box-shadow: var(--shadow-1);
  color: var(--gray-12);
}

.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldInput)::-moz-placeholder {
  color: var(--gray-a10);
}

.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldInput)::placeholder {
  color: var(--gray-a10);
}

.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldSlot) {
  color: var(--gray-a11);
}

.rt-TextFieldRoot:where(.rt-variant-classic) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  background-image: linear-gradient(var(--focus-a2), var(--focus-a2));
  box-shadow: inset 0 0 0 1px var(--focus-a5), inset 0 0 0 1px var(--gray-a5);
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-TextFieldRoot:where(.rt-variant-classic):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-image: linear-gradient(var(--gray-a2), var(--gray-a2));
}

.rt-TextFieldRoot:where(.rt-variant-soft) {
  --text-field-selection-color: var(--accent-a5);
  --text-field-focus-color: var(--accent-8);
  --text-field-border-width: 0px;
  background-color: var(--accent-a3);
  color: var(--accent-12);
}

.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldInput)::-moz-placeholder {
  color: var(--accent-12);
  opacity: 0.6;
}

.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldInput)::placeholder {
  color: var(--accent-12);
  opacity: 0.6;
}

.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldSlot) {
  color: var(--accent-12);
}

.rt-TextFieldRoot:where(.rt-variant-soft) :where(.rt-TextFieldSlot):where([data-accent-color]) {
  color: var(--accent-a11);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:-webkit-autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:-moz-read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:autofill, [data-com-onepassword-filled]):not(:disabled):not(:read-only))) {
  box-shadow: inset 0 0 0 1px var(--accent-a5), inset 0 0 0 1px var(--gray-a4);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  background-color: var(--gray-a3);
}

.rt-TextFieldRoot:where(.rt-variant-soft):where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  background-color: var(--gray-a3);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.rt-TextFieldInput:where(:disabled, :read-only) {
  cursor: text;
  color: var(--gray-a11);
  -webkit-text-fill-color: var(--gray-a11);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only)::-moz-placeholder {
  opacity: 0.5;
}

.rt-TextFieldInput:where(:disabled, :read-only)::-moz-placeholder {
  opacity: 0.5;
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: 0.5;
}

.rt-TextFieldInput:where(:disabled, :read-only)::-moz-placeholder {
  opacity: 0.5;
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: 0.5;
}

.rt-TextFieldInput:where(:disabled, :read-only)::-moz-placeholder {
  opacity: 0.5;
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only)::placeholder {
  opacity: 0.5;
}

.rt-TextFieldInput:where(:disabled, :read-only)::placeholder {
  opacity: 0.5;
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:-moz-placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:placeholder-shown) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:-moz-placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:-moz-placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :-moz-read-only):where(:placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldInput:where(:disabled, :read-only):where(:placeholder-shown) ~ :where(.rt-TextFieldSlot) {
  cursor: var(--cursor-disabled);
}

.rt-TextFieldRoot:where(:has(.rt-TextFieldInput:where(:disabled, :-moz-read-only))) {
  --text-field-selection-color: var(--gray-a5);
  --text-field-focus-color: var(--gray-8);
}

.rt-TextFieldRoot:where(:has(.rt-TextFieldInput:where(:disabled, :read-only))) {
  --text-field-selection-color: var(--gray-a5);
  --text-field-focus-color: var(--gray-8);
}

.rt-ThemePanelShortcut:where(:focus-visible) {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: var(--accent-9);
}

.rt-ThemePanelSwatch,
.rt-ThemePanelRadioCard {
  position: relative;
}

.rt-ThemePanelSwatchInput,
.rt-ThemePanelRadioCardInput {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  margin: 0;
  outline: none;
  outline-width: 2px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.rt-ThemePanelSwatch {
  width: var(--space-5);
  height: var(--space-5);
  border-radius: 100%;
}

.rt-ThemePanelSwatchInput {
  outline-offset: 2px;
}

.rt-ThemePanelSwatchInput:where(:checked) {
  outline-style: solid;
  outline-color: var(--gray-12);
}

.rt-ThemePanelSwatchInput:where(:focus-visible) {
  outline-style: solid;
  outline-color: var(--accent-9);
}

.rt-ThemePanelRadioCard {
  border-radius: var(--radius-1);
  box-shadow: 0 0 0 1px var(--gray-7);
}

.rt-ThemePanelRadioCardInput {
  outline-offset: -1px;
}

.rt-ThemePanelRadioCardInput:where(:checked) {
  outline-style: solid;
  outline-color: var(--gray-12);
}

.rt-ThemePanelRadioCardInput:where(:focus-visible) {
  background-color: var(--accent-a3);
  outline-style: solid;
  outline-color: var(--accent-9);
}

.rt-TooltipContent {
  box-sizing: border-box;
  padding: var(--space-1) var(--space-2);
  background-color: var(--gray-12);
  border-radius: var(--radius-2);
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation-duration: 140ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}

@media (prefers-reduced-motion: no-preference) {
  .rt-TooltipContent:where([data-state='delayed-open']):where([data-side='top']) {
    animation-name: rt-slide-from-top, rt-fade-in;
  }

  .rt-TooltipContent:where([data-state='delayed-open']):where([data-side='bottom']) {
    animation-name: rt-slide-from-bottom, rt-fade-in;
  }

  .rt-TooltipContent:where([data-state='delayed-open']):where([data-side='left']) {
    animation-name: rt-slide-from-left, rt-fade-in;
  }

  .rt-TooltipContent:where([data-state='delayed-open']):where([data-side='right']) {
    animation-name: rt-slide-from-right, rt-fade-in;
  }
}

.rt-TooltipText {
  color: var(--gray-1);
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  cursor: default;
}

.rt-TooltipArrow {
  fill: var(--gray-12);
}

.radix-themes:where([data-is-root-theme='true']) {
  position: relative;
  z-index: 0;
  min-height: 100vh;
}

@supports (min-height: 100dvh) {
  .radix-themes:where([data-is-root-theme='true']) {
    min-height: 100dvh;
  }
}

.rt-r-ai-start {
  align-items: flex-start;
}

.rt-r-ai-center {
  align-items: center;
}

.rt-r-ai-end {
  align-items: flex-end;
}

.rt-r-ai-baseline {
  align-items: baseline;
}

.rt-r-ai-stretch {
  align-items: stretch;
}

@media (min-width: 640px) {
  .xs\:rt-r-ai-start {
    align-items: flex-start;
  }

  .xs\:rt-r-ai-center {
    align-items: center;
  }

  .xs\:rt-r-ai-end {
    align-items: flex-end;
  }

  .xs\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .xs\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ai-start {
    align-items: flex-start;
  }

  .sm\:rt-r-ai-center {
    align-items: center;
  }

  .sm\:rt-r-ai-end {
    align-items: flex-end;
  }

  .sm\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .sm\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ai-start {
    align-items: flex-start;
  }

  .md\:rt-r-ai-center {
    align-items: center;
  }

  .md\:rt-r-ai-end {
    align-items: flex-end;
  }

  .md\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .md\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ai-start {
    align-items: flex-start;
  }

  .lg\:rt-r-ai-center {
    align-items: center;
  }

  .lg\:rt-r-ai-end {
    align-items: flex-end;
  }

  .lg\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .lg\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-ai-start {
    align-items: flex-start;
  }

  .xl\:rt-r-ai-center {
    align-items: center;
  }

  .xl\:rt-r-ai-end {
    align-items: flex-end;
  }

  .xl\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .xl\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

.rt-r-as-start {
  align-self: flex-start;
}

.rt-r-as-center {
  align-self: center;
}

.rt-r-as-end {
  align-self: flex-end;
}

.rt-r-as-baseline {
  align-self: baseline;
}

.rt-r-as-stretch {
  align-self: stretch;
}

@media (min-width: 640px) {
  .xs\:rt-r-as-start {
    align-self: flex-start;
  }

  .xs\:rt-r-as-center {
    align-self: center;
  }

  .xs\:rt-r-as-end {
    align-self: flex-end;
  }

  .xs\:rt-r-as-baseline {
    align-self: baseline;
  }

  .xs\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-as-start {
    align-self: flex-start;
  }

  .sm\:rt-r-as-center {
    align-self: center;
  }

  .sm\:rt-r-as-end {
    align-self: flex-end;
  }

  .sm\:rt-r-as-baseline {
    align-self: baseline;
  }

  .sm\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-as-start {
    align-self: flex-start;
  }

  .md\:rt-r-as-center {
    align-self: center;
  }

  .md\:rt-r-as-end {
    align-self: flex-end;
  }

  .md\:rt-r-as-baseline {
    align-self: baseline;
  }

  .md\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-as-start {
    align-self: flex-start;
  }

  .lg\:rt-r-as-center {
    align-self: center;
  }

  .lg\:rt-r-as-end {
    align-self: flex-end;
  }

  .lg\:rt-r-as-baseline {
    align-self: baseline;
  }

  .lg\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-as-start {
    align-self: flex-start;
  }

  .xl\:rt-r-as-center {
    align-self: center;
  }

  .xl\:rt-r-as-end {
    align-self: flex-end;
  }

  .xl\:rt-r-as-baseline {
    align-self: baseline;
  }

  .xl\:rt-r-as-stretch {
    align-self: stretch;
  }
}

.rt-r-display-block {
  display: block;
}

.rt-r-display-inline {
  display: inline;
}

.rt-r-display-inline-block {
  display: inline-block;
}

.rt-r-display-flex {
  display: flex;
}

.rt-r-display-inline-flex {
  display: inline-flex;
}

.rt-r-display-grid {
  display: grid;
}

.rt-r-display-inline-grid {
  display: inline-grid;
}

.rt-r-display-none {
  display: none;
}

@media (min-width: 640px) {
  .xs\:rt-r-display-block {
    display: block;
  }

  .xs\:rt-r-display-inline {
    display: inline;
  }

  .xs\:rt-r-display-inline-block {
    display: inline-block;
  }

  .xs\:rt-r-display-flex {
    display: flex;
  }

  .xs\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .xs\:rt-r-display-grid {
    display: grid;
  }

  .xs\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .xs\:rt-r-display-none {
    display: none;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-display-block {
    display: block;
  }

  .sm\:rt-r-display-inline {
    display: inline;
  }

  .sm\:rt-r-display-inline-block {
    display: inline-block;
  }

  .sm\:rt-r-display-flex {
    display: flex;
  }

  .sm\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .sm\:rt-r-display-grid {
    display: grid;
  }

  .sm\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .sm\:rt-r-display-none {
    display: none;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-display-block {
    display: block;
  }

  .md\:rt-r-display-inline {
    display: inline;
  }

  .md\:rt-r-display-inline-block {
    display: inline-block;
  }

  .md\:rt-r-display-flex {
    display: flex;
  }

  .md\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .md\:rt-r-display-grid {
    display: grid;
  }

  .md\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .md\:rt-r-display-none {
    display: none;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-display-block {
    display: block;
  }

  .lg\:rt-r-display-inline {
    display: inline;
  }

  .lg\:rt-r-display-inline-block {
    display: inline-block;
  }

  .lg\:rt-r-display-flex {
    display: flex;
  }

  .lg\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .lg\:rt-r-display-grid {
    display: grid;
  }

  .lg\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .lg\:rt-r-display-none {
    display: none;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-display-block {
    display: block;
  }

  .xl\:rt-r-display-inline {
    display: inline;
  }

  .xl\:rt-r-display-inline-block {
    display: inline-block;
  }

  .xl\:rt-r-display-flex {
    display: flex;
  }

  .xl\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .xl\:rt-r-display-grid {
    display: grid;
  }

  .xl\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .xl\:rt-r-display-none {
    display: none;
  }
}

.rt-r-fb {
  flex-basis: var(--flex-basis);
}

@media (min-width: 640px) {
  .xs\:rt-r-fb {
    flex-basis: var(--flex-basis-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fb {
    flex-basis: var(--flex-basis-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fb {
    flex-basis: var(--flex-basis-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fb {
    flex-basis: var(--flex-basis-lg);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-fb {
    flex-basis: var(--flex-basis-xl);
  }
}

.rt-r-fd-row {
  flex-direction: row;
}

.rt-r-fd-column {
  flex-direction: column;
}

.rt-r-fd-row-reverse {
  flex-direction: row-reverse;
}

.rt-r-fd-column-reverse {
  flex-direction: column-reverse;
}

@media (min-width: 640px) {
  .xs\:rt-r-fd-row {
    flex-direction: row;
  }

  .xs\:rt-r-fd-column {
    flex-direction: column;
  }

  .xs\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .xs\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fd-row {
    flex-direction: row;
  }

  .sm\:rt-r-fd-column {
    flex-direction: column;
  }

  .sm\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fd-row {
    flex-direction: row;
  }

  .md\:rt-r-fd-column {
    flex-direction: column;
  }

  .md\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fd-row {
    flex-direction: row;
  }

  .lg\:rt-r-fd-column {
    flex-direction: column;
  }

  .lg\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-fd-row {
    flex-direction: row;
  }

  .xl\:rt-r-fd-column {
    flex-direction: column;
  }

  .xl\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

.rt-r-fg {
  flex-grow: var(--flex-grow)
}

.rt-r-fg-0 {
  flex-grow: 0;
}

.rt-r-fg-1 {
  flex-grow: 1;
}

@media (min-width: 640px) {
  .xs\:rt-r-fg {
    flex-grow: var(--flex-grow-xs)
  }

  .xs\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .xs\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fg {
    flex-grow: var(--flex-grow-sm)
  }

  .sm\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .sm\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fg {
    flex-grow: var(--flex-grow-md)
  }

  .md\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .md\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fg {
    flex-grow: var(--flex-grow-lg)
  }

  .lg\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .lg\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-fg {
    flex-grow: var(--flex-grow-xl)
  }

  .xl\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .xl\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

.rt-r-fs {
  flex-shrink: var(--flex-shrink)
}

.rt-r-fs-0 {
  flex-shrink: 0;
}

.rt-r-fs-1 {
  flex-shrink: 1;
}

@media (min-width: 640px) {
  .xs\:rt-r-fs {
    flex-shrink: var(--flex-shrink-xs)
  }

  .xs\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .xs\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fs {
    flex-shrink: var(--flex-shrink-sm)
  }

  .sm\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .sm\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fs {
    flex-shrink: var(--flex-shrink-md)
  }

  .md\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .md\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fs {
    flex-shrink: var(--flex-shrink-lg)
  }

  .lg\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .lg\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-fs {
    flex-shrink: var(--flex-shrink-xl)
  }

  .xl\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .xl\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

.rt-r-fw-nowrap {
  flex-wrap: nowrap;
}

.rt-r-fw-wrap {
  flex-wrap: wrap;
}

.rt-r-fw-wrap-reverse {
  flex-wrap: wrap-reverse;
}

@media (min-width: 640px) {
  .xs\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .xs\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .xs\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .sm\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .md\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .md\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .lg\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .xl\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

.rt-r-gap {
  gap: var(--gap)
}

.rt-r-gap-0 {
  gap: 0;
}

.rt-r-gap-1 {
  gap: var(--space-1);
}

.rt-r-gap-2 {
  gap: var(--space-2);
}

.rt-r-gap-3 {
  gap: var(--space-3);
}

.rt-r-gap-4 {
  gap: var(--space-4);
}

.rt-r-gap-5 {
  gap: var(--space-5);
}

.rt-r-gap-6 {
  gap: var(--space-6);
}

.rt-r-gap-7 {
  gap: var(--space-7);
}

.rt-r-gap-8 {
  gap: var(--space-8);
}

.rt-r-gap-9 {
  gap: var(--space-9);
}

.rt-r-cg {
  -moz-column-gap: var(--column-gap);
       column-gap: var(--column-gap)
}

.rt-r-cg-0 {
  -moz-column-gap: 0;
       column-gap: 0;
}

.rt-r-cg-1 {
  -moz-column-gap: var(--space-1);
       column-gap: var(--space-1);
}

.rt-r-cg-2 {
  -moz-column-gap: var(--space-2);
       column-gap: var(--space-2);
}

.rt-r-cg-3 {
  -moz-column-gap: var(--space-3);
       column-gap: var(--space-3);
}

.rt-r-cg-4 {
  -moz-column-gap: var(--space-4);
       column-gap: var(--space-4);
}

.rt-r-cg-5 {
  -moz-column-gap: var(--space-5);
       column-gap: var(--space-5);
}

.rt-r-cg-6 {
  -moz-column-gap: var(--space-6);
       column-gap: var(--space-6);
}

.rt-r-cg-7 {
  -moz-column-gap: var(--space-7);
       column-gap: var(--space-7);
}

.rt-r-cg-8 {
  -moz-column-gap: var(--space-8);
       column-gap: var(--space-8);
}

.rt-r-cg-9 {
  -moz-column-gap: var(--space-9);
       column-gap: var(--space-9);
}

.rt-r-rg {
  row-gap: var(--row-gap)
}

.rt-r-rg-0 {
  row-gap: 0;
}

.rt-r-rg-1 {
  row-gap: var(--space-1);
}

.rt-r-rg-2 {
  row-gap: var(--space-2);
}

.rt-r-rg-3 {
  row-gap: var(--space-3);
}

.rt-r-rg-4 {
  row-gap: var(--space-4);
}

.rt-r-rg-5 {
  row-gap: var(--space-5);
}

.rt-r-rg-6 {
  row-gap: var(--space-6);
}

.rt-r-rg-7 {
  row-gap: var(--space-7);
}

.rt-r-rg-8 {
  row-gap: var(--space-8);
}

.rt-r-rg-9 {
  row-gap: var(--space-9);
}

@media (min-width: 640px) {
  .xs\:rt-r-gap {
    gap: var(--gap-xs)
  }

  .xs\:rt-r-gap-0 {
    gap: 0;
  }

  .xs\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .xs\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .xs\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .xs\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .xs\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .xs\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .xs\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .xs\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .xs\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .xs\:rt-r-cg {
    -moz-column-gap: var(--column-gap-xs);
         column-gap: var(--column-gap-xs)
  }

  .xs\:rt-r-cg-0 {
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .xs\:rt-r-cg-1 {
    -moz-column-gap: var(--space-1);
         column-gap: var(--space-1);
  }

  .xs\:rt-r-cg-2 {
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
  }

  .xs\:rt-r-cg-3 {
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
  }

  .xs\:rt-r-cg-4 {
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
  }

  .xs\:rt-r-cg-5 {
    -moz-column-gap: var(--space-5);
         column-gap: var(--space-5);
  }

  .xs\:rt-r-cg-6 {
    -moz-column-gap: var(--space-6);
         column-gap: var(--space-6);
  }

  .xs\:rt-r-cg-7 {
    -moz-column-gap: var(--space-7);
         column-gap: var(--space-7);
  }

  .xs\:rt-r-cg-8 {
    -moz-column-gap: var(--space-8);
         column-gap: var(--space-8);
  }

  .xs\:rt-r-cg-9 {
    -moz-column-gap: var(--space-9);
         column-gap: var(--space-9);
  }

  .xs\:rt-r-rg {
    row-gap: var(--row-gap-xs)
  }

  .xs\:rt-r-rg-0 {
    row-gap: 0;
  }

  .xs\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .xs\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .xs\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .xs\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .xs\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .xs\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .xs\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .xs\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .xs\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gap {
    gap: var(--gap-sm)
  }

  .sm\:rt-r-gap-0 {
    gap: 0;
  }

  .sm\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .sm\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .sm\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .sm\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .sm\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .sm\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .sm\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .sm\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .sm\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .sm\:rt-r-cg {
    -moz-column-gap: var(--column-gap-sm);
         column-gap: var(--column-gap-sm)
  }

  .sm\:rt-r-cg-0 {
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .sm\:rt-r-cg-1 {
    -moz-column-gap: var(--space-1);
         column-gap: var(--space-1);
  }

  .sm\:rt-r-cg-2 {
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
  }

  .sm\:rt-r-cg-3 {
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
  }

  .sm\:rt-r-cg-4 {
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
  }

  .sm\:rt-r-cg-5 {
    -moz-column-gap: var(--space-5);
         column-gap: var(--space-5);
  }

  .sm\:rt-r-cg-6 {
    -moz-column-gap: var(--space-6);
         column-gap: var(--space-6);
  }

  .sm\:rt-r-cg-7 {
    -moz-column-gap: var(--space-7);
         column-gap: var(--space-7);
  }

  .sm\:rt-r-cg-8 {
    -moz-column-gap: var(--space-8);
         column-gap: var(--space-8);
  }

  .sm\:rt-r-cg-9 {
    -moz-column-gap: var(--space-9);
         column-gap: var(--space-9);
  }

  .sm\:rt-r-rg {
    row-gap: var(--row-gap-sm)
  }

  .sm\:rt-r-rg-0 {
    row-gap: 0;
  }

  .sm\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .sm\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .sm\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .sm\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .sm\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .sm\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .sm\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .sm\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .sm\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gap {
    gap: var(--gap-md)
  }

  .md\:rt-r-gap-0 {
    gap: 0;
  }

  .md\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .md\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .md\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .md\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .md\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .md\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .md\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .md\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .md\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .md\:rt-r-cg {
    -moz-column-gap: var(--column-gap-md);
         column-gap: var(--column-gap-md)
  }

  .md\:rt-r-cg-0 {
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .md\:rt-r-cg-1 {
    -moz-column-gap: var(--space-1);
         column-gap: var(--space-1);
  }

  .md\:rt-r-cg-2 {
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
  }

  .md\:rt-r-cg-3 {
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
  }

  .md\:rt-r-cg-4 {
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
  }

  .md\:rt-r-cg-5 {
    -moz-column-gap: var(--space-5);
         column-gap: var(--space-5);
  }

  .md\:rt-r-cg-6 {
    -moz-column-gap: var(--space-6);
         column-gap: var(--space-6);
  }

  .md\:rt-r-cg-7 {
    -moz-column-gap: var(--space-7);
         column-gap: var(--space-7);
  }

  .md\:rt-r-cg-8 {
    -moz-column-gap: var(--space-8);
         column-gap: var(--space-8);
  }

  .md\:rt-r-cg-9 {
    -moz-column-gap: var(--space-9);
         column-gap: var(--space-9);
  }

  .md\:rt-r-rg {
    row-gap: var(--row-gap-md)
  }

  .md\:rt-r-rg-0 {
    row-gap: 0;
  }

  .md\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .md\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .md\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .md\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .md\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .md\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .md\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .md\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .md\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gap {
    gap: var(--gap-lg)
  }

  .lg\:rt-r-gap-0 {
    gap: 0;
  }

  .lg\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .lg\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .lg\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .lg\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .lg\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .lg\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .lg\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .lg\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .lg\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .lg\:rt-r-cg {
    -moz-column-gap: var(--column-gap-lg);
         column-gap: var(--column-gap-lg)
  }

  .lg\:rt-r-cg-0 {
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .lg\:rt-r-cg-1 {
    -moz-column-gap: var(--space-1);
         column-gap: var(--space-1);
  }

  .lg\:rt-r-cg-2 {
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
  }

  .lg\:rt-r-cg-3 {
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
  }

  .lg\:rt-r-cg-4 {
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
  }

  .lg\:rt-r-cg-5 {
    -moz-column-gap: var(--space-5);
         column-gap: var(--space-5);
  }

  .lg\:rt-r-cg-6 {
    -moz-column-gap: var(--space-6);
         column-gap: var(--space-6);
  }

  .lg\:rt-r-cg-7 {
    -moz-column-gap: var(--space-7);
         column-gap: var(--space-7);
  }

  .lg\:rt-r-cg-8 {
    -moz-column-gap: var(--space-8);
         column-gap: var(--space-8);
  }

  .lg\:rt-r-cg-9 {
    -moz-column-gap: var(--space-9);
         column-gap: var(--space-9);
  }

  .lg\:rt-r-rg {
    row-gap: var(--row-gap-lg)
  }

  .lg\:rt-r-rg-0 {
    row-gap: 0;
  }

  .lg\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .lg\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .lg\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .lg\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .lg\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .lg\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .lg\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .lg\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .lg\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gap {
    gap: var(--gap-xl)
  }

  .xl\:rt-r-gap-0 {
    gap: 0;
  }

  .xl\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .xl\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .xl\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .xl\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .xl\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .xl\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .xl\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .xl\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .xl\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .xl\:rt-r-cg {
    -moz-column-gap: var(--column-gap-xl);
         column-gap: var(--column-gap-xl)
  }

  .xl\:rt-r-cg-0 {
    -moz-column-gap: 0;
         column-gap: 0;
  }

  .xl\:rt-r-cg-1 {
    -moz-column-gap: var(--space-1);
         column-gap: var(--space-1);
  }

  .xl\:rt-r-cg-2 {
    -moz-column-gap: var(--space-2);
         column-gap: var(--space-2);
  }

  .xl\:rt-r-cg-3 {
    -moz-column-gap: var(--space-3);
         column-gap: var(--space-3);
  }

  .xl\:rt-r-cg-4 {
    -moz-column-gap: var(--space-4);
         column-gap: var(--space-4);
  }

  .xl\:rt-r-cg-5 {
    -moz-column-gap: var(--space-5);
         column-gap: var(--space-5);
  }

  .xl\:rt-r-cg-6 {
    -moz-column-gap: var(--space-6);
         column-gap: var(--space-6);
  }

  .xl\:rt-r-cg-7 {
    -moz-column-gap: var(--space-7);
         column-gap: var(--space-7);
  }

  .xl\:rt-r-cg-8 {
    -moz-column-gap: var(--space-8);
         column-gap: var(--space-8);
  }

  .xl\:rt-r-cg-9 {
    -moz-column-gap: var(--space-9);
         column-gap: var(--space-9);
  }

  .xl\:rt-r-rg {
    row-gap: var(--row-gap-xl)
  }

  .xl\:rt-r-rg-0 {
    row-gap: 0;
  }

  .xl\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .xl\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .xl\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .xl\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .xl\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .xl\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .xl\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .xl\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .xl\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

.rt-r-ga {
  grid-area: var(--grid-area);
}

.rt-r-gaf-row {
  grid-auto-flow: row;
}

.rt-r-gaf-column {
  grid-auto-flow: column;
}

.rt-r-gaf-dense {
  grid-auto-flow: dense;
}

.rt-r-gaf-row-dense {
  grid-auto-flow: row dense;
}

.rt-r-gaf-column-dense {
  grid-auto-flow: column dense;
}

@media (min-width: 640px) {
  .xs\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .xs\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .xs\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .xs\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .xs\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .sm\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .sm\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .sm\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .md\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .md\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .md\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .lg\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .lg\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .lg\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .xl\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .xl\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .xl\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

.rt-r-gc {
  grid-column: var(--grid-column)
}

.rt-r-gc-1 {
  grid-column: 1;
}

.rt-r-gc-2 {
  grid-column: 2;
}

.rt-r-gc-3 {
  grid-column: 3;
}

.rt-r-gc-4 {
  grid-column: 4;
}

.rt-r-gc-5 {
  grid-column: 5;
}

.rt-r-gc-6 {
  grid-column: 6;
}

.rt-r-gc-7 {
  grid-column: 7;
}

.rt-r-gc-8 {
  grid-column: 8;
}

.rt-r-gc-9 {
  grid-column: 9;
}

@media (min-width: 640px) {
  .xs\:rt-r-gc {
    grid-column: var(--grid-column-xs)
  }

  .xs\:rt-r-gc-1 {
    grid-column: 1;
  }

  .xs\:rt-r-gc-2 {
    grid-column: 2;
  }

  .xs\:rt-r-gc-3 {
    grid-column: 3;
  }

  .xs\:rt-r-gc-4 {
    grid-column: 4;
  }

  .xs\:rt-r-gc-5 {
    grid-column: 5;
  }

  .xs\:rt-r-gc-6 {
    grid-column: 6;
  }

  .xs\:rt-r-gc-7 {
    grid-column: 7;
  }

  .xs\:rt-r-gc-8 {
    grid-column: 8;
  }

  .xs\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gc {
    grid-column: var(--grid-column-sm)
  }

  .sm\:rt-r-gc-1 {
    grid-column: 1;
  }

  .sm\:rt-r-gc-2 {
    grid-column: 2;
  }

  .sm\:rt-r-gc-3 {
    grid-column: 3;
  }

  .sm\:rt-r-gc-4 {
    grid-column: 4;
  }

  .sm\:rt-r-gc-5 {
    grid-column: 5;
  }

  .sm\:rt-r-gc-6 {
    grid-column: 6;
  }

  .sm\:rt-r-gc-7 {
    grid-column: 7;
  }

  .sm\:rt-r-gc-8 {
    grid-column: 8;
  }

  .sm\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gc {
    grid-column: var(--grid-column-md)
  }

  .md\:rt-r-gc-1 {
    grid-column: 1;
  }

  .md\:rt-r-gc-2 {
    grid-column: 2;
  }

  .md\:rt-r-gc-3 {
    grid-column: 3;
  }

  .md\:rt-r-gc-4 {
    grid-column: 4;
  }

  .md\:rt-r-gc-5 {
    grid-column: 5;
  }

  .md\:rt-r-gc-6 {
    grid-column: 6;
  }

  .md\:rt-r-gc-7 {
    grid-column: 7;
  }

  .md\:rt-r-gc-8 {
    grid-column: 8;
  }

  .md\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gc {
    grid-column: var(--grid-column-lg)
  }

  .lg\:rt-r-gc-1 {
    grid-column: 1;
  }

  .lg\:rt-r-gc-2 {
    grid-column: 2;
  }

  .lg\:rt-r-gc-3 {
    grid-column: 3;
  }

  .lg\:rt-r-gc-4 {
    grid-column: 4;
  }

  .lg\:rt-r-gc-5 {
    grid-column: 5;
  }

  .lg\:rt-r-gc-6 {
    grid-column: 6;
  }

  .lg\:rt-r-gc-7 {
    grid-column: 7;
  }

  .lg\:rt-r-gc-8 {
    grid-column: 8;
  }

  .lg\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gc {
    grid-column: var(--grid-column-xl)
  }

  .xl\:rt-r-gc-1 {
    grid-column: 1;
  }

  .xl\:rt-r-gc-2 {
    grid-column: 2;
  }

  .xl\:rt-r-gc-3 {
    grid-column: 3;
  }

  .xl\:rt-r-gc-4 {
    grid-column: 4;
  }

  .xl\:rt-r-gc-5 {
    grid-column: 5;
  }

  .xl\:rt-r-gc-6 {
    grid-column: 6;
  }

  .xl\:rt-r-gc-7 {
    grid-column: 7;
  }

  .xl\:rt-r-gc-8 {
    grid-column: 8;
  }

  .xl\:rt-r-gc-9 {
    grid-column: 9;
  }
}

.rt-r-gcs {
  grid-column-start: var(--grid-column-start)
}

.rt-r-gcs-1 {
  grid-column-start: 1;
}

.rt-r-gcs-2 {
  grid-column-start: 2;
}

.rt-r-gcs-3 {
  grid-column-start: 3;
}

.rt-r-gcs-4 {
  grid-column-start: 4;
}

.rt-r-gcs-5 {
  grid-column-start: 5;
}

.rt-r-gcs-6 {
  grid-column-start: 6;
}

.rt-r-gcs-7 {
  grid-column-start: 7;
}

.rt-r-gcs-8 {
  grid-column-start: 8;
}

.rt-r-gcs-9 {
  grid-column-start: 9;
}

@media (min-width: 640px) {
  .xs\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-xs)
  }

  .xs\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .xs\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .xs\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .xs\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .xs\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .xs\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .xs\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .xs\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .xs\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-sm)
  }

  .sm\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .sm\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .sm\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .sm\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .sm\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .sm\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .sm\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .sm\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .sm\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-md)
  }

  .md\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .md\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .md\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .md\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .md\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .md\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .md\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .md\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .md\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-lg)
  }

  .lg\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .lg\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .lg\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .lg\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .lg\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .lg\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .lg\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .lg\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .lg\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-xl)
  }

  .xl\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .xl\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .xl\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .xl\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .xl\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .xl\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .xl\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .xl\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .xl\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

.rt-r-gce {
  grid-column-end: var(--grid-column-end)
}

.rt-r-gce-1 {
  grid-column-end: 1;
}

.rt-r-gce-2 {
  grid-column-end: 2;
}

.rt-r-gce-3 {
  grid-column-end: 3;
}

.rt-r-gce-4 {
  grid-column-end: 4;
}

.rt-r-gce-5 {
  grid-column-end: 5;
}

.rt-r-gce-6 {
  grid-column-end: 6;
}

.rt-r-gce-7 {
  grid-column-end: 7;
}

.rt-r-gce-8 {
  grid-column-end: 8;
}

.rt-r-gce-9 {
  grid-column-end: 9;
}

@media (min-width: 640px) {
  .xs\:rt-r-gce {
    grid-column-end: var(--grid-column-end-xs)
  }

  .xs\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .xs\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .xs\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .xs\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .xs\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .xs\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .xs\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .xs\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .xs\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gce {
    grid-column-end: var(--grid-column-end-sm)
  }

  .sm\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .sm\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .sm\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .sm\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .sm\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .sm\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .sm\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .sm\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .sm\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gce {
    grid-column-end: var(--grid-column-end-md)
  }

  .md\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .md\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .md\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .md\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .md\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .md\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .md\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .md\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .md\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gce {
    grid-column-end: var(--grid-column-end-lg)
  }

  .lg\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .lg\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .lg\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .lg\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .lg\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .lg\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .lg\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .lg\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .lg\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gce {
    grid-column-end: var(--grid-column-end-xl)
  }

  .xl\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .xl\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .xl\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .xl\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .xl\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .xl\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .xl\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .xl\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .xl\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

.rt-r-gr {
  grid-row: var(--grid-row)
}

.rt-r-gr-1 {
  grid-row: 1;
}

.rt-r-gr-2 {
  grid-row: 2;
}

.rt-r-gr-3 {
  grid-row: 3;
}

.rt-r-gr-4 {
  grid-row: 4;
}

.rt-r-gr-5 {
  grid-row: 5;
}

.rt-r-gr-6 {
  grid-row: 6;
}

.rt-r-gr-7 {
  grid-row: 7;
}

.rt-r-gr-8 {
  grid-row: 8;
}

.rt-r-gr-9 {
  grid-row: 9;
}

@media (min-width: 640px) {
  .xs\:rt-r-gr {
    grid-row: var(--grid-row-xs)
  }

  .xs\:rt-r-gr-1 {
    grid-row: 1;
  }

  .xs\:rt-r-gr-2 {
    grid-row: 2;
  }

  .xs\:rt-r-gr-3 {
    grid-row: 3;
  }

  .xs\:rt-r-gr-4 {
    grid-row: 4;
  }

  .xs\:rt-r-gr-5 {
    grid-row: 5;
  }

  .xs\:rt-r-gr-6 {
    grid-row: 6;
  }

  .xs\:rt-r-gr-7 {
    grid-row: 7;
  }

  .xs\:rt-r-gr-8 {
    grid-row: 8;
  }

  .xs\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gr {
    grid-row: var(--grid-row-sm)
  }

  .sm\:rt-r-gr-1 {
    grid-row: 1;
  }

  .sm\:rt-r-gr-2 {
    grid-row: 2;
  }

  .sm\:rt-r-gr-3 {
    grid-row: 3;
  }

  .sm\:rt-r-gr-4 {
    grid-row: 4;
  }

  .sm\:rt-r-gr-5 {
    grid-row: 5;
  }

  .sm\:rt-r-gr-6 {
    grid-row: 6;
  }

  .sm\:rt-r-gr-7 {
    grid-row: 7;
  }

  .sm\:rt-r-gr-8 {
    grid-row: 8;
  }

  .sm\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gr {
    grid-row: var(--grid-row-md)
  }

  .md\:rt-r-gr-1 {
    grid-row: 1;
  }

  .md\:rt-r-gr-2 {
    grid-row: 2;
  }

  .md\:rt-r-gr-3 {
    grid-row: 3;
  }

  .md\:rt-r-gr-4 {
    grid-row: 4;
  }

  .md\:rt-r-gr-5 {
    grid-row: 5;
  }

  .md\:rt-r-gr-6 {
    grid-row: 6;
  }

  .md\:rt-r-gr-7 {
    grid-row: 7;
  }

  .md\:rt-r-gr-8 {
    grid-row: 8;
  }

  .md\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gr {
    grid-row: var(--grid-row-lg)
  }

  .lg\:rt-r-gr-1 {
    grid-row: 1;
  }

  .lg\:rt-r-gr-2 {
    grid-row: 2;
  }

  .lg\:rt-r-gr-3 {
    grid-row: 3;
  }

  .lg\:rt-r-gr-4 {
    grid-row: 4;
  }

  .lg\:rt-r-gr-5 {
    grid-row: 5;
  }

  .lg\:rt-r-gr-6 {
    grid-row: 6;
  }

  .lg\:rt-r-gr-7 {
    grid-row: 7;
  }

  .lg\:rt-r-gr-8 {
    grid-row: 8;
  }

  .lg\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gr {
    grid-row: var(--grid-row-xl)
  }

  .xl\:rt-r-gr-1 {
    grid-row: 1;
  }

  .xl\:rt-r-gr-2 {
    grid-row: 2;
  }

  .xl\:rt-r-gr-3 {
    grid-row: 3;
  }

  .xl\:rt-r-gr-4 {
    grid-row: 4;
  }

  .xl\:rt-r-gr-5 {
    grid-row: 5;
  }

  .xl\:rt-r-gr-6 {
    grid-row: 6;
  }

  .xl\:rt-r-gr-7 {
    grid-row: 7;
  }

  .xl\:rt-r-gr-8 {
    grid-row: 8;
  }

  .xl\:rt-r-gr-9 {
    grid-row: 9;
  }
}

.rt-r-grs {
  grid-row-start: var(--grid-row-start)
}

.rt-r-grs-1 {
  grid-row-start: 1;
}

.rt-r-grs-2 {
  grid-row-start: 2;
}

.rt-r-grs-3 {
  grid-row-start: 3;
}

.rt-r-grs-4 {
  grid-row-start: 4;
}

.rt-r-grs-5 {
  grid-row-start: 5;
}

.rt-r-grs-6 {
  grid-row-start: 6;
}

.rt-r-grs-7 {
  grid-row-start: 7;
}

.rt-r-grs-8 {
  grid-row-start: 8;
}

.rt-r-grs-9 {
  grid-row-start: 9;
}

@media (min-width: 640px) {
  .xs\:rt-r-grs {
    grid-row-start: var(--grid-row-start-xs)
  }

  .xs\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .xs\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .xs\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .xs\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .xs\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .xs\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .xs\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .xs\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .xs\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-grs {
    grid-row-start: var(--grid-row-start-sm)
  }

  .sm\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .sm\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .sm\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .sm\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .sm\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .sm\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .sm\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .sm\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .sm\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-grs {
    grid-row-start: var(--grid-row-start-md)
  }

  .md\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .md\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .md\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .md\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .md\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .md\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .md\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .md\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .md\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-grs {
    grid-row-start: var(--grid-row-start-lg)
  }

  .lg\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .lg\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .lg\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .lg\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .lg\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .lg\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .lg\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .lg\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .lg\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-grs {
    grid-row-start: var(--grid-row-start-xl)
  }

  .xl\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .xl\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .xl\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .xl\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .xl\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .xl\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .xl\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .xl\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .xl\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

.rt-r-gre {
  grid-row-end: var(--grid-row-end)
}

.rt-r-gre-1 {
  grid-row-end: 1;
}

.rt-r-gre-2 {
  grid-row-end: 2;
}

.rt-r-gre-3 {
  grid-row-end: 3;
}

.rt-r-gre-4 {
  grid-row-end: 4;
}

.rt-r-gre-5 {
  grid-row-end: 5;
}

.rt-r-gre-6 {
  grid-row-end: 6;
}

.rt-r-gre-7 {
  grid-row-end: 7;
}

.rt-r-gre-8 {
  grid-row-end: 8;
}

.rt-r-gre-9 {
  grid-row-end: 9;
}

@media (min-width: 640px) {
  .xs\:rt-r-gre {
    grid-row-end: var(--grid-row-end-xs)
  }

  .xs\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .xs\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .xs\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .xs\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .xs\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .xs\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .xs\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .xs\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .xs\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gre {
    grid-row-end: var(--grid-row-end-sm)
  }

  .sm\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .sm\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .sm\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .sm\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .sm\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .sm\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .sm\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .sm\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .sm\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gre {
    grid-row-end: var(--grid-row-end-md)
  }

  .md\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .md\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .md\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .md\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .md\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .md\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .md\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .md\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .md\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gre {
    grid-row-end: var(--grid-row-end-lg)
  }

  .lg\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .lg\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .lg\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .lg\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .lg\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .lg\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .lg\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .lg\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .lg\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gre {
    grid-row-end: var(--grid-row-end-xl)
  }

  .xl\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .xl\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .xl\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .xl\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .xl\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .xl\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .xl\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .xl\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .xl\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

.rt-r-gta {
  grid-template-areas: var(--grid-template-areas);
}

@media (min-width: 640px) {
  .xs\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-lg);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-xl);
  }
}

.rt-r-gtc {
  grid-template-columns: var(--grid-template-columns)
}

.rt-r-gtc-1 {
  grid-template-columns: minmax(0, 1fr);
}

.rt-r-gtc-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.rt-r-gtc-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.rt-r-gtc-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.rt-r-gtc-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.rt-r-gtc-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.rt-r-gtc-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.rt-r-gtc-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.rt-r-gtc-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

@media (min-width: 640px) {
  .xs\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-xs)
  }

  .xs\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .xs\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-sm)
  }

  .sm\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .sm\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-md)
  }

  .md\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .md\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-lg)
  }

  .lg\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .lg\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-xl)
  }

  .xl\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .xl\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

.rt-r-gtr {
  grid-template-rows: var(--grid-template-rows)
}

.rt-r-gtr-1 {
  grid-template-rows: minmax(0, 1fr);
}

.rt-r-gtr-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.rt-r-gtr-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.rt-r-gtr-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.rt-r-gtr-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.rt-r-gtr-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.rt-r-gtr-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.rt-r-gtr-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.rt-r-gtr-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

@media (min-width: 640px) {
  .xs\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-xs)
  }

  .xs\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .xs\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-sm)
  }

  .sm\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .sm\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-md)
  }

  .md\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .md\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-lg)
  }

  .lg\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .lg\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-xl)
  }

  .xl\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .xl\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

.rt-r-h {
  height: var(--height);
}

@media (min-width: 640px) {
  .xs\:rt-r-h {
    height: var(--height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-h {
    height: var(--height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-h {
    height: var(--height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-h {
    height: var(--height-lg);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-h {
    height: var(--height-xl);
  }
}

.rt-r-min-h {
  min-height: var(--min-height);
}

@media (min-width: 640px) {
  .xs\:rt-r-min-h {
    min-height: var(--min-height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-min-h {
    min-height: var(--min-height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-min-h {
    min-height: var(--min-height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-min-h {
    min-height: var(--min-height-lg);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-min-h {
    min-height: var(--min-height-xl);
  }
}

.rt-r-max-h {
  max-height: var(--max-height);
}

@media (min-width: 640px) {
  .xs\:rt-r-max-h {
    max-height: var(--max-height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-max-h {
    max-height: var(--max-height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-max-h {
    max-height: var(--max-height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-max-h {
    max-height: var(--max-height-lg);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-max-h {
    max-height: var(--max-height-xl);
  }
}

.rt-r-inset {
  top: var(--inset);
  right: var(--inset);
  bottom: var(--inset);
  left: var(--inset)
}

.rt-r-inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.rt-r-inset-1 {
  top: var(--space-1);
  right: var(--space-1);
  bottom: var(--space-1);
  left: var(--space-1);
}

.rt-r-inset-2 {
  top: var(--space-2);
  right: var(--space-2);
  bottom: var(--space-2);
  left: var(--space-2);
}

.rt-r-inset-3 {
  top: var(--space-3);
  right: var(--space-3);
  bottom: var(--space-3);
  left: var(--space-3);
}

.rt-r-inset-4 {
  top: var(--space-4);
  right: var(--space-4);
  bottom: var(--space-4);
  left: var(--space-4);
}

.rt-r-inset-5 {
  top: var(--space-5);
  right: var(--space-5);
  bottom: var(--space-5);
  left: var(--space-5);
}

.rt-r-inset-6 {
  top: var(--space-6);
  right: var(--space-6);
  bottom: var(--space-6);
  left: var(--space-6);
}

.rt-r-inset-7 {
  top: var(--space-7);
  right: var(--space-7);
  bottom: var(--space-7);
  left: var(--space-7);
}

.rt-r-inset-8 {
  top: var(--space-8);
  right: var(--space-8);
  bottom: var(--space-8);
  left: var(--space-8);
}

.rt-r-inset-9 {
  top: var(--space-9);
  right: var(--space-9);
  bottom: var(--space-9);
  left: var(--space-9);
}

@media (min-width: 640px) {
  .xs\:rt-r-inset {
    top: var(--inset-xs);
    right: var(--inset-xs);
    bottom: var(--inset-xs);
    left: var(--inset-xs)
  }

  .xs\:rt-r-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .xs\:rt-r-inset-1 {
    top: var(--space-1);
    right: var(--space-1);
    bottom: var(--space-1);
    left: var(--space-1);
  }

  .xs\:rt-r-inset-2 {
    top: var(--space-2);
    right: var(--space-2);
    bottom: var(--space-2);
    left: var(--space-2);
  }

  .xs\:rt-r-inset-3 {
    top: var(--space-3);
    right: var(--space-3);
    bottom: var(--space-3);
    left: var(--space-3);
  }

  .xs\:rt-r-inset-4 {
    top: var(--space-4);
    right: var(--space-4);
    bottom: var(--space-4);
    left: var(--space-4);
  }

  .xs\:rt-r-inset-5 {
    top: var(--space-5);
    right: var(--space-5);
    bottom: var(--space-5);
    left: var(--space-5);
  }

  .xs\:rt-r-inset-6 {
    top: var(--space-6);
    right: var(--space-6);
    bottom: var(--space-6);
    left: var(--space-6);
  }

  .xs\:rt-r-inset-7 {
    top: var(--space-7);
    right: var(--space-7);
    bottom: var(--space-7);
    left: var(--space-7);
  }

  .xs\:rt-r-inset-8 {
    top: var(--space-8);
    right: var(--space-8);
    bottom: var(--space-8);
    left: var(--space-8);
  }

  .xs\:rt-r-inset-9 {
    top: var(--space-9);
    right: var(--space-9);
    bottom: var(--space-9);
    left: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-inset {
    top: var(--inset-sm);
    right: var(--inset-sm);
    bottom: var(--inset-sm);
    left: var(--inset-sm)
  }

  .sm\:rt-r-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sm\:rt-r-inset-1 {
    top: var(--space-1);
    right: var(--space-1);
    bottom: var(--space-1);
    left: var(--space-1);
  }

  .sm\:rt-r-inset-2 {
    top: var(--space-2);
    right: var(--space-2);
    bottom: var(--space-2);
    left: var(--space-2);
  }

  .sm\:rt-r-inset-3 {
    top: var(--space-3);
    right: var(--space-3);
    bottom: var(--space-3);
    left: var(--space-3);
  }

  .sm\:rt-r-inset-4 {
    top: var(--space-4);
    right: var(--space-4);
    bottom: var(--space-4);
    left: var(--space-4);
  }

  .sm\:rt-r-inset-5 {
    top: var(--space-5);
    right: var(--space-5);
    bottom: var(--space-5);
    left: var(--space-5);
  }

  .sm\:rt-r-inset-6 {
    top: var(--space-6);
    right: var(--space-6);
    bottom: var(--space-6);
    left: var(--space-6);
  }

  .sm\:rt-r-inset-7 {
    top: var(--space-7);
    right: var(--space-7);
    bottom: var(--space-7);
    left: var(--space-7);
  }

  .sm\:rt-r-inset-8 {
    top: var(--space-8);
    right: var(--space-8);
    bottom: var(--space-8);
    left: var(--space-8);
  }

  .sm\:rt-r-inset-9 {
    top: var(--space-9);
    right: var(--space-9);
    bottom: var(--space-9);
    left: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-inset {
    top: var(--inset-md);
    right: var(--inset-md);
    bottom: var(--inset-md);
    left: var(--inset-md)
  }

  .md\:rt-r-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .md\:rt-r-inset-1 {
    top: var(--space-1);
    right: var(--space-1);
    bottom: var(--space-1);
    left: var(--space-1);
  }

  .md\:rt-r-inset-2 {
    top: var(--space-2);
    right: var(--space-2);
    bottom: var(--space-2);
    left: var(--space-2);
  }

  .md\:rt-r-inset-3 {
    top: var(--space-3);
    right: var(--space-3);
    bottom: var(--space-3);
    left: var(--space-3);
  }

  .md\:rt-r-inset-4 {
    top: var(--space-4);
    right: var(--space-4);
    bottom: var(--space-4);
    left: var(--space-4);
  }

  .md\:rt-r-inset-5 {
    top: var(--space-5);
    right: var(--space-5);
    bottom: var(--space-5);
    left: var(--space-5);
  }

  .md\:rt-r-inset-6 {
    top: var(--space-6);
    right: var(--space-6);
    bottom: var(--space-6);
    left: var(--space-6);
  }

  .md\:rt-r-inset-7 {
    top: var(--space-7);
    right: var(--space-7);
    bottom: var(--space-7);
    left: var(--space-7);
  }

  .md\:rt-r-inset-8 {
    top: var(--space-8);
    right: var(--space-8);
    bottom: var(--space-8);
    left: var(--space-8);
  }

  .md\:rt-r-inset-9 {
    top: var(--space-9);
    right: var(--space-9);
    bottom: var(--space-9);
    left: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-inset {
    top: var(--inset-lg);
    right: var(--inset-lg);
    bottom: var(--inset-lg);
    left: var(--inset-lg)
  }

  .lg\:rt-r-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lg\:rt-r-inset-1 {
    top: var(--space-1);
    right: var(--space-1);
    bottom: var(--space-1);
    left: var(--space-1);
  }

  .lg\:rt-r-inset-2 {
    top: var(--space-2);
    right: var(--space-2);
    bottom: var(--space-2);
    left: var(--space-2);
  }

  .lg\:rt-r-inset-3 {
    top: var(--space-3);
    right: var(--space-3);
    bottom: var(--space-3);
    left: var(--space-3);
  }

  .lg\:rt-r-inset-4 {
    top: var(--space-4);
    right: var(--space-4);
    bottom: var(--space-4);
    left: var(--space-4);
  }

  .lg\:rt-r-inset-5 {
    top: var(--space-5);
    right: var(--space-5);
    bottom: var(--space-5);
    left: var(--space-5);
  }

  .lg\:rt-r-inset-6 {
    top: var(--space-6);
    right: var(--space-6);
    bottom: var(--space-6);
    left: var(--space-6);
  }

  .lg\:rt-r-inset-7 {
    top: var(--space-7);
    right: var(--space-7);
    bottom: var(--space-7);
    left: var(--space-7);
  }

  .lg\:rt-r-inset-8 {
    top: var(--space-8);
    right: var(--space-8);
    bottom: var(--space-8);
    left: var(--space-8);
  }

  .lg\:rt-r-inset-9 {
    top: var(--space-9);
    right: var(--space-9);
    bottom: var(--space-9);
    left: var(--space-9);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-inset {
    top: var(--inset-xl);
    right: var(--inset-xl);
    bottom: var(--inset-xl);
    left: var(--inset-xl)
  }

  .xl\:rt-r-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .xl\:rt-r-inset-1 {
    top: var(--space-1);
    right: var(--space-1);
    bottom: var(--space-1);
    left: var(--space-1);
  }

  .xl\:rt-r-inset-2 {
    top: var(--space-2);
    right: var(--space-2);
    bottom: var(--space-2);
    left: var(--space-2);
  }

  .xl\:rt-r-inset-3 {
    top: var(--space-3);
    right: var(--space-3);
    bottom: var(--space-3);
    left: var(--space-3);
  }

  .xl\:rt-r-inset-4 {
    top: var(--space-4);
    right: var(--space-4);
    bottom: var(--space-4);
    left: var(--space-4);
  }

  .xl\:rt-r-inset-5 {
    top: var(--space-5);
    right: var(--space-5);
    bottom: var(--space-5);
    left: var(--space-5);
  }

  .xl\:rt-r-inset-6 {
    top: var(--space-6);
    right: var(--space-6);
    bottom: var(--space-6);
    left: var(--space-6);
  }

  .xl\:rt-r-inset-7 {
    top: var(--space-7);
    right: var(--space-7);
    bottom: var(--space-7);
    left: var(--space-7);
  }

  .xl\:rt-r-inset-8 {
    top: var(--space-8);
    right: var(--space-8);
    bottom: var(--space-8);
    left: var(--space-8);
  }

  .xl\:rt-r-inset-9 {
    top: var(--space-9);
    right: var(--space-9);
    bottom: var(--space-9);
    left: var(--space-9);
  }
}

.rt-r-top {
  top: var(--top)
}

.rt-r-top-0 {
  top: 0;
}

.rt-r-top-1 {
  top: var(--space-1);
}

.rt-r-top-2 {
  top: var(--space-2);
}

.rt-r-top-3 {
  top: var(--space-3);
}

.rt-r-top-4 {
  top: var(--space-4);
}

.rt-r-top-5 {
  top: var(--space-5);
}

.rt-r-top-6 {
  top: var(--space-6);
}

.rt-r-top-7 {
  top: var(--space-7);
}

.rt-r-top-8 {
  top: var(--space-8);
}

.rt-r-top-9 {
  top: var(--space-9);
}

@media (min-width: 640px) {
  .xs\:rt-r-top {
    top: var(--top-xs)
  }

  .xs\:rt-r-top-0 {
    top: 0;
  }

  .xs\:rt-r-top-1 {
    top: var(--space-1);
  }

  .xs\:rt-r-top-2 {
    top: var(--space-2);
  }

  .xs\:rt-r-top-3 {
    top: var(--space-3);
  }

  .xs\:rt-r-top-4 {
    top: var(--space-4);
  }

  .xs\:rt-r-top-5 {
    top: var(--space-5);
  }

  .xs\:rt-r-top-6 {
    top: var(--space-6);
  }

  .xs\:rt-r-top-7 {
    top: var(--space-7);
  }

  .xs\:rt-r-top-8 {
    top: var(--space-8);
  }

  .xs\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-top {
    top: var(--top-sm)
  }

  .sm\:rt-r-top-0 {
    top: 0;
  }

  .sm\:rt-r-top-1 {
    top: var(--space-1);
  }

  .sm\:rt-r-top-2 {
    top: var(--space-2);
  }

  .sm\:rt-r-top-3 {
    top: var(--space-3);
  }

  .sm\:rt-r-top-4 {
    top: var(--space-4);
  }

  .sm\:rt-r-top-5 {
    top: var(--space-5);
  }

  .sm\:rt-r-top-6 {
    top: var(--space-6);
  }

  .sm\:rt-r-top-7 {
    top: var(--space-7);
  }

  .sm\:rt-r-top-8 {
    top: var(--space-8);
  }

  .sm\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-top {
    top: var(--top-md)
  }

  .md\:rt-r-top-0 {
    top: 0;
  }

  .md\:rt-r-top-1 {
    top: var(--space-1);
  }

  .md\:rt-r-top-2 {
    top: var(--space-2);
  }

  .md\:rt-r-top-3 {
    top: var(--space-3);
  }

  .md\:rt-r-top-4 {
    top: var(--space-4);
  }

  .md\:rt-r-top-5 {
    top: var(--space-5);
  }

  .md\:rt-r-top-6 {
    top: var(--space-6);
  }

  .md\:rt-r-top-7 {
    top: var(--space-7);
  }

  .md\:rt-r-top-8 {
    top: var(--space-8);
  }

  .md\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-top {
    top: var(--top-lg)
  }

  .lg\:rt-r-top-0 {
    top: 0;
  }

  .lg\:rt-r-top-1 {
    top: var(--space-1);
  }

  .lg\:rt-r-top-2 {
    top: var(--space-2);
  }

  .lg\:rt-r-top-3 {
    top: var(--space-3);
  }

  .lg\:rt-r-top-4 {
    top: var(--space-4);
  }

  .lg\:rt-r-top-5 {
    top: var(--space-5);
  }

  .lg\:rt-r-top-6 {
    top: var(--space-6);
  }

  .lg\:rt-r-top-7 {
    top: var(--space-7);
  }

  .lg\:rt-r-top-8 {
    top: var(--space-8);
  }

  .lg\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-top {
    top: var(--top-xl)
  }

  .xl\:rt-r-top-0 {
    top: 0;
  }

  .xl\:rt-r-top-1 {
    top: var(--space-1);
  }

  .xl\:rt-r-top-2 {
    top: var(--space-2);
  }

  .xl\:rt-r-top-3 {
    top: var(--space-3);
  }

  .xl\:rt-r-top-4 {
    top: var(--space-4);
  }

  .xl\:rt-r-top-5 {
    top: var(--space-5);
  }

  .xl\:rt-r-top-6 {
    top: var(--space-6);
  }

  .xl\:rt-r-top-7 {
    top: var(--space-7);
  }

  .xl\:rt-r-top-8 {
    top: var(--space-8);
  }

  .xl\:rt-r-top-9 {
    top: var(--space-9);
  }
}

.rt-r-right {
  right: var(--right)
}

.rt-r-right-0 {
  right: 0;
}

.rt-r-right-1 {
  right: var(--space-1);
}

.rt-r-right-2 {
  right: var(--space-2);
}

.rt-r-right-3 {
  right: var(--space-3);
}

.rt-r-right-4 {
  right: var(--space-4);
}

.rt-r-right-5 {
  right: var(--space-5);
}

.rt-r-right-6 {
  right: var(--space-6);
}

.rt-r-right-7 {
  right: var(--space-7);
}

.rt-r-right-8 {
  right: var(--space-8);
}

.rt-r-right-9 {
  right: var(--space-9);
}

@media (min-width: 640px) {
  .xs\:rt-r-right {
    right: var(--right-xs)
  }

  .xs\:rt-r-right-0 {
    right: 0;
  }

  .xs\:rt-r-right-1 {
    right: var(--space-1);
  }

  .xs\:rt-r-right-2 {
    right: var(--space-2);
  }

  .xs\:rt-r-right-3 {
    right: var(--space-3);
  }

  .xs\:rt-r-right-4 {
    right: var(--space-4);
  }

  .xs\:rt-r-right-5 {
    right: var(--space-5);
  }

  .xs\:rt-r-right-6 {
    right: var(--space-6);
  }

  .xs\:rt-r-right-7 {
    right: var(--space-7);
  }

  .xs\:rt-r-right-8 {
    right: var(--space-8);
  }

  .xs\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-right {
    right: var(--right-sm)
  }

  .sm\:rt-r-right-0 {
    right: 0;
  }

  .sm\:rt-r-right-1 {
    right: var(--space-1);
  }

  .sm\:rt-r-right-2 {
    right: var(--space-2);
  }

  .sm\:rt-r-right-3 {
    right: var(--space-3);
  }

  .sm\:rt-r-right-4 {
    right: var(--space-4);
  }

  .sm\:rt-r-right-5 {
    right: var(--space-5);
  }

  .sm\:rt-r-right-6 {
    right: var(--space-6);
  }

  .sm\:rt-r-right-7 {
    right: var(--space-7);
  }

  .sm\:rt-r-right-8 {
    right: var(--space-8);
  }

  .sm\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-right {
    right: var(--right-md)
  }

  .md\:rt-r-right-0 {
    right: 0;
  }

  .md\:rt-r-right-1 {
    right: var(--space-1);
  }

  .md\:rt-r-right-2 {
    right: var(--space-2);
  }

  .md\:rt-r-right-3 {
    right: var(--space-3);
  }

  .md\:rt-r-right-4 {
    right: var(--space-4);
  }

  .md\:rt-r-right-5 {
    right: var(--space-5);
  }

  .md\:rt-r-right-6 {
    right: var(--space-6);
  }

  .md\:rt-r-right-7 {
    right: var(--space-7);
  }

  .md\:rt-r-right-8 {
    right: var(--space-8);
  }

  .md\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-right {
    right: var(--right-lg)
  }

  .lg\:rt-r-right-0 {
    right: 0;
  }

  .lg\:rt-r-right-1 {
    right: var(--space-1);
  }

  .lg\:rt-r-right-2 {
    right: var(--space-2);
  }

  .lg\:rt-r-right-3 {
    right: var(--space-3);
  }

  .lg\:rt-r-right-4 {
    right: var(--space-4);
  }

  .lg\:rt-r-right-5 {
    right: var(--space-5);
  }

  .lg\:rt-r-right-6 {
    right: var(--space-6);
  }

  .lg\:rt-r-right-7 {
    right: var(--space-7);
  }

  .lg\:rt-r-right-8 {
    right: var(--space-8);
  }

  .lg\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-right {
    right: var(--right-xl)
  }

  .xl\:rt-r-right-0 {
    right: 0;
  }

  .xl\:rt-r-right-1 {
    right: var(--space-1);
  }

  .xl\:rt-r-right-2 {
    right: var(--space-2);
  }

  .xl\:rt-r-right-3 {
    right: var(--space-3);
  }

  .xl\:rt-r-right-4 {
    right: var(--space-4);
  }

  .xl\:rt-r-right-5 {
    right: var(--space-5);
  }

  .xl\:rt-r-right-6 {
    right: var(--space-6);
  }

  .xl\:rt-r-right-7 {
    right: var(--space-7);
  }

  .xl\:rt-r-right-8 {
    right: var(--space-8);
  }

  .xl\:rt-r-right-9 {
    right: var(--space-9);
  }
}

.rt-r-bottom {
  bottom: var(--bottom)
}

.rt-r-bottom-0 {
  bottom: 0;
}

.rt-r-bottom-1 {
  bottom: var(--space-1);
}

.rt-r-bottom-2 {
  bottom: var(--space-2);
}

.rt-r-bottom-3 {
  bottom: var(--space-3);
}

.rt-r-bottom-4 {
  bottom: var(--space-4);
}

.rt-r-bottom-5 {
  bottom: var(--space-5);
}

.rt-r-bottom-6 {
  bottom: var(--space-6);
}

.rt-r-bottom-7 {
  bottom: var(--space-7);
}

.rt-r-bottom-8 {
  bottom: var(--space-8);
}

.rt-r-bottom-9 {
  bottom: var(--space-9);
}

@media (min-width: 640px) {
  .xs\:rt-r-bottom {
    bottom: var(--bottom-xs)
  }

  .xs\:rt-r-bottom-0 {
    bottom: 0;
  }

  .xs\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .xs\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .xs\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .xs\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .xs\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .xs\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .xs\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .xs\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .xs\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-bottom {
    bottom: var(--bottom-sm)
  }

  .sm\:rt-r-bottom-0 {
    bottom: 0;
  }

  .sm\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .sm\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .sm\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .sm\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .sm\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .sm\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .sm\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .sm\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .sm\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-bottom {
    bottom: var(--bottom-md)
  }

  .md\:rt-r-bottom-0 {
    bottom: 0;
  }

  .md\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .md\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .md\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .md\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .md\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .md\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .md\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .md\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .md\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-bottom {
    bottom: var(--bottom-lg)
  }

  .lg\:rt-r-bottom-0 {
    bottom: 0;
  }

  .lg\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .lg\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .lg\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .lg\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .lg\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .lg\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .lg\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .lg\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .lg\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-bottom {
    bottom: var(--bottom-xl)
  }

  .xl\:rt-r-bottom-0 {
    bottom: 0;
  }

  .xl\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .xl\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .xl\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .xl\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .xl\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .xl\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .xl\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .xl\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .xl\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

.rt-r-left {
  left: var(--left)
}

.rt-r-left-0 {
  left: 0;
}

.rt-r-left-1 {
  left: var(--space-1);
}

.rt-r-left-2 {
  left: var(--space-2);
}

.rt-r-left-3 {
  left: var(--space-3);
}

.rt-r-left-4 {
  left: var(--space-4);
}

.rt-r-left-5 {
  left: var(--space-5);
}

.rt-r-left-6 {
  left: var(--space-6);
}

.rt-r-left-7 {
  left: var(--space-7);
}

.rt-r-left-8 {
  left: var(--space-8);
}

.rt-r-left-9 {
  left: var(--space-9);
}

@media (min-width: 640px) {
  .xs\:rt-r-left {
    left: var(--left-xs)
  }

  .xs\:rt-r-left-0 {
    left: 0;
  }

  .xs\:rt-r-left-1 {
    left: var(--space-1);
  }

  .xs\:rt-r-left-2 {
    left: var(--space-2);
  }

  .xs\:rt-r-left-3 {
    left: var(--space-3);
  }

  .xs\:rt-r-left-4 {
    left: var(--space-4);
  }

  .xs\:rt-r-left-5 {
    left: var(--space-5);
  }

  .xs\:rt-r-left-6 {
    left: var(--space-6);
  }

  .xs\:rt-r-left-7 {
    left: var(--space-7);
  }

  .xs\:rt-r-left-8 {
    left: var(--space-8);
  }

  .xs\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-left {
    left: var(--left-sm)
  }

  .sm\:rt-r-left-0 {
    left: 0;
  }

  .sm\:rt-r-left-1 {
    left: var(--space-1);
  }

  .sm\:rt-r-left-2 {
    left: var(--space-2);
  }

  .sm\:rt-r-left-3 {
    left: var(--space-3);
  }

  .sm\:rt-r-left-4 {
    left: var(--space-4);
  }

  .sm\:rt-r-left-5 {
    left: var(--space-5);
  }

  .sm\:rt-r-left-6 {
    left: var(--space-6);
  }

  .sm\:rt-r-left-7 {
    left: var(--space-7);
  }

  .sm\:rt-r-left-8 {
    left: var(--space-8);
  }

  .sm\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-left {
    left: var(--left-md)
  }

  .md\:rt-r-left-0 {
    left: 0;
  }

  .md\:rt-r-left-1 {
    left: var(--space-1);
  }

  .md\:rt-r-left-2 {
    left: var(--space-2);
  }

  .md\:rt-r-left-3 {
    left: var(--space-3);
  }

  .md\:rt-r-left-4 {
    left: var(--space-4);
  }

  .md\:rt-r-left-5 {
    left: var(--space-5);
  }

  .md\:rt-r-left-6 {
    left: var(--space-6);
  }

  .md\:rt-r-left-7 {
    left: var(--space-7);
  }

  .md\:rt-r-left-8 {
    left: var(--space-8);
  }

  .md\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-left {
    left: var(--left-lg)
  }

  .lg\:rt-r-left-0 {
    left: 0;
  }

  .lg\:rt-r-left-1 {
    left: var(--space-1);
  }

  .lg\:rt-r-left-2 {
    left: var(--space-2);
  }

  .lg\:rt-r-left-3 {
    left: var(--space-3);
  }

  .lg\:rt-r-left-4 {
    left: var(--space-4);
  }

  .lg\:rt-r-left-5 {
    left: var(--space-5);
  }

  .lg\:rt-r-left-6 {
    left: var(--space-6);
  }

  .lg\:rt-r-left-7 {
    left: var(--space-7);
  }

  .lg\:rt-r-left-8 {
    left: var(--space-8);
  }

  .lg\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-left {
    left: var(--left-xl)
  }

  .xl\:rt-r-left-0 {
    left: 0;
  }

  .xl\:rt-r-left-1 {
    left: var(--space-1);
  }

  .xl\:rt-r-left-2 {
    left: var(--space-2);
  }

  .xl\:rt-r-left-3 {
    left: var(--space-3);
  }

  .xl\:rt-r-left-4 {
    left: var(--space-4);
  }

  .xl\:rt-r-left-5 {
    left: var(--space-5);
  }

  .xl\:rt-r-left-6 {
    left: var(--space-6);
  }

  .xl\:rt-r-left-7 {
    left: var(--space-7);
  }

  .xl\:rt-r-left-8 {
    left: var(--space-8);
  }

  .xl\:rt-r-left-9 {
    left: var(--space-9);
  }
}

.rt-r-jc-start {
  justify-content: flex-start;
}

.rt-r-jc-center {
  justify-content: center;
}

.rt-r-jc-end {
  justify-content: flex-end;
}

.rt-r-jc-space-between {
  justify-content: space-between;
}

@media (min-width: 640px) {
  .xs\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .xs\:rt-r-jc-center {
    justify-content: center;
  }

  .xs\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .xs\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .sm\:rt-r-jc-center {
    justify-content: center;
  }

  .sm\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .sm\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .md\:rt-r-jc-center {
    justify-content: center;
  }

  .md\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .md\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .lg\:rt-r-jc-center {
    justify-content: center;
  }

  .lg\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .lg\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .xl\:rt-r-jc-center {
    justify-content: center;
  }

  .xl\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .xl\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

.rt-r-m,
.rt-r-m-0,
.rt-r-m-1,
.rt-r-m-2,
.rt-r-m-3,
.rt-r-m-4,
.rt-r-m-5,
.rt-r-m-6,
.rt-r-m-7,
.rt-r-m-8,
.rt-r-m-9,
.-rt-r-m-1,
.-rt-r-m-2,
.-rt-r-m-3,
.-rt-r-m-4,
.-rt-r-m-5,
.-rt-r-m-6,
.-rt-r-m-7,
.-rt-r-m-8,
.-rt-r-m-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
  margin-right: var(--margin-right-override, var(--margin-right));
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  margin-left: var(--margin-left-override, var(--margin-left));
}

.rt-r-m {
  --margin-top: var(--m);
  --margin-right: var(--m);
  --margin-bottom: var(--m);
  --margin-left: var(--m)

}

.rt-r-m-0 {
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
}

.rt-r-m-1 {
  --margin-top: var(--space-1);
  --margin-right: var(--space-1);
  --margin-bottom: var(--space-1);
  --margin-left: var(--space-1);
}

.rt-r-m-2 {
  --margin-top: var(--space-2);
  --margin-right: var(--space-2);
  --margin-bottom: var(--space-2);
  --margin-left: var(--space-2);
}

.rt-r-m-3 {
  --margin-top: var(--space-3);
  --margin-right: var(--space-3);
  --margin-bottom: var(--space-3);
  --margin-left: var(--space-3);
}

.rt-r-m-4 {
  --margin-top: var(--space-4);
  --margin-right: var(--space-4);
  --margin-bottom: var(--space-4);
  --margin-left: var(--space-4);
}

.rt-r-m-5 {
  --margin-top: var(--space-5);
  --margin-right: var(--space-5);
  --margin-bottom: var(--space-5);
  --margin-left: var(--space-5);
}

.rt-r-m-6 {
  --margin-top: var(--space-6);
  --margin-right: var(--space-6);
  --margin-bottom: var(--space-6);
  --margin-left: var(--space-6);
}

.rt-r-m-7 {
  --margin-top: var(--space-7);
  --margin-right: var(--space-7);
  --margin-bottom: var(--space-7);
  --margin-left: var(--space-7);
}

.rt-r-m-8 {
  --margin-top: var(--space-8);
  --margin-right: var(--space-8);
  --margin-bottom: var(--space-8);
  --margin-left: var(--space-8);
}

.rt-r-m-9 {
  --margin-top: var(--space-9);
  --margin-right: var(--space-9);
  --margin-bottom: var(--space-9);
  --margin-left: var(--space-9);
}

.-rt-r-m-1 {
  --margin-top: calc(-1 * var(--space-1));
  --margin-right: calc(-1 * var(--space-1));
  --margin-bottom: calc(-1 * var(--space-1));
  --margin-left: calc(-1 * var(--space-1));
}

.-rt-r-m-2 {
  --margin-top: calc(-1 * var(--space-2));
  --margin-right: calc(-1 * var(--space-2));
  --margin-bottom: calc(-1 * var(--space-2));
  --margin-left: calc(-1 * var(--space-2));
}

.-rt-r-m-3 {
  --margin-top: calc(-1 * var(--space-3));
  --margin-right: calc(-1 * var(--space-3));
  --margin-bottom: calc(-1 * var(--space-3));
  --margin-left: calc(-1 * var(--space-3));
}

.-rt-r-m-4 {
  --margin-top: calc(-1 * var(--space-4));
  --margin-right: calc(-1 * var(--space-4));
  --margin-bottom: calc(-1 * var(--space-4));
  --margin-left: calc(-1 * var(--space-4));
}

.-rt-r-m-5 {
  --margin-top: calc(-1 * var(--space-5));
  --margin-right: calc(-1 * var(--space-5));
  --margin-bottom: calc(-1 * var(--space-5));
  --margin-left: calc(-1 * var(--space-5));
}

.-rt-r-m-6 {
  --margin-top: calc(-1 * var(--space-6));
  --margin-right: calc(-1 * var(--space-6));
  --margin-bottom: calc(-1 * var(--space-6));
  --margin-left: calc(-1 * var(--space-6));
}

.-rt-r-m-7 {
  --margin-top: calc(-1 * var(--space-7));
  --margin-right: calc(-1 * var(--space-7));
  --margin-bottom: calc(-1 * var(--space-7));
  --margin-left: calc(-1 * var(--space-7));
}

.-rt-r-m-8 {
  --margin-top: calc(-1 * var(--space-8));
  --margin-right: calc(-1 * var(--space-8));
  --margin-bottom: calc(-1 * var(--space-8));
  --margin-left: calc(-1 * var(--space-8));
}

.-rt-r-m-9 {
  --margin-top: calc(-1 * var(--space-9));
  --margin-right: calc(-1 * var(--space-9));
  --margin-bottom: calc(-1 * var(--space-9));
  --margin-left: calc(-1 * var(--space-9));
}

@media (min-width: 640px) {
  .xs\:rt-r-m,
  .xs\:rt-r-m-0,
  .xs\:rt-r-m-1,
  .xs\:rt-r-m-2,
  .xs\:rt-r-m-3,
  .xs\:rt-r-m-4,
  .xs\:rt-r-m-5,
  .xs\:rt-r-m-6,
  .xs\:rt-r-m-7,
  .xs\:rt-r-m-8,
  .xs\:rt-r-m-9,
  .xs\:-rt-r-m-1,
  .xs\:-rt-r-m-2,
  .xs\:-rt-r-m-3,
  .xs\:-rt-r-m-4,
  .xs\:-rt-r-m-5,
  .xs\:-rt-r-m-6,
  .xs\:-rt-r-m-7,
  .xs\:-rt-r-m-8,
  .xs\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xs\:rt-r-m {
    --margin-top: var(--m-xs);
    --margin-right: var(--m-xs);
    --margin-bottom: var(--m-xs);
    --margin-left: var(--m-xs)
  
  }

  .xs\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .xs\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .xs\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .xs\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .xs\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .xs\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .xs\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .xs\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .xs\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .xs\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .xs\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-m,
  .sm\:rt-r-m-0,
  .sm\:rt-r-m-1,
  .sm\:rt-r-m-2,
  .sm\:rt-r-m-3,
  .sm\:rt-r-m-4,
  .sm\:rt-r-m-5,
  .sm\:rt-r-m-6,
  .sm\:rt-r-m-7,
  .sm\:rt-r-m-8,
  .sm\:rt-r-m-9,
  .sm\:-rt-r-m-1,
  .sm\:-rt-r-m-2,
  .sm\:-rt-r-m-3,
  .sm\:-rt-r-m-4,
  .sm\:-rt-r-m-5,
  .sm\:-rt-r-m-6,
  .sm\:-rt-r-m-7,
  .sm\:-rt-r-m-8,
  .sm\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .sm\:rt-r-m {
    --margin-top: var(--m-sm);
    --margin-right: var(--m-sm);
    --margin-bottom: var(--m-sm);
    --margin-left: var(--m-sm)
  
  }

  .sm\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .sm\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .sm\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .sm\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .sm\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .sm\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .sm\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .sm\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .sm\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .sm\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .sm\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-m,
  .md\:rt-r-m-0,
  .md\:rt-r-m-1,
  .md\:rt-r-m-2,
  .md\:rt-r-m-3,
  .md\:rt-r-m-4,
  .md\:rt-r-m-5,
  .md\:rt-r-m-6,
  .md\:rt-r-m-7,
  .md\:rt-r-m-8,
  .md\:rt-r-m-9,
  .md\:-rt-r-m-1,
  .md\:-rt-r-m-2,
  .md\:-rt-r-m-3,
  .md\:-rt-r-m-4,
  .md\:-rt-r-m-5,
  .md\:-rt-r-m-6,
  .md\:-rt-r-m-7,
  .md\:-rt-r-m-8,
  .md\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .md\:rt-r-m {
    --margin-top: var(--m-md);
    --margin-right: var(--m-md);
    --margin-bottom: var(--m-md);
    --margin-left: var(--m-md)
  
  }

  .md\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .md\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .md\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .md\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .md\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .md\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .md\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .md\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .md\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .md\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .md\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-m,
  .lg\:rt-r-m-0,
  .lg\:rt-r-m-1,
  .lg\:rt-r-m-2,
  .lg\:rt-r-m-3,
  .lg\:rt-r-m-4,
  .lg\:rt-r-m-5,
  .lg\:rt-r-m-6,
  .lg\:rt-r-m-7,
  .lg\:rt-r-m-8,
  .lg\:rt-r-m-9,
  .lg\:-rt-r-m-1,
  .lg\:-rt-r-m-2,
  .lg\:-rt-r-m-3,
  .lg\:-rt-r-m-4,
  .lg\:-rt-r-m-5,
  .lg\:-rt-r-m-6,
  .lg\:-rt-r-m-7,
  .lg\:-rt-r-m-8,
  .lg\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .lg\:rt-r-m {
    --margin-top: var(--m-lg);
    --margin-right: var(--m-lg);
    --margin-bottom: var(--m-lg);
    --margin-left: var(--m-lg)
  
  }

  .lg\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .lg\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .lg\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .lg\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .lg\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .lg\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .lg\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .lg\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .lg\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .lg\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .lg\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-m,
  .xl\:rt-r-m-0,
  .xl\:rt-r-m-1,
  .xl\:rt-r-m-2,
  .xl\:rt-r-m-3,
  .xl\:rt-r-m-4,
  .xl\:rt-r-m-5,
  .xl\:rt-r-m-6,
  .xl\:rt-r-m-7,
  .xl\:rt-r-m-8,
  .xl\:rt-r-m-9,
  .xl\:-rt-r-m-1,
  .xl\:-rt-r-m-2,
  .xl\:-rt-r-m-3,
  .xl\:-rt-r-m-4,
  .xl\:-rt-r-m-5,
  .xl\:-rt-r-m-6,
  .xl\:-rt-r-m-7,
  .xl\:-rt-r-m-8,
  .xl\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xl\:rt-r-m {
    --margin-top: var(--m-xl);
    --margin-right: var(--m-xl);
    --margin-bottom: var(--m-xl);
    --margin-left: var(--m-xl)
  
  }

  .xl\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .xl\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .xl\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .xl\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .xl\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .xl\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .xl\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .xl\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .xl\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .xl\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .xl\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

.rt-r-mx,
.rt-r-mx-0,
.rt-r-mx-1,
.rt-r-mx-2,
.rt-r-mx-3,
.rt-r-mx-4,
.rt-r-mx-5,
.rt-r-mx-6,
.rt-r-mx-7,
.rt-r-mx-8,
.rt-r-mx-9,
.-rt-r-mx-1,
.-rt-r-mx-2,
.-rt-r-mx-3,
.-rt-r-mx-4,
.-rt-r-mx-5,
.-rt-r-mx-6,
.-rt-r-mx-7,
.-rt-r-mx-8,
.-rt-r-mx-9 {
  margin-left: var(--margin-left-override, var(--margin-left));
  margin-right: var(--margin-right-override, var(--margin-right));
}

.rt-r-mx {
  --margin-left: var(--ml);
  --margin-right: var(--mr)

}

.rt-r-mx-0 {
  --margin-left: 0px;
  --margin-right: 0px;
}

.rt-r-mx-1 {
  --margin-left: var(--space-1);
  --margin-right: var(--space-1);
}

.rt-r-mx-2 {
  --margin-left: var(--space-2);
  --margin-right: var(--space-2);
}

.rt-r-mx-3 {
  --margin-left: var(--space-3);
  --margin-right: var(--space-3);
}

.rt-r-mx-4 {
  --margin-left: var(--space-4);
  --margin-right: var(--space-4);
}

.rt-r-mx-5 {
  --margin-left: var(--space-5);
  --margin-right: var(--space-5);
}

.rt-r-mx-6 {
  --margin-left: var(--space-6);
  --margin-right: var(--space-6);
}

.rt-r-mx-7 {
  --margin-left: var(--space-7);
  --margin-right: var(--space-7);
}

.rt-r-mx-8 {
  --margin-left: var(--space-8);
  --margin-right: var(--space-8);
}

.rt-r-mx-9 {
  --margin-left: var(--space-9);
  --margin-right: var(--space-9);
}

.-rt-r-mx-1 {
  --margin-left: calc(-1 * var(--space-1));
  --margin-right: calc(-1 * var(--space-1));
}

.-rt-r-mx-2 {
  --margin-left: calc(-1 * var(--space-2));
  --margin-right: calc(-1 * var(--space-2));
}

.-rt-r-mx-3 {
  --margin-left: calc(-1 * var(--space-3));
  --margin-right: calc(-1 * var(--space-3));
}

.-rt-r-mx-4 {
  --margin-left: calc(-1 * var(--space-4));
  --margin-right: calc(-1 * var(--space-4));
}

.-rt-r-mx-5 {
  --margin-left: calc(-1 * var(--space-5));
  --margin-right: calc(-1 * var(--space-5));
}

.-rt-r-mx-6 {
  --margin-left: calc(-1 * var(--space-6));
  --margin-right: calc(-1 * var(--space-6));
}

.-rt-r-mx-7 {
  --margin-left: calc(-1 * var(--space-7));
  --margin-right: calc(-1 * var(--space-7));
}

.-rt-r-mx-8 {
  --margin-left: calc(-1 * var(--space-8));
  --margin-right: calc(-1 * var(--space-8));
}

.-rt-r-mx-9 {
  --margin-left: calc(-1 * var(--space-9));
  --margin-right: calc(-1 * var(--space-9));
}

@media (min-width: 640px) {
  .xs\:rt-r-mx,
  .xs\:rt-r-mx-0,
  .xs\:rt-r-mx-1,
  .xs\:rt-r-mx-2,
  .xs\:rt-r-mx-3,
  .xs\:rt-r-mx-4,
  .xs\:rt-r-mx-5,
  .xs\:rt-r-mx-6,
  .xs\:rt-r-mx-7,
  .xs\:rt-r-mx-8,
  .xs\:rt-r-mx-9,
  .xs\:-rt-r-mx-1,
  .xs\:-rt-r-mx-2,
  .xs\:-rt-r-mx-3,
  .xs\:-rt-r-mx-4,
  .xs\:-rt-r-mx-5,
  .xs\:-rt-r-mx-6,
  .xs\:-rt-r-mx-7,
  .xs\:-rt-r-mx-8,
  .xs\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xs\:rt-r-mx {
    --margin-left: var(--ml-xs);
    --margin-right: var(--mr-xs)
  
  }

  .xs\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .xs\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .xs\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .xs\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .xs\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .xs\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .xs\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .xs\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .xs\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .xs\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .xs\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mx,
  .sm\:rt-r-mx-0,
  .sm\:rt-r-mx-1,
  .sm\:rt-r-mx-2,
  .sm\:rt-r-mx-3,
  .sm\:rt-r-mx-4,
  .sm\:rt-r-mx-5,
  .sm\:rt-r-mx-6,
  .sm\:rt-r-mx-7,
  .sm\:rt-r-mx-8,
  .sm\:rt-r-mx-9,
  .sm\:-rt-r-mx-1,
  .sm\:-rt-r-mx-2,
  .sm\:-rt-r-mx-3,
  .sm\:-rt-r-mx-4,
  .sm\:-rt-r-mx-5,
  .sm\:-rt-r-mx-6,
  .sm\:-rt-r-mx-7,
  .sm\:-rt-r-mx-8,
  .sm\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .sm\:rt-r-mx {
    --margin-left: var(--ml-md);
    --margin-right: var(--mr-md)
  
  }

  .sm\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .sm\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .sm\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .sm\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .sm\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .sm\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .sm\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .sm\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .sm\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .sm\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .sm\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mx,
  .md\:rt-r-mx-0,
  .md\:rt-r-mx-1,
  .md\:rt-r-mx-2,
  .md\:rt-r-mx-3,
  .md\:rt-r-mx-4,
  .md\:rt-r-mx-5,
  .md\:rt-r-mx-6,
  .md\:rt-r-mx-7,
  .md\:rt-r-mx-8,
  .md\:rt-r-mx-9,
  .md\:-rt-r-mx-1,
  .md\:-rt-r-mx-2,
  .md\:-rt-r-mx-3,
  .md\:-rt-r-mx-4,
  .md\:-rt-r-mx-5,
  .md\:-rt-r-mx-6,
  .md\:-rt-r-mx-7,
  .md\:-rt-r-mx-8,
  .md\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .md\:rt-r-mx {
    --margin-left: var(--ml-md);
    --margin-right: var(--mr-md)
  
  }

  .md\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .md\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .md\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .md\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .md\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .md\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .md\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .md\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .md\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .md\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .md\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mx,
  .lg\:rt-r-mx-0,
  .lg\:rt-r-mx-1,
  .lg\:rt-r-mx-2,
  .lg\:rt-r-mx-3,
  .lg\:rt-r-mx-4,
  .lg\:rt-r-mx-5,
  .lg\:rt-r-mx-6,
  .lg\:rt-r-mx-7,
  .lg\:rt-r-mx-8,
  .lg\:rt-r-mx-9,
  .lg\:-rt-r-mx-1,
  .lg\:-rt-r-mx-2,
  .lg\:-rt-r-mx-3,
  .lg\:-rt-r-mx-4,
  .lg\:-rt-r-mx-5,
  .lg\:-rt-r-mx-6,
  .lg\:-rt-r-mx-7,
  .lg\:-rt-r-mx-8,
  .lg\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .lg\:rt-r-mx {
    --margin-left: var(--ml-lg);
    --margin-right: var(--mr-lg)
  
  }

  .lg\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .lg\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .lg\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .lg\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .lg\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .lg\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .lg\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .lg\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .lg\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .lg\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .lg\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-mx,
  .xl\:rt-r-mx-0,
  .xl\:rt-r-mx-1,
  .xl\:rt-r-mx-2,
  .xl\:rt-r-mx-3,
  .xl\:rt-r-mx-4,
  .xl\:rt-r-mx-5,
  .xl\:rt-r-mx-6,
  .xl\:rt-r-mx-7,
  .xl\:rt-r-mx-8,
  .xl\:rt-r-mx-9,
  .xl\:-rt-r-mx-1,
  .xl\:-rt-r-mx-2,
  .xl\:-rt-r-mx-3,
  .xl\:-rt-r-mx-4,
  .xl\:-rt-r-mx-5,
  .xl\:-rt-r-mx-6,
  .xl\:-rt-r-mx-7,
  .xl\:-rt-r-mx-8,
  .xl\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xl\:rt-r-mx {
    --margin-left: var(--ml-xl);
    --margin-right: var(--mr-xl)
  
  }

  .xl\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .xl\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .xl\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .xl\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .xl\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .xl\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .xl\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .xl\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .xl\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .xl\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .xl\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

.rt-r-my,
.rt-r-my-0,
.rt-r-my-1,
.rt-r-my-2,
.rt-r-my-3,
.rt-r-my-4,
.rt-r-my-5,
.rt-r-my-6,
.rt-r-my-7,
.rt-r-my-8,
.rt-r-my-9,
.-rt-r-my-1,
.-rt-r-my-2,
.-rt-r-my-3,
.-rt-r-my-4,
.-rt-r-my-5,
.-rt-r-my-6,
.-rt-r-my-7,
.-rt-r-my-8,
.-rt-r-my-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
}

.rt-r-my {
  --margin-top: var(--mt);
  --margin-bottom: var(--mb)

}

.rt-r-my-0 {
  --margin-top: 0px;
  --margin-bottom: 0px;
}

.rt-r-my-1 {
  --margin-top: var(--space-1);
  --margin-bottom: var(--space-1);
}

.rt-r-my-2 {
  --margin-top: var(--space-2);
  --margin-bottom: var(--space-2);
}

.rt-r-my-3 {
  --margin-top: var(--space-3);
  --margin-bottom: var(--space-3);
}

.rt-r-my-4 {
  --margin-top: var(--space-4);
  --margin-bottom: var(--space-4);
}

.rt-r-my-5 {
  --margin-top: var(--space-5);
  --margin-bottom: var(--space-5);
}

.rt-r-my-6 {
  --margin-top: var(--space-6);
  --margin-bottom: var(--space-6);
}

.rt-r-my-7 {
  --margin-top: var(--space-7);
  --margin-bottom: var(--space-7);
}

.rt-r-my-8 {
  --margin-top: var(--space-8);
  --margin-bottom: var(--space-8);
}

.rt-r-my-9 {
  --margin-top: var(--space-9);
  --margin-bottom: var(--space-9);
}

.-rt-r-my-1 {
  --margin-top: calc(-1 * var(--space-1));
  --margin-bottom: calc(-1 * var(--space-1));
}

.-rt-r-my-2 {
  --margin-top: calc(-1 * var(--space-2));
  --margin-bottom: calc(-1 * var(--space-2));
}

.-rt-r-my-3 {
  --margin-top: calc(-1 * var(--space-3));
  --margin-bottom: calc(-1 * var(--space-3));
}

.-rt-r-my-4 {
  --margin-top: calc(-1 * var(--space-4));
  --margin-bottom: calc(-1 * var(--space-4));
}

.-rt-r-my-5 {
  --margin-top: calc(-1 * var(--space-5));
  --margin-bottom: calc(-1 * var(--space-5));
}

.-rt-r-my-6 {
  --margin-top: calc(-1 * var(--space-6));
  --margin-bottom: calc(-1 * var(--space-6));
}

.-rt-r-my-7 {
  --margin-top: calc(-1 * var(--space-7));
  --margin-bottom: calc(-1 * var(--space-7));
}

.-rt-r-my-8 {
  --margin-top: calc(-1 * var(--space-8));
  --margin-bottom: calc(-1 * var(--space-8));
}

.-rt-r-my-9 {
  --margin-top: calc(-1 * var(--space-9));
  --margin-bottom: calc(-1 * var(--space-9));
}

@media (min-width: 640px) {
  .xs\:rt-r-my,
  .xs\:rt-r-my-0,
  .xs\:rt-r-my-1,
  .xs\:rt-r-my-2,
  .xs\:rt-r-my-3,
  .xs\:rt-r-my-4,
  .xs\:rt-r-my-5,
  .xs\:rt-r-my-6,
  .xs\:rt-r-my-7,
  .xs\:rt-r-my-8,
  .xs\:rt-r-my-9,
  .xs\:-rt-r-my-1,
  .xs\:-rt-r-my-2,
  .xs\:-rt-r-my-3,
  .xs\:-rt-r-my-4,
  .xs\:-rt-r-my-5,
  .xs\:-rt-r-my-6,
  .xs\:-rt-r-my-7,
  .xs\:-rt-r-my-8,
  .xs\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xs\:rt-r-my {
    --margin-top: var(--mt-xs);
    --margin-bottom: var(--mb-xs)
  
  }

  .xs\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .xs\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .xs\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .xs\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .xs\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .xs\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .xs\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .xs\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .xs\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .xs\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .xs\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-my,
  .sm\:rt-r-my-0,
  .sm\:rt-r-my-1,
  .sm\:rt-r-my-2,
  .sm\:rt-r-my-3,
  .sm\:rt-r-my-4,
  .sm\:rt-r-my-5,
  .sm\:rt-r-my-6,
  .sm\:rt-r-my-7,
  .sm\:rt-r-my-8,
  .sm\:rt-r-my-9,
  .sm\:-rt-r-my-1,
  .sm\:-rt-r-my-2,
  .sm\:-rt-r-my-3,
  .sm\:-rt-r-my-4,
  .sm\:-rt-r-my-5,
  .sm\:-rt-r-my-6,
  .sm\:-rt-r-my-7,
  .sm\:-rt-r-my-8,
  .sm\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .sm\:rt-r-my {
    --margin-top: var(--mt-sm);
    --margin-bottom: var(--mb-sm)
  
  }

  .sm\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .sm\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .sm\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .sm\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .sm\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .sm\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .sm\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .sm\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .sm\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .sm\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .sm\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-my,
  .md\:rt-r-my-0,
  .md\:rt-r-my-1,
  .md\:rt-r-my-2,
  .md\:rt-r-my-3,
  .md\:rt-r-my-4,
  .md\:rt-r-my-5,
  .md\:rt-r-my-6,
  .md\:rt-r-my-7,
  .md\:rt-r-my-8,
  .md\:rt-r-my-9,
  .md\:-rt-r-my-1,
  .md\:-rt-r-my-2,
  .md\:-rt-r-my-3,
  .md\:-rt-r-my-4,
  .md\:-rt-r-my-5,
  .md\:-rt-r-my-6,
  .md\:-rt-r-my-7,
  .md\:-rt-r-my-8,
  .md\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .md\:rt-r-my {
    --margin-top: var(--mt-md);
    --margin-bottom: var(--mb-md)
  
  }

  .md\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .md\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .md\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .md\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .md\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .md\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .md\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .md\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .md\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .md\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .md\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-my,
  .lg\:rt-r-my-0,
  .lg\:rt-r-my-1,
  .lg\:rt-r-my-2,
  .lg\:rt-r-my-3,
  .lg\:rt-r-my-4,
  .lg\:rt-r-my-5,
  .lg\:rt-r-my-6,
  .lg\:rt-r-my-7,
  .lg\:rt-r-my-8,
  .lg\:rt-r-my-9,
  .lg\:-rt-r-my-1,
  .lg\:-rt-r-my-2,
  .lg\:-rt-r-my-3,
  .lg\:-rt-r-my-4,
  .lg\:-rt-r-my-5,
  .lg\:-rt-r-my-6,
  .lg\:-rt-r-my-7,
  .lg\:-rt-r-my-8,
  .lg\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .lg\:rt-r-my {
    --margin-top: var(--mt-lg);
    --margin-bottom: var(--mb-lg)
  
  }

  .lg\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .lg\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .lg\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .lg\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .lg\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .lg\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .lg\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .lg\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .lg\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .lg\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .lg\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-my,
  .xl\:rt-r-my-0,
  .xl\:rt-r-my-1,
  .xl\:rt-r-my-2,
  .xl\:rt-r-my-3,
  .xl\:rt-r-my-4,
  .xl\:rt-r-my-5,
  .xl\:rt-r-my-6,
  .xl\:rt-r-my-7,
  .xl\:rt-r-my-8,
  .xl\:rt-r-my-9,
  .xl\:-rt-r-my-1,
  .xl\:-rt-r-my-2,
  .xl\:-rt-r-my-3,
  .xl\:-rt-r-my-4,
  .xl\:-rt-r-my-5,
  .xl\:-rt-r-my-6,
  .xl\:-rt-r-my-7,
  .xl\:-rt-r-my-8,
  .xl\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xl\:rt-r-my {
    --margin-top: var(--mt-xl);
    --margin-bottom: var(--mb-xl)
  
  }

  .xl\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .xl\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .xl\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .xl\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .xl\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .xl\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .xl\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .xl\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .xl\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .xl\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .xl\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

.rt-r-mt,
.rt-r-mt-0,
.rt-r-mt-1,
.rt-r-mt-2,
.rt-r-mt-3,
.rt-r-mt-4,
.rt-r-mt-5,
.rt-r-mt-6,
.rt-r-mt-7,
.rt-r-mt-8,
.rt-r-mt-9,
.-rt-r-mt-1,
.-rt-r-mt-2,
.-rt-r-mt-3,
.-rt-r-mt-4,
.-rt-r-mt-5,
.-rt-r-mt-6,
.-rt-r-mt-7,
.-rt-r-mt-8,
.-rt-r-mt-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
}

.rt-r-mt {
  --margin-top: var(--mt)

}

.rt-r-mt-0 {
  --margin-top: 0px;
}

.rt-r-mt-1 {
  --margin-top: var(--space-1);
}

.rt-r-mt-2 {
  --margin-top: var(--space-2);
}

.rt-r-mt-3 {
  --margin-top: var(--space-3);
}

.rt-r-mt-4 {
  --margin-top: var(--space-4);
}

.rt-r-mt-5 {
  --margin-top: var(--space-5);
}

.rt-r-mt-6 {
  --margin-top: var(--space-6);
}

.rt-r-mt-7 {
  --margin-top: var(--space-7);
}

.rt-r-mt-8 {
  --margin-top: var(--space-8);
}

.rt-r-mt-9 {
  --margin-top: var(--space-9);
}

.-rt-r-mt-1 {
  --margin-top: calc(-1 * var(--space-1));
}

.-rt-r-mt-2 {
  --margin-top: calc(-1 * var(--space-2));
}

.-rt-r-mt-3 {
  --margin-top: calc(-1 * var(--space-3));
}

.-rt-r-mt-4 {
  --margin-top: calc(-1 * var(--space-4));
}

.-rt-r-mt-5 {
  --margin-top: calc(-1 * var(--space-5));
}

.-rt-r-mt-6 {
  --margin-top: calc(-1 * var(--space-6));
}

.-rt-r-mt-7 {
  --margin-top: calc(-1 * var(--space-7));
}

.-rt-r-mt-8 {
  --margin-top: calc(-1 * var(--space-8));
}

.-rt-r-mt-9 {
  --margin-top: calc(-1 * var(--space-9));
}

@media (min-width: 640px) {
  .xs\:rt-r-mt,
  .xs\:rt-r-mt-0,
  .xs\:rt-r-mt-1,
  .xs\:rt-r-mt-2,
  .xs\:rt-r-mt-3,
  .xs\:rt-r-mt-4,
  .xs\:rt-r-mt-5,
  .xs\:rt-r-mt-6,
  .xs\:rt-r-mt-7,
  .xs\:rt-r-mt-8,
  .xs\:rt-r-mt-9,
  .xs\:-rt-r-mt-1,
  .xs\:-rt-r-mt-2,
  .xs\:-rt-r-mt-3,
  .xs\:-rt-r-mt-4,
  .xs\:-rt-r-mt-5,
  .xs\:-rt-r-mt-6,
  .xs\:-rt-r-mt-7,
  .xs\:-rt-r-mt-8,
  .xs\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .xs\:rt-r-mt {
    --margin-top: var(--mt-xs)
  
  }

  .xs\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .xs\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .xs\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .xs\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .xs\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .xs\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .xs\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .xs\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .xs\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .xs\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .xs\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mt,
  .sm\:rt-r-mt-0,
  .sm\:rt-r-mt-1,
  .sm\:rt-r-mt-2,
  .sm\:rt-r-mt-3,
  .sm\:rt-r-mt-4,
  .sm\:rt-r-mt-5,
  .sm\:rt-r-mt-6,
  .sm\:rt-r-mt-7,
  .sm\:rt-r-mt-8,
  .sm\:rt-r-mt-9,
  .sm\:-rt-r-mt-1,
  .sm\:-rt-r-mt-2,
  .sm\:-rt-r-mt-3,
  .sm\:-rt-r-mt-4,
  .sm\:-rt-r-mt-5,
  .sm\:-rt-r-mt-6,
  .sm\:-rt-r-mt-7,
  .sm\:-rt-r-mt-8,
  .sm\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .sm\:rt-r-mt {
    --margin-top: var(--mt-sm)
  
  }

  .sm\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .sm\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .sm\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .sm\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .sm\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .sm\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .sm\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .sm\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .sm\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .sm\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .sm\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mt,
  .md\:rt-r-mt-0,
  .md\:rt-r-mt-1,
  .md\:rt-r-mt-2,
  .md\:rt-r-mt-3,
  .md\:rt-r-mt-4,
  .md\:rt-r-mt-5,
  .md\:rt-r-mt-6,
  .md\:rt-r-mt-7,
  .md\:rt-r-mt-8,
  .md\:rt-r-mt-9,
  .md\:-rt-r-mt-1,
  .md\:-rt-r-mt-2,
  .md\:-rt-r-mt-3,
  .md\:-rt-r-mt-4,
  .md\:-rt-r-mt-5,
  .md\:-rt-r-mt-6,
  .md\:-rt-r-mt-7,
  .md\:-rt-r-mt-8,
  .md\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .md\:rt-r-mt {
    --margin-top: var(--mt-md)
  
  }

  .md\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .md\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .md\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .md\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .md\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .md\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .md\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .md\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .md\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .md\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .md\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mt,
  .lg\:rt-r-mt-0,
  .lg\:rt-r-mt-1,
  .lg\:rt-r-mt-2,
  .lg\:rt-r-mt-3,
  .lg\:rt-r-mt-4,
  .lg\:rt-r-mt-5,
  .lg\:rt-r-mt-6,
  .lg\:rt-r-mt-7,
  .lg\:rt-r-mt-8,
  .lg\:rt-r-mt-9,
  .lg\:-rt-r-mt-1,
  .lg\:-rt-r-mt-2,
  .lg\:-rt-r-mt-3,
  .lg\:-rt-r-mt-4,
  .lg\:-rt-r-mt-5,
  .lg\:-rt-r-mt-6,
  .lg\:-rt-r-mt-7,
  .lg\:-rt-r-mt-8,
  .lg\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .lg\:rt-r-mt {
    --margin-top: var(--mt-lg)
  
  }

  .lg\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .lg\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .lg\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .lg\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .lg\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .lg\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .lg\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .lg\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .lg\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .lg\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .lg\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-mt,
  .xl\:rt-r-mt-0,
  .xl\:rt-r-mt-1,
  .xl\:rt-r-mt-2,
  .xl\:rt-r-mt-3,
  .xl\:rt-r-mt-4,
  .xl\:rt-r-mt-5,
  .xl\:rt-r-mt-6,
  .xl\:rt-r-mt-7,
  .xl\:rt-r-mt-8,
  .xl\:rt-r-mt-9,
  .xl\:-rt-r-mt-1,
  .xl\:-rt-r-mt-2,
  .xl\:-rt-r-mt-3,
  .xl\:-rt-r-mt-4,
  .xl\:-rt-r-mt-5,
  .xl\:-rt-r-mt-6,
  .xl\:-rt-r-mt-7,
  .xl\:-rt-r-mt-8,
  .xl\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .xl\:rt-r-mt {
    --margin-top: var(--mt-xl)
  
  }

  .xl\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .xl\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .xl\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .xl\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .xl\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .xl\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .xl\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .xl\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .xl\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .xl\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .xl\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

.rt-r-mr,
.rt-r-mr-0,
.rt-r-mr-1,
.rt-r-mr-2,
.rt-r-mr-3,
.rt-r-mr-4,
.rt-r-mr-5,
.rt-r-mr-6,
.rt-r-mr-7,
.rt-r-mr-8,
.rt-r-mr-9,
.-rt-r-mr-1,
.-rt-r-mr-2,
.-rt-r-mr-3,
.-rt-r-mr-4,
.-rt-r-mr-5,
.-rt-r-mr-6,
.-rt-r-mr-7,
.-rt-r-mr-8,
.-rt-r-mr-9 {
  margin-right: var(--margin-right-override, var(--margin-right));
}

.rt-r-mr {
  --margin-right: var(--mr)

}

.rt-r-mr-0 {
  --margin-right: 0px;
}

.rt-r-mr-1 {
  --margin-right: var(--space-1);
}

.rt-r-mr-2 {
  --margin-right: var(--space-2);
}

.rt-r-mr-3 {
  --margin-right: var(--space-3);
}

.rt-r-mr-4 {
  --margin-right: var(--space-4);
}

.rt-r-mr-5 {
  --margin-right: var(--space-5);
}

.rt-r-mr-6 {
  --margin-right: var(--space-6);
}

.rt-r-mr-7 {
  --margin-right: var(--space-7);
}

.rt-r-mr-8 {
  --margin-right: var(--space-8);
}

.rt-r-mr-9 {
  --margin-right: var(--space-9);
}

.-rt-r-mr-1 {
  --margin-right: calc(-1 * var(--space-1));
}

.-rt-r-mr-2 {
  --margin-right: calc(-1 * var(--space-2));
}

.-rt-r-mr-3 {
  --margin-right: calc(-1 * var(--space-3));
}

.-rt-r-mr-4 {
  --margin-right: calc(-1 * var(--space-4));
}

.-rt-r-mr-5 {
  --margin-right: calc(-1 * var(--space-5));
}

.-rt-r-mr-6 {
  --margin-right: calc(-1 * var(--space-6));
}

.-rt-r-mr-7 {
  --margin-right: calc(-1 * var(--space-7));
}

.-rt-r-mr-8 {
  --margin-right: calc(-1 * var(--space-8));
}

.-rt-r-mr-9 {
  --margin-right: calc(-1 * var(--space-9));
}

@media (min-width: 640px) {
  .xs\:rt-r-mr,
  .xs\:rt-r-mr-0,
  .xs\:rt-r-mr-1,
  .xs\:rt-r-mr-2,
  .xs\:rt-r-mr-3,
  .xs\:rt-r-mr-4,
  .xs\:rt-r-mr-5,
  .xs\:rt-r-mr-6,
  .xs\:rt-r-mr-7,
  .xs\:rt-r-mr-8,
  .xs\:rt-r-mr-9,
  .xs\:-rt-r-mr-1,
  .xs\:-rt-r-mr-2,
  .xs\:-rt-r-mr-3,
  .xs\:-rt-r-mr-4,
  .xs\:-rt-r-mr-5,
  .xs\:-rt-r-mr-6,
  .xs\:-rt-r-mr-7,
  .xs\:-rt-r-mr-8,
  .xs\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xs\:rt-r-mr {
    --margin-right: var(--mr-xs)
  
  }

  .xs\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .xs\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .xs\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .xs\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .xs\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .xs\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .xs\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .xs\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .xs\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .xs\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .xs\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mr,
  .sm\:rt-r-mr-0,
  .sm\:rt-r-mr-1,
  .sm\:rt-r-mr-2,
  .sm\:rt-r-mr-3,
  .sm\:rt-r-mr-4,
  .sm\:rt-r-mr-5,
  .sm\:rt-r-mr-6,
  .sm\:rt-r-mr-7,
  .sm\:rt-r-mr-8,
  .sm\:rt-r-mr-9,
  .sm\:-rt-r-mr-1,
  .sm\:-rt-r-mr-2,
  .sm\:-rt-r-mr-3,
  .sm\:-rt-r-mr-4,
  .sm\:-rt-r-mr-5,
  .sm\:-rt-r-mr-6,
  .sm\:-rt-r-mr-7,
  .sm\:-rt-r-mr-8,
  .sm\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .sm\:rt-r-mr {
    --margin-right: var(--mr-sm)
  
  }

  .sm\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .sm\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .sm\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .sm\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .sm\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .sm\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .sm\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .sm\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .sm\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .sm\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .sm\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mr,
  .md\:rt-r-mr-0,
  .md\:rt-r-mr-1,
  .md\:rt-r-mr-2,
  .md\:rt-r-mr-3,
  .md\:rt-r-mr-4,
  .md\:rt-r-mr-5,
  .md\:rt-r-mr-6,
  .md\:rt-r-mr-7,
  .md\:rt-r-mr-8,
  .md\:rt-r-mr-9,
  .md\:-rt-r-mr-1,
  .md\:-rt-r-mr-2,
  .md\:-rt-r-mr-3,
  .md\:-rt-r-mr-4,
  .md\:-rt-r-mr-5,
  .md\:-rt-r-mr-6,
  .md\:-rt-r-mr-7,
  .md\:-rt-r-mr-8,
  .md\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .md\:rt-r-mr {
    --margin-right: var(--mr-md)
  
  }

  .md\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .md\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .md\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .md\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .md\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .md\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .md\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .md\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .md\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .md\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .md\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mr,
  .lg\:rt-r-mr-0,
  .lg\:rt-r-mr-1,
  .lg\:rt-r-mr-2,
  .lg\:rt-r-mr-3,
  .lg\:rt-r-mr-4,
  .lg\:rt-r-mr-5,
  .lg\:rt-r-mr-6,
  .lg\:rt-r-mr-7,
  .lg\:rt-r-mr-8,
  .lg\:rt-r-mr-9,
  .lg\:-rt-r-mr-1,
  .lg\:-rt-r-mr-2,
  .lg\:-rt-r-mr-3,
  .lg\:-rt-r-mr-4,
  .lg\:-rt-r-mr-5,
  .lg\:-rt-r-mr-6,
  .lg\:-rt-r-mr-7,
  .lg\:-rt-r-mr-8,
  .lg\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .lg\:rt-r-mr {
    --margin-right: var(--mr-lg)
  
  }

  .lg\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .lg\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .lg\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .lg\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .lg\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .lg\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .lg\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .lg\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .lg\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .lg\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .lg\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-mr,
  .xl\:rt-r-mr-0,
  .xl\:rt-r-mr-1,
  .xl\:rt-r-mr-2,
  .xl\:rt-r-mr-3,
  .xl\:rt-r-mr-4,
  .xl\:rt-r-mr-5,
  .xl\:rt-r-mr-6,
  .xl\:rt-r-mr-7,
  .xl\:rt-r-mr-8,
  .xl\:rt-r-mr-9,
  .xl\:-rt-r-mr-1,
  .xl\:-rt-r-mr-2,
  .xl\:-rt-r-mr-3,
  .xl\:-rt-r-mr-4,
  .xl\:-rt-r-mr-5,
  .xl\:-rt-r-mr-6,
  .xl\:-rt-r-mr-7,
  .xl\:-rt-r-mr-8,
  .xl\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xl\:rt-r-mr {
    --margin-right: var(--mr-xl)
  
  }

  .xl\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .xl\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .xl\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .xl\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .xl\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .xl\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .xl\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .xl\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .xl\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .xl\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .xl\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

.rt-r-mb,
.rt-r-mb-0,
.rt-r-mb-1,
.rt-r-mb-2,
.rt-r-mb-3,
.rt-r-mb-4,
.rt-r-mb-5,
.rt-r-mb-6,
.rt-r-mb-7,
.rt-r-mb-8,
.rt-r-mb-9,
.-rt-r-mb-1,
.-rt-r-mb-2,
.-rt-r-mb-3,
.-rt-r-mb-4,
.-rt-r-mb-5,
.-rt-r-mb-6,
.-rt-r-mb-7,
.-rt-r-mb-8,
.-rt-r-mb-9 {
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
}

.rt-r-mb {
  --margin-bottom: var(--mb)

}

.rt-r-mb-0 {
  --margin-bottom: 0px;
}

.rt-r-mb-1 {
  --margin-bottom: var(--space-1);
}

.rt-r-mb-2 {
  --margin-bottom: var(--space-2);
}

.rt-r-mb-3 {
  --margin-bottom: var(--space-3);
}

.rt-r-mb-4 {
  --margin-bottom: var(--space-4);
}

.rt-r-mb-5 {
  --margin-bottom: var(--space-5);
}

.rt-r-mb-6 {
  --margin-bottom: var(--space-6);
}

.rt-r-mb-7 {
  --margin-bottom: var(--space-7);
}

.rt-r-mb-8 {
  --margin-bottom: var(--space-8);
}

.rt-r-mb-9 {
  --margin-bottom: var(--space-9);
}

.-rt-r-mb-1 {
  --margin-bottom: calc(-1 * var(--space-1));
}

.-rt-r-mb-2 {
  --margin-bottom: calc(-1 * var(--space-2));
}

.-rt-r-mb-3 {
  --margin-bottom: calc(-1 * var(--space-3));
}

.-rt-r-mb-4 {
  --margin-bottom: calc(-1 * var(--space-4));
}

.-rt-r-mb-5 {
  --margin-bottom: calc(-1 * var(--space-5));
}

.-rt-r-mb-6 {
  --margin-bottom: calc(-1 * var(--space-6));
}

.-rt-r-mb-7 {
  --margin-bottom: calc(-1 * var(--space-7));
}

.-rt-r-mb-8 {
  --margin-bottom: calc(-1 * var(--space-8));
}

.-rt-r-mb-9 {
  --margin-bottom: calc(-1 * var(--space-9));
}

@media (min-width: 640px) {
  .xs\:rt-r-mb,
  .xs\:rt-r-mb-0,
  .xs\:rt-r-mb-1,
  .xs\:rt-r-mb-2,
  .xs\:rt-r-mb-3,
  .xs\:rt-r-mb-4,
  .xs\:rt-r-mb-5,
  .xs\:rt-r-mb-6,
  .xs\:rt-r-mb-7,
  .xs\:rt-r-mb-8,
  .xs\:rt-r-mb-9,
  .xs\:-rt-r-mb-1,
  .xs\:-rt-r-mb-2,
  .xs\:-rt-r-mb-3,
  .xs\:-rt-r-mb-4,
  .xs\:-rt-r-mb-5,
  .xs\:-rt-r-mb-6,
  .xs\:-rt-r-mb-7,
  .xs\:-rt-r-mb-8,
  .xs\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xs\:rt-r-mb {
    --margin-bottom: var(--mb-xs)
  
  }

  .xs\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .xs\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .xs\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .xs\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .xs\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .xs\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .xs\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .xs\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .xs\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .xs\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .xs\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mb,
  .sm\:rt-r-mb-0,
  .sm\:rt-r-mb-1,
  .sm\:rt-r-mb-2,
  .sm\:rt-r-mb-3,
  .sm\:rt-r-mb-4,
  .sm\:rt-r-mb-5,
  .sm\:rt-r-mb-6,
  .sm\:rt-r-mb-7,
  .sm\:rt-r-mb-8,
  .sm\:rt-r-mb-9,
  .sm\:-rt-r-mb-1,
  .sm\:-rt-r-mb-2,
  .sm\:-rt-r-mb-3,
  .sm\:-rt-r-mb-4,
  .sm\:-rt-r-mb-5,
  .sm\:-rt-r-mb-6,
  .sm\:-rt-r-mb-7,
  .sm\:-rt-r-mb-8,
  .sm\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .sm\:rt-r-mb {
    --margin-bottom: var(--mb-sm)
  
  }

  .sm\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .sm\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .sm\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .sm\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .sm\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .sm\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .sm\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .sm\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .sm\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .sm\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .sm\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mb,
  .md\:rt-r-mb-0,
  .md\:rt-r-mb-1,
  .md\:rt-r-mb-2,
  .md\:rt-r-mb-3,
  .md\:rt-r-mb-4,
  .md\:rt-r-mb-5,
  .md\:rt-r-mb-6,
  .md\:rt-r-mb-7,
  .md\:rt-r-mb-8,
  .md\:rt-r-mb-9,
  .md\:-rt-r-mb-1,
  .md\:-rt-r-mb-2,
  .md\:-rt-r-mb-3,
  .md\:-rt-r-mb-4,
  .md\:-rt-r-mb-5,
  .md\:-rt-r-mb-6,
  .md\:-rt-r-mb-7,
  .md\:-rt-r-mb-8,
  .md\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .md\:rt-r-mb {
    --margin-bottom: var(--mb-md)
  
  }

  .md\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .md\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .md\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .md\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .md\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .md\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .md\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .md\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .md\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .md\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .md\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mb,
  .lg\:rt-r-mb-0,
  .lg\:rt-r-mb-1,
  .lg\:rt-r-mb-2,
  .lg\:rt-r-mb-3,
  .lg\:rt-r-mb-4,
  .lg\:rt-r-mb-5,
  .lg\:rt-r-mb-6,
  .lg\:rt-r-mb-7,
  .lg\:rt-r-mb-8,
  .lg\:rt-r-mb-9,
  .lg\:-rt-r-mb-1,
  .lg\:-rt-r-mb-2,
  .lg\:-rt-r-mb-3,
  .lg\:-rt-r-mb-4,
  .lg\:-rt-r-mb-5,
  .lg\:-rt-r-mb-6,
  .lg\:-rt-r-mb-7,
  .lg\:-rt-r-mb-8,
  .lg\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .lg\:rt-r-mb {
    --margin-bottom: var(--mb-lg)
  
  }

  .lg\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .lg\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .lg\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .lg\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .lg\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .lg\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .lg\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .lg\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .lg\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .lg\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .lg\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-mb,
  .xl\:rt-r-mb-0,
  .xl\:rt-r-mb-1,
  .xl\:rt-r-mb-2,
  .xl\:rt-r-mb-3,
  .xl\:rt-r-mb-4,
  .xl\:rt-r-mb-5,
  .xl\:rt-r-mb-6,
  .xl\:rt-r-mb-7,
  .xl\:rt-r-mb-8,
  .xl\:rt-r-mb-9,
  .xl\:-rt-r-mb-1,
  .xl\:-rt-r-mb-2,
  .xl\:-rt-r-mb-3,
  .xl\:-rt-r-mb-4,
  .xl\:-rt-r-mb-5,
  .xl\:-rt-r-mb-6,
  .xl\:-rt-r-mb-7,
  .xl\:-rt-r-mb-8,
  .xl\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xl\:rt-r-mb {
    --margin-bottom: var(--mb-xl)
  
  }

  .xl\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .xl\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .xl\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .xl\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .xl\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .xl\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .xl\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .xl\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .xl\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .xl\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .xl\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

.rt-r-ml,
.rt-r-ml-0,
.rt-r-ml-1,
.rt-r-ml-2,
.rt-r-ml-3,
.rt-r-ml-4,
.rt-r-ml-5,
.rt-r-ml-6,
.rt-r-ml-7,
.rt-r-ml-8,
.rt-r-ml-9,
.-rt-r-ml-1,
.-rt-r-ml-2,
.-rt-r-ml-3,
.-rt-r-ml-4,
.-rt-r-ml-5,
.-rt-r-ml-6,
.-rt-r-ml-7,
.-rt-r-ml-8,
.-rt-r-ml-9 {
  margin-left: var(--margin-left-override, var(--margin-left));
}

.rt-r-ml {
  --margin-left: var(--ml)

}

.rt-r-ml-0 {
  --margin-left: 0px;
}

.rt-r-ml-1 {
  --margin-left: var(--space-1);
}

.rt-r-ml-2 {
  --margin-left: var(--space-2);
}

.rt-r-ml-3 {
  --margin-left: var(--space-3);
}

.rt-r-ml-4 {
  --margin-left: var(--space-4);
}

.rt-r-ml-5 {
  --margin-left: var(--space-5);
}

.rt-r-ml-6 {
  --margin-left: var(--space-6);
}

.rt-r-ml-7 {
  --margin-left: var(--space-7);
}

.rt-r-ml-8 {
  --margin-left: var(--space-8);
}

.rt-r-ml-9 {
  --margin-left: var(--space-9);
}

.-rt-r-ml-1 {
  --margin-left: calc(-1 * var(--space-1));
}

.-rt-r-ml-2 {
  --margin-left: calc(-1 * var(--space-2));
}

.-rt-r-ml-3 {
  --margin-left: calc(-1 * var(--space-3));
}

.-rt-r-ml-4 {
  --margin-left: calc(-1 * var(--space-4));
}

.-rt-r-ml-5 {
  --margin-left: calc(-1 * var(--space-5));
}

.-rt-r-ml-6 {
  --margin-left: calc(-1 * var(--space-6));
}

.-rt-r-ml-7 {
  --margin-left: calc(-1 * var(--space-7));
}

.-rt-r-ml-8 {
  --margin-left: calc(-1 * var(--space-8));
}

.-rt-r-ml-9 {
  --margin-left: calc(-1 * var(--space-9));
}

@media (min-width: 640px) {
  .xs\:rt-r-ml,
  .xs\:rt-r-ml-0,
  .xs\:rt-r-ml-1,
  .xs\:rt-r-ml-2,
  .xs\:rt-r-ml-3,
  .xs\:rt-r-ml-4,
  .xs\:rt-r-ml-5,
  .xs\:rt-r-ml-6,
  .xs\:rt-r-ml-7,
  .xs\:rt-r-ml-8,
  .xs\:rt-r-ml-9,
  .xs\:-rt-r-ml-1,
  .xs\:-rt-r-ml-2,
  .xs\:-rt-r-ml-3,
  .xs\:-rt-r-ml-4,
  .xs\:-rt-r-ml-5,
  .xs\:-rt-r-ml-6,
  .xs\:-rt-r-ml-7,
  .xs\:-rt-r-ml-8,
  .xs\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xs\:rt-r-ml {
    --margin-left: var(--ml-xs)
  
  }

  .xs\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .xs\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .xs\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .xs\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .xs\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .xs\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .xs\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .xs\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .xs\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .xs\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .xs\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ml,
  .sm\:rt-r-ml-0,
  .sm\:rt-r-ml-1,
  .sm\:rt-r-ml-2,
  .sm\:rt-r-ml-3,
  .sm\:rt-r-ml-4,
  .sm\:rt-r-ml-5,
  .sm\:rt-r-ml-6,
  .sm\:rt-r-ml-7,
  .sm\:rt-r-ml-8,
  .sm\:rt-r-ml-9,
  .sm\:-rt-r-ml-1,
  .sm\:-rt-r-ml-2,
  .sm\:-rt-r-ml-3,
  .sm\:-rt-r-ml-4,
  .sm\:-rt-r-ml-5,
  .sm\:-rt-r-ml-6,
  .sm\:-rt-r-ml-7,
  .sm\:-rt-r-ml-8,
  .sm\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .sm\:rt-r-ml {
    --margin-left: var(--ml-sm)
  
  }

  .sm\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .sm\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .sm\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .sm\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .sm\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .sm\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .sm\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .sm\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .sm\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .sm\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .sm\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ml,
  .md\:rt-r-ml-0,
  .md\:rt-r-ml-1,
  .md\:rt-r-ml-2,
  .md\:rt-r-ml-3,
  .md\:rt-r-ml-4,
  .md\:rt-r-ml-5,
  .md\:rt-r-ml-6,
  .md\:rt-r-ml-7,
  .md\:rt-r-ml-8,
  .md\:rt-r-ml-9,
  .md\:-rt-r-ml-1,
  .md\:-rt-r-ml-2,
  .md\:-rt-r-ml-3,
  .md\:-rt-r-ml-4,
  .md\:-rt-r-ml-5,
  .md\:-rt-r-ml-6,
  .md\:-rt-r-ml-7,
  .md\:-rt-r-ml-8,
  .md\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .md\:rt-r-ml {
    --margin-left: var(--ml-md)
  
  }

  .md\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .md\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .md\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .md\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .md\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .md\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .md\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .md\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .md\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .md\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .md\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ml,
  .lg\:rt-r-ml-0,
  .lg\:rt-r-ml-1,
  .lg\:rt-r-ml-2,
  .lg\:rt-r-ml-3,
  .lg\:rt-r-ml-4,
  .lg\:rt-r-ml-5,
  .lg\:rt-r-ml-6,
  .lg\:rt-r-ml-7,
  .lg\:rt-r-ml-8,
  .lg\:rt-r-ml-9,
  .lg\:-rt-r-ml-1,
  .lg\:-rt-r-ml-2,
  .lg\:-rt-r-ml-3,
  .lg\:-rt-r-ml-4,
  .lg\:-rt-r-ml-5,
  .lg\:-rt-r-ml-6,
  .lg\:-rt-r-ml-7,
  .lg\:-rt-r-ml-8,
  .lg\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .lg\:rt-r-ml {
    --margin-left: var(--ml-lg)
  
  }

  .lg\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .lg\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .lg\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .lg\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .lg\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .lg\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .lg\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .lg\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .lg\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .lg\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .lg\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-ml,
  .xl\:rt-r-ml-0,
  .xl\:rt-r-ml-1,
  .xl\:rt-r-ml-2,
  .xl\:rt-r-ml-3,
  .xl\:rt-r-ml-4,
  .xl\:rt-r-ml-5,
  .xl\:rt-r-ml-6,
  .xl\:rt-r-ml-7,
  .xl\:rt-r-ml-8,
  .xl\:rt-r-ml-9,
  .xl\:-rt-r-ml-1,
  .xl\:-rt-r-ml-2,
  .xl\:-rt-r-ml-3,
  .xl\:-rt-r-ml-4,
  .xl\:-rt-r-ml-5,
  .xl\:-rt-r-ml-6,
  .xl\:-rt-r-ml-7,
  .xl\:-rt-r-ml-8,
  .xl\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xl\:rt-r-ml {
    --margin-left: var(--ml-xl)
  
  }

  .xl\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .xl\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .xl\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .xl\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .xl\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .xl\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .xl\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .xl\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .xl\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .xl\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .xl\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

.rt-r-overflow-visible {
  overflow: visible;
}

.rt-r-overflow-hidden {
  overflow: hidden;
}

.rt-r-overflow-clip {
  overflow: clip;
}

.rt-r-overflow-scroll {
  overflow: scroll;
}

.rt-r-overflow-auto {
  overflow: auto;
}

.rt-r-ox-visible {
  overflow-x: visible;
}

.rt-r-ox-hidden {
  overflow-x: hidden;
}

.rt-r-ox-clip {
  overflow-x: clip;
}

.rt-r-ox-scroll {
  overflow-x: scroll;
}

.rt-r-ox-auto {
  overflow-x: auto;
}

.rt-r-oy-visible {
  overflow-y: visible;
}

.rt-r-oy-hidden {
  overflow-y: hidden;
}

.rt-r-oy-clip {
  overflow-y: clip;
}

.rt-r-oy-scroll {
  overflow-y: scroll;
}

.rt-r-oy-auto {
  overflow-y: auto;
}

@media (min-width: 640px) {
  .xs\:rt-r-overflow-visible {
    overflow: visible;
  }

  .xs\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .xs\:rt-r-overflow-clip {
    overflow: clip;
  }

  .xs\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .xs\:rt-r-overflow-auto {
    overflow: auto;
  }

  .xs\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .xs\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .xs\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .xs\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .xs\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .xs\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .xs\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .xs\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .xs\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .xs\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-overflow-visible {
    overflow: visible;
  }

  .sm\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .sm\:rt-r-overflow-clip {
    overflow: clip;
  }

  .sm\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .sm\:rt-r-overflow-auto {
    overflow: auto;
  }

  .sm\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .sm\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .sm\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .sm\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .sm\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .sm\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .sm\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .sm\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .sm\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .sm\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-overflow-visible {
    overflow: visible;
  }

  .md\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .md\:rt-r-overflow-clip {
    overflow: clip;
  }

  .md\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .md\:rt-r-overflow-auto {
    overflow: auto;
  }

  .md\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .md\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .md\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .md\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .md\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .md\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .md\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .md\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .md\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .md\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-overflow-visible {
    overflow: visible;
  }

  .lg\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .lg\:rt-r-overflow-clip {
    overflow: clip;
  }

  .lg\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .lg\:rt-r-overflow-auto {
    overflow: auto;
  }

  .lg\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .lg\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .lg\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .lg\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .lg\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .lg\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .lg\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .lg\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .lg\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .lg\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-overflow-visible {
    overflow: visible;
  }

  .xl\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .xl\:rt-r-overflow-clip {
    overflow: clip;
  }

  .xl\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .xl\:rt-r-overflow-auto {
    overflow: auto;
  }

  .xl\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .xl\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .xl\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .xl\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .xl\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .xl\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .xl\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .xl\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .xl\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .xl\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

.rt-r-p {
  padding: var(--p)
}

.rt-r-p-0 {
  padding: 0;
}

.rt-r-p-1 {
  padding: var(--space-1);
}

.rt-r-p-2 {
  padding: var(--space-2);
}

.rt-r-p-3 {
  padding: var(--space-3);
}

.rt-r-p-4 {
  padding: var(--space-4);
}

.rt-r-p-5 {
  padding: var(--space-5);
}

.rt-r-p-6 {
  padding: var(--space-6);
}

.rt-r-p-7 {
  padding: var(--space-7);
}

.rt-r-p-8 {
  padding: var(--space-8);
}

.rt-r-p-9 {
  padding: var(--space-9);
}

.rt-r-p-inset {
  padding-top: var(--inset-padding-top);
  padding-right: var(--inset-padding-right);
  padding-bottom: var(--inset-padding-bottom);
  padding-left: var(--inset-padding-left);
}

@media (min-width: 640px) {
  .xs\:rt-r-p {
    padding: var(--p-xs)
  }

  .xs\:rt-r-p-0 {
    padding: 0;
  }

  .xs\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .xs\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .xs\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .xs\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .xs\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .xs\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .xs\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .xs\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .xs\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .xs\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-p {
    padding: var(--p-sm)
  }

  .sm\:rt-r-p-0 {
    padding: 0;
  }

  .sm\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .sm\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .sm\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .sm\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .sm\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .sm\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .sm\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .sm\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .sm\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .sm\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-p {
    padding: var(--p-md)
  }

  .md\:rt-r-p-0 {
    padding: 0;
  }

  .md\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .md\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .md\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .md\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .md\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .md\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .md\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .md\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .md\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .md\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-p {
    padding: var(--p-lg)
  }

  .lg\:rt-r-p-0 {
    padding: 0;
  }

  .lg\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .lg\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .lg\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .lg\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .lg\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .lg\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .lg\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .lg\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .lg\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .lg\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-p {
    padding: var(--p-xl)
  }

  .xl\:rt-r-p-0 {
    padding: 0;
  }

  .xl\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .xl\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .xl\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .xl\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .xl\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .xl\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .xl\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .xl\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .xl\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .xl\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

.rt-r-px {
  padding-left: var(--pl);
  padding-right: var(--pr)
}

.rt-r-px-0 {
  padding-left: 0;
  padding-right: 0;
}

.rt-r-px-1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}

.rt-r-px-2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-r-px-3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.rt-r-px-4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.rt-r-px-5 {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}

.rt-r-px-6 {
  padding-left: var(--space-6);
  padding-right: var(--space-6);
}

.rt-r-px-7 {
  padding-left: var(--space-7);
  padding-right: var(--space-7);
}

.rt-r-px-8 {
  padding-left: var(--space-8);
  padding-right: var(--space-8);
}

.rt-r-px-9 {
  padding-left: var(--space-9);
  padding-right: var(--space-9);
}

.rt-r-px-inset {
  padding-left: var(--inset-padding-left);
  padding-right: var(--inset-padding-right);
}

@media (min-width: 640px) {
  .xs\:rt-r-px {
    padding-left: var(--pl-xs);
    padding-right: var(--pr-xs)
  }

  .xs\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xs\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .xs\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .xs\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .xs\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .xs\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .xs\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .xs\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .xs\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .xs\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .xs\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-px {
    padding-left: var(--pl-sm);
    padding-right: var(--pr-sm)
  }

  .sm\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .sm\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .sm\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .sm\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .sm\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .sm\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .sm\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .sm\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .sm\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .sm\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-px {
    padding-left: var(--pl-md);
    padding-right: var(--pr-md)
  }

  .md\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .md\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .md\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .md\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .md\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .md\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .md\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .md\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .md\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .md\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-px {
    padding-left: var(--pl-lg);
    padding-right: var(--pr-lg)
  }

  .lg\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .lg\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .lg\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .lg\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .lg\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .lg\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .lg\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .lg\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .lg\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .lg\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-px {
    padding-left: var(--pl-xl);
    padding-right: var(--pr-xl)
  }

  .xl\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .xl\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .xl\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .xl\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .xl\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .xl\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .xl\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .xl\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .xl\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .xl\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

.rt-r-py {
  padding-top: var(--pt);
  padding-bottom: var(--pb)
}

.rt-r-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.rt-r-py-1 {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}

.rt-r-py-2 {
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}

.rt-r-py-3 {
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);
}

.rt-r-py-4 {
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
}

.rt-r-py-5 {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.rt-r-py-6 {
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
}

.rt-r-py-7 {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}

.rt-r-py-8 {
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
}

.rt-r-py-9 {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}

.rt-r-py-inset {
  padding-top: var(--inset-padding-top);
  padding-bottom: var(--inset-padding-bottom);
}

@media (min-width: 640px) {
  .xs\:rt-r-py {
    padding-top: var(--pt-xs);
    padding-bottom: var(--pb-xs)
  }

  .xs\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xs\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .xs\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .xs\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .xs\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .xs\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .xs\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .xs\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .xs\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .xs\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .xs\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-py {
    padding-top: var(--pt-sm);
    padding-bottom: var(--pb-sm)
  }

  .sm\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .sm\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .sm\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .sm\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .sm\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .sm\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .sm\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .sm\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .sm\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .sm\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-py {
    padding-top: var(--pt-md);
    padding-bottom: var(--pb-md)
  }

  .md\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .md\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .md\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .md\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .md\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .md\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .md\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .md\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .md\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .md\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-py {
    padding-top: var(--pt-lg);
    padding-bottom: var(--pb-lg)
  }

  .lg\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .lg\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .lg\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .lg\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .lg\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .lg\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .lg\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .lg\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .lg\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .lg\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-py {
    padding-top: var(--pt-xl);
    padding-bottom: var(--pb-xl)
  }

  .xl\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .xl\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .xl\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .xl\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .xl\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .xl\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .xl\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .xl\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .xl\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .xl\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

.rt-r-pt {
  padding-top: var(--pt)
}

.rt-r-pt-0 {
  padding-top: 0;
}

.rt-r-pt-1 {
  padding-top: var(--space-1);
}

.rt-r-pt-2 {
  padding-top: var(--space-2);
}

.rt-r-pt-3 {
  padding-top: var(--space-3);
}

.rt-r-pt-4 {
  padding-top: var(--space-4);
}

.rt-r-pt-5 {
  padding-top: var(--space-5);
}

.rt-r-pt-6 {
  padding-top: var(--space-6);
}

.rt-r-pt-7 {
  padding-top: var(--space-7);
}

.rt-r-pt-8 {
  padding-top: var(--space-8);
}

.rt-r-pt-9 {
  padding-top: var(--space-9);
}

.rt-r-pt-inset {
  padding-top: var(--inset-padding-top);
}

@media (min-width: 640px) {
  .xs\:rt-r-pt {
    padding-top: var(--pt-xs)
  }

  .xs\:rt-r-pt-0 {
    padding-top: 0;
  }

  .xs\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .xs\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .xs\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .xs\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .xs\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .xs\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .xs\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .xs\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .xs\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .xs\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pt {
    padding-top: var(--pt-sm)
  }

  .sm\:rt-r-pt-0 {
    padding-top: 0;
  }

  .sm\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .sm\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .sm\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .sm\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .sm\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .sm\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .sm\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .sm\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .sm\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .sm\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pt {
    padding-top: var(--pt-md)
  }

  .md\:rt-r-pt-0 {
    padding-top: 0;
  }

  .md\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .md\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .md\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .md\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .md\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .md\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .md\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .md\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .md\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .md\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pt {
    padding-top: var(--pt-lg)
  }

  .lg\:rt-r-pt-0 {
    padding-top: 0;
  }

  .lg\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .lg\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .lg\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .lg\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .lg\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .lg\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .lg\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .lg\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .lg\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .lg\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-pt {
    padding-top: var(--pt-xl)
  }

  .xl\:rt-r-pt-0 {
    padding-top: 0;
  }

  .xl\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .xl\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .xl\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .xl\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .xl\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .xl\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .xl\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .xl\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .xl\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .xl\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

.rt-r-pr {
  padding-right: var(--pr)
}

.rt-r-pr-0 {
  padding-right: 0;
}

.rt-r-pr-1 {
  padding-right: var(--space-1);
}

.rt-r-pr-2 {
  padding-right: var(--space-2);
}

.rt-r-pr-3 {
  padding-right: var(--space-3);
}

.rt-r-pr-4 {
  padding-right: var(--space-4);
}

.rt-r-pr-5 {
  padding-right: var(--space-5);
}

.rt-r-pr-6 {
  padding-right: var(--space-6);
}

.rt-r-pr-7 {
  padding-right: var(--space-7);
}

.rt-r-pr-8 {
  padding-right: var(--space-8);
}

.rt-r-pr-9 {
  padding-right: var(--space-9);
}

.rt-r-pr-inset {
  padding-right: var(--inset-padding-right);
}

@media (min-width: 640px) {
  .xs\:rt-r-pr {
    padding-right: var(--pr-xs)
  }

  .xs\:rt-r-pr-0 {
    padding-right: 0;
  }

  .xs\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .xs\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .xs\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .xs\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .xs\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .xs\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .xs\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .xs\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .xs\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .xs\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pr {
    padding-right: var(--pr-sm)
  }

  .sm\:rt-r-pr-0 {
    padding-right: 0;
  }

  .sm\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .sm\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .sm\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .sm\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .sm\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .sm\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .sm\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .sm\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .sm\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .sm\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pr {
    padding-right: var(--pr-md)
  }

  .md\:rt-r-pr-0 {
    padding-right: 0;
  }

  .md\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .md\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .md\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .md\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .md\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .md\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .md\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .md\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .md\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .md\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pr {
    padding-right: var(--pr-lg)
  }

  .lg\:rt-r-pr-0 {
    padding-right: 0;
  }

  .lg\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .lg\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .lg\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .lg\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .lg\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .lg\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .lg\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .lg\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .lg\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .lg\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-pr {
    padding-right: var(--pr-xl)
  }

  .xl\:rt-r-pr-0 {
    padding-right: 0;
  }

  .xl\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .xl\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .xl\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .xl\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .xl\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .xl\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .xl\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .xl\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .xl\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .xl\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

.rt-r-pb {
  padding-bottom: var(--pb)
}

.rt-r-pb-0 {
  padding-bottom: 0;
}

.rt-r-pb-1 {
  padding-bottom: var(--space-1);
}

.rt-r-pb-2 {
  padding-bottom: var(--space-2);
}

.rt-r-pb-3 {
  padding-bottom: var(--space-3);
}

.rt-r-pb-4 {
  padding-bottom: var(--space-4);
}

.rt-r-pb-5 {
  padding-bottom: var(--space-5);
}

.rt-r-pb-6 {
  padding-bottom: var(--space-6);
}

.rt-r-pb-7 {
  padding-bottom: var(--space-7);
}

.rt-r-pb-8 {
  padding-bottom: var(--space-8);
}

.rt-r-pb-9 {
  padding-bottom: var(--space-9);
}

.rt-r-pb-inset {
  padding-bottom: var(--inset-padding-bottom);
}

@media (min-width: 640px) {
  .xs\:rt-r-pb {
    padding-bottom: var(--pb-xs)
  }

  .xs\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .xs\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .xs\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .xs\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .xs\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .xs\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .xs\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .xs\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .xs\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .xs\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .xs\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pb {
    padding-bottom: var(--pb-sm)
  }

  .sm\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .sm\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .sm\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .sm\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .sm\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .sm\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .sm\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .sm\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .sm\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .sm\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .sm\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pb {
    padding-bottom: var(--pb-md)
  }

  .md\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .md\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .md\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .md\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .md\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .md\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .md\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .md\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .md\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .md\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .md\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pb {
    padding-bottom: var(--pb-lg)
  }

  .lg\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .lg\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .lg\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .lg\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .lg\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .lg\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .lg\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .lg\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .lg\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .lg\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .lg\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-pb {
    padding-bottom: var(--pb-xl)
  }

  .xl\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .xl\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .xl\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .xl\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .xl\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .xl\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .xl\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .xl\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .xl\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .xl\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .xl\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

.rt-r-pl {
  padding-left: var(--pl)
}

.rt-r-pl-0 {
  padding-left: 0;
}

.rt-r-pl-1 {
  padding-left: var(--space-1);
}

.rt-r-pl-2 {
  padding-left: var(--space-2);
}

.rt-r-pl-3 {
  padding-left: var(--space-3);
}

.rt-r-pl-4 {
  padding-left: var(--space-4);
}

.rt-r-pl-5 {
  padding-left: var(--space-5);
}

.rt-r-pl-6 {
  padding-left: var(--space-6);
}

.rt-r-pl-7 {
  padding-left: var(--space-7);
}

.rt-r-pl-8 {
  padding-left: var(--space-8);
}

.rt-r-pl-9 {
  padding-left: var(--space-9);
}

.rt-r-pl-inset {
  padding-left: var(--inset-padding-left);
}

@media (min-width: 640px) {
  .xs\:rt-r-pl {
    padding-left: var(--pl-xs)
  }

  .xs\:rt-r-pl-0 {
    padding-left: 0;
  }

  .xs\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .xs\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .xs\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .xs\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .xs\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .xs\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .xs\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .xs\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .xs\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .xs\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pl {
    padding-left: var(--pl-sm)
  }

  .sm\:rt-r-pl-0 {
    padding-left: 0;
  }

  .sm\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .sm\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .sm\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .sm\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .sm\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .sm\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .sm\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .sm\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .sm\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .sm\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pl {
    padding-left: var(--pl-md)
  }

  .md\:rt-r-pl-0 {
    padding-left: 0;
  }

  .md\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .md\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .md\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .md\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .md\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .md\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .md\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .md\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .md\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .md\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pl {
    padding-left: var(--pl-lg)
  }

  .lg\:rt-r-pl-0 {
    padding-left: 0;
  }

  .lg\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .lg\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .lg\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .lg\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .lg\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .lg\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .lg\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .lg\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .lg\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .lg\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-pl {
    padding-left: var(--pl-xl)
  }

  .xl\:rt-r-pl-0 {
    padding-left: 0;
  }

  .xl\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .xl\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .xl\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .xl\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .xl\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .xl\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .xl\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .xl\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .xl\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .xl\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

.rt-r-position-static {
  position: static;
}

.rt-r-position-absolute {
  position: absolute;
}

.rt-r-position-relative {
  position: relative;
}

.rt-r-position-fixed {
  position: fixed;
}

.rt-r-position-sticky {
  position: sticky;
}

@media (min-width: 640px) {
  .xs\:rt-r-position-static {
    position: static;
  }

  .xs\:rt-r-position-absolute {
    position: absolute;
  }

  .xs\:rt-r-position-relative {
    position: relative;
  }

  .xs\:rt-r-position-fixed {
    position: fixed;
  }

  .xs\:rt-r-position-sticky {
    position: sticky;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-position-static {
    position: static;
  }

  .sm\:rt-r-position-absolute {
    position: absolute;
  }

  .sm\:rt-r-position-relative {
    position: relative;
  }

  .sm\:rt-r-position-fixed {
    position: fixed;
  }

  .sm\:rt-r-position-sticky {
    position: sticky;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-position-static {
    position: static;
  }

  .md\:rt-r-position-absolute {
    position: absolute;
  }

  .md\:rt-r-position-relative {
    position: relative;
  }

  .md\:rt-r-position-fixed {
    position: fixed;
  }

  .md\:rt-r-position-sticky {
    position: sticky;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-position-static {
    position: static;
  }

  .lg\:rt-r-position-absolute {
    position: absolute;
  }

  .lg\:rt-r-position-relative {
    position: relative;
  }

  .lg\:rt-r-position-fixed {
    position: fixed;
  }

  .lg\:rt-r-position-sticky {
    position: sticky;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-position-static {
    position: static;
  }

  .xl\:rt-r-position-absolute {
    position: absolute;
  }

  .xl\:rt-r-position-relative {
    position: relative;
  }

  .xl\:rt-r-position-fixed {
    position: fixed;
  }

  .xl\:rt-r-position-sticky {
    position: sticky;
  }
}

.rt-r-w {
  width: var(--width);
}

@media (min-width: 640px) {
  .xs\:rt-r-w {
    width: var(--width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-w {
    width: var(--width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-w {
    width: var(--width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-w {
    width: var(--width-lg);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-w {
    width: var(--width-xl);
  }
}

.rt-r-min-w {
  min-width: var(--min-width);
}

@media (min-width: 640px) {
  .xs\:rt-r-min-w {
    min-width: var(--min-width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-min-w {
    min-width: var(--min-width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-min-w {
    min-width: var(--min-width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-min-w {
    min-width: var(--min-width-lg);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-min-w {
    min-width: var(--min-width-xl);
  }
}

.rt-r-max-w {
  max-width: var(--max-width);
}

@media (min-width: 640px) {
  .xs\:rt-r-max-w {
    max-width: var(--max-width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-max-w {
    max-width: var(--max-width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-max-w {
    max-width: var(--max-width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-max-w {
    max-width: var(--max-width-lg);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-max-w {
    max-width: var(--max-width-xl);
  }
}

.rt-r-weight-light {
  font-weight: var(--font-weight-light);
}

.rt-r-weight-regular {
  font-weight: var(--font-weight-regular);
}

.rt-r-weight-medium {
  font-weight: var(--font-weight-medium);
}

.rt-r-weight-bold {
  font-weight: var(--font-weight-bold);
}

@media (min-width: 640px) {
  .xs\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .xs\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .xs\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .xs\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .sm\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .sm\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .sm\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .md\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .md\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .md\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .lg\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .lg\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .lg\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-weight-light {
    font-weight: var(--font-weight-light);
  }

  .xl\:rt-r-weight-regular {
    font-weight: var(--font-weight-regular);
  }

  .xl\:rt-r-weight-medium {
    font-weight: var(--font-weight-medium);
  }

  .xl\:rt-r-weight-bold {
    font-weight: var(--font-weight-bold);
  }
}

.rt-r-lt-normal::before,
.rt-r-lt-end::before,
.rt-r-lt-normal::after,
.rt-r-lt-start::after {
  content: none;
}

.rt-r-lt-start::before,
.rt-r-lt-both::before,
.rt-r-lt-end::after,
.rt-r-lt-both::after {
  content: '';
  display: table;
}

.rt-r-lt-start::before,
.rt-r-lt-both::before {
  margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
}

.rt-r-lt-end::after,
.rt-r-lt-both::after {
  margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
}

@media (min-width: 640px) {
  .xs\:rt-r-lt-normal::before,
  .xs\:rt-r-lt-end::before,
  .xs\:rt-r-lt-normal::after,
  .xs\:rt-r-lt-start::after {
    content: none;
  }

  .xs\:rt-r-lt-start::before,
  .xs\:rt-r-lt-both::before,
  .xs\:rt-r-lt-end::after,
  .xs\:rt-r-lt-both::after {
    content: '';
    display: table;
  }

  .xs\:rt-r-lt-start::before,
  .xs\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .xs\:rt-r-lt-end::after,
  .xs\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-lt-normal::before,
  .sm\:rt-r-lt-end::before,
  .sm\:rt-r-lt-normal::after,
  .sm\:rt-r-lt-start::after {
    content: none;
  }

  .sm\:rt-r-lt-start::before,
  .sm\:rt-r-lt-both::before,
  .sm\:rt-r-lt-end::after,
  .sm\:rt-r-lt-both::after {
    content: '';
    display: table;
  }

  .sm\:rt-r-lt-start::before,
  .sm\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .sm\:rt-r-lt-end::after,
  .sm\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-lt-normal::before,
  .md\:rt-r-lt-end::before,
  .md\:rt-r-lt-normal::after,
  .md\:rt-r-lt-start::after {
    content: none;
  }

  .md\:rt-r-lt-start::before,
  .md\:rt-r-lt-both::before,
  .md\:rt-r-lt-end::after,
  .md\:rt-r-lt-both::after {
    content: '';
    display: table;
  }

  .md\:rt-r-lt-start::before,
  .md\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .md\:rt-r-lt-end::after,
  .md\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-lt-normal::before,
  .lg\:rt-r-lt-end::before,
  .lg\:rt-r-lt-normal::after,
  .lg\:rt-r-lt-start::after {
    content: none;
  }

  .lg\:rt-r-lt-start::before,
  .lg\:rt-r-lt-both::before,
  .lg\:rt-r-lt-end::after,
  .lg\:rt-r-lt-both::after {
    content: '';
    display: table;
  }

  .lg\:rt-r-lt-start::before,
  .lg\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .lg\:rt-r-lt-end::after,
  .lg\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-lt-normal::before,
  .xl\:rt-r-lt-end::before,
  .xl\:rt-r-lt-normal::after,
  .xl\:rt-r-lt-start::after {
    content: none;
  }

  .xl\:rt-r-lt-start::before,
  .xl\:rt-r-lt-both::before,
  .xl\:rt-r-lt-end::after,
  .xl\:rt-r-lt-both::after {
    content: '';
    display: table;
  }

  .xl\:rt-r-lt-start::before,
  .xl\:rt-r-lt-both::before {
    margin-bottom: calc(var(--leading-trim-start, var(--default-leading-trim-start)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }

  .xl\:rt-r-lt-end::after,
  .xl\:rt-r-lt-both::after {
    margin-top: calc(var(--leading-trim-end, var(--default-leading-trim-end)) - var(--line-height, calc(1em * var(--default-line-height))) / 2);
  }
}

.rt-r-resize-none {
  resize: none;
}

.rt-r-resize-vertical {
  resize: vertical;
}

.rt-r-resize-horizontal {
  resize: horizontal;
}

.rt-r-resize-both {
  resize: both;
}

@media (min-width: 640px) {
  .xs\:rt-r-resize-none {
    resize: none;
  }

  .xs\:rt-r-resize-vertical {
    resize: vertical;
  }

  .xs\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .xs\:rt-r-resize-both {
    resize: both;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-resize-none {
    resize: none;
  }

  .sm\:rt-r-resize-vertical {
    resize: vertical;
  }

  .sm\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .sm\:rt-r-resize-both {
    resize: both;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-resize-none {
    resize: none;
  }

  .md\:rt-r-resize-vertical {
    resize: vertical;
  }

  .md\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .md\:rt-r-resize-both {
    resize: both;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-resize-none {
    resize: none;
  }

  .lg\:rt-r-resize-vertical {
    resize: vertical;
  }

  .lg\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .lg\:rt-r-resize-both {
    resize: both;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-resize-none {
    resize: none;
  }

  .xl\:rt-r-resize-vertical {
    resize: vertical;
  }

  .xl\:rt-r-resize-horizontal {
    resize: horizontal;
  }

  .xl\:rt-r-resize-both {
    resize: both;
  }
}

.rt-r-tl-auto {
  table-layout: auto;
}

.rt-r-tl-fixed {
  table-layout: fixed;
}

@media (min-width: 640px) {
  .xs\:rt-r-tl-auto {
    table-layout: auto;
  }

  .xs\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-tl-auto {
    table-layout: auto;
  }

  .sm\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-tl-auto {
    table-layout: auto;
  }

  .md\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-tl-auto {
    table-layout: auto;
  }

  .lg\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-tl-auto {
    table-layout: auto;
  }

  .xl\:rt-r-tl-fixed {
    table-layout: fixed;
  }
}

.rt-r-ta-left {
  text-align: left;
}

.rt-r-ta-center {
  text-align: center;
}

.rt-r-ta-right {
  text-align: right;
}

@media (min-width: 640px) {
  .xs\:rt-r-ta-left {
    text-align: left;
  }

  .xs\:rt-r-ta-center {
    text-align: center;
  }

  .xs\:rt-r-ta-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ta-left {
    text-align: left;
  }

  .sm\:rt-r-ta-center {
    text-align: center;
  }

  .sm\:rt-r-ta-right {
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ta-left {
    text-align: left;
  }

  .md\:rt-r-ta-center {
    text-align: center;
  }

  .md\:rt-r-ta-right {
    text-align: right;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ta-left {
    text-align: left;
  }

  .lg\:rt-r-ta-center {
    text-align: center;
  }

  .lg\:rt-r-ta-right {
    text-align: right;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-ta-left {
    text-align: left;
  }

  .xl\:rt-r-ta-center {
    text-align: center;
  }

  .xl\:rt-r-ta-right {
    text-align: right;
  }
}

.rt-r-tw-wrap {
  white-space: normal;
}

.rt-r-tw-nowrap {
  white-space: nowrap;
}

.rt-r-tw-pretty {
  white-space: normal;
  text-wrap: pretty;
}

.rt-r-tw-balance {
  white-space: normal;
  text-wrap: balance;
}

@media (min-width: 640px) {
  .xs\:rt-r-tw-wrap {
    white-space: normal;
  }

  .xs\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .xs\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .xs\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-tw-wrap {
    white-space: normal;
  }

  .sm\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .sm\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .sm\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-tw-wrap {
    white-space: normal;
  }

  .md\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .md\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .md\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-tw-wrap {
    white-space: normal;
  }

  .lg\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .lg\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .lg\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-tw-wrap {
    white-space: normal;
  }

  .xl\:rt-r-tw-nowrap {
    white-space: nowrap;
  }

  .xl\:rt-r-tw-pretty {
    white-space: normal;
    text-wrap: pretty;
  }

  .xl\:rt-r-tw-balance {
    white-space: normal;
    text-wrap: balance;
  }
}

.rt-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rt-r-va-baseline {
  vertical-align: baseline;
}

.rt-r-va-top {
  vertical-align: top;
}

.rt-r-va-middle {
  vertical-align: middle;
}

.rt-r-va-bottom {
  vertical-align: bottom;
}

@media (min-width: 640px) {
  .xs\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .xs\:rt-r-va-top {
    vertical-align: top;
  }

  .xs\:rt-r-va-middle {
    vertical-align: middle;
  }

  .xs\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .sm\:rt-r-va-top {
    vertical-align: top;
  }

  .sm\:rt-r-va-middle {
    vertical-align: middle;
  }

  .sm\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .md\:rt-r-va-top {
    vertical-align: top;
  }

  .md\:rt-r-va-middle {
    vertical-align: middle;
  }

  .md\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .lg\:rt-r-va-top {
    vertical-align: top;
  }

  .lg\:rt-r-va-middle {
    vertical-align: middle;
  }

  .lg\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1536px) {
  .xl\:rt-r-va-baseline {
    vertical-align: baseline;
  }

  .xl\:rt-r-va-top {
    vertical-align: top;
  }

  .xl\:rt-r-va-middle {
    vertical-align: middle;
  }

  .xl\:rt-r-va-bottom {
    vertical-align: bottom;
  }
}

/* Figma variables to CSS variables */

/* To generate this file use this [plugin](https://www.figma.com/community/plugin/1260472771849439434) and replace the colors under with the content of the file */

/* Variables  */

:root {
  --colors-tomato-1-light: rgba(249,246,245,1);
  --colors-tomato-1-dark: rgba(24,17,17,1);
  --colors-tomato-2-light: rgba(252,239,234,1);
  --colors-tomato-2-dark: rgba(25,14,10,1);
  --colors-tomato-3-light: rgba(255,226,215,1);
  --colors-tomato-3-dark: rgba(51,22,12,1);
  --colors-tomato-4-light: rgba(255,205,182,1);
  --colors-tomato-4-dark: rgba(76,22,0,1);
  --colors-tomato-5-light: rgba(255,190,162,1);
  --colors-tomato-5-dark: rgba(92,31,5,1);
  --colors-tomato-6-light: rgba(255,174,141,1);
  --colors-tomato-6-dark: rgba(109,46,21,1);
  --colors-tomato-7-light: rgba(249,156,122,1);
  --colors-tomato-7-dark: rgba(133,62,35,1);
  --colors-tomato-8-light: rgba(239,130,90,1);
  --colors-tomato-8-dark: rgba(171,80,45,1);
  --colors-tomato-9-light: rgba(255,92,0,1);
  --colors-tomato-9-dark: rgba(255,92,0,1);
  --colors-tomato-10-light: rgba(242,76,0,1);
  --colors-tomato-10-dark: rgba(241,79,0,1);
  --colors-tomato-11-light: rgba(208,53,0,1);
  --colors-tomato-11-dark: rgba(255,154,113,1);
  --colors-tomato-12-light: rgba(92,39,31,1);
  --colors-tomato-12-dark: rgba(251,211,203,1);
  --colors-grass-1-light: rgba(251,254,251,1);
  --colors-grass-1-dark: rgba(14,21,17,1);
  --colors-grass-2-light: rgba(245,251,245,1);
  --colors-grass-2-dark: rgba(20,26,21,1);
  --colors-grass-3-light: rgba(233,246,233,1);
  --colors-grass-3-dark: rgba(27,42,30,1);
  --colors-grass-4-light: rgba(218,241,219,1);
  --colors-grass-4-dark: rgba(29,58,36,1);
  --colors-grass-5-light: rgba(201,232,202,1);
  --colors-grass-5-dark: rgba(37,72,45,1);
  --colors-grass-6-light: rgba(178,221,181,1);
  --colors-grass-6-dark: rgba(45,87,54,1);
  --colors-grass-7-light: rgba(148,206,154,1);
  --colors-grass-7-dark: rgba(54,103,64,1);
  --colors-grass-8-light: rgba(101,186,116,1);
  --colors-grass-8-dark: rgba(62,121,73,1);
  --colors-grass-9-light: rgba(70,167,88,1);
  --colors-grass-9-dark: rgba(70,167,88,1);
  --colors-grass-10-light: rgba(62,155,79,1);
  --colors-grass-10-dark: rgba(83,179,101,1);
  --colors-grass-11-light: rgba(42,126,59,1);
  --colors-grass-11-dark: rgba(113,208,131,1);
  --colors-grass-12-light: rgba(32,60,37,1);
  --colors-grass-12-dark: rgba(194,240,194,1);
  --colors-sky-1-light: rgba(243,248,249,1);
  --colors-sky-1-dark: rgba(0,0,0,1);
  --colors-sky-2-light: rgba(234,244,247,1);
  --colors-sky-2-dark: rgba(8,20,24,1);
  --colors-sky-3-light: rgba(212,241,248,1);
  --colors-sky-3-dark: rgba(3,40,48,1);
  --colors-sky-4-light: rgba(189,235,247,1);
  --colors-sky-4-dark: rgba(0,55,66,1);
  --colors-sky-5-light: rgba(166,227,242,1);
  --colors-sky-5-dark: rgba(0,70,83,1);
  --colors-sky-6-light: rgba(138,215,234,1);
  --colors-sky-6-dark: rgba(6,86,100,1);
  --colors-sky-7-light: rgba(99,200,222,1);
  --colors-sky-7-dark: rgba(18,105,121,1);
  --colors-sky-8-light: rgba(0,179,210,1);
  --colors-sky-8-dark: rgba(19,129,150,1);
  --colors-sky-9-light: rgba(0,186,220,1);
  --colors-sky-9-dark: rgba(111,211,234,1);
  --colors-sky-10-light: rgba(0,173,206,1);
  --colors-sky-10-dark: rgba(107,199,221,1);
  --colors-sky-11-light: rgba(0,116,140,1);
  --colors-sky-11-dark: rgba(102,202,225,1);
  --colors-sky-12-light: rgba(0,64,78,1);
  --colors-sky-12-dark: rgba(191,237,249,1);
  --colors-amber-1-light: rgba(248,247,245,1);
  --colors-amber-1-dark: rgba(0,0,0,1);
  --colors-amber-2-light: rgba(252,243,227,1);
  --colors-amber-2-dark: rgba(21,17,9,1);
  --colors-amber-3-light: rgba(255,235,186,1);
  --colors-amber-3-dark: rgba(41,30,6,1);
  --colors-amber-4-light: rgba(255,224,148,1);
  --colors-amber-4-dark: rgba(59,39,0,1);
  --colors-amber-5-light: rgba(255,212,110,1);
  --colors-amber-5-dark: rgba(73,49,0,1);
  --colors-amber-6-light: rgba(247,201,108,1);
  --colors-amber-6-dark: rgba(87,63,6,1);
  --colors-amber-7-light: rgba(228,183,91,1);
  --colors-amber-7-dark: rgba(107,81,26,1);
  --colors-amber-8-light: rgba(211,159,39,1);
  --colors-amber-8-dark: rgba(136,104,36,1);
  --colors-amber-9-light: rgba(255,186,0,1);
  --colors-amber-9-dark: rgba(246,182,0,1);
  --colors-amber-10-light: rgba(241,177,0,1);
  --colors-amber-10-dark: rgba(235,171,0,1);
  --colors-amber-11-light: rgba(148,102,0,1);
  --colors-amber-11-dark: rgba(255,198,54,1);
  --colors-amber-12-light: rgba(71,57,28,1);
  --colors-amber-12-dark: rgba(255,230,184,1);
  --overlays-black-alpha-1-light: rgba(0,0,0,0.05098);
  --overlays-black-alpha-1-dark: rgba(0,0,0,0.05098);
  --overlays-black-alpha-2-light: rgba(0,0,0,0.10196);
  --overlays-black-alpha-2-dark: rgba(0,0,0,0.10196);
  --overlays-black-alpha-3-light: rgba(0,0,0,0.14902);
  --overlays-black-alpha-3-dark: rgba(0,0,0,0.14902);
  --overlays-black-alpha-4-light: rgba(0,0,0,0.2);
  --overlays-black-alpha-4-dark: rgba(0,0,0,0.2);
  --overlays-black-alpha-5-light: rgba(0,0,0,0.30196);
  --overlays-black-alpha-5-dark: rgba(0,0,0,0.30196);
  --overlays-black-alpha-6-light: rgba(0,0,0,0.4);
  --overlays-black-alpha-6-dark: rgba(0,0,0,0.4);
  --overlays-black-alpha-7-light: rgba(0,0,0,0.50196);
  --overlays-black-alpha-7-dark: rgba(0,0,0,0.50196);
  --overlays-black-alpha-8-light: rgba(0,0,0,0.6);
  --overlays-black-alpha-8-dark: rgba(0,0,0,0.6);
  --overlays-black-alpha-9-light: rgba(0,0,0,0.70196);
  --overlays-black-alpha-9-dark: rgba(0,0,0,0.70196);
  --overlays-black-alpha-10-light: rgba(0,0,0,0.8);
  --overlays-black-alpha-10-dark: rgba(0,0,0,0.8);
  --overlays-black-alpha-11-light: rgba(0,0,0,0.90196);
  --overlays-black-alpha-11-dark: rgba(0,0,0,0.90196);
  --overlays-black-alpha-12-light: rgba(0,0,0,0.94902);
  --overlays-black-alpha-12-dark: rgba(0,0,0,0.94902);
  --colors-tomato-alpha-1-light: rgba(211,218,255,0.0902);
  --colors-tomato-alpha-1-dark: rgba(255,255,255,0);
  --colors-tomato-alpha-2-light: rgba(230,18,13,0.03922);
  --colors-tomato-alpha-2-dark: rgba(255,143,102,0.10196);
  --colors-tomato-alpha-3-light: rgba(255,226,215,1);
  --colors-tomato-alpha-3-dark: rgba(255,110,60,0.2);
  --colors-tomato-alpha-4-light: rgba(255,205,182,1);
  --colors-tomato-alpha-4-dark: rgba(255,74,0,0.30196);
  --colors-tomato-alpha-5-light: rgba(255,190,162,1);
  --colors-tomato-alpha-5-dark: rgba(255,86,14,0.36078);
  --colors-tomato-alpha-6-light: rgba(255,174,141,1);
  --colors-tomato-alpha-6-dark: rgba(255,108,50,0.43137);
  --colors-tomato-alpha-7-light: rgba(246,64,0,0.50196);
  --colors-tomato-alpha-7-dark: rgba(255,119,68,0.52157);
  --colors-tomato-alpha-8-light: rgba(231,60,0,0.63137);
  --colors-tomato-alpha-8-dark: rgba(255,120,67,0.67059);
  --colors-tomato-alpha-9-light: rgba(255,92,0,1);
  --colors-tomato-alpha-9-dark: rgba(255,92,0,1);
  --colors-tomato-alpha-10-light: rgba(242,76,0,1);
  --colors-tomato-alpha-10-dark: rgba(255,84,0,0.94902);
  --colors-tomato-alpha-11-light: rgba(208,53,0,1);
  --colors-tomato-alpha-11-dark: rgba(255,154,113,1);
  --colors-tomato-alpha-12-light: rgba(73,21,0,0.90196);
  --colors-tomato-alpha-12-dark: rgba(255,218,201,1);
  --colors-grass-alpha-1-light: rgba(0,192,0,0.01961);
  --colors-grass-alpha-1-dark: rgba(0,222,18,0.01961);
  --colors-grass-alpha-2-light: rgba(0,153,0,0.03922);
  --colors-grass-alpha-2-dark: rgba(94,247,120,0.03922);
  --colors-grass-alpha-3-light: rgba(0,151,0,0.0902);
  --colors-grass-alpha-3-dark: rgba(112,254,140,0.1098);
  --colors-grass-alpha-4-light: rgba(0,159,7,0.14902);
  --colors-grass-alpha-4-dark: rgba(87,255,128,0.16863);
  --colors-grass-alpha-5-light: rgba(0,147,5,0.21176);
  --colors-grass-alpha-5-dark: rgba(104,255,139,0.23137);
  --colors-grass-alpha-6-light: rgba(0,143,10,0.30196);
  --colors-grass-alpha-6-dark: rgba(113,255,143,0.2902);
  --colors-grass-alpha-7-light: rgba(1,139,15,0.41961);
  --colors-grass-alpha-7-dark: rgba(119,253,146,0.36078);
  --colors-grass-alpha-8-light: rgba(0,141,25,0.6);
  --colors-grass-alpha-8-dark: rgba(119,253,144,0.43922);
  --colors-grass-alpha-9-light: rgba(0,134,25,0.72941);
  --colors-grass-alpha-9-dark: rgba(101,255,130,0.63137);
  --colors-grass-alpha-10-light: rgba(0,123,23,0.76078);
  --colors-grass-alpha-10-dark: rgba(114,255,141,0.67843);
  --colors-grass-alpha-11-light: rgba(0,101,20,0.83922);
  --colors-grass-alpha-11-dark: rgba(137,255,159,0.8);
  --colors-grass-alpha-12-light: rgba(0,32,6,0.87059);
  --colors-grass-alpha-12-dark: rgba(206,255,206,0.94118);
  --colors-sky-alpha-1-light: rgba(232,247,255,0.45098);
  --colors-sky-alpha-1-dark: rgba(255,255,255,0);
  --colors-sky-alpha-2-light: rgba(184,231,255,0.27059);
  --colors-sky-alpha-2-dark: rgba(85,213,255,0.0902);
  --colors-sky-alpha-3-light: rgba(141,228,255,0.36078);
  --colors-sky-alpha-3-dark: rgba(16,213,255,0.18824);
  --colors-sky-alpha-4-light: rgba(20,199,255,0.27059);
  --colors-sky-alpha-4-dark: rgba(0,213,255,0.25882);
  --colors-sky-alpha-5-light: rgba(1,186,239,0.34902);
  --colors-sky-alpha-5-dark: rgba(0,216,255,0.32941);
  --colors-sky-alpha-6-light: rgba(1,174,223,0.45098);
  --colors-sky-alpha-6-dark: rgba(16,220,255,0.38824);
  --colors-sky-alpha-7-light: rgba(2,169,208,0.61176);
  --colors-sky-alpha-7-dark: rgba(38,222,255,0.47059);
  --colors-sky-alpha-8-light: rgba(0,179,210,1);
  --colors-sky-alpha-8-dark: rgba(33,220,255,0.58824);
  --colors-sky-alpha-9-light: rgba(0,186,220,1);
  --colors-sky-alpha-9-dark: rgba(121,230,255,0.92157);
  --colors-sky-alpha-10-light: rgba(0,173,206,1);
  --colors-sky-alpha-10-dark: rgba(124,230,255,0.87059);
  --colors-sky-alpha-11-light: rgba(0,116,140,1);
  --colors-sky-alpha-11-dark: rgba(116,229,255,0.87843);
  --colors-sky-alpha-12-light: rgba(0,64,78,1);
  --colors-sky-alpha-12-dark: rgba(196,243,255,0.98039);
  --colors-amber-alpha-1-light: rgba(200,228,255,0.0902);
  --colors-amber-alpha-1-dark: rgba(255,255,255,0);
  --colors-amber-alpha-2-light: rgba(239,164,4,0.07059);
  --colors-amber-alpha-2-dark: rgba(255,207,110,0.07843);
  --colors-amber-alpha-3-light: rgba(255,235,186,1);
  --colors-amber-alpha-3-dark: rgba(255,187,38,0.16078);
  --colors-amber-alpha-4-light: rgba(255,224,148,1);
  --colors-amber-alpha-4-dark: rgba(255,169,0,0.23137);
  --colors-amber-alpha-5-light: rgba(255,212,110,1);
  --colors-amber-alpha-5-dark: rgba(255,172,0,0.2902);
  --colors-amber-alpha-6-light: rgba(243,164,2,0.56078);
  --colors-amber-alpha-6-dark: rgba(255,185,18,0.34118);
  --colors-amber-alpha-7-light: rgba(214,144,0,0.63137);
  --colors-amber-alpha-7-dark: rgba(255,194,62,0.41961);
  --colors-amber-alpha-8-light: rgba(203,142,1,0.78824);
  --colors-amber-alpha-8-dark: rgba(255,195,68,0.52941);
  --colors-amber-alpha-9-light: rgba(255,186,0,1);
  --colors-amber-alpha-9-dark: rgba(255,189,0,0.96078);
  --colors-amber-alpha-10-light: rgba(241,177,0,1);
  --colors-amber-alpha-10-dark: rgba(255,186,0,0.92157);
  --colors-amber-alpha-11-light: rgba(148,102,0,1);
  --colors-amber-alpha-11-dark: rgba(255,198,54,1);
  --colors-amber-alpha-12-light: rgba(47,33,0,0.8902);
  --colors-amber-alpha-12-dark: rgba(255,230,184,1);
  --overlays-white-alpha-1-light: rgba(255,255,255,0.05098);
  --overlays-white-alpha-1-dark: rgba(255,255,255,0.05098);
  --overlays-white-alpha-2-light: rgba(255,255,255,0.10196);
  --overlays-white-alpha-2-dark: rgba(255,255,255,0.10196);
  --overlays-white-alpha-3-light: rgba(255,255,255,0.14902);
  --overlays-white-alpha-3-dark: rgba(255,255,255,0.14902);
  --overlays-white-alpha-4-light: rgba(255,255,255,0.2);
  --overlays-white-alpha-4-dark: rgba(255,255,255,0.2);
  --overlays-white-alpha-5-light: rgba(255,255,255,0.30196);
  --overlays-white-alpha-5-dark: rgba(255,255,255,0.30196);
  --overlays-white-alpha-6-light: rgba(255,255,255,0.4);
  --overlays-white-alpha-6-dark: rgba(255,255,255,0.4);
  --overlays-white-alpha-7-light: rgba(255,255,255,0.50196);
  --overlays-white-alpha-7-dark: rgba(255,255,255,0.50196);
  --overlays-white-alpha-8-light: rgba(255,255,255,0.6);
  --overlays-white-alpha-8-dark: rgba(255,255,255,0.6);
  --overlays-white-alpha-9-light: rgba(255,255,255,0.70196);
  --overlays-white-alpha-9-dark: rgba(255,255,255,0.70196);
  --overlays-white-alpha-10-light: rgba(255,255,255,0.8);
  --overlays-white-alpha-10-dark: rgba(255,255,255,0.8);
  --overlays-white-alpha-11-light: rgba(255,255,255,0.90196);
  --overlays-white-alpha-11-dark: rgba(255,255,255,0.90196);
  --overlays-white-alpha-12-light: rgba(255,255,255,0.94902);
  --overlays-white-alpha-12-dark: rgba(255,255,255,0.94902);
  --variables-misc-white-to-dark-light: rgba(255,255,255,1);
  --variables-misc-backdrop-dark: rgba(0,0,0,0.74902);
  --variables-effects-translucent-light: rgba(255,255,255,0.8);
  --variables-effects-translucent-dark: rgba(29,29,33,0.70196);
  --variables-effects-solid-light: rgba(255,255,255,1);
  --variables-effects-solid-dark: rgba(0,0,0,1);
  --variables-misc-white-to-dark-2-light: rgba(255,255,255,0.90196);
  --variables-misc-white-to-dark-2-dark: rgba(0,0,0,0.25098);
  --variables-misc-dark-to-white-dark: rgba(255,255,255,1);
  --colors-scale-1-light: rgba(253,253,255,1);
  --colors-scale-1-dark: rgba(0,0,0,1);
  --colors-scale-2-light: rgba(248,248,255,1);
  --colors-scale-2-dark: rgba(15,14,34,1);
  --colors-scale-3-light: rgba(240,241,255,1);
  --colors-scale-3-dark: rgba(32,25,77,1);
  --colors-scale-4-light: rgba(229,230,255,1);
  --colors-scale-4-dark: rgba(45,28,111,1);
  --colors-scale-5-light: rgba(217,218,255,1);
  --colors-scale-5-dark: rgba(55,39,128,1);
  --colors-scale-6-light: rgba(203,203,255,1);
  --colors-scale-6-dark: rgba(65,50,144,1);
  --colors-scale-7-light: rgba(183,181,255,1);
  --colors-scale-7-dark: rgba(79,63,167,1);
  --colors-scale-8-light: rgba(158,150,255,1);
  --colors-scale-8-dark: rgba(95,77,201,1);
  --colors-scale-9-light: rgba(113,77,255,1);
  --colors-scale-9-dark: rgba(113,77,255,1);
  --colors-scale-10-light: rgba(101,68,229,1);
  --colors-scale-10-dark: rgba(102,60,239,1);
  --colors-scale-11-light: rgba(97,62,224,1);
  --colors-scale-11-dark: rgba(173,168,255,1);
  --colors-scale-12-light: rgba(46,30,113,1);
  --colors-scale-12-dark: rgba(221,222,255,1);
  --colors-scale-alpha-1-light: rgba(0,0,255,0.01176);
  --colors-scale-alpha-1-dark: rgba(0,0,0,0);
  --colors-scale-alpha-2-light: rgba(0,0,255,0.03137);
  --colors-scale-alpha-2-dark: rgba(113,105,255,0.12941);
  --colors-scale-alpha-3-light: rgba(0,17,255,0.05882);
  --colors-scale-alpha-3-dark: rgba(106,83,255,0.30196);
  --colors-scale-alpha-4-light: rgba(0,10,255,0.10196);
  --colors-scale-alpha-4-dark: rgba(104,65,255,0.43922);
  --colors-scale-alpha-5-light: rgba(0,7,255,0.14902);
  --colors-scale-alpha-5-dark: rgba(110,78,255,0.50196);
  --colors-scale-alpha-6-light: rgba(0,0,255,0.2);
  --colors-scale-alpha-6-dark: rgba(115,89,255,0.56078);
  --colors-scale-alpha-7-light: rgba(7,1,255,0.2902);
  --colors-scale-alpha-7-dark: rgba(121,96,255,0.65098);
  --colors-scale-alpha-8-light: rgba(20,0,255,0.41176);
  --colors-scale-alpha-8-dark: rgba(121,98,255,0.78824);
  --colors-scale-alpha-9-light: rgba(52,0,255,0.70196);
  --colors-scale-alpha-9-dark: rgba(113,77,255,1);
  --colors-scale-alpha-10-light: rgba(45,0,220,0.72941);
  --colors-scale-alpha-10-dark: rgba(109,64,255,0.94118);
  --colors-scale-alpha-11-light: rgba(46,0,214,0.76078);
  --colors-scale-alpha-11-dark: rgba(173,168,255,1);
  --colors-scale-alpha-12-light: rgba(18,0,94,0.87843);
  --colors-scale-alpha-12-dark: rgba(221,222,255,1);
  --colors-mauve-1-light: rgba(251,252,255,1);
  --colors-mauve-1-dark: rgba(0,0,0,1);
  --colors-mauve-alpha-1-light: rgba(0,64,255,0.01961);
  --colors-mauve-alpha-1-dark: rgba(0,0,0,0);
  --colors-mauve-2-light: rgba(247,250,255,1);
  --colors-mauve-2-dark: rgba(16,18,23,1);
  --colors-mauve-3-light: rgba(236,240,249,1);
  --colors-mauve-3-dark: rgba(28,31,37,1);
  --colors-mauve-4-light: rgba(227,232,244,1);
  --colors-mauve-4-dark: rgba(36,40,48,1);
  --colors-mauve-5-light: rgba(218,225,240,1);
  --colors-mauve-5-dark: rgba(43,48,57,1);
  --colors-mauve-6-light: rgba(209,218,235,1);
  --colors-mauve-6-dark: rgba(52,57,69,1);
  --colors-mauve-7-light: rgba(197,207,228,1);
  --colors-mauve-7-dark: rgba(64,71,86,1);
  --colors-mauve-8-light: rgba(175,188,216,1);
  --colors-mauve-8-dark: rgba(87,96,116,1);
  --colors-mauve-9-light: rgba(129,142,169,1);
  --colors-mauve-9-dark: rgba(100,110,131,1);
  --colors-mauve-10-light: rgba(120,131,156,1);
  --colors-mauve-10-dark: rgba(114,123,144,1);
  --colors-mauve-11-light: rgba(91,101,121,1);
  --colors-mauve-11-dark: rgba(172,180,196,1);
  --colors-mauve-12-light: rgba(25,32,47,1);
  --colors-mauve-12-dark: rgba(236,238,242,1);
  --colors-mauve-alpha-2-light: rgba(0,96,255,0.03137);
  --colors-mauve-alpha-2-dark: rgba(178,200,255,0.0902);
  --colors-mauve-alpha-3-light: rgba(0,54,175,0.07059);
  --colors-mauve-alpha-3-dark: rgba(193,214,255,0.14902);
  --colors-mauve-alpha-4-light: rgba(0,46,155,0.1098);
  --colors-mauve-alpha-4-dark: rgba(192,213,255,0.18824);
  --colors-mauve-alpha-5-light: rgba(0,49,152,0.14902);
  --colors-mauve-alpha-5-dark: rgba(193,215,255,0.21961);
  --colors-mauve-alpha-6-light: rgba(0,50,145,0.18039);
  --colors-mauve-alpha-6-dark: rgba(193,211,255,0.27059);
  --colors-mauve-alpha-7-light: rgba(0,44,137,0.23137);
  --colors-mauve-alpha-7-dark: rgba(190,211,255,0.34118);
  --colors-mauve-alpha-8-light: rgba(0,42,131,0.3098);
  --colors-mauve-alpha-8-dark: rgba(192,212,255,0.45098);
  --colors-mauve-alpha-9-light: rgba(0,27,81,0.4902);
  --colors-mauve-alpha-9-dark: rgba(195,215,255,0.5098);
  --colors-mauve-alpha-10-light: rgba(0,21,68,0.52941);
  --colors-mauve-alpha-10-dark: rgba(202,218,255,0.56078);
  --colors-mauve-alpha-11-light: rgba(0,16,47,0.63922);
  --colors-mauve-alpha-11-dark: rgba(224,234,255,0.76863);
  --colors-mauve-alpha-12-light: rgba(0,8,24,0.90196);
  --colors-mauve-alpha-12-dark: rgba(249,251,255,0.94902);
  --colors-dune-1-light: rgba(255,252,252,1);
  --colors-dune-1-dark: rgba(24,14,15,1);
  --colors-dune-2-light: rgba(255,247,248,1);
  --colors-dune-2-dark: rgba(32,19,21,1);
  --colors-dune-3-light: rgba(255,232,235,1);
  --colors-dune-3-dark: rgba(63,13,25,1);
  --colors-dune-4-light: rgba(255,216,222,1);
  --colors-dune-4-dark: rgba(86,2,30,1);
  --colors-dune-5-light: rgba(255,201,209,1);
  --colors-dune-5-dark: rgba(103,5,38,1);
  --colors-dune-6-light: rgba(255,184,194,1);
  --colors-dune-6-dark: rgba(121,21,50,1);
  --colors-dune-7-light: rgba(254,163,176,1);
  --colors-dune-7-dark: rgba(148,35,65,1);
  --colors-dune-8-light: rgba(247,134,153,1);
  --colors-dune-8-dark: rgba(194,45,86,1);
  --colors-dune-9-light: rgba(137,22,56,1);
  --colors-dune-9-dark: rgba(137,22,56,1);
  --colors-dune-10-light: rgba(118,0,41,1);
  --colors-dune-10-dark: rgba(113,9,43,1);
  --colors-dune-11-light: rgba(182,68,92,1);
  --colors-dune-11-dark: rgba(255,141,162,1);
  --colors-dune-12-light: rgba(113,0,37,1);
  --colors-dune-12-dark: rgba(255,206,213,1);
  --colors-dune-alpha-1-light: rgba(255,0,0,0.01176);
  --colors-dune-alpha-1-dark: rgba(241,0,0,0.03137);
  --colors-dune-alpha-2-light: rgba(255,0,32,0.03137);
  --colors-dune-alpha-2-dark: rgba(242,47,77,0.07059);
  --colors-dune-alpha-3-light: rgba(255,0,34,0.0902);
  --colors-dune-alpha-3-dark: rgba(252,0,58,0.2);
  --colors-dune-alpha-4-light: rgba(255,0,40,0.14902);
  --colors-dune-alpha-4-dark: rgba(255,0,62,0.2902);
  --colors-dune-alpha-5-light: rgba(255,0,38,0.21176);
  --colors-dune-alpha-5-dark: rgba(253,0,75,0.36078);
  --colors-dune-alpha-6-light: rgba(255,0,36,0.27843);
  --colors-dune-alpha-6-dark: rgba(253,26,92,0.43922);
  --colors-dune-alpha-7-light: rgba(253,0,37,0.36078);
  --colors-dune-alpha-7-dark: rgba(254,49,103,0.54902);
  --colors-dune-alpha-8-light: rgba(239,0,41,0.47059);
  --colors-dune-alpha-8-dark: rgba(255,55,110,0.74902);
  --colors-dune-alpha-9-light: rgba(126,0,37,0.9098);
  --colors-dune-alpha-9-dark: rgba(255,27,95,0.5098);
  --colors-dune-alpha-10-light: rgba(118,0,41,1);
  --colors-dune-alpha-10-dark: rgba(255,0,82,0.4);
  --colors-dune-alpha-11-light: rgba(156,0,33,0.72941);
  --colors-dune-alpha-11-dark: rgba(255,141,162,1);
  --colors-dune-alpha-12-light: rgba(113,0,37,1);
  --colors-dune-alpha-12-dark: rgba(255,206,213,1);
  --colors-dune-shade-1-light: rgba(252,252,252,1);
  --colors-dune-shade-1-dark: rgba(17,17,17,1);
  --colors-dune-shade-2-light: rgba(249,249,249,1);
  --colors-dune-shade-2-dark: rgba(25,25,25,1);
  --colors-dune-shade-3-light: rgba(240,240,240,1);
  --colors-dune-shade-3-dark: rgba(34,34,34,1);
  --colors-dune-shade-4-light: rgba(232,232,232,1);
  --colors-dune-shade-4-dark: rgba(41,41,41,1);
  --colors-dune-shade-5-light: rgba(225,225,225,1);
  --colors-dune-shade-5-dark: rgba(48,48,48,1);
  --colors-dune-shade-6-light: rgba(217,217,217,1);
  --colors-dune-shade-6-dark: rgba(57,57,57,1);
  --colors-dune-shade-7-light: rgba(206,206,206,1);
  --colors-dune-shade-7-dark: rgba(71,71,71,1);
  --colors-dune-shade-8-light: rgba(187,187,187,1);
  --colors-dune-shade-8-dark: rgba(96,96,96,1);
  --colors-dune-shade-9-light: rgba(140,140,140,1);
  --colors-dune-shade-9-dark: rgba(109,109,109,1);
  --colors-dune-shade-10-light: rgba(130,130,130,1);
  --colors-dune-shade-10-dark: rgba(123,123,123,1);
  --colors-dune-shade-11-light: rgba(99,99,99,1);
  --colors-dune-shade-11-dark: rgba(179,179,179,1);
  --colors-dune-shade-12-light: rgba(31,31,31,1);
  --colors-dune-shade-12-dark: rgba(237,237,237,1);
  --colors-dune-shade-alpha-1-light: rgba(0,0,0,0.01176);
  --colors-dune-shade-alpha-1-dark: rgba(0,0,0,0);
  --colors-dune-shade-alpha-2-light: rgba(0,0,0,0.01961);
  --colors-dune-shade-alpha-2-dark: rgba(255,255,255,0.03922);
  --colors-dune-shade-alpha-3-light: rgba(0,0,0,0.05882);
  --colors-dune-shade-alpha-3-dark: rgba(255,255,255,0.07059);
  --colors-dune-shade-alpha-4-light: rgba(0,0,0,0.0902);
  --colors-dune-shade-alpha-4-dark: rgba(255,255,255,0.10196);
  --colors-dune-shade-alpha-5-light: rgba(0,0,0,0.12157);
  --colors-dune-shade-alpha-5-dark: rgba(255,255,255,0.12941);
  --colors-dune-shade-alpha-6-light: rgba(0,0,0,0.14902);
  --colors-dune-shade-alpha-6-dark: rgba(255,255,255,0.16863);
  --colors-dune-shade-alpha-7-light: rgba(0,0,0,0.18824);
  --colors-dune-shade-alpha-7-dark: rgba(255,255,255,0.23137);
  --colors-dune-shade-alpha-8-light: rgba(0,0,0,0.27059);
  --colors-dune-shade-alpha-8-dark: rgba(255,255,255,0.32941);
  --colors-dune-shade-alpha-9-light: rgba(0,0,0,0.45098);
  --colors-dune-shade-alpha-9-dark: rgba(255,255,255,0.38824);
  --colors-dune-shade-alpha-10-light: rgba(0,0,0,0.4902);
  --colors-dune-shade-alpha-10-dark: rgba(255,255,255,0.45098);
  --colors-dune-shade-alpha-11-light: rgba(0,0,0,0.61176);
  --colors-dune-shade-alpha-11-dark: rgba(255,255,255,0.67843);
  --colors-dune-shade-alpha-12-light: rgba(0,0,0,0.87843);
  --colors-dune-shade-alpha-12-dark: rgba(255,255,255,0.92941);
  --colors-pink-1-light: rgba(255,252,254,1);
  --colors-pink-1-dark: rgba(31,18,27,1);
  --colors-pink-2-light: rgba(255,247,252,1);
  --colors-pink-2-dark: rgba(41,21,35,1);
  --colors-pink-3-light: rgba(254,238,248,1);
  --colors-pink-3-dark: rgba(55,25,46,1);
  --colors-pink-4-light: rgba(252,229,243,1);
  --colors-pink-4-dark: rgba(65,28,53,1);
  --colors-pink-5-light: rgba(249,216,236,1);
  --colors-pink-5-dark: rgba(75,31,61,1);
  --colors-pink-6-light: rgba(243,198,226,1);
  --colors-pink-6-dark: rgba(93,34,74,1);
  --colors-pink-7-light: rgba(236,173,212,1);
  --colors-pink-7-dark: rgba(124,40,96,1);
  --colors-pink-8-light: rgba(227,142,195,1);
  --colors-pink-8-dark: rgba(188,47,136,1);
  --colors-pink-9-light: rgba(214,64,159,1);
  --colors-pink-9-dark: rgba(214,64,159,1);
  --colors-pink-10-light: rgba(205,48,147,1);
  --colors-pink-10-dark: rgba(228,94,175,1);
  --colors-pink-11-light: rgba(196,28,135,1);
  --colors-pink-11-dark: rgba(249,134,201,1);
  --colors-pink-12-light: rgba(101,18,73,1);
  --colors-pink-12-dark: rgba(253,209,234,1);
  --colors-plum-1-light: rgba(254,252,255,1);
  --colors-plum-1-dark: rgba(29,19,29,1);
  --colors-plum-2-light: rgba(255,248,255,1);
  --colors-plum-2-dark: rgba(38,21,38,1);
  --colors-plum-3-light: rgba(252,239,252,1);
  --colors-plum-3-dark: rgba(52,27,52,1);
  --colors-plum-4-light: rgba(249,229,249,1);
  --colors-plum-4-dark: rgba(61,31,62,1);
  --colors-plum-5-light: rgba(243,217,244,1);
  --colors-plum-5-dark: rgba(70,35,72,1);
  --colors-plum-6-light: rgba(235,200,237,1);
  --colors-plum-6-dark: rgba(84,41,87,1);
  --colors-plum-7-light: rgba(223,175,227,1);
  --colors-plum-7-dark: rgba(110,52,115,1);
  --colors-plum-8-light: rgba(207,145,216,1);
  --colors-plum-8-dark: rgba(158,73,171,1);
  --colors-plum-9-light: rgba(171,74,186,1);
  --colors-plum-9-dark: rgba(171,74,186,1);
  --colors-plum-10-light: rgba(164,60,180,1);
  --colors-plum-10-dark: rgba(190,102,205,1);
  --colors-plum-11-light: rgba(156,43,173,1);
  --colors-plum-11-dark: rgba(220,142,235,1);
  --colors-plum-12-light: rgba(83,25,93,1);
  --colors-plum-12-dark: rgba(244,212,244,1);
  --colors-pink-alpha-1-light: rgba(255,5,172,0.01176);
  --colors-pink-alpha-1-dark: rgba(250,0,121,0.03137);
  --colors-pink-alpha-2-light: rgba(255,5,159,0.03137);
  --colors-pink-alpha-2-dark: rgba(254,0,174,0.07059);
  --colors-pink-alpha-3-light: rgba(240,1,148,0.07059);
  --colors-pink-alpha-3-dark: rgba(254,31,187,0.14118);
  --colors-pink-alpha-4-light: rgba(226,0,139,0.10196);
  --colors-pink-alpha-4-dark: rgba(254,47,189,0.18039);
  --colors-pink-alpha-5-light: rgba(216,0,129,0.14902);
  --colors-pink-alpha-5-dark: rgba(255,56,192,0.21961);
  --colors-pink-alpha-6-light: rgba(201,1,124,0.21961);
  --colors-pink-alpha-6-dark: rgba(255,57,192,0.30196);
  --colors-pink-alpha-7-light: rgba(196,0,121,0.32157);
  --colors-pink-alpha-7-dark: rgba(255,61,190,0.43137);
  --colors-pink-alpha-8-light: rgba(192,0,118,0.43922);
  --colors-pink-alpha-8-dark: rgba(255,57,182,0.7098);
  --colors-pink-alpha-9-light: rgba(200,0,127,0.74902);
  --colors-pink-alpha-9-dark: rgba(255,73,188,0.81961);
  --colors-pink-alpha-10-light: rgba(193,0,122,0.81176);
  --colors-pink-alpha-10-dark: rgba(255,103,194,0.87843);
  --colors-pink-alpha-11-light: rgba(189,0,120,0.8902);
  --colors-pink-alpha-11-dark: rgba(255,137,206,0.96863);
  --colors-pink-alpha-12-light: rgba(90,0,60,0.92941);
  --colors-pink-alpha-12-dark: rgba(255,210,236,0.98824);
  --colors-plum-alpha-1-light: rgba(172,5,255,0.01176);
  --colors-plum-alpha-1-dark: rgba(251,0,251,0.01961);
  --colors-plum-alpha-2-light: rgba(255,5,255,0.03137);
  --colors-plum-alpha-2-dark: rgba(253,0,253,0.05882);
  --colors-plum-alpha-3-light: rgba(208,1,208,0.05882);
  --colors-plum-alpha-3-dark: rgba(254,48,254,0.12157);
  --colors-plum-alpha-4-light: rgba(196,0,196,0.10196);
  --colors-plum-alpha-4-dark: rgba(248,66,254,0.16863);
  --colors-plum-alpha-5-light: rgba(175,2,181,0.14902);
  --colors-plum-alpha-5-dark: rgba(246,77,255,0.21176);
  --colors-plum-alpha-6-light: rgba(163,0,172,0.21961);
  --colors-plum-alpha-6-dark: rgba(244,86,255,0.27059);
  --colors-plum-alpha-7-light: rgba(152,0,166,0.3098);
  --colors-plum-alpha-7-dark: rgba(242,95,255,0.38824);
  --colors-plum-alpha-8-light: rgba(143,0,165,0.43137);
  --colors-plum-alpha-8-dark: rgba(234,101,255,0.63922);
  --colors-plum-alpha-9-light: rgba(137,0,158,0.7098);
  --colors-plum-alpha-9-dark: rgba(233,95,255,0.70196);
  --colors-plum-alpha-10-light: rgba(136,0,157,0.76863);
  --colors-plum-alpha-10-dark: rgba(235,124,255,0.78039);
  --colors-plum-alpha-11-light: rgba(136,0,156,0.83137);
  --colors-plum-alpha-11-dark: rgba(238,153,255,0.9098);
  --colors-plum-alpha-12-light: rgba(64,0,75,0.90196);
  --colors-plum-alpha-12-dark: rgba(255,221,255,0.94902);
  --colors-teal-1-light: rgba(250,254,253,1);
  --colors-teal-1-dark: rgba(9,26,22,1);
  --colors-teal-2-light: rgba(241,252,250,1);
  --colors-teal-2-dark: rgba(9,31,26,1);
  --colors-teal-3-light: rgba(231,249,245,1);
  --colors-teal-3-dark: rgba(13,41,35,1);
  --colors-teal-4-light: rgba(217,243,238,1);
  --colors-teal-4-dark: rgba(15,49,43,1);
  --colors-teal-5-light: rgba(199,235,229,1);
  --colors-teal-5-dark: rgba(18,58,50,1);
  --colors-teal-6-light: rgba(175,223,215,1);
  --colors-teal-6-dark: rgba(22,70,61,1);
  --colors-teal-7-light: rgba(141,206,195,1);
  --colors-teal-7-dark: rgba(27,94,84,1);
  --colors-teal-8-light: rgba(83,185,171,1);
  --colors-teal-8-dark: rgba(35,139,127,1);
  --colors-teal-9-light: rgba(18,165,148,1);
  --colors-teal-9-dark: rgba(18,165,148,1);
  --colors-teal-10-light: rgba(14,152,136,1);
  --colors-teal-10-dark: rgba(10,187,164,1);
  --colors-teal-11-light: rgba(6,122,111,1);
  --colors-teal-11-dark: rgba(11,216,182,1);
  --colors-teal-12-light: rgba(13,61,56,1);
  --colors-teal-12-dark: rgba(173,240,221,1);
  --colors-teal-alpha-1-light: rgba(5,205,155,0.01961);
  --colors-teal-alpha-1-dark: rgba(0,246,0,0.01176);
  --colors-teal-alpha-2-light: rgba(1,200,164,0.05882);
  --colors-teal-alpha-2-dark: rgba(0,250,87,0.03137);
  --colors-teal-alpha-3-light: rgba(2,192,151,0.10196);
  --colors-teal-alpha-3-dark: rgba(0,254,174,0.07059);
  --colors-teal-alpha-4-light: rgba(2,175,140,0.14902);
  --colors-teal-alpha-4-dark: rgba(0,253,199,0.1098);
  --colors-teal-alpha-5-light: rgba(0,164,137,0.21961);
  --colors-teal-alpha-5-dark: rgba(0,254,199,0.14902);
  --colors-teal-alpha-6-light: rgba(0,153,128,0.3098);
  --colors-teal-alpha-6-dark: rgba(14,254,210,0.2);
  --colors-teal-alpha-7-light: rgba(1,146,122,0.45098);
  --colors-teal-alpha-7-dark: rgba(34,254,221,0.30196);
  --colors-teal-alpha-8-light: rgba(0,151,131,0.67843);
  --colors-teal-alpha-8-dark: rgba(46,255,231,0.50196);
  --colors-teal-alpha-9-light: rgba(0,158,140,0.92941);
  --colors-teal-alpha-9-dark: rgba(14,255,227,0.61176);
  --colors-teal-alpha-10-light: rgba(0,146,129,0.94902);
  --colors-teal-alpha-10-dark: rgba(4,255,221,0.7098);
  --colors-teal-alpha-11-light: rgba(0,119,107,0.98039);
  --colors-teal-alpha-11-dark: rgba(8,255,214,0.83137);
  --colors-teal-alpha-12-light: rgba(0,51,46,0.94902);
  --colors-teal-alpha-12-dark: rgba(183,255,235,0.94118);
  --colors-outlier-1-light: rgba(254,251,255,1);
  --colors-outlier-1-dark: rgba(0,0,0,1);
  --colors-outlier-2-light: rgba(252,247,255,1);
  --colors-outlier-2-dark: rgba(21,16,24,1);
  --colors-outlier-3-light: rgba(245,236,255,1);
  --colors-outlier-3-dark: rgba(34,29,37,1);
  --colors-outlier-4-light: rgba(240,225,255,1);
  --colors-outlier-4-dark: rgba(43,38,46,1);
  --colors-outlier-5-light: rgba(234,215,255,1);
  --colors-outlier-5-dark: rgba(51,46,55,1);
  --colors-outlier-6-light: rgba(228,205,255,1);
  --colors-outlier-6-dark: rgba(61,55,65,1);
  --colors-outlier-7-light: rgba(220,191,254,1);
  --colors-outlier-7-dark: rgba(75,69,79,1);
  --colors-outlier-8-light: rgba(205,165,249,1);
  --colors-outlier-8-dark: rgba(100,94,104,1);
  --colors-outlier-9-light: rgba(27,0,48,1);
  --colors-outlier-9-dark: rgba(252,247,255,1);
  --colors-outlier-10-light: rgba(53,27,78,1);
  --colors-outlier-10-dark: rgba(243,238,246,1);
  --colors-outlier-11-light: rgba(117,80,156,1);
  --colors-outlier-11-dark: rgba(184,177,188,1);
  --colors-outlier-12-light: rgba(44,17,68,1);
  --colors-outlier-12-dark: rgba(241,236,244,1);
  --colors-outlier-alpha-1-light: rgba(192,0,255,0.01961);
  --colors-outlier-alpha-1-dark: rgba(0,0,0,0);
  --colors-outlier-alpha-2-light: rgba(160,0,255,0.03137);
  --colors-outlier-alpha-2-dark: rgba(224,170,255,0.0902);
  --colors-outlier-alpha-3-light: rgba(121,0,255,0.07059);
  --colors-outlier-alpha-3-dark: rgba(235,200,255,0.14902);
  --colors-outlier-alpha-4-light: rgba(128,0,255,0.12157);
  --colors-outlier-alpha-4-dark: rgba(239,211,255,0.18039);
  --colors-outlier-alpha-5-light: rgba(122,0,255,0.16078);
  --colors-outlier-alpha-5-dark: rgba(237,214,255,0.21961);
  --colors-outlier-alpha-6-light: rgba(118,0,255,0.2);
  --colors-outlier-alpha-6-dark: rgba(240,216,255,0.25098);
  --colors-outlier-alpha-7-light: rgba(116,0,252,0.25098);
  --colors-outlier-alpha-7-dark: rgba(243,223,255,0.3098);
  --colors-outlier-alpha-8-light: rgba(114,1,239,0.34902);
  --colors-outlier-alpha-8-dark: rgba(246,231,255,0.41176);
  --colors-outlier-alpha-9-light: rgba(27,0,48,1);
  --colors-outlier-alpha-9-dark: rgba(252,247,255,1);
  --colors-outlier-alpha-10-light: rgba(29,0,57,0.8902);
  --colors-outlier-alpha-10-dark: rgba(252,247,255,0.96078);
  --colors-outlier-alpha-11-light: rgba(54,0,111,0.6902);
  --colors-outlier-alpha-11-dark: rgba(250,240,255,0.74118);
  --colors-outlier-alpha-12-light: rgba(29,0,55,0.92941);
  --colors-outlier-alpha-12-dark: rgba(252,247,255,0.96078);
  --colors-outlier-shade-1-light: rgba(253,252,254,1);
  --colors-outlier-shade-1-dark: rgba(0,0,0,1);
  --colors-outlier-shade-2-light: rgba(250,248,254,1);
  --colors-outlier-shade-2-dark: rgba(21,15,28,1);
  --colors-outlier-shade-3-light: rgba(242,239,247,1);
  --colors-outlier-shade-3-dark: rgba(34,27,45,1);
  --colors-outlier-shade-4-light: rgba(234,230,241,1);
  --colors-outlier-shade-4-dark: rgba(43,35,55,1);
  --colors-outlier-shade-5-light: rgba(228,222,237,1);
  --colors-outlier-shade-5-dark: rgba(51,43,64,1);
  --colors-outlier-shade-6-light: rgba(221,214,231,1);
  --colors-outlier-shade-6-dark: rgba(61,52,74,1);
  --colors-outlier-shade-7-light: rgba(210,202,224,1);
  --colors-outlier-shade-7-dark: rgba(75,66,88,1);
  --colors-outlier-shade-8-light: rgba(192,182,209,1);
  --colors-outlier-shade-8-dark: rgba(100,91,115,1);
  --colors-outlier-shade-9-light: rgba(146,137,162,1);
  --colors-outlier-shade-9-dark: rgba(114,105,129,1);
  --colors-outlier-shade-10-light: rgba(135,126,151,1);
  --colors-outlier-shade-10-dark: rgba(128,118,142,1);
  --colors-outlier-shade-11-light: rgba(104,95,117,1);
  --colors-outlier-shade-11-dark: rgba(184,174,200,1);
  --colors-outlier-shade-12-light: rgba(34,29,42,1);
  --colors-outlier-shade-12-dark: rgba(240,235,248,1);
  --colors-outlier-shade-alpha-1-light: rgba(85,0,170,0.01176);
  --colors-outlier-shade-alpha-1-dark: rgba(0,0,0,0);
  --colors-outlier-shade-alpha-2-light: rgba(73,0,219,0.03137);
  --colors-outlier-shade-alpha-2-dark: rgba(192,137,255,0.1098);
  --colors-outlier-shade-alpha-3-light: rgba(48,0,128,0.05882);
  --colors-outlier-shade-alpha-3-dark: rgba(193,153,255,0.18039);
  --colors-outlier-shade-alpha-4-light: rgba(41,0,113,0.10196);
  --colors-outlier-shade-alpha-4-dark: rgba(200,163,255,0.21961);
  --colors-outlier-shade-alpha-5-light: rgba(47,0,116,0.12941);
  --colors-outlier-shade-alpha-5-dark: rgba(204,172,255,0.25098);
  --colors-outlier-shade-alpha-6-light: rgba(44,0,106,0.16078);
  --colors-outlier-shade-alpha-6-dark: rgba(211,180,255,0.2902);
  --colors-outlier-shade-alpha-7-light: rgba(39,0,106,0.21176);
  --colors-outlier-shade-alpha-7-dark: rgba(218,192,255,0.34902);
  --colors-outlier-shade-alpha-8-light: rgba(35,0,95,0.2902);
  --colors-outlier-shade-alpha-8-dark: rgba(222,202,255,0.45098);
  --colors-outlier-shade-alpha-9-light: rgba(20,0,55,0.45882);
  --colors-outlier-shade-alpha-9-dark: rgba(226,208,255,0.5098);
  --colors-outlier-shade-alpha-10-light: rgba(18,0,50,0.5098);
  --colors-outlier-shade-alpha-10-dark: rgba(230,212,255,0.56078);
  --colors-outlier-shade-alpha-11-light: rgba(15,0,35,0.63137);
  --colors-outlier-shade-alpha-11-dark: rgba(235,222,255,0.78039);
  --colors-outlier-shade-alpha-12-light: rgba(6,0,15,0.8902);
  --colors-outlier-shade-alpha-12-dark: rgba(247,242,255,0.96863);
  --colors-outlier-2-1-light: rgba(248,247,246,1);
  --colors-outlier-2-1-dark: rgba(0,0,0,1);
  --colors-outlier-2-2-light: rgba(245,243,239,1);
  --colors-outlier-2-2-dark: rgba(19,18,16,1);
  --colors-outlier-2-3-light: rgba(237,232,225,1);
  --colors-outlier-2-3-dark: rgba(33,31,27,1);
  --colors-outlier-2-4-light: rgba(231,223,212,1);
  --colors-outlier-2-4-dark: rgba(43,40,35,1);
  --colors-outlier-2-5-light: rgba(224,213,198,1);
  --colors-outlier-2-5-dark: rgba(53,48,42,1);
  --colors-outlier-2-6-light: rgba(214,202,186,1);
  --colors-outlier-2-6-dark: rgba(65,59,50,1);
  --colors-outlier-2-7-light: rgba(199,188,172,1);
  --colors-outlier-2-7-dark: rgba(82,73,62,1);
  --colors-outlier-2-8-light: rgba(178,166,151,1);
  --colors-outlier-2-8-dark: rgba(107,97,82,1);
  --colors-outlier-2-9-light: rgba(140,129,114,1);
  --colors-outlier-2-9-dark: rgba(240,232,221,1);
  --colors-outlier-2-10-light: rgba(127,116,102,1);
  --colors-outlier-2-10-dark: rgba(230,222,212,1);
  --colors-outlier-2-11-light: rgba(103,93,79,1);
  --colors-outlier-2-11-dark: rgba(193,181,166,1);
  --colors-outlier-2-12-light: rgba(53,47,39,1);
  --colors-outlier-2-12-dark: rgba(241,233,222,1);
  --colors-outlier-2-alpha-1-light: rgba(226,239,255,0.18039);
  --colors-outlier-2-alpha-1-dark: rgba(0,0,0,0);
  --colors-outlier-2-alpha-2-light: rgba(27,79,103,0.03922);
  --colors-outlier-2-alpha-2-dark: rgba(255,242,215,0.07059);
  --colors-outlier-2-alpha-3-light: rgba(49,33,2,0.07843);
  --colors-outlier-2-alpha-3-dark: rgba(255,240,209,0.12941);
  --colors-outlier-2-alpha-4-light: rgba(88,54,4,0.12941);
  --colors-outlier-2-alpha-4-dark: rgba(255,238,208,0.16863);
  --colors-outlier-2-alpha-5-light: rgba(103,62,5,0.18824);
  --colors-outlier-2-alpha-5-dark: rgba(255,231,203,0.21176);
  --colors-outlier-2-alpha-6-light: rgba(90,53,2,0.23922);
  --colors-outlier-2-alpha-6-dark: rgba(255,232,197,0.25098);
  --colors-outlier-2-alpha-7-light: rgba(72,45,3,0.30196);
  --colors-outlier-2-alpha-7-dark: rgba(255,228,193,0.32157);
  --colors-outlier-2-alpha-8-light: rgba(58,34,3,0.38039);
  --colors-outlier-2-alpha-8-dark: rgba(255,232,196,0.41961);
  --colors-outlier-2-alpha-9-light: rgba(42,24,0,0.52941);
  --colors-outlier-2-alpha-9-dark: rgba(255,247,235,0.94118);
  --colors-outlier-2-alpha-10-light: rgba(38,21,1,0.58039);
  --colors-outlier-2-alpha-10-dark: rgba(255,246,235,0.90196);
  --colors-outlier-2-alpha-11-light: rgba(32,19,1,0.67843);
  --colors-outlier-2-alpha-11-dark: rgba(255,239,219,0.76078);
  --colors-outlier-2-alpha-12-light: rgba(16,10,1,0.83922);
  --colors-outlier-2-alpha-12-dark: rgba(255,247,235,0.94902);
  --colors-gray-1-light: rgba(247,247,247,1);
  --colors-gray-1-dark: rgba(0,0,0,1);
  --colors-gray-2-light: rgba(242,242,242,1);
  --colors-gray-2-dark: rgba(18,18,18,1);
  --colors-gray-3-light: rgba(233,233,233,1);
  --colors-gray-3-dark: rgba(31,31,31,1);
  --colors-gray-4-light: rgba(225,225,225,1);
  --colors-gray-4-dark: rgba(40,40,40,1);
  --colors-gray-5-light: rgba(217,217,217,1);
  --colors-gray-5-dark: rgba(48,48,48,1);
  --colors-gray-6-light: rgba(209,209,209,1);
  --colors-gray-6-dark: rgba(58,58,58,1);
  --colors-gray-7-light: rgba(198,198,198,1);
  --colors-gray-7-dark: rgba(71,71,71,1);
  --colors-gray-8-light: rgba(179,179,179,1);
  --colors-gray-8-dark: rgba(96,96,96,1);
  --colors-gray-9-light: rgba(132,132,132,1);
  --colors-gray-9-dark: rgba(109,109,109,1);
  --colors-gray-10-light: rgba(122,122,122,1);
  --colors-gray-10-dark: rgba(122,122,122,1);
  --colors-gray-11-light: rgba(92,92,92,1);
  --colors-gray-11-dark: rgba(179,179,179,1);
  --colors-gray-12-light: rgba(31,31,31,1);
  --colors-gray-12-dark: rgba(238,238,238,1);
  --colors-gray-alpha-1-light: rgba(232,242,255,0.27059);
  --colors-gray-alpha-1-dark: rgba(255,255,255,0);
  --colors-gray-alpha-2-light: rgba(20,101,201,0.05098);
  --colors-gray-alpha-2-dark: rgba(255,255,255,0.07059);
  --colors-gray-alpha-3-light: rgba(11,55,111,0.07843);
  --colors-gray-alpha-3-dark: rgba(255,255,255,0.12157);
  --colors-gray-alpha-4-light: rgba(7,38,77,0.1098);
  --colors-gray-alpha-4-dark: rgba(255,255,255,0.16078);
  --colors-gray-alpha-5-light: rgba(5,29,58,0.14902);
  --colors-gray-alpha-5-dark: rgba(255,255,255,0.18824);
  --colors-gray-alpha-6-light: rgba(4,23,46,0.18039);
  --colors-gray-alpha-6-dark: rgba(255,255,255,0.23137);
  --colors-gray-alpha-7-light: rgba(3,17,35,0.21961);
  --colors-gray-alpha-7-dark: rgba(255,255,255,0.27843);
  --colors-gray-alpha-8-light: rgba(1,11,24,0.2902);
  --colors-gray-alpha-8-dark: rgba(255,255,255,0.38039);
  --colors-gray-alpha-9-light: rgba(1,5,10,0.47843);
  --colors-gray-alpha-9-dark: rgba(255,255,255,0.43137);
  --colors-gray-alpha-10-light: rgba(2,6,10,0.52157);
  --colors-gray-alpha-10-dark: rgba(255,255,255,0.47843);
  --colors-gray-alpha-11-light: rgba(2,3,7,0.63922);
  --colors-gray-alpha-11-dark: rgba(255,255,255,0.70196);
  --colors-gray-alpha-12-light: rgba(0,1,1,0.87843);
  --colors-gray-alpha-12-dark: rgba(255,255,255,0.92941);
  --colors-lime-1-light: rgba(246,247,244,1);
  --colors-lime-1-dark: rgba(0,0,0,1);
  --colors-lime-2-light: rgba(242,246,235,1);
  --colors-lime-2-dark: rgba(16,18,10,1);
  --colors-lime-3-light: rgba(231,244,204,1);
  --colors-lime-3-dark: rgba(31,36,19,1);
  --colors-lime-4-light: rgba(220,238,180,1);
  --colors-lime-4-dark: rgba(44,51,25,1);
  --colors-lime-5-light: rgba(208,229,158,1);
  --colors-lime-5-dark: rgba(56,65,31,1);
  --colors-lime-6-light: rgba(193,214,143,1);
  --colors-lime-6-dark: rgba(68,79,39,1);
  --colors-lime-7-light: rgba(177,196,128,1);
  --colors-lime-7-dark: rgba(82,95,47,1);
  --colors-lime-8-light: rgba(153,175,95,1);
  --colors-lime-8-dark: rgba(98,114,55,1);
  --colors-lime-9-light: rgba(199,232,101,1);
  --colors-lime-9-dark: rgba(221,255,125,1);
  --colors-lime-10-light: rgba(190,221,99,1);
  --colors-lime-10-dark: rgba(212,245,115,1);
  --colors-lime-11-light: rgba(99,118,45,1);
  --colors-lime-11-dark: rgba(197,227,115,1);
  --colors-lime-12-light: rgba(56,64,39,1);
  --colors-lime-12-dark: rgba(229,246,191,1);
  --colors-lime-alpha-1-light: rgba(30,186,245,0.03137);
  --colors-lime-alpha-1-dark: rgba(255,255,255,0);
  --colors-lime-alpha-2-light: rgba(20,186,53,0.05098);
  --colors-lime-alpha-2-dark: rgba(227,255,142,0.07059);
  --colors-lime-alpha-3-light: rgba(120,219,2,0.16078);
  --colors-lime-alpha-3-dark: rgba(220,255,135,0.14118);
  --colors-lime-alpha-4-light: rgba(127,207,1,0.25882);
  --colors-lime-alpha-4-dark: rgba(220,255,125,0.2);
  --colors-lime-alpha-5-light: rgba(126,193,1,0.34902);
  --colors-lime-alpha-5-dark: rgba(220,255,122,0.25098);
  --colors-lime-alpha-6-light: rgba(109,165,1,0.41961);
  --colors-lime-alpha-6-dark: rgba(220,255,126,0.3098);
  --colors-lime-alpha-7-light: rgba(95,138,2,0.47843);
  --colors-lime-alpha-7-dark: rgba(221,255,127,0.36863);
  --colors-lime-alpha-8-light: rgba(90,128,0,0.61176);
  --colors-lime-alpha-8-dark: rgba(220,255,124,0.45098);
  --colors-lime-alpha-9-light: rgba(162,220,1,0.58824);
  --colors-lime-alpha-9-dark: rgba(221,255,125,1);
  --colors-lime-alpha-10-light: rgba(148,202,0,0.6);
  --colors-lime-alpha-10-dark: rgba(221,255,120,0.96078);
  --colors-lime-alpha-11-light: rgba(64,88,0,0.81961);
  --colors-lime-alpha-11-dark: rgba(221,255,129,0.8902);
  --colors-lime-alpha-12-light: rgba(19,30,1,0.83922);
  --colors-lime-alpha-12-dark: rgba(237,255,198,0.96078);
}

/* Scale to Radix Themes colors */

/* We match the 'indigo' | 'gray' | 'tomato' | 'grass' | 'sky' | 'amber' colors from the figma color scheme to the radix color scheme. These are the default of [RadixTheme](https://www.radix-ui.com/themes/docs/components/theme) */

/* To generate this file use this [plugin](https://www.figma.com/community/plugin/1260472771849439434) and replace the theme under with the content of the file */

:root,
.light,
.light-theme {
  /* Accent */
  --indigo-1: var(--colors-scale-1-light);
  --indigo-2: var(--colors-scale-2-light);
  --indigo-3: var(--colors-scale-3-light);
  --indigo-4: var(--colors-scale-4-light);
  --indigo-5: var(--colors-scale-5-light);
  --indigo-6: var(--colors-scale-6-light);
  --indigo-7: var(--colors-scale-7-light);
  --indigo-8: var(--colors-scale-8-light);
  --indigo-9: var(--colors-scale-9-light);
  --indigo-10: var(--colors-scale-10-light);
  --indigo-11: var(--colors-scale-11-light);
  --indigo-12: var(--colors-scale-12-light);
  --indigo-a1: var(--colors-scale-alpha-1-light);
  --indigo-a2: var(--colors-scale-alpha-2-light);
  --indigo-a3: var(--colors-scale-alpha-3-light);
  --indigo-a4: var(--colors-scale-alpha-4-light);
  --indigo-a5: var(--colors-scale-alpha-5-light);
  --indigo-a6: var(--colors-scale-alpha-6-light);
  --indigo-a7: var(--colors-scale-alpha-7-light);
  --indigo-a8: var(--colors-scale-alpha-8-light);
  --indigo-a9: var(--colors-scale-alpha-9-light);
  --indigo-a10: var(--colors-scale-alpha-10-light);
  --indigo-a11: var(--colors-scale-alpha-11-light);
  --indigo-a12: var(--colors-scale-alpha-12-light);
  /* Neutral */
  --slate-1: var(--colors-mauve-1-light);
  --slate-2: var(--colors-mauve-2-light);
  --slate-3: var(--colors-mauve-3-light);
  --slate-4: var(--colors-mauve-4-light);
  --slate-5: var(--colors-mauve-5-light);
  --slate-6: var(--colors-mauve-6-light);
  --slate-7: var(--colors-mauve-7-light);
  --slate-8: var(--colors-mauve-8-light);
  --slate-9: var(--colors-mauve-9-light);
  --slate-10: var(--colors-mauve-10-light);
  --slate-11: var(--colors-mauve-11-light);
  --slate-12: var(--colors-mauve-12-light);
  --slate-a1: var(--colors-mauve-alpha-1-light);
  --slate-a2: var(--colors-mauve-alpha-2-light);
  --slate-a3: var(--colors-mauve-alpha-3-light);
  --slate-a4: var(--colors-mauve-alpha-4-light);
  --slate-a5: var(--colors-mauve-alpha-5-light);
  --slate-a6: var(--colors-mauve-alpha-6-light);
  --slate-a7: var(--colors-mauve-alpha-7-light);
  --slate-a8: var(--colors-mauve-alpha-8-light);
  --slate-a9: var(--colors-mauve-alpha-9-light);
  --slate-a10: var(--colors-mauve-alpha-10-light);
  --slate-a11: var(--colors-mauve-alpha-11-light);
  --slate-a12: var(--colors-mauve-alpha-12-light);
  /* Info */
  --sky-1: var(--colors-sky-1-light);
  --sky-2: var(--colors-sky-2-light);
  --sky-3: var(--colors-sky-3-light);
  --sky-4: var(--colors-sky-4-light);
  --sky-5: var(--colors-sky-5-light);
  --sky-6: var(--colors-sky-6-light);
  --sky-7: var(--colors-sky-7-light);
  --sky-8: var(--colors-sky-8-light);
  --sky-9: var(--colors-sky-9-light);
  --sky-10: var(--colors-sky-10-light);
  --sky-11: var(--colors-sky-11-light);
  --sky-12: var(--colors-sky-12-light);
  --sky-a1: var(--colors-sky-alpha-1-light);
  --sky-a2: var(--colors-sky-alpha-2-light);
  --sky-a3: var(--colors-sky-alpha-3-light);
  --sky-a4: var(--colors-sky-alpha-4-light);
  --sky-a5: var(--colors-sky-alpha-5-light);
  --sky-a6: var(--colors-sky-alpha-6-light);
  --sky-a7: var(--colors-sky-alpha-7-light);
  --sky-a8: var(--colors-sky-alpha-8-light);
  --sky-a9: var(--colors-sky-alpha-9-light);
  --sky-a10: var(--colors-sky-alpha-10-light);
  --sky-a11: var(--colors-sky-alpha-11-light);
  --sky-a12: var(--colors-sky-alpha-12-light);
  /* Success */
  --grass-1: var(--colors-grass-1-light);
  --grass-2: var(--colors-grass-2-light);
  --grass-3: var(--colors-grass-3-light);
  --grass-4: var(--colors-grass-4-light);
  --grass-5: var(--colors-grass-5-light);
  --grass-6: var(--colors-grass-6-light);
  --grass-7: var(--colors-grass-7-light);
  --grass-8: var(--colors-grass-8-light);
  --grass-9: var(--colors-grass-9-light);
  --grass-10: var(--colors-grass-10-light);
  --grass-11: var(--colors-grass-11-light);
  --grass-12: var(--colors-grass-12-light);
  --grass-a1: var(--colors-grass-alpha-1-light);
  --grass-a2: var(--colors-grass-alpha-2-light);
  --grass-a3: var(--colors-grass-alpha-3-light);
  --grass-a4: var(--colors-grass-alpha-4-light);
  --grass-a5: var(--colors-grass-alpha-5-light);
  --grass-a6: var(--colors-grass-alpha-6-light);
  --grass-a7: var(--colors-grass-alpha-7-light);
  --grass-a8: var(--colors-grass-alpha-8-light);
  --grass-a9: var(--colors-grass-alpha-9-light);
  --grass-a10: var(--colors-grass-alpha-10-light);
  --grass-a11: var(--colors-grass-alpha-11-light);
  --grass-a12: var(--colors-grass-alpha-12-light);
  /* Warning */
  --amber-1: var(--colors-amber-1-light);
  --amber-2: var(--colors-amber-2-light);
  --amber-3: var(--colors-amber-3-light);
  --amber-4: var(--colors-amber-4-light);
  --amber-5: var(--colors-amber-5-light);
  --amber-6: var(--colors-amber-6-light);
  --amber-7: var(--colors-amber-7-light);
  --amber-8: var(--colors-amber-8-light);
  --amber-9: var(--colors-amber-9-light);
  --amber-10: var(--colors-amber-10-light);
  --amber-11: var(--colors-amber-11-light);
  --amber-12: var(--colors-amber-12-light);
  --amber-a1: var(--colors-amber-alpha-1-light);
  --amber-a2: var(--colors-amber-alpha-2-light);
  --amber-a3: var(--colors-amber-alpha-3-light);
  --amber-a4: var(--colors-amber-alpha-4-light);
  --amber-a5: var(--colors-amber-alpha-5-light);
  --amber-a6: var(--colors-amber-alpha-6-light);
  --amber-a7: var(--colors-amber-alpha-7-light);
  --amber-a8: var(--colors-amber-alpha-8-light);
  --amber-a9: var(--colors-amber-alpha-9-light);
  --amber-a10: var(--colors-amber-alpha-10-light);
  --amber-a11: var(--colors-amber-alpha-11-light);
  --amber-a12: var(--colors-amber-alpha-12-light);
  /* Error */
  --tomato-1: var(--colors-tomato-1-light);
  --tomato-2: var(--colors-tomato-2-light);
  --tomato-3: var(--colors-tomato-3-light);
  --tomato-4: var(--colors-tomato-4-light);
  --tomato-5: var(--colors-tomato-5-light);
  --tomato-6: var(--colors-tomato-6-light);
  --tomato-7: var(--colors-tomato-7-light);
  --tomato-8: var(--colors-tomato-8-light);
  --tomato-9: var(--colors-tomato-9-light);
  --tomato-10: var(--colors-tomato-10-light);
  --tomato-11: var(--colors-tomato-11-light);
  --tomato-12: var(--colors-tomato-12-light);
  --tomato-a1: var(--colors-tomato-alpha-1-light);
  --tomato-a2: var(--colors-tomato-alpha-2-light);
  --tomato-a3: var(--colors-tomato-alpha-3-light);
  --tomato-a4: var(--colors-tomato-alpha-4-light);
  --tomato-a5: var(--colors-tomato-alpha-5-light);
  --tomato-a6: var(--colors-tomato-alpha-6-light);
  --tomato-a7: var(--colors-tomato-alpha-7-light);
  --tomato-a8: var(--colors-tomato-alpha-8-light);
  --tomato-a9: var(--colors-tomato-alpha-9-light);
  --tomato-a10: var(--colors-tomato-alpha-10-light);
  --tomato-a11: var(--colors-tomato-alpha-11-light);
  --tomato-a12: var(--colors-tomato-alpha-12-light);
}

.dark,
.dark-theme {
  /* Accent */
  --indigo-1: var(--colors-scale-1-dark);
  --indigo-2: var(--colors-scale-2-dark);
  --indigo-3: var(--colors-scale-3-dark);
  --indigo-4: var(--colors-scale-4-dark);
  --indigo-5: var(--colors-scale-5-dark);
  --indigo-6: var(--colors-scale-6-dark);
  --indigo-7: var(--colors-scale-7-dark);
  --indigo-8: var(--colors-scale-8-dark);
  --indigo-9: var(--colors-scale-9-dark);
  --indigo-10: var(--colors-scale-10-dark);
  --indigo-11: var(--colors-scale-11-dark);
  --indigo-12: var(--colors-scale-12-dark);
  --indigo-a1: var(--colors-scale-alpha-1-dark);
  --indigo-a2: var(--colors-scale-alpha-2-dark);
  --indigo-a3: var(--colors-scale-alpha-3-dark);
  --indigo-a4: var(--colors-scale-alpha-4-dark);
  --indigo-a5: var(--colors-scale-alpha-5-dark);
  --indigo-a6: var(--colors-scale-alpha-6-dark);
  --indigo-a7: var(--colors-scale-alpha-7-dark);
  --indigo-a8: var(--colors-scale-alpha-8-dark);
  --indigo-a9: var(--colors-scale-alpha-9-dark);
  --indigo-a10: var(--colors-scale-alpha-10-dark);
  --indigo-a11: var(--colors-scale-alpha-11-dark);
  --indigo-a12: var(--colors-scale-alpha-12-dark);
  /* Neutral */
  --slate-1: var(--colors-mauve-1-dark);
  --slate-2: var(--colors-mauve-2-dark);
  --slate-3: var(--colors-mauve-3-dark);
  --slate-4: var(--colors-mauve-4-dark);
  --slate-5: var(--colors-mauve-5-dark);
  --slate-6: var(--colors-mauve-6-dark);
  --slate-7: var(--colors-mauve-7-dark);
  --slate-8: var(--colors-mauve-8-dark);
  --slate-9: var(--colors-mauve-9-dark);
  --slate-10: var(--colors-mauve-10-dark);
  --slate-11: var(--colors-mauve-11-dark);
  --slate-12: var(--colors-mauve-12-dark);
  --slate-a1: var(--colors-mauve-alpha-1-dark);
  --slate-a2: var(--colors-mauve-alpha-2-dark);
  --slate-a3: var(--colors-mauve-alpha-3-dark);
  --slate-a4: var(--colors-mauve-alpha-4-dark);
  --slate-a5: var(--colors-mauve-alpha-5-dark);
  --slate-a6: var(--colors-mauve-alpha-6-dark);
  --slate-a7: var(--colors-mauve-alpha-7-dark);
  --slate-a8: var(--colors-mauve-alpha-8-dark);
  --slate-a9: var(--colors-mauve-alpha-9-dark);
  --slate-a10: var(--colors-mauve-alpha-10-dark);
  --slate-a11: var(--colors-mauve-alpha-11-dark);
  --slate-a12: var(--colors-mauve-alpha-12-dark);
  /* Info */
  --sky-1: var(--colors-sky-1-dark);
  --sky-2: var(--colors-sky-2-dark);
  --sky-3: var(--colors-sky-3-dark);
  --sky-4: var(--colors-sky-4-dark);
  --sky-5: var(--colors-sky-5-dark);
  --sky-6: var(--colors-sky-6-dark);
  --sky-7: var(--colors-sky-7-dark);
  --sky-8: var(--colors-sky-8-dark);
  --sky-9: var(--colors-sky-9-dark);
  --sky-10: var(--colors-sky-10-dark);
  --sky-11: var(--colors-sky-11-dark);
  --sky-12: var(--colors-sky-12-dark);
  --sky-a1: var(--colors-sky-alpha-1-dark);
  --sky-a2: var(--colors-sky-alpha-2-dark);
  --sky-a3: var(--colors-sky-alpha-3-dark);
  --sky-a4: var(--colors-sky-alpha-4-dark);
  --sky-a5: var(--colors-sky-alpha-5-dark);
  --sky-a6: var(--colors-sky-alpha-6-dark);
  --sky-a7: var(--colors-sky-alpha-7-dark);
  --sky-a8: var(--colors-sky-alpha-8-dark);
  --sky-a9: var(--colors-sky-alpha-9-dark);
  --sky-a10: var(--colors-sky-alpha-10-dark);
  --sky-a11: var(--colors-sky-alpha-11-dark);
  --sky-a12: var(--colors-sky-alpha-12-dark);
  /* Success */
  --grass-1: var(--colors-grass-1-dark);
  --grass-2: var(--colors-grass-2-dark);
  --grass-3: var(--colors-grass-3-dark);
  --grass-4: var(--colors-grass-4-dark);
  --grass-5: var(--colors-grass-5-dark);
  --grass-6: var(--colors-grass-6-dark);
  --grass-7: var(--colors-grass-7-dark);
  --grass-8: var(--colors-grass-8-dark);
  --grass-9: var(--colors-grass-9-dark);
  --grass-10: var(--colors-grass-10-dark);
  --grass-11: var(--colors-grass-11-dark);
  --grass-12: var(--colors-grass-12-dark);
  --grass-a1: var(--colors-grass-alpha-1-dark);
  --grass-a2: var(--colors-grass-alpha-2-dark);
  --grass-a3: var(--colors-grass-alpha-3-dark);
  --grass-a4: var(--colors-grass-alpha-4-dark);
  --grass-a5: var(--colors-grass-alpha-5-dark);
  --grass-a6: var(--colors-grass-alpha-6-dark);
  --grass-a7: var(--colors-grass-alpha-7-dark);
  --grass-a8: var(--colors-grass-alpha-8-dark);
  --grass-a9: var(--colors-grass-alpha-9-dark);
  --grass-a10: var(--colors-grass-alpha-10-dark);
  --grass-a11: var(--colors-grass-alpha-11-dark);
  --grass-a12: var(--colors-grass-alpha-12-dark);
  /* Warning */
  --amber-1: var(--colors-amber-1-dark);
  --amber-2: var(--colors-amber-2-dark);
  --amber-3: var(--colors-amber-3-dark);
  --amber-4: var(--colors-amber-4-dark);
  --amber-5: var(--colors-amber-5-dark);
  --amber-6: var(--colors-amber-6-dark);
  --amber-7: var(--colors-amber-7-dark);
  --amber-8: var(--colors-amber-8-dark);
  --amber-9: var(--colors-amber-9-dark);
  --amber-10: var(--colors-amber-10-dark);
  --amber-11: var(--colors-amber-11-dark);
  --amber-12: var(--colors-amber-12-dark);
  --amber-a1: var(--colors-amber-alpha-1-dark);
  --amber-a2: var(--colors-amber-alpha-2-dark);
  --amber-a3: var(--colors-amber-alpha-3-dark);
  --amber-a4: var(--colors-amber-alpha-4-dark);
  --amber-a5: var(--colors-amber-alpha-5-dark);
  --amber-a6: var(--colors-amber-alpha-6-dark);
  --amber-a7: var(--colors-amber-alpha-7-dark);
  --amber-a8: var(--colors-amber-alpha-8-dark);
  --amber-a9: var(--colors-amber-alpha-9-dark);
  --amber-a10: var(--colors-amber-alpha-10-dark);
  --amber-a11: var(--colors-amber-alpha-11-dark);
  --amber-a12: var(--colors-amber-alpha-12-dark);
  /* Error */
  --tomato-1: var(--colors-tomato-1-dark);
  --tomato-2: var(--colors-tomato-2-dark);
  --tomato-3: var(--colors-tomato-3-dark);
  --tomato-4: var(--colors-tomato-4-dark);
  --tomato-5: var(--colors-tomato-5-dark);
  --tomato-6: var(--colors-tomato-6-dark);
  --tomato-7: var(--colors-tomato-7-dark);
  --tomato-8: var(--colors-tomato-8-dark);
  --tomato-9: var(--colors-tomato-9-dark);
  --tomato-10: var(--colors-tomato-10-dark);
  --tomato-11: var(--colors-tomato-11-dark);
  --tomato-12: var(--colors-tomato-12-dark);
  --tomato-a1: var(--colors-tomato-alpha-1-dark);
  --tomato-a2: var(--colors-tomato-alpha-2-dark);
  --tomato-a3: var(--colors-tomato-alpha-3-dark);
  --tomato-a4: var(--colors-tomato-alpha-4-dark);
  --tomato-a5: var(--colors-tomato-alpha-5-dark);
  --tomato-a6: var(--colors-tomato-alpha-6-dark);
  --tomato-a7: var(--colors-tomato-alpha-7-dark);
  --tomato-a8: var(--colors-tomato-alpha-8-dark);
  --tomato-a9: var(--colors-tomato-alpha-9-dark);
  --tomato-a10: var(--colors-tomato-alpha-10-dark);
  --tomato-a11: var(--colors-tomato-alpha-11-dark);
  --tomato-a12: var(--colors-tomato-alpha-12-dark);
}

/* Scale to Radix Themes fonts */

/* More info Typography customization [here](https://www.radix-ui.com/themes/docs/theme/typography#customization) */

.radix-themes {
  /* Font family */
  --default-font-family: Inter, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif;
  /* Other */
  --default-font-size: var(--font-size-2);
  --default-font-style: normal;
  --default-font-weight: normal;
  --default-line-height: normal;
  --default-letter-spacing: normal;
}

/* Scale to Radix Themes cursors */

/* More info about cursor customizations [here](https://www.radix-ui.com/themes/docs/theme/cursors#customization) */

.radix-themes {
  --cursor-button: pointer;
  --cursor-checkbox: pointer;
  --cursor-disabled: default;
  --cursor-link: pointer;
  --cursor-menu-item: pointer;
  --cursor-radio: pointer;
  --cursor-slider-thumb: grab;
  --cursor-slider-thumb-active: grabbing;
  --cursor-switch: pointer;
}

/* Radix Themes overrides */

.rt-TableCell {
  --table-row-box-shadow: inset -1px -1px var(--gray-a5);
}

.rt-TableRoot:where(.rt-variant-surface)
  :where(.rt-TableRootTable)
  :where(.rt-TableHeader)
  :where(.rt-TableCell:last-child) {
  --table-row-box-shadow: inset 0px -1px var(--gray-a5);
}

.rt-TableRoot:where(.rt-variant-surface)
  :where(.rt-TableRootTable)
  :where(.rt-TableBody)
  :where(.rt-TableCell:last-child) {
  --table-row-box-shadow: inset 0px -1px var(--gray-a5);
}

.rt-TableRoot:where(.rt-variant-surface)
  :where(.rt-TableRootTable)
  :where(.rt-TableBody)
  :where(.rt-TableRow:last-child)
  :where(.rt-TableCell) {
  --table-row-box-shadow: inset -1px 0px var(--gray-a5);
}

.rt-TableRoot:where(.rt-variant-surface)
  :where(.rt-TableRootTable)
  :where(.rt-TableBody)
  :where(.rt-TableRow:last-child)
  :where(.rt-TableCell:last-child) {
  --table-row-box-shadow: inset 0px 0px var(--gray-a5);
}

/* .rt-TableRoot:where(.rt-variant-surface)
    :where(.rt-TableRootTable)
    :where(.rt-TableBody)
    :where(.rt-TableRow:last-child) {
    box-shadow: inset 0 -1px var(--gray-a5);
  } */

.rt-BaseButton:where(.rt-variant-soft) {
  background-color: transparent;
}

.rt-BaseButton:where(.rt-variant-soft):where(:focus-visible) {
    outline: 2px solid var(--accent-8);
    outline-offset: -1px;
  }

@media (hover: hover) {
    .rt-BaseButton:where(.rt-variant-soft):where(:hover) {
      background-color: var(--accent-a4);
    }
  }

.rt-BaseButton:where(.rt-variant-soft):where([data-state='open']) {
    background-color: var(--accent-a4);
  }

.rt-BaseButton:where(.rt-variant-soft):where(:active:not([data-state='open'])) {
    background-color: var(--accent-a5);
  }

.rt-BaseButton:where(.rt-variant-soft):where([data-disabled]) {
    color: var(--gray-a8);
    background-color: transparent;
  }

.rt-AvatarRoot:where(.rt-r-size-1) {
    --avatar-size: var(--space-3);
  }

.rt-AvatarRoot:where(.rt-r-size-2) {
    --avatar-size: var(--space-4);
  }

.rt-AvatarRoot:where(.rt-r-size-3) {
    --avatar-size: var(--space-5);
  }

.rt-AvatarRoot:where(.rt-r-size-4) {
    --avatar-size: var(--space-6);
  }

.rt-AvatarRoot:where(.rt-r-size-5) {
    --avatar-size: var(--space-7);
  }

.rt-AvatarRoot:where(.rt-r-size-6) {
    --avatar-size: var(--space-8);
  }

.rt-AvatarRoot:where(.rt-r-size-7) {
    --avatar-size: var(--space-9);
  }

.rt-AvatarRoot:where(.rt-r-size-8) {
    --avatar-size: 80px;
  }

.rt-AvatarRoot:where(.rt-r-size-9) {
    --avatar-size: 96px;
  }

.rt-TabsList:where(.rt-variant-soft) {
  box-shadow: none;
}

.rt-TabsTrigger:where(.rt-variant-soft):where([data-state='active'], [data-active])::before {
    background-color: transparent;
  }

:where(.rt-BaseTabList.rt-high-contrast) .rt-TabsTrigger:where(.rt-variant-soft):where([data-state='active'], [data-active])::before {
      background-color: transparent;
    }

.rt-SegmentedControlItemLabelActive {
  color: var(--accent-11);
}

.rt-SegmentedControlRoot:where(.rt-variant-ghost) {
  background-color: transparent;
  background-image: none;
}

.rt-SegmentedControlRoot:where(.rt-variant-ghost) :where(.rt-SegmentedControlItem:not([disabled])) ~ :where(.rt-SegmentedControlIndicator)::before {
      box-shadow: none;
    }

.rt-SegmentedControlRoot:where(.rt-variant-ghost) :where(.rt-SegmentedControlItem:not(:first-child)) :where(.rt-SegmentedControlItemSeparator) {
      opacity: 1;
    }

.rt-SegmentedControlRoot:where(.rt-variant-ghost):where(.rt-r-size-1) :where(.rt-SegmentedControlItemLabel) {
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }

.rt-SegmentedControlRoot:where(.rt-variant-ghost):where(.rt-r-size-2) :where(.rt-SegmentedControlItemLabel) {
      padding-left: var(--space-3);
      padding-right: var(--space-3);
    }

.rt-SegmentedControlRoot:where(.rt-variant-ghost):where(.rt-r-size-3) :where(.rt-SegmentedControlItemLabel) {
      padding-left: var(--space-3);
      padding-right: var(--space-3);
    }

.rt-type-multiple .rt-SegmentedControlIndicator::before {
    background-color: transparent;
  }

.rt-type-multiple .rt-SegmentedControlItem {
  border: 1px solid transparent;
}

.rt-type-multiple .rt-SegmentedControlItem:focus {
    z-index: 1;
  }

.rt-SegmentedControlRoot.rt-type-multiple:not(.rt-variant-ghost) .rt-SegmentedControlItem:where(:not(:first-child)) {
    margin-left: -1px;
  }

.rt-SegmentedControlRoot.rt-type-multiple:not(.rt-variant-ghost)
  .rt-SegmentedControlItem:where([data-state='on']) {
  background-color: var(--accent-1);
}

.dark :is(.rt-SegmentedControlRoot.rt-type-multiple:not(.rt-variant-ghost)
  .rt-SegmentedControlItem:where([data-state='on'])) {
    background-color: var(--gray-a3);
    border: 1px solid var(--gray-3);
  }

.dark-theme :is(.rt-SegmentedControlRoot.rt-type-multiple:not(.rt-variant-ghost)
  .rt-SegmentedControlItem:where([data-state='on'])) {
    background-color: var(--gray-a3);
    border: 1px solid var(--gray-3);
  }

.rt-type-multiple .rt-SegmentedControlItem:where([data-state='on']) {
  border: 1px solid var(--gray-a3);
}

.dark :is(.rt-type-multiple .rt-SegmentedControlItem:where([data-state='on'])) {
    background-color: var(--gray-a3);
    border: 1px solid var(--gray-3);
  }

.dark-theme :is(.rt-type-multiple .rt-SegmentedControlItem:where([data-state='on'])) {
    background-color: var(--gray-a3);
    border: 1px solid var(--gray-3);
  }

.rt-type-multiple .rt-SegmentedControlItem:where([data-state='on']):hover {
    background-color: var(--accent-2);
  }

.dark :is(.rt-type-multiple .rt-SegmentedControlItem:where([data-state='on']):hover) {
      background-color: var(--gray-a4);
    }

.dark-theme :is(.rt-type-multiple .rt-SegmentedControlItem:where([data-state='on']):hover) {
      background-color: var(--gray-a4);
    }

.rt-type-multiple .rt-SegmentedControlItem:where([data-state='off']):hover {
    border: 1px solid var(--gray-a2);
  }

.rt-type-multiple.rt-SegmentedControlRoot:where(.rt-variant-ghost) .rt-SegmentedControlItem {
  border: none;
}

.rt-SelectContent:where(.rt-variant-solid) :where(.rt-SelectItem[data-highlighted]) {
    background-color: var(--accent-a2);
    color: var(--gray-12);
  }

.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuItem[data-highlighted]) {
    background-color: var(--accent-a2);
    color: var(--gray-12);
  }

.rt-BaseMenuContent:where(.rt-variant-solid) :where(.rt-BaseMenuItem[data-highlighted]) :where(.rt-BaseMenuSubTriggerIcon) {
      color: var(--gray-12);
    }

.rt-BaseButton.rt-variant-outline[data-accent-color='gray'] {
  color: var(--slate-12);
}

.rt-Badge:where(.rt-r-size-4) {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    letter-spacing: var(--letter-spacing-3);
    padding: calc(var(--space-2)) calc(var(--space-3) * 1.5);
    gap: var(--space-3);
    border-radius: max(var(--radius-3), var(--radius-full));
  }

.rt-Badge:where(.rt-r-size-5) {
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    letter-spacing: var(--letter-spacing-4);
    padding: calc(var(--space-2) * 1.5) calc(var(--space-3) * 2);
    gap: var(--space-4);
    border-radius: max(var(--radius-4), var(--radius-full));
  }

@keyframes rt-skeleton-pulse {
  from {
    fill: var(--gray-a3);
    background-color: var(--gray-a3);
  }

  to {
    fill: var(--gray-a4);
    background-color: var(--gray-a4);
  }
}

.rt-Tooltip .rt-Link {
  color: var(--slate-1);
}

.dark :is(.rt-Tooltip .rt-Link) {
    color: var(--gray-1);
  }

.dark-theme :is(.rt-Tooltip .rt-Link) {
    color: var(--gray-1);
  }

/* ScaleUI Radix components */

.rt-AutocompleteItems,
.rt-AutocompleteAsyncItems {
  pointer-events: auto;
  padding: 0;
}

.rt-AutocompleteReset {
  margin-left: -40px;
}

/* We created this Prism theme to match the [VS Code Light Modern theme](https://github.com/microsoft/vscode/blob/main/extensions/theme-defaults/themes/light_modern.json) as much as we can */

.rt-CodeBlockContent-light-modern pre[class*='language-'],
.rt-CodeBlockContent-light-modern code[class*='language-'] {
  color: #3b3b3b;
  font-size: 13px;
  text-shadow: none;
  font-family: Menlo, Monaco, Consolas, 'Andale Mono', 'Ubuntu Mono', 'Courier New', monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
          hyphens: none;
}

.rt-CodeBlockContent-light-modern pre[class*='language-']::-moz-selection, .rt-CodeBlockContent-light-modern code[class*='language-']::-moz-selection, .rt-CodeBlockContent-light-modern pre[class*='language-'] *::-moz-selection, .rt-CodeBlockContent-light-modern code[class*='language-'] *::-moz-selection {
  text-shadow: none;
  background-color: rgba(173,214,255,0.50196);
}

.rt-CodeBlockContent-light-modern pre[class*='language-']::selection,
.rt-CodeBlockContent-light-modern code[class*='language-']::selection,
.rt-CodeBlockContent-light-modern pre[class*='language-'] *::selection,
.rt-CodeBlockContent-light-modern code[class*='language-'] *::selection {
  text-shadow: none;
  background-color: rgba(173,214,255,0.50196);
}

@media print {
  .rt-CodeBlockContent-light-modern pre[class*='language-'],
  .rt-CodeBlockContent-light-modern code[class*='language-'] {
    text-shadow: none;
  }
}

.rt-CodeBlockContent-light-modern pre[class*='language-'] {
  padding: var(--code-block-padding);
  overflow: auto;
  background-color: var(--color-editor-background);
  padding: var(--space-3);
}

.rt-CodeBlockContent-light-modern:not(pre) > code[class*='language-'] {
  padding: 0.1em 0.3em;
  border-radius: 0.3em;
  color: #895503;
  background-color: var(--color-editor-background);
}

/*********************************************************
  * Tokens
  */

.rt-CodeBlockContent-light-modern .token.plain-text {
  color: #000000;
}

.rt-CodeBlockContent-light-modern .namespace {
  opacity: 0.7;
}

.rt-CodeBlockContent-light-modern .token.doctype .token.doctype-tag {
  color: #005fb8;
}

.rt-CodeBlockContent-light-modern .token.doctype .token.name {
  color: #171184;
}

.rt-CodeBlockContent-light-modern .token.comment,
.rt-CodeBlockContent-light-modern .token.prolog {
  color: #008000;
}

.rt-CodeBlockContent-light-modern .token.punctuation,
.rt-CodeBlockContent-light-modern .language-html .language-css .token.punctuation,
.rt-CodeBlockContent-light-modern .language-html .language-javascript .token.punctuation {
  color: #0431fa;
}

.rt-CodeBlockContent-light-modern .token.property,
.rt-CodeBlockContent-light-modern .token.tag,
.rt-CodeBlockContent-light-modern .token.boolean,
.rt-CodeBlockContent-light-modern .token.number,
.rt-CodeBlockContent-light-modern .token.constant,
.rt-CodeBlockContent-light-modern .token.symbol,
.rt-CodeBlockContent-light-modern .token.inserted,
.rt-CodeBlockContent-light-modern .token.unit {
  color: #098658;
}

.rt-CodeBlockContent-light-modern .token.selector,
.rt-CodeBlockContent-light-modern .token.attr-name,
.rt-CodeBlockContent-light-modern .token.string,
.rt-CodeBlockContent-light-modern .token.char,
.rt-CodeBlockContent-light-modern .token.builtin,
.rt-CodeBlockContent-light-modern .token.deleted {
  color: #a31515;
}

.rt-CodeBlockContent-light-modern .language-css .token.string.url {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.rt-CodeBlockContent-light-modern .token.operator,
.rt-CodeBlockContent-light-modern .token.entity {
  color: #0000ff;
}

.rt-CodeBlockContent-light-modern .token.operator.arrow {
  color: #005fb8;
}

.rt-CodeBlockContent-light-modern .token.atrule {
  color: #a31515;
}

.rt-CodeBlockContent-light-modern .token.atrule .token.rule {
  color: #af00db;
}

.rt-CodeBlockContent-light-modern .token.atrule .token.url {
  color: #171184;
}

.rt-CodeBlockContent-light-modern .token.atrule .token.url .token.function {
  color: #795e26;
}

.rt-CodeBlockContent-light-modern .token.atrule .token.url .token.punctuation {
  color: #767676;
}

.rt-CodeBlockContent-light-modern .token.keyword {
  color: #0000ff;
}

.rt-CodeBlockContent-light-modern .token.keyword.module,
.rt-CodeBlockContent-light-modern .token.keyword.control-flow {
  color: #af00db;
}

.rt-CodeBlockContent-light-modern .token.function,
.rt-CodeBlockContent-light-modern .token.function .token.maybe-class-name {
  color: #000000;
}

.rt-CodeBlockContent-light-modern .token.regex {
  color: #811f3f;
}

.rt-CodeBlockContent-light-modern .token.important {
  color: #005fb8;
}

.rt-CodeBlockContent-light-modern .token.italic {
  font-style: italic;
}

.rt-CodeBlockContent-light-modern .token.constant {
  color: #000000;
}

.rt-CodeBlockContent-light-modern .token.class-name,
.rt-CodeBlockContent-light-modern .token.maybe-class-name {
  color: #800000;
}

.rt-CodeBlockContent-light-modern .token.console {
  color: #171184;
}

.rt-CodeBlockContent-light-modern .token.parameter {
  color: #171184;
}

.rt-CodeBlockContent-light-modern .token.interpolation {
  color: #171184;
}

.rt-CodeBlockContent-light-modern .token.punctuation.interpolation-punctuation {
  color: #005fb8;
}

.rt-CodeBlockContent-light-modern .token.boolean {
  color: #005fb8;
}

.rt-CodeBlockContent-light-modern .token.property,
.rt-CodeBlockContent-light-modern .token.variable,
.rt-CodeBlockContent-light-modern .token.imports .token.maybe-class-name,
.rt-CodeBlockContent-light-modern .token.exports .token.maybe-class-name {
  color: #171184;
}

.rt-CodeBlockContent-light-modern .token.selector {
  color: #795e26;
}

.rt-CodeBlockContent-light-modern .token.escape {
  color: #795e26;
}

.rt-CodeBlockContent-light-modern .token.tag {
  color: #005fb8;
}

.rt-CodeBlockContent-light-modern .token.tag .token.punctuation {
  color: #6e7681;
}

.rt-CodeBlockContent-light-modern .token.cdata {
  color: #319331;
}

.rt-CodeBlockContent-light-modern .token.attr-name {
  color: #e50000;
}

.rt-CodeBlockContent-light-modern .token.attr-value,
.rt-CodeBlockContent-light-modern .token.attr-value .token.punctuation {
  color: #a31515;
}

.rt-CodeBlockContent-light-modern .token.attr-value .token.punctuation.attr-equals {
  color: #767676;
}

.rt-CodeBlockContent-light-modern .token.entity {
  color: #005fb8;
}

.rt-CodeBlockContent-light-modern .token.namespace {
  color: #267f99;
}

/*********************************************************
  * Language Specific
  */

.rt-CodeBlockContent-light-modern pre[class*='language-javascript'],
.rt-CodeBlockContent-light-modern code[class*='language-javascript'],
.rt-CodeBlockContent-light-modern pre[class*='language-jsx'],
.rt-CodeBlockContent-light-modern code[class*='language-jsx'],
.rt-CodeBlockContent-light-modern pre[class*='language-typescript'],
.rt-CodeBlockContent-light-modern code[class*='language-typescript'],
.rt-CodeBlockContent-light-modern pre[class*='language-tsx'],
.rt-CodeBlockContent-light-modern code[class*='language-tsx'] {
  color: #000000;
}

.rt-CodeBlockContent-light-modern pre[class*='language-css'],
.rt-CodeBlockContent-light-modern code[class*='language-css'] {
  color: #a31515;
}

.rt-CodeBlockContent-light-modern pre[class*='language-html'],
.rt-CodeBlockContent-light-modern code[class*='language-html'] {
  color: #3b3b3b;
}

.rt-CodeBlockContent-light-modern .language-regex .token.anchor {
  color: #795e26;
}

.rt-CodeBlockContent-light-modern .language-html .token.punctuation {
  color: #6e7681;
}

/*********************************************************
  * Line highlighting
  */

pre[class*='language-'].rt-CodeBlockContent-light-modern > code[class*='language-'] {
  position: relative;
  z-index: 1;
}

.rt-CodeBlockContent-light-modern .line-highlight.line-highlight {
  background-color: rgba(187,128,9,0.4);
  box-shadow: inset 5px 0 0 rgba(187,128,9,0.4);
  z-index: 0;
}

/* We created this Prism theme to match the [VS Code Dark Modern theme](https://github.com/microsoft/vscode/blob/main/extensions/theme-defaults/themes/dark_modern.json) as much as we can */

.rt-CodeBlockContent-dark-modern pre[class*='language-'],
.rt-CodeBlockContent-dark-modern code[class*='language-'] {
  color: #cccccc;
  font-size: 13px;
  text-shadow: none;
  font-family: Menlo, Monaco, Consolas, 'Andale Mono', 'Ubuntu Mono', 'Courier New', monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
          hyphens: none;
}

.rt-CodeBlockContent-dark-modern pre[class*='language-']::-moz-selection, .rt-CodeBlockContent-dark-modern code[class*='language-']::-moz-selection, .rt-CodeBlockContent-dark-modern pre[class*='language-'] *::-moz-selection, .rt-CodeBlockContent-dark-modern code[class*='language-'] *::-moz-selection {
  text-shadow: none;
  background-color: #0078d4;
}

.rt-CodeBlockContent-dark-modern pre[class*='language-']::selection,
.rt-CodeBlockContent-dark-modern code[class*='language-']::selection,
.rt-CodeBlockContent-dark-modern pre[class*='language-'] *::selection,
.rt-CodeBlockContent-dark-modern code[class*='language-'] *::selection {
  text-shadow: none;
  background-color: #0078d4;
}

@media print {
  .rt-CodeBlockContent-dark-modern pre[class*='language-'],
  .rt-CodeBlockContent-dark-modern code[class*='language-'] {
    text-shadow: none;
  }
}

.rt-CodeBlockContent-dark-modern pre[class*='language-'] {
  padding: var(--code-block-padding);
  overflow: auto;
  background-color: var(--color-editor-background);
  padding: var(--space-3);
}

.rt-CodeBlockContent-dark-modern :not(pre) > code[class*='language-'] {
  padding: 0.1em 0.3em;
  border-radius: 0.3em;
  color: #d16969;
  background-color: var(--color-editor-background);
}

/*********************************************************
  * Tokens
  */

.rt-CodeBlockContent-dark-modern .token.plain-text {
  color: #cccccc;
}

.rt-CodeBlockContent-dark-modern .namespace {
  opacity: 0.7;
}

.rt-CodeBlockContent-dark-modern .token.doctype .token.doctype-tag {
  color: #569cd6;
}

.rt-CodeBlockContent-dark-modern .token.doctype .token.name {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern .token.comment,
.rt-CodeBlockContent-dark-modern .token.prolog {
  color: #6a9955;
}

.rt-CodeBlockContent-dark-modern .token.punctuation,
.rt-CodeBlockContent-dark-modern .language-html .language-css .token.punctuation,
.rt-CodeBlockContent-dark-modern .language-html .language-javascript .token.punctuation {
  color: #d4d4d4;
}

.rt-CodeBlockContent-dark-modern .token.property,
.rt-CodeBlockContent-dark-modern .token.tag,
.rt-CodeBlockContent-dark-modern .token.boolean,
.rt-CodeBlockContent-dark-modern .token.number,
.rt-CodeBlockContent-dark-modern .token.constant,
.rt-CodeBlockContent-dark-modern .token.symbol,
.rt-CodeBlockContent-dark-modern .token.inserted,
.rt-CodeBlockContent-dark-modern .token.unit {
  color: #b5cea8;
}

.rt-CodeBlockContent-dark-modern .token.selector,
.rt-CodeBlockContent-dark-modern .token.attr-name,
.rt-CodeBlockContent-dark-modern .token.string,
.rt-CodeBlockContent-dark-modern .token.char,
.rt-CodeBlockContent-dark-modern .token.builtin,
.rt-CodeBlockContent-dark-modern .token.deleted {
  color: #ce9178;
}

.rt-CodeBlockContent-dark-modern .language-css .token.string.url {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.rt-CodeBlockContent-dark-modern .token.operator,
.rt-CodeBlockContent-dark-modern .token.entity {
  color: #d4d4d4;
}

.rt-CodeBlockContent-dark-modern .token.operator.arrow {
  color: #569cd6;
}

.rt-CodeBlockContent-dark-modern .token.atrule {
  color: #ce9178;
}

.rt-CodeBlockContent-dark-modern .token.atrule .token.rule {
  color: #c586c0;
}

.rt-CodeBlockContent-dark-modern .token.atrule .token.url {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern .token.atrule .token.url .token.function {
  color: #dcdcaa;
}

.rt-CodeBlockContent-dark-modern .token.atrule .token.url .token.punctuation {
  color: #d4d4d4;
}

.rt-CodeBlockContent-dark-modern .token.keyword {
  color: #569cd6;
}

.rt-CodeBlockContent-dark-modern .token.keyword.module,
.rt-CodeBlockContent-dark-modern .token.keyword.control-flow {
  color: #c586c0;
}

.rt-CodeBlockContent-dark-modern .token.function,
.rt-CodeBlockContent-dark-modern .token.function .token.maybe-class-name {
  color: #dcdcaa;
}

.rt-CodeBlockContent-dark-modern .token.regex {
  color: #d16969;
}

.rt-CodeBlockContent-dark-modern .token.important {
  color: #569cd6;
}

.rt-CodeBlockContent-dark-modern .token.italic {
  font-style: italic;
}

.rt-CodeBlockContent-dark-modern .token.constant {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern .token.class-name,
.rt-CodeBlockContent-dark-modern .token.maybe-class-name {
  color: #4ec9b0;
}

.rt-CodeBlockContent-dark-modern .token.console {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern .token.parameter {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern .token.interpolation {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern .token.punctuation.interpolation-punctuation {
  color: #569cd6;
}

.rt-CodeBlockContent-dark-modern .token.boolean {
  color: #569cd6;
}

.rt-CodeBlockContent-dark-modern .token.property,
.rt-CodeBlockContent-dark-modern .token.variable,
.rt-CodeBlockContent-dark-modern .token.imports .token.maybe-class-name,
.rt-CodeBlockContent-dark-modern .token.exports .token.maybe-class-name {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern .token.selector {
  color: #d7ba7d;
}

.rt-CodeBlockContent-dark-modern .token.escape {
  color: #d7ba7d;
}

.rt-CodeBlockContent-dark-modern .token.tag {
  color: #569cd6;
}

.rt-CodeBlockContent-dark-modern .token.tag .token.punctuation {
  color: #808080;
}

.rt-CodeBlockContent-dark-modern .token.cdata {
  color: #808080;
}

.rt-CodeBlockContent-dark-modern .token.attr-name {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern .token.attr-value,
.rt-CodeBlockContent-dark-modern .token.attr-value .token.punctuation {
  color: #ce9178;
}

.rt-CodeBlockContent-dark-modern .token.attr-value .token.punctuation.attr-equals {
  color: #d4d4d4;
}

.rt-CodeBlockContent-dark-modern .token.entity {
  color: #569cd6;
}

.rt-CodeBlockContent-dark-modern .token.namespace {
  color: #4ec9b0;
}

/*********************************************************
  * Language Specific
  */

.rt-CodeBlockContent-dark-modern pre[class*='language-javascript'],
.rt-CodeBlockContent-dark-modern code[class*='language-javascript'],
.rt-CodeBlockContent-dark-modern pre[class*='language-jsx'],
.rt-CodeBlockContent-dark-modern code[class*='language-jsx'],
.rt-CodeBlockContent-dark-modern pre[class*='language-typescript'],
.rt-CodeBlockContent-dark-modern code[class*='language-typescript'],
.rt-CodeBlockContent-dark-modern pre[class*='language-tsx'],
.rt-CodeBlockContent-dark-modern code[class*='language-tsx'] {
  color: #9cdcfe;
}

.rt-CodeBlockContent-dark-modern pre[class*='language-css'],
.rt-CodeBlockContent-dark-modern code[class*='language-css'] {
  color: #ce9178;
}

.rt-CodeBlockContent-dark-modern pre[class*='language-html'],
.rt-CodeBlockContent-dark-modern code[class*='language-html'] {
  color: #d4d4d4;
}

.rt-CodeBlockContent-dark-modern .language-regex .token.anchor {
  color: #dcdcaa;
}

.rt-CodeBlockContent-dark-modern .language-html .token.punctuation {
  color: #808080;
}

/*********************************************************
  * Line highlighting
  */

.rt-CodeBlockContent-dark-modern pre[class*='language-'] > code[class*='language-'] {
  position: relative;
  z-index: 1;
}

.rt-CodeBlockContent-dark-modern .line-highlight.line-highlight {
  background-color: rgba(187,128,9,0.4);
  box-shadow: inset 5px 0 0 rgba(187,128,9,0.4);
  z-index: 0;
}

.rt-CodeBlockContent-dark-modern {
  position: relative;
  padding: 0;
}

.rt-CodeBlockContent-dark-modern:hover .rt-CodeBlockCopyButton {
      visibility: visible;
    }

.rt-CodeBlockContent-light-modern {
  --color-editor-background: white;
  --color-editor-background-contrast: black;
  --color-editor-background-contrast-hover: rgba(0,0,0,0.12157);
}

.rt-CodeBlockContent-dark-modern {
  --color-editor-background: #1f1f1f;
  --color-editor-background-contrast: white;
  --color-editor-background-contrast-hover: rgba(255,255,255,0.12157);
}

.rt-CodeBlockShowMore {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--space-5);
  background-color: var(--color-editor-background);
  z-index: 1;
  box-shadow: var(--shadow-1);
  border-bottom-left-radius: var(--radius-4);
  border-bottom-right-radius: var(--radius-4);
  color: var(--color-editor-background-contrast);
  opacity: 0.7;
}

.rt-CodeBlockShowLess {
  width: 100%;
  height: var(--space-5);
  background-color: var(--color-editor-background);
  color: var(--color-editor-background-contrast);
}

.rt-CodeBlockCopyButton {
  position: absolute;
  top: var(--space-3);
  right: var(--space-3);
  color: var(--gray-1);
  visibility: hidden;
}

.rt-CodeBlockCopyButton:hover {
    background-color: var(--color-editor-background-contrast-hover);
  }

.rt-CodeBlockCopyButtonIcon {
  color: var(--color-editor-background-contrast);
}

.rt-Flex > .rt-Separator:where(.rt-r-orientation-vertical) {
  height: auto;
  height: initial;
  align-self: stretch;
}

@media (min-width: 640px) {

.rt-Flex > .rt-Separator:where(.xs\:rt-r-orientation-vertical) {
      height: auto;
      height: initial;
      align-self: stretch;
    }
}

@media (min-width: 768px) {

.rt-Flex > .rt-Separator:where(.sm\:rt-r-orientation-vertical) {
      height: auto;
      height: initial;
      align-self: stretch;
    }
}

@media (min-width: 1024px) {

.rt-Flex > .rt-Separator:where(.md\:rt-r-orientation-vertical) {
      height: auto;
      height: initial;
      align-self: stretch;
    }
}

@media (min-width: 1280px) {

.rt-Flex > .rt-Separator:where(.lg\:rt-r-orientation-vertical) {
      height: auto;
      height: initial;
      align-self: stretch;
    }
}

@media (min-width: 1536px) {

.rt-Flex > .rt-Separator:where(.xl\:rt-r-orientation-vertical) {
      height: auto;
      height: initial;
      align-self: stretch;
    }
}

.rt-SheetOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-overlay);
}

.rt-SheetContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: calc(100% - 80px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: var(--color-panel-solid);
  box-shadow: var(--shadow-6);
  border-top-left-radius: var(--space-4);
  border-top-right-radius: var(--space-4);
  --sheet-dragger-container-height: 20px;
}

.rt-SheetContent {
  flex: 1;
  min-height: 0;
  padding: var(--space-5);
  overflow: auto;
  width: 100%;
}

.rt-SheetDraggerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--color-panel-solid);
  cursor: grab;
  height: var(--sheet-dragger-container-height);
  min-height: var(--sheet-dragger-container-height);
  border-top-left-radius: var(--space-4);
  border-top-right-radius: var(--space-4);
}

.rt-SheetDraggerContainer:where(:disabled, [data-disabled]) {
    cursor: var(--cursor-disabled);
  }

.rt-SheetDragger {
  width: 100px;
  height: 4px;
  background-color: var(--gray-6);
  border-radius: 2px;
}

.rt-DataTableBodyEmptyMessage {
  width: -moz-max-content;
  width: max-content;
  color: var(--gray-9);
  white-space: nowrap;
  position: sticky;
  align-items: center;
  justify-content: center;
  gap: var(--space-2);
  padding: var(--space-4);
  left: 50%;
  transform: translateX(-50%);
}

/* Tailwind CSS utilities */

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bottom-0 {
  bottom: 0px;
}

.right-\[-1px\] {
  right: -1px;
}

.top-0 {
  top: 0px;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[2147483647\] {
  z-index: 2147483647;
}

.-mr-\[6px\] {
  margin-right: -6px;
}

.ml-auto {
  margin-left: auto;
}

.mr-\[-6px\] {
  margin-right: -6px;
}

.mr-auto {
  margin-right: auto;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.\!table {
  display: table !important;
}

.table {
  display: table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-header-group {
  display: table-header-group;
}

.table-row {
  display: table-row;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[12px\] {
  height: 12px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[500px\] {
  height: 500px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.min-h-\[var\(--text-field-height\)\] {
  min-height: var(--text-field-height);
}

.w-\[3px\] {
  width: 3px;
}

.w-full {
  width: 100%;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-\[14px\] {
  min-width: 14px;
}

.min-w-\[35px\] {
  min-width: 35px;
}

.min-w-\[50px\] {
  min-width: 50px;
}

.min-w-\[65px\] {
  min-width: 65px;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.translate-y-px {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-col-resize {
  cursor: col-resize;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.resize {
  resize: both;
}

.scroll-my-52 {
  scroll-margin-top: 13rem;
  scroll-margin-bottom: 13rem;
}

.list-none {
  list-style-type: none;
}

.columns-3 {
  -moz-columns: 3;
       columns: 3;
}

.flex-wrap {
  flex-wrap: wrap;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-none {
  border-radius: 0px;
}

.\!rounded-b-none {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.border {
  border-width: 1px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-accent-a7 {
  border-color: var(--indigo-a7);
}

.border-neutral-5 {
  border-color: var(--gray-5);
}

.border-neutral-6 {
  border-color: var(--gray-6);
}

.bg-accent-3 {
  background-color: var(--indigo-3);
}

.bg-accent-a3 {
  background-color: var(--indigo-a3);
}

.bg-neutral-12 {
  background-color: var(--gray-12);
}

.bg-neutral-2 {
  background-color: var(--gray-2);
}

.bg-neutral-3 {
  background-color: var(--gray-3);
}

.bg-neutral-5 {
  background-color: var(--gray-5);
}

.bg-neutral-7 {
  background-color: var(--gray-7);
}

.bg-neutral-a2 {
  background-color: var(--gray-a2);
}

.bg-neutral-surface {
  background-color: var(--gray-surface);
}

.p-0 {
  padding: 0px;
}

.text-center {
  text-align: center;
}

.font {
  font-family: var(--default-font-family);
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[24px\] {
  font-size: 24px;
}

.italic {
  font-style: italic;
}

.leading-\[12px\] {
  line-height: 12px;
}

.leading-\[16px\] {
  line-height: 16px;
}

.leading-\[20px\] {
  line-height: 20px;
}

.leading-\[24px\] {
  line-height: 24px;
}

.text-neutral-11 {
  color: var(--gray-11);
}

.text-neutral-8 {
  color: var(--gray-8);
}

.text-neutral-a12 {
  color: var(--gray-a12);
}

.text-neutral-a7 {
  color: var(--gray-a7);
}

.text-transparent {
  color: transparent;
}

.underline {
  text-decoration-line: underline;
}

.overline {
  text-decoration-line: overline;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hover\:bg-neutral-a1:hover {
  background-color: var(--gray-a1);
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.group\/columnHeaderCell:hover .group-hover\/columnHeaderCell\:block {
  display: block;
}

.\[\&\>\*\]\:min-h-\[calc\(var\(--text-field-height\)-4px\)\]>* {
  min-height: calc(var(--text-field-height) - 4px);
}

.\[\&\>\*\]\:pr-1>* {
  padding-right: 0.25rem;
}

.\[\&\>input\]\:invisible>input {
  visibility: hidden;
}

.\[\&\>input\]\:mr-\[36px\]>input {
  margin-right: 36px;
}

.\[\&\>input\]\:w-auto>input {
  width: auto;
}

.\[\&\>input\]\:min-w-48>input {
  min-width: 12rem;
}

.\[\&\>input\]\:flex-1>input {
  flex: 1 1 0%;
}

.\[\&\>input\]\:pl-2>input {
  padding-left: 0.5rem;
}
