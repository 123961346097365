/* The order of the import is important as described [here](https://ui.scale.com/themes/docs/overview/tailwind-css-integration#common-issues). */

/* Tailwind CSS */
@tailwind components;
@tailwind utilities;

/* Scale UI Radix CSS */
@import '@scale/scaleui-radix/dist/styles.css';

/* My app CSS */
:root {
  --header-height: 48px;
  --quick-nav-display: none;
  --component-highlights-item-width: calc(100vw - 100px);
  --developer-experience-code-block-background: var(--slate-12);

  /* Use the defaults from the Radix theme instead of Scale ones */
  --default-font-size: var(--font-size-3);
  --default-font-weight: var(--font-weight-regular);
  --default-line-height: 1.5;
  --default-letter-spacing: 0em;
}

:is(:root, .light-theme, .light) {
  --hero-card-background-color: rgba(255, 255, 255, 0.8);
}

:is(.dark-theme, .dark) {
  --hero-card-background-color: #1b1d1eb3;
}

:where(.dark-theme, .dark) :where(.dark-theme, .dark) {
  --developer-experience-code-block-background: var(--black-a9);
}

@media (min-width: 768px) {
  :root {
    --component-highlights-item-width: 325px;
  }
}

@media (min-width: 1280px) {
  :root {
    --component-highlights-image-height: 850px;
  }
}

@media (min-width: 1440px) {
  :root {
    --quick-nav-display: block;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-width: 320px;
  color: var(--gray-12);
  -webkit-text-size-adjust: 100%;

  /* Provide a default family for portalled elements not using themes (e.g. Primitives examples) */
  font-family: Inter, system-ui, sans-serif;
}

svg {
  display: block;
  vertical-align: middle;
  overflow: visible;
}

pre {
  margin: 0;
}

#__next {
  position: relative;
  z-index: 0;
}
