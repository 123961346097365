.HeaderRoot {
  height: var(--header-height);
}

.HeaderInner {
  min-width: 320px;
  position: fixed;
  height: inherit;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  user-select: none;
  box-shadow: 0 1px transparent;
  transition: background-color 180ms, box-shadow 180ms;
}

/* Faster transition when the header gains background (and slower when it loses it) */
.HeaderRoot[data-scroll-state='scrolling-down'] .HeaderInner {
  transition-duration: 30ms, 120ms;
}

.HeaderRoot:not(.ghost[data-scroll-state='at-top']) > .HeaderInner {
  background-color: var(--color-background);
  box-shadow: 0 1px var(--gray-a4);
}

:where([data-mobile-menu-open='false']) .HeaderProductLinksContainer {
  display: none;
}

:where([data-mobile-menu-open='true']) .HeaderProductLinksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderProductLinksContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% - 300px);
  width: 600px;
}

@media (min-width: 768px) {
  .HeaderProductLinksContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.HeaderProductLink {
  cursor: pointer;
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: var(--font-size-2);
  line-height: 16px;
  letter-spacing: var(--letter-spacing-2);
  position: relative;
  color: var(--gray-a11);
  outline: 0;

  padding-left: 1px;
  padding-right: 1px;
}

/* Downscale on small devices */
@media (max-width: 369px) {
  .HeaderProductLink {
    font-size: 13px;
  }
}

@media (min-width: 440px) {
  .HeaderProductLink {
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media (hover: hover) {
  .HeaderProductLink:hover {
    color: var(--gray-12);
  }
}

.HeaderProductLink:focus-visible {
  color: var(--gray-12);
}

.HeaderProductLink[data-state='active'] {
  color: var(--color-background);
}

.HeaderProductLinkInner,
.HeaderProductLinkInnerHidden {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
  padding-left: var(--space-1);
  padding-right: var(--space-1);
  border-radius: 999px;
}

:where(.HeaderProductLink[data-state='active']) .HeaderProductLinkInner,
:where(.HeaderProductLink[data-state='active']) .HeaderProductLinkInnerHidden {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

@media (min-width: 370px) {
  .HeaderProductLinkInner,
  .HeaderProductLinkInnerHidden {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }
}
@media (min-width: 440px) {
  .HeaderProductLinkInner,
  .HeaderProductLinkInnerHidden {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.HeaderProductLinkInner {
  position: absolute;
  letter-spacing: 0.01em;
}

.HeaderProductLinkInnerHidden {
  visibility: hidden;
  font-weight: bold;
  letter-spacing: -0.01em;
}

@media (hover: hover) {
  .HeaderProductLink:hover .HeaderProductLinkInner {
    background-color: var(--gray-a3);
  }
}

.HeaderProductLink:focus-visible .HeaderProductLinkInner {
  box-shadow: 0 0 0 2px var(--accent-a8);
}

@media (hover: hover) {
  .HeaderProductLink:focus-visible:where(:hover) .HeaderProductLinkInner {
    background-color: var(--accent-a3);
  }
}

.HeaderProductLink[data-state='active'] .HeaderProductLinkInner {
  font-weight: bold;
  letter-spacing: -0.01em;
  background-color: var(--gray-12);
}

.HeaderProductLink[data-state='active']::before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.HeaderThemeToggleContainer {
  display: flex;
  align-items: center;
}

@media (max-width: 519px) {
  [data-mobile-menu-open='true'] .HeaderThemeToggleContainer {
    display: none;
  }
}
